import React, { useState, useEffect } from 'react';
import { Grid, Paper } from "@mui/material";
import DutyTable from "../components/callDutyScheduler/dutyTable.component";
import translationsEn from "../i18n/translations_en";
import DutyTableLegend from "../components/callDutyScheduler/dutyTableLegend.component";
import DutyActionsMenu from "../components/callDutyScheduler/dutyActionsMenu.component";
import NotificationBar from '../components/notificationBar/notificationBar.component';
import MonthNavigator from "../components/monthNavigator/monthNavigator.component";
import HeadingTitle from "../components/headingTitle/headingTitle.component";
import DutyFilterPanel from "../components/callDutyScheduler/dutyFilterPanel.component";
import { createActiveCallDuty, updateActiveCallDuty, getAllCallDutyDays, getAllCallDutyRecords } from '../api/activeDutyApi';
import { getFullTimeEmployees } from '../api/employeeApi';
import moment from 'moment';
import ActiveDutyModal from '../components/callDutyScheduler/modals/activeDutyModal.component';

const CallDutyScheduler = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<'create' | 'update' | 'scheduler'>('scheduler');
  const [selectedDuty, setSelectedDuty] = useState(null);
  const [callDutyDays, setCallDutyDays] = useState([]);
  const [callDutyRecords, setCallDutyRecords] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [filter, setFilter] = useState({ month: '', year: '', company: '' });

  useEffect(() => {
    fetchData();
  }, [currentDate, filter]);

  useEffect(() => {
    fetchFullTimeEmployees();
  }, []);

  const fetchData = async () => {
    moment.locale("en");
    let periodFilter;

    if (filter.month) {
      const selectedYear = filter.year ? Number(filter.year) : new Date().getFullYear();
      const selectedMonth = Number(filter.month) - 1;

      const lastWeekStartDate = moment().year(selectedYear).month(selectedMonth).endOf('month').startOf('isoWeek').toDate();
      periodFilter = { dateFrom: lastWeekStartDate, dateTo: moment(lastWeekStartDate).endOf('isoWeek').toDate() };
    } else {
      periodFilter = { dateFrom: getStartDateOfWeek(), dateTo: getEndDateOfWeek() };
    }

    const filterParams = { periodDto: periodFilter, companyId: filter.company || null };

    try {
      const callDutyDaysResponse = await getAllCallDutyDays(filterParams);
      const callDutyRecordsResponse = await getAllCallDutyRecords(filterParams);
      setCallDutyDays(callDutyDaysResponse.data);
      setCallDutyRecords(callDutyRecordsResponse.data);
    } catch (error) {
      console.error('Failed to fetch call duty days', error);
    }
  };

  const fetchFullTimeEmployees = async () => {
    try {
      const teamsFilter: any = [];
      const employees = await getFullTimeEmployees(teamsFilter);

      // Map the response to get the necessary fields
      const formattedPersons = employees.data.map((emp: any) => ({
        id: emp.employeeDto.id,
        firstName: emp.employeeDto.firstName,
        lastName: emp.employeeDto.lastName,
        companyId: emp.employeeDto.companyDto.id,
      }));
      setEmployees(formattedPersons);
    } catch (error) {
      console.error('Failed to fetch full-time employees:', error);
    }
  };

  const getStartDateOfWeek = () => {
    const start = new Date(currentDate);
    start.setDate(start.getDate() - start.getDay());
    return start.toISOString().split('T')[0];
  };

  const getEndDateOfWeek = () => {
    const end = new Date(currentDate);
    end.setDate(end.getDate() - end.getDay() + 6);
    return end.toISOString().split('T')[0];
  };

  const handleTodayClick = () => {
    setCurrentDate(new Date());
  };

  const handlePrev = (navigateBy: any) => {
    if (navigateBy === 'month') {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    } else {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
    }
  };

  const handleNext = (navigateBy: any) => {
    if (navigateBy === 'month') {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    } else {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
    }
  };

  const handleCellClick = (duty: any, mode: any) => {
    setSelectedDuty(duty);
    setModalMode(mode);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalSubmit = async (data: any) => {
    try {
      await createActiveCallDuty(data);
      fetchData(); // Refresh data after submission
    } catch (error) {
      console.error(`Failed to create active call duty`, error);
    }
  };

  const handleSearch = (searchFilter: any) => {
    setFilter(searchFilter);
    const selectedYear = searchFilter.year ? searchFilter.year : new Date().getFullYear();
    setCurrentDate(new Date(selectedYear, searchFilter.month ? searchFilter.month : currentDate.getMonth()));
  };

  const weekDays = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() - currentDate.getDay() + i);
    return date;
  });

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <HeadingTitle text={translationsEn.sidebar.callDuty} />
          <MonthNavigator
            currentDate={currentDate}
            onPrev={() => handlePrev('week')}
            onNext={() => handleNext('week')}
            navigateBy="week"
          />
          <DutyActionsMenu persons={employees} onActionComplete={fetchData}/>
        </Grid>
      </Paper>
      <DutyFilterPanel onSearch={handleSearch} />
      <DutyTable
        callDutyDays={callDutyDays}
        callDutyRecords={callDutyRecords}
        onCellClick={handleCellClick}
        weekDays={weekDays}
        handleTodayClick={handleTodayClick}
      />
      <DutyTableLegend />
      <NotificationBar message={'Export data to PDF can only be made if day and company are selected'}/>
      {selectedDuty && (
        <ActiveDutyModal
          open={isModalOpen}
          onClose={handleModalClose}
          onSubmit={handleModalSubmit}
          initialData={selectedDuty}
          persons={employees}
          mode={modalMode}
        />
      )}
    </div>
  );
};

export default CallDutyScheduler;

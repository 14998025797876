import React, { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  Autocomplete,
  Checkbox,
  TextField,
  FormControl,
} from "@mui/material";
import { Clear, PersonAdd } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import {
  getAllCompanies,
  getEmployeesByTeam,
  getOverviewTeams,
} from "../../api/overviewApi";
import { IEmployee, ITeams } from "../../interfaces/overview.interface";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface AddNewEmployeeProps {
  open: boolean;
  handleClose: () => void;
  onSaveSelectedEmployees: (selectedIds: string[]) => void;
  selectedUserIds: string[];
  children?: React.ReactNode;
}

const AddNewEmployee: React.FC<AddNewEmployeeProps> = ({
  open,
  handleClose,
  onSaveSelectedEmployees,
  selectedUserIds,
}) => {
  const [companies, setCompanies] = useState([] as any);
  const [teams, setTeams] = useState<ITeams[]>([]);
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [selectedTeams, setSelectedTeams] = useState([] as any);
  const [selectedCompanies, setSelectedCompanies] = useState([] as any);
  const [selectedEmployees, setSelectedEmployees] = useState([] as any);
  const [isEmployeeSelectDisabled, setIsEmployeeSelectDisabled] =
    useState<boolean>(true);

  //get all companies for SelectInput when modal is open
  useEffect(() => {
    if (open) {
      getAllCompanies()
        .then(setCompanies)
        .catch((error) => console.error("Failed to fetch companies:", error));
    }
  }, [open]);

  //get all teams for SelectInput when modal is open and company selected
  useEffect(() => {
    if (open && selectedCompanies.length > 0) {
      const companyIds = selectedCompanies.map((company: any) => company.id);
      const teamsFilter = { companyIds: companyIds, employmentStatusId: null };
      getOverviewTeams(teamsFilter)
        .then((fetchedTeams) => setTeams(fetchedTeams.data as any))
        .catch((error) => console.error("Failed to fetch teams:", error));
    }
  }, [open, selectedCompanies]);

  //get all employees for SelectInput when team selected
  useEffect(() => {
    if (open && selectedTeams.length > 0) {
      const teamIds = selectedTeams.map((team: any) => team.id);
      getEmployeesByTeam(teamIds)
        .then((response) => {
          setEmployees(response)
        })
        .catch((error) =>
          console.error("Failed to fetch employees for teams:", error)
        );
    }
  }, [open, selectedTeams]);

  useEffect(() => {
    setIsEmployeeSelectDisabled(
      !selectedCompanies.length || !selectedTeams.length
    );
  }, [selectedCompanies, selectedTeams]);

  useEffect(() => {
    if (employees && selectedUserIds) {
      const preSelectedEmployees = employees.filter((employee: any) =>
        employee && selectedUserIds.includes(employee.id)
      );
      setSelectedEmployees(preSelectedEmployees);
    }
  }, [employees, selectedUserIds]);

  const handleModalClose = () => {
    setSelectedTeams([]);
    setSelectedCompanies([]);
    setEmployees([]);
    setSelectedEmployees([]);
    handleClose();
  };

  const handleSave = () => {
    const newSelectedIds = selectedEmployees.map(
      (employee: any) => employee.id
    );
    const retainedIds = selectedUserIds.filter(
      (id: any) => !employees.map((e) => e.id).includes(id)
    );
    const finalSelectedIds = Array.from(
      new Set([...retainedIds, ...newSelectedIds])
    );
    onSaveSelectedEmployees(finalSelectedIds);
    handleModalClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "20%",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <PersonAdd style={{ marginRight: "5px" }} />
              <Typography>{translationsEn.teamRequest.name}</Typography>
            </Box>

            <Clear onClick={handleModalClose} />
          </Box>
          <hr></hr>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormControl style={{ width: "100%" }}>
              <Autocomplete
                multiple
                id="company-select-autocomplete"
                options={companies} // Assuming 'companies' is the array of company objects
                getOptionLabel={(option) => option.name} // Use the 'name' property to display options
                value={selectedCompanies}
                onChange={(event, newValue) => {
                  setSelectedCompanies(newValue); // Update the state with the new selection
                  setTeams([]);
                  setSelectedTeams([]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Companies"
                    placeholder="Search and select"
                  />
                )}
                style={{ margin: "10px 0" }}
              />
            </FormControl>

            <FormControl style={{ width: "100%" }}>
              <Autocomplete
                disabled={selectedCompanies === 0 || teams.length === 0} // Disable if no company is selected or no teams are fetched
                multiple
                id="team-select-autocomplete"
                options={teams}
                getOptionLabel={(option) => option.name} // Assuming your team object has a 'name' property
                value={selectedTeams}
                onChange={(event, newValue) => {
                  setSelectedTeams(newValue);
                  setEmployees([]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Teams"
                    placeholder="Search and select"
                  />
                )}
                style={{ margin: "10px 0" }} // Adjusted for consistency
              />
            </FormControl>

            <FormControl style={{ width: "100%" }}>
              <Autocomplete
                disabled={isEmployeeSelectDisabled}
                multiple
                id="checkboxes-tags-demo"
                options={employees}
                disableCloseOnSelect
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName}`
                }
                value={selectedEmployees} // Bind the component to the state
                onChange={(event, newValue) => {
                  setSelectedEmployees(newValue);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                    />
                    {`${option.firstName} ${option.lastName}`}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Employees"
                    placeholder="Search and select"
                  />
                )}
                style={{ width: "100%", marginTop: "10px" }}
              />
            </FormControl>
          </Box>
          <hr></hr>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={handleSave}
              variant="contained"
              sx={{
                backgroundColor: themeOptions.palette.confirm.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.confirm.hover,
                },
              }}
            >
              {translationsEn.buttons.save}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default AddNewEmployee;

import { ICv, IEmployee } from "../interfaces/overview.interface";
import APICall from "./axios";

const toQueryString = (config: any) => {
  const query: Array<string> = [];
  const param = config.params ? config.params : config;
  Object.keys(param).forEach((key) => {
    if (
      key !== "" &&
      param[key] !== null &&
      param[key] !== undefined &&
      param[key] !== ""
    ) {
      query.push(`${key}=${encodeURIComponent(param[key])}`);
    }
  });
  return `?${query.join("&")}`;
};

const handleApiResponse = (response: any) => {
  if (response.data) {
    return response.data;
  } else {
    throw new Error("Data is missing or in an unexpected format.");
  }
};

export const createCv = async ({
  employeeId,
}: {
  employeeId: number;
}): Promise<any> => {
  try {
    const payload = {
      employeeId: employeeId,
    };

    const response = await APICall.post<any>("api/cv-management/cvs", payload);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Team data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error creating team`, error);
    throw error;
  }
};

export const getCv = async (employeeId: number): Promise<any> => {
  try {
    const { data: apiResponse } = await APICall.get<{
      data: any;
      error: string;
    }>(`api/cv-management/cvs/employee/${employeeId}`);

    if (apiResponse.data) {
      return apiResponse.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(
      `Error fetching details for employee ID ${employeeId}:`,
      error
    );
    throw error;
  }
};

export const getCvForEmployee = async (cvId: number): Promise<IEmployee> => {
  try {
    const { data: apiResponse } = await APICall.get<{
      data: IEmployee;
      error: string;
    }>(`api/cv-management/cvs/${cvId}`);

    if (apiResponse.data) {
      return apiResponse.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching details for employee ID ${cvId}:`, error);
    throw error;
  }
};

export const getAllCvs = async (): Promise<any[]> => {
  try {
    const response = await APICall.get<{
      data: any[];
      error: string;
    }>(`api/cv-management/cvs/admin?employeeIds`);

    const { data, error } = response.data;

    if (error) {
      console.error("Failed to fetch educations with error:", error);
      throw new Error(error);
    }

    if (Array.isArray(data)) {
      return data;
    } else if (data) {
      return [data];
    } else {
      throw new Error("Data array is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Failed to fetch educations:", error);
    throw error;
  }
};

export const createSummary = async ({
  cvId,
  text,
}: {
  cvId: number;
  text: string;
}): Promise<any> => {
  try {
    const payload = {
      cvId: cvId,
      text: text,
    };

    const response = await APICall.post<any>(
      "api/cv-management/summaries",
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Summaries data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error creating summaries`, error);
    throw error;
  }
};

export const getSummaries = async (cvId: number): Promise<any | null> => {
  try {
    const { data: apiResponse } = await APICall.get<{
      data: ICv;
      error: string;
    }>(`api/cv-management/summaries?cvId=${cvId}`);

    if (apiResponse.data) {
      return apiResponse.data;
    } else {
      throw new Error("Summaries data is missing or in an unexpected format.");
    }
  } catch (error) {
    return null;
  }
};

export const addSummary = async ({
  cvId,
  text,
}: {
  cvId: number;
  text: string;
}): Promise<any> => {
  try {
    const payload = {
      cvId: cvId,
      text: text,
    };

    const response = await APICall.post<any>(
      "api/cv-management/summaries",
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Team data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error creating team`, error);
    throw error;
  }
};
export const updateSummary = async ({
  id,
  cvId,
  text,
}: {
  id: number;
  cvId: number;
  text: string;
}): Promise<any> => {
  try {
    const payload = {
      cvId: cvId,
      text: text,
    };

    const response = await APICall.put<any>(
      `api/cv-management/summaries/${id}`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Summary data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error updating summary`, error);
    throw error;
  }
};

export const addEducation = async ({
  cvId,
  school,
  degree,
  fieldOfStudy,
  startDate,
  endDate,
  description,
}: {
  cvId: number;
  school: string;
  degree: string;
  fieldOfStudy: string;
  startDate: Date | null;
  endDate: Date | null;
  description: string;
}): Promise<any> => {
  try {
    const formatYear = (date: Date | null) => {
      return date ? date.getFullYear() : null;
    };

    const payload = {
      cvId,
      school,
      degree,
      fieldOfStudy,
      startDate: formatYear(startDate),
      endDate: formatYear(endDate),
      description,
    };

    const response = await APICall.post<any>(
      "api/cv-management/educations",
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Education data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Error creating education", error);
    throw error;
  }
};
export const getEducation = async (cvId: number): Promise<any[]> => {
  try {
    const response = await APICall.get<{
      data: any[];
      error: string;
    }>(`api/cv-management/educations?cvId=${cvId}`);

    const { data, error } = response.data;

    if (error) {
      console.error("Failed to fetch educations with error:", error);
      throw new Error(error);
    }

    if (Array.isArray(data)) {
      return data;
    } else if (data) {
      return [data];
    } else {
      throw new Error("Data array is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Failed to fetch educations:", error);
    throw error;
  }
};

export const updateEducation = async ({
  id,
  cvId,
  school,
  degree,
  fieldOfStudy,
  startDate,
  endDate,
  description,
}: {
  id: number;
  cvId: number;
  school: string;
  degree: string;
  fieldOfStudy: string;
  startDate: Date | null;
  endDate: Date | null;
  description: string;
}): Promise<any> => {
  try {
    const formatYear = (date: Date | null) => {
      return date ? date.getFullYear() : null;
    };
    const payload = {
      cvId,
      school,
      degree,
      fieldOfStudy,
      startDate: formatYear(startDate),
      endDate: formatYear(endDate),
      description,
    };

    const response = await APICall.put<any>(
      `/api/cv-management/educations/${id}`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Summary data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error updating summary`, error);
    throw error;
  }
};

export const addLanguage = async ({
  cvId,
  languageId,
  proficiency,
}: {
  cvId: number;
  languageId: number;
  proficiency: number;
}): Promise<any> => {
  try {
    const payload = {
      cvId,
      languageId,
      proficiency,
    };

    const response = await APICall.post<any>(
      "api/cv-management/language-proficiencies",
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Education data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Error creating education", error);
    throw error;
  }
};
export const getLanguage = async (): Promise<any[]> => {
  try {
    const response = await APICall.get<{
      data: any[];
      error: string;
    }>(`api/cv-management/languages`);

    const { data, error } = response.data;

    if (error) {
      console.error("Failed to fetch educations with error:", error);
      throw new Error(error);
    }

    if (Array.isArray(data)) {
      return data;
    } else if (data) {
      return [data];
    } else {
      throw new Error("Data array is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Failed to fetch educations:", error);
    throw error;
  }
};

export const updatedLanguage = async ({
  id,
  cvId,
  languageId,
  proficiency,
}: {
  id: number;
  cvId: number;
  languageId: number;
  proficiency: number;
}): Promise<any> => {
  try {
    const formatYear = (date: Date | null) => {
      return date ? date.getFullYear() : null;
    };
    const payload = {
      cvId,
      languageId,
      proficiency,
    };

    const response = await APICall.put<any>(
      `/api/cv-management/language-proficiencies/${id}`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Language data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error updating language`, error);
    throw error;
  }
};
export const getLanguageForUser = async (cvId: number): Promise<any[]> => {
  try {
    const response = await APICall.get<{
      data: any[];
      error: string;
    }>(`api/cv-management/language-proficiencies?cvId=${cvId}`);

    const { data, error } = response.data;

    if (error) {
      console.error("Failed to fetch educations with error:", error);
      throw new Error(error);
    }

    if (Array.isArray(data)) {
      return data;
    } else if (data) {
      return [data];
    } else {
      throw new Error("Data array is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Failed to fetch educations:", error);
    throw error;
  }
};
export const addExperience = async ({
  cvId,
  company,
  title,
  contact,
  startDate,
  endDate,
  description,
}: {
  cvId: number;
  company: string;
  title: string;
  contact: string;
  startDate: string | null;
  endDate: string | null;
  description: string;
}): Promise<any> => {
  try {
    const payload = {
      cvId,
      company,
      title,
      contact,
      startDate,
      endDate,
      description,
    };

    const response = await APICall.post<any>(
      "api/cv-management/experiences",
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Education data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Error creating education", error);
    throw error;
  }
};

export const updatedExperience = async ({
  id,
  cvId,
  company,
  title,
  contact,
  startDate,
  endDate,
  description,
}: {
  id: number;
  cvId: number;
  company: string;
  title: string;
  contact: string;
  startDate: string | null;
  endDate: string | null;
  description: string;
}): Promise<any> => {
  const formatYear = (date: Date | null) => {
    return date ? date.getFullYear() : null;
  };
  try {
    const payload = {
      cvId,
      company,
      title,
      contact,
      startDate,
      endDate,
      description,
    };

    const response = await APICall.put<any>(
      `/api/cv-management/experiences/${id}`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Experience data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error updating experience`, error);
    throw error;
  }
};
export const getExperiences = async (cvId: number): Promise<any[]> => {
  try {
    const response = await APICall.get<{
      data: any[];
      error: string;
    }>(`api/cv-management/experiences?cvId=${cvId}`);

    const { data, error } = response.data;

    if (error) {
      console.error("Failed to fetch educations with error:", error);
      throw new Error(error);
    }

    if (Array.isArray(data)) {
      return data;
    } else if (data) {
      return [data];
    } else {
      throw new Error("Data array is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Failed to fetch educations:", error);
    throw error;
  }
};
export const addProject = async ({
  cvId,
  client,
  descriptionOfResponsibilities,
  name,
  startDate,
  endDate,
  role,
  shortDescriptionOfTheProject,
  techStack,
}: {
  cvId: number;
  client: string;
  descriptionOfResponsibilities: string;
  name: string;
  startDate: string | null;
  endDate: string | null;
  role: string;
  shortDescriptionOfTheProject: string;
  techStack: string;
}): Promise<any> => {
  try {
    const payload = {
      cvId,
      client,
      descriptionOfResponsibilities,
      name,
      startDate,
      endDate,
      role,
      shortDescriptionOfTheProject,
      techStack,
    };

    const response = await APICall.post<any>(
      "api/cv-management/projects",
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Education data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Error creating education", error);
    throw error;
  }
};

export const updatedProject = async ({
  id,
  cvId,
  client,
  descriptionOfResponsibilities,
  name,
  startDate,
  endDate,
  role,
  shortDescriptionOfTheProject,
  techStack,
}: {
  id: number;
  cvId: number;
  client: string;
  descriptionOfResponsibilities: string;
  name: string;
  startDate: string | null;
  endDate: string | null;
  role: string;
  shortDescriptionOfTheProject: string;
  techStack: string;
}): Promise<any> => {
  const formatYear = (date: Date | null) => {
    return date ? date.getFullYear() : null;
  };
  try {
    const payload = {
      cvId,
      client,
      descriptionOfResponsibilities,
      name,
      startDate,
      endDate,
      role,
      shortDescriptionOfTheProject,
      techStack,
    };

    const response = await APICall.put<any>(
      `/api/cv-management/projects/${id}`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Experience data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error updating experience`, error);
    throw error;
  }
};
export const getProjects = async (cvId: number): Promise<any[]> => {
  try {
    const response = await APICall.get<{
      data: any[];
      error: string;
    }>(`api/cv-management/projects?cvId=${cvId}`);

    const { data, error } = response.data;

    if (error) {
      console.error("Failed to fetch projects with error:", error);
      throw new Error(error);
    }

    if (Array.isArray(data)) {
      return data;
    } else if (data) {
      return [data];
    } else {
      throw new Error("Data array is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Failed to fetch educations:", error);
    throw error;
  }
};
export const addCertificates = async ({
  cvId,
  issuer,
  url,
  name,
  startDate,
  endDate,
}: {
  cvId: number;
  issuer: string;
  url: string;
  name: string;
  startDate: string | null;
  endDate: string | null;
}): Promise<any> => {
  try {
    const payload = {
      cvId,
      issuer,
      url,
      name,
      startDate,
      endDate,
    };

    const response = await APICall.post<any>(
      "api/cv-management/certificates",
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Education data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Error creating education", error);
    throw error;
  }
};
export const updatedCertificate = async ({
  id,
  cvId,
  issuer,
  url,
  name,
  startDate,
  endDate,
}: {
  id: number;
  cvId: number;
  issuer: string;
  url: string;
  name: string;
  startDate: string | null;
  endDate: string | null;
}): Promise<any> => {
  try {
    const payload = {
      cvId,
      issuer,
      url,
      name,
      startDate,
      endDate,
    };

    const response = await APICall.put<any>(
      `/api/cv-management/certificates/${id}`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Certificates data is missing or in an unexpected format."
      );
    }
  } catch (error) {
    console.error(`Error updating certificates`, error);
    throw error;
  }
};
export const getCertificates = async (cvId: number): Promise<any[]> => {
  try {
    const response = await APICall.get<{
      data: any[];
      error: string;
    }>(`api/cv-management/certificates?cvId=${cvId}`);

    const { data, error } = response.data;

    if (error) {
      console.error("Failed to fetch certificates with error:", error);
      throw new Error(error);
    }

    if (Array.isArray(data)) {
      return data;
    } else if (data) {
      return [data];
    } else {
      throw new Error("Data array is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Failed to fetch certificates:", error);
    throw error;
  }
};
export const addPublication = async ({
  cvId,
  dateOfEvent,
  event,
  name,
  url,
}: {
  cvId: number;
  event: string;
  url: string;
  name: string;
  dateOfEvent: string | null;
}): Promise<any> => {
  try {
    const payload = {
      cvId,
      event,
      url,
      name,
      dateOfEvent,
    };

    const response = await APICall.post<any>(
      "api/cv-management/publications",
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Education data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Error creating education", error);
    throw error;
  }
};

export const updatePublication = async ({
  id,
  cvId,
  dateOfEvent,
  event,
  name,
  url,
}: {
  id: number;
  cvId: number;
  event: string;
  url: string;
  name: string;
  dateOfEvent: string | null;
}): Promise<any> => {
  try {
    const payload = {
      cvId,
      event,
      url,
      name,
      dateOfEvent,
    };

    const response = await APICall.put<any>(
      `/api/cv-management/publications/${id}`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Certificates data is missing or in an unexpected format."
      );
    }
  } catch (error) {
    console.error(`Error updating certificates`, error);
    throw error;
  }
};
export const getPublications = async (cvId: number): Promise<any[]> => {
  try {
    const response = await APICall.get<{
      data: any[];
      error: string;
    }>(`api/cv-management/publications?cvId=${cvId}`);

    const { data, error } = response.data;

    if (error) {
      console.error("Failed to fetch publications with error:", error);
      throw new Error(error);
    }

    if (Array.isArray(data)) {
      return data;
    } else if (data) {
      return [data];
    } else {
      throw new Error("Data array is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Failed to fetch publications:", error);
    throw error;
  }
};
export const getAllSkills = async (): Promise<any[]> => {
  try {
    const response = await APICall.get<{
      data: any[];
      error: string;
    }>(`api/cv-management/skills`);

    const { data, error } = response.data;

    if (error) {
      console.error("Failed to fetch publications with error:", error);
      throw new Error(error);
    }

    if (Array.isArray(data)) {
      return data;
    } else if (data) {
      return [data];
    } else {
      throw new Error("Data array is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Failed to fetch publications:", error);
    throw error;
  }
};
export const addSkillToCv = async (
  cvId: number,
  skillIds: number[]
): Promise<any> => {
  try {
    const payload = { skillIds };
    const response = await APICall.post<any>(
      `api/cv-management/cv-skills/cv/${cvId}`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Skill data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Error adding skill to CV", error);
    throw error;
  }
};
export const createSkill = async ({ name }: { name: string }): Promise<any> => {
  try {
    const payload = {
      name,
    };

    const response = await APICall.post<any>(
      `api/cv-management/skills`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Skill data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Error creating skill", error);
    throw error;
  }
};

export const updateSkill = async ({
  id,
  cvId,
  skillIds,
}: {
  id: number;
  cvId: number;
  skillIds: number[];
}): Promise<any> => {
  try {
    const payload = {
      cvId,
      skillIds,
    };

    const response = await APICall.put<any>(
      `/api/cv-management/skills/${id}`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Certificates data is missing or in an unexpected format."
      );
    }
  } catch (error) {
    console.error(`Error updating certificates`, error);
    throw error;
  }
};
export const getSkillsCv = async (cvId: number): Promise<any[]> => {
  try {
    const response = await APICall.get<{
      data: any[];
      error: string;
    }>(`api/cv-management/cv-skills/cv/${cvId}`);

    const { data, error } = response.data;

    if (error) {
      console.error("Failed to fetch skills with error:", error);
      throw new Error(error);
    }

    if (Array.isArray(data)) {
      return data;
    } else if (data) {
      return [data];
    } else {
      throw new Error("Data array is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Failed to fetch skills:", error);
    throw error;
  }
};
export const addHonors = async ({
  cvId,
  issueDate,
  issuer,
  name,
  description,
}: {
  cvId: number;
  issueDate: string;
  issuer: string;
  name: string;
  description: string;
}): Promise<any> => {
  try {
    const payload = {
      cvId,
      issueDate,
      issuer,
      name,
      description,
    };

    const response = await APICall.post<any>(
      "api/cv-management/honors-and-awards",
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Education data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Error creating education", error);
    throw error;
  }
};

export const updateHonors = async ({
  id,
  cvId,
  issueDate,
  issuer,
  name,
  description,
}: {
  id: number;
  cvId: number;
  issueDate: string;
  issuer: string;
  name: string;
  description: string;
}): Promise<any> => {
  try {
    const payload = {
      cvId,
      issueDate,
      issuer,
      name,
      description,
    };

    const response = await APICall.put<any>(
      `/api/cv-management/honors-and-awards/${id}`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Certificates data is missing or in an unexpected format."
      );
    }
  } catch (error) {
    console.error("Error updating certificates", error);
    throw error;
  }
};
export const getHonors = async (cvId: number): Promise<any[]> => {
  try {
    const response = await APICall.get<{
      data: any[];
      error: string;
    }>(`api/cv-management/honors-and-awards?cvId=${cvId}`);

    const { data, error } = response.data;

    if (error) {
      console.error("Failed to fetch honors with error:", error);
      throw new Error(error);
    }

    if (Array.isArray(data)) {
      return data;
    } else if (data) {
      return [data];
    } else {
      throw new Error("Data array is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error("Failed to fetch honors:", error);
    throw error;
  }
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  TablePagination,
  Autocomplete,
  TextField,
  Tooltip,
} from "@mui/material";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";
import DeleteModal from "../modals/confirmationsModal/deleteModal";
import {
  createActiveCallDuty,
  updateActiveCallDuty,
  deleteActiveCallDuty,
} from "../api/activeDutyApi";
import ActiveDutyModal from "../components/callDutyScheduler/modals/activeDutyModal.component";
import { getActiveDuty, getAllEmployees } from "../api/employeeApi";
import moment from "moment";

export default function ActiveDutiesOverview() {
  const [modalStates, setModalStates] = useState({
    newDuty: false,
    edit: false,
    delete: false,
  });
  const [loading, setLoading] = React.useState<boolean>(true);
  const [modalMode, setModalMode] = useState<"create" | "update" | "scheduler">(
    "create"
  );
  const [selectedDuty, setSelectedDuty] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [size, setPageSize] = useState(5);
  const [allEmployees, setAllEmployees] = useState<any>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const emptyRows = tableData ? size - tableData!.length : 0;
  const [selectedMonth, setSelectedMonth] = useState<string | number>(
    new Date().getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState<string | number>(
    new Date().getFullYear()
  );

  const handleOpenModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: true,
    }));

    if (modalName !== "delete") {
      const mode = modalName === "newDuty" ? "create" : "update";
      setModalMode(mode);
    }
  };

  const handleCloseModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: false,
    }));
    if (modalName === "edit") {
      setSelectedDuty(null); // Clear selected duty after closing edit modal
    }
  };

  useEffect(() => {
    setLoading(true);

    const payload = {
      employeeId: selectedEmployee || null,
      equipmentCategoryId: null,
      equipmentStatusId: null,
      equipmentTypeId: null,
      month: selectedMonth,
      onlyEquipmentCurrentlyInUse: null,
      orderStatus: null,
      serialNumber: null,
      statusId: null,
      teamIds: [],
      year: selectedYear,
    };

    getActiveDuty(page, size, payload)
      .then((response) => {
        setTableData(response.data.content);
        setTotalCount(response.data.totalElements);
        setLoading(false);
      })
      .catch((error) =>
        console.error("Failed to fetch active duty data:", error)
      );
  }, [page, size, searchClicked]);

  useEffect(() => {
    const filter: any = { companyIds: [] };

    getAllEmployees(filter)
      .then((employees) => {
        // Map the response to get the necessary fields
        const formattedPersons = employees.data.map((emp: any) => ({
          id: emp.employeeDto.id,
          firstName: emp.employeeDto.firstName,
          lastName: emp.employeeDto.lastName,
          companyId: emp.employeeDto.companyDto.id,
        }));
        setAllEmployees(formattedPersons);
      })
      .catch((error) => console.error("Failed to fetch teams:", error));
  }, []);

  const handleChangePage = (
    event: unknown,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModalSubmit = async (data: any) => {
    try {
      if (modalMode === "create") {
        // Call the function to create a new duty
        await createActiveCallDuty(data);
        console.log("Active duty created successfully");
      } else if (modalMode === "update") {
        // Call the function to update an existing duty
        await updateActiveCallDuty(data);
        console.log("Active duty updated successfully");
      }
      // Refresh the list or close modal after successful operation
      handleCloseModal(modalMode === "create" ? "newDuty" : "edit");
      setSearchClicked((prev) => !prev); // trigger a re-fetch of the duty list
    } catch (error) {
      console.error(
        `Failed to ${
          modalMode === "create" ? "create" : "update"
        } active call duty`,
        error
      );
    }
  };

  const handleDelete = async () => {
    if (selectedDuty && selectedDuty.id) {
      try {
        await deleteActiveCallDuty(selectedDuty.id);
        console.log("Active duty deleted successfully");
        setSelectedDuty(null);
        setSearchClicked((prev) => !prev);
        // Optionally refresh the list here or show notification
        handleCloseModal("delete");
      } catch (error) {
        console.error("Failed to delete active call duty", error);
        setSelectedDuty(null);
        // Optionally show an error notification
      }
    }
  };

  return (
    <>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          margin: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.activeDuty.pageName}
        </Typography>
        <Button
          variant="contained"
          style={{
            marginRight: "10px",
            borderRadius: "20px",
          }}
          sx={{
            backgroundColor: themeOptions.palette.primary.main,
            ":hover": {
              backgroundColor: themeOptions.palette.primary.hover,
            },
          }}
          onClick={() => handleOpenModal("newDuty")}
        >
          Create
        </Button>
      </Paper>

      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          margin: "10px",
        }}
      >
        <Box
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Grid style={{ display: "flex", width: "28%" }}>
            <FormControl style={{ width: "100%", marginRight: "10px" }}>
              <InputLabel id="demo-simple-select-label">
                {translationsEn.labels.month}
              </InputLabel>
              <Select
                labelId="month-label"
                label={translationsEn.labels.month}
                value={selectedMonth}
                onChange={(event) => setSelectedMonth(event.target.value)}
              >
                {Array.from({ length: 12 }, (_, index) => {
                  const month = index + 1;
                  return (
                    <MenuItem key={month} value={month}>
                      {new Date(0, month - 1).toLocaleString("default", {
                        month: "long",
                      })}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">
                {" "}
                {translationsEn.labels.year}
              </InputLabel>
              <Select
                labelId="year-label"
                label={translationsEn.labels.year}
                value={selectedYear}
                onChange={(event) => setSelectedYear(event.target.value)}
              >
                {Array.from({ length: 100 }, (_, index) => {
                  const year = new Date().getFullYear() - index;
                  return (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <FormControl style={{ marginTop: "10px", width: "28%" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={allEmployees}
              getOptionLabel={(option: any) =>
                option.id ? `${option.firstName} ${option.lastName}` : ""
              }
              onChange={(event: any, value: any) => {
                const employeeId = value?.id;

                setSelectedEmployee(employeeId);
              }}
              renderInput={(params) => (
                <TextField {...params} label={translationsEn.labels.person} />
              )}
            />
          </FormControl>

          <Button
            variant="contained"
            style={{
              marginTop: "10px",
              borderRadius: "20px",
              height: "60%",
            }}
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              color: "white",
              ":hover": {
                background: themeOptions.palette.primary.hover,
              },
            }}
            onClick={() => {
              setSearchClicked(!searchClicked);
            }}
          >
            {translationsEn.buttons.search}
          </Button>
        </Box>
      </Paper>
      <Paper
        style={{
          margin: "10px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <TableContainer
          style={{ padding: "10px" }}
          sx={{ maxWidth: "98.7%" }}
          component={Paper}
        >
          <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.id}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.firstName}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.lastName}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.date}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.fromDate}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.toDate}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.reason}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.ticket}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.comment}
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.length === 0 && (
                <TableRow style={{ height: 344 }}>
                  <TableCell colSpan={12} align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>{translationsEn.table.noData}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {tableData?.map((dataRow: any, index: any) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{dataRow.id}</TableCell>
                  <TableCell align="left">
                    {dataRow.employeeDto.firstName}
                  </TableCell>
                  <TableCell align="left">
                    {dataRow.employeeDto.lastName}
                  </TableCell>
                  <TableCell align="left">
                    {moment(dataRow.date).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="left">{dataRow.timeFrom}</TableCell>
                  <TableCell align="left">{dataRow.timeTo}</TableCell>
                  <Tooltip title={dataRow.reason} placement="top">
                    <TableCell
                      align="left"
                      style={{
                        maxWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {dataRow.reason}
                    </TableCell>
                  </Tooltip>
                  <TableCell align="left">{dataRow.ticketReference}</TableCell>
                  <Tooltip title={dataRow.responderComment} placement="top">
                    <TableCell
                      align="left"
                      style={{
                        maxWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {dataRow.responderComment}
                    </TableCell>
                  </Tooltip>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleOpenModal("edit");
                        setSelectedDuty(dataRow);
                      }}
                      sx={{
                        backgroundColor: themeOptions.palette.primary.main,
                        ":hover": {
                          backgroundColor: themeOptions.palette.primary.hover,
                        },
                      }}
                    >
                      {translationsEn.buttons.edit}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleOpenModal("delete");
                        setSelectedDuty(dataRow);
                      }}
                      sx={{
                        backgroundColor: themeOptions.palette.cancel.main,
                        ":hover": {
                          backgroundColor: themeOptions.palette.cancel.hover,
                        },
                      }}
                    >
                      {translationsEn.buttons.delete}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {tableData.length !== 0 && emptyRows > 0 && (
                <TableRow style={{ height: 70 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            colSpan={tableData.length}
            count={totalCount}
            rowsPerPage={size}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
      <ActiveDutyModal
        open={modalStates.newDuty || modalStates.edit}
        onClose={() => {
          handleCloseModal("newDuty");
          handleCloseModal("edit");
          setSelectedDuty(null);
        }}
        onSubmit={handleModalSubmit}
        initialData={selectedDuty}
        persons={allEmployees}
        mode={modalMode}
      />
      <DeleteModal
        open={modalStates.delete}
        handleClose={() => handleCloseModal("delete")}
        onConfirm={handleDelete}
      />
    </>
  );
}

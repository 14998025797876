import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  Close,
  Done,
  ExpandLess,
  KeyboardArrowDown,
} from "@mui/icons-material";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";
import ApproveModal from "../modals/absenceRequest/approveModal";
import DeniedModal from "../modals/absenceRequest/deniedModal";
import { getAbsenceRequests, getAllEmployees } from "../api/requestsApi";
import moment from "moment";

export default function AbsenceRequestOverview() {
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [allEmployees, setAllEmployees] = useState<any>([]);
  const [currentRequestId, setCurrentRequestId] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [page, setPage] = useState(0);
  const [extendedPage, setExtendedPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = React.useState<boolean>(true);
  const emptyRows = tableData
    ? 5 - tableData.slice(page * pageSize, page * pageSize + pageSize)!.length
    : 0;

  const [modalStates, setModalStates] = useState({
    approve: false,
    reject: false,
  });
  const handleOpenModal = (modalName: keyof typeof modalStates, id: any) => {
    setCurrentRequestId(id);
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: true,
    }));
  };

  const handleCloseModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: false,
    }));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangePageExtended = (
    event: unknown,
    newPageExtended: number
  ) => {
    setExtendedPage(newPageExtended);
  };

  const handleChangeRowsPerPageExtended = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setExtendedPage(0);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleRowExpansion = (id: any) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

   // Function to fetch data
   const fetchData = async () => {
    try {
      const filter = { employeeId: selectedEmployee?.employeeDto?.id };
      const response = await getAbsenceRequests(filter);
      setTableData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch teams:", error);
    }
  };

  // Function to handle refresh after modal actions
  const handleRefresh = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [searchClicked]);


  useEffect(() => {
    const filter = { companyIds: [] };

    getAllEmployees(filter)
      .then((response) => setAllEmployees(response.data))
      .catch((error) => console.error("Failed to fetch teams:", error));
  }, []);

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.absenceRequest.name}
        </Typography>
      </Paper>

      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
        }}
        variant="outlined"
      >
        <Box
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Grid
            style={{ display: "flex", width: "500px", alignItems: "center" }}
          >
            <FormControl
              style={{ marginTop: "10px", width: "40%", marginRight: "10px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allEmployees}
                getOptionLabel={(option: any) =>
                  option.employeeDto.firstName +
                  " " +
                  option.employeeDto.lastName
                }
                onChange={(event: any, value: string | null) => {
                  setSelectedEmployee(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={translationsEn.labels.person} />
                )}
              />
            </FormControl>

            <Button
              variant="contained"
              style={{
                marginTop: "10px",
                borderRadius: "20px",
                height: "60%",
              }}
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
              }}
              onClick={() => {
                setSearchClicked(!searchClicked);
              }}
            >
              {translationsEn.buttons.search}
            </Button>
          </Grid>
        </Box>
      </Paper>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 401 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell width={20}></TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.teamRequest.firstName}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.teamRequest.lastName}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ height: "344px" }}>
              {tableData.length === 0 && (
                <TableRow style={{ height: 344 }}>
                  <TableCell colSpan={3} align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>{translationsEn.table.noData}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {tableData
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((person: any) => (
                  <React.Fragment key={person.employee.id}>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Button
                          onClick={() => toggleRowExpansion(person.employee.id)}
                          sx={{
                            backgroundColor: themeOptions.palette.primary.main,
                            color: "white",
                            ":hover": {
                              background: themeOptions.palette.primary.hover,
                            },
                          }}
                        >
                          {expandedRows[person.employee.id] ? (
                            <KeyboardArrowDown />
                          ) : (
                            <ExpandLess />
                          )}
                        </Button>
                      </TableCell>
                      <TableCell align="left">
                        {person.employee.firstName}
                      </TableCell>
                      <TableCell align="left">
                        {person.employee.lastName}
                      </TableCell>
                    </TableRow>

                    {expandedRows[person.employee.id] && (
                      <TableRow sx={{ height: "30px !important" }}>
                        <TableCell colSpan={3}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.id}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.type}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.status}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.author}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.created}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.dateFrom}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.dateTo}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.note}
                                  </TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {person.vacationDays.map((vacation: any) => (
                                  <TableRow>
                                    <TableCell align="left">
                                      {vacation.id}
                                    </TableCell>
                                    <TableCell align="left">
                                      {
                                        translationsEn.requestTypes[
                                          vacation.requestType
                                            .name as keyof typeof translationsEn.requestTypes
                                        ]
                                      }
                                    </TableCell>
                                    <TableCell align="left">
                                      {
                                        translationsEn.statuses[
                                          vacation.status
                                            .name as keyof typeof translationsEn.statuses
                                        ]
                                      }
                                    </TableCell>
                                    <TableCell align="left">
                                      {vacation.authorEmployee.firstName}
                                    </TableCell>
                                    <TableCell align="left">
                                      {moment(vacation.createDate).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </TableCell>
                                    <TableCell align="left">
                                      {moment(vacation.dateFrom).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </TableCell>
                                    <TableCell align="left">
                                      {moment(vacation.dateTo).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </TableCell>
                                    <TableCell align="left">
                                      {vacation.note}
                                    </TableCell>
                                    <TableCell>
                                      <Grid
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          sx={{
                                            backgroundColor:
                                              themeOptions.palette.confirm.main,
                                            ":hover": {
                                              backgroundColor:
                                                themeOptions.palette.confirm
                                                  .hover,
                                            },
                                            marginRight: "5px",
                                          }}
                                          onClick={() =>
                                            handleOpenModal("approve", vacation.id)
                                          }
                                        >
                                          <Done />
                                        </Button>
                                        <Button
                                          variant="contained"
                                          sx={{
                                            backgroundColor:
                                              themeOptions.palette.cancel.main,
                                            ":hover": {
                                              backgroundColor:
                                                themeOptions.palette.cancel
                                                  .hover,
                                            },
                                          }}
                                          onClick={() =>
                                            handleOpenModal("reject", vacation.id)
                                          }
                                        >
                                          <Close />
                                        </Button>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                              <TableFooter>
                                <TableRow>
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10]}
                                    colSpan={9}
                                    count={person?.vacationDays?.length}
                                    rowsPerPage={pageSize}
                                    page={extendedPage}
                                    onPageChange={handleChangePageExtended}
                                    onRowsPerPageChange={
                                      handleChangeRowsPerPageExtended
                                    }
                                  />
                                </TableRow>
                              </TableFooter>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              {tableData.slice(page * pageSize, page * pageSize + pageSize)
                .length !== 0 &&
                emptyRows > 0 && (
                  <TableRow style={{ height: 68 * emptyRows }}>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          colSpan={3}
          count={tableData.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ApproveModal
          open={modalStates.approve}
          requestID={currentRequestId}
          handleClose={() => handleCloseModal("approve")}
          onActionComplete={handleRefresh}
        />
        <DeniedModal
          open={modalStates.reject}
          requestID={currentRequestId}
          handleClose={() => handleCloseModal("reject")}
          onActionComplete={handleRefresh}
        />
      </Paper>
    </div>
  );
}

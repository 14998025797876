import React, { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  Alert,
} from "@mui/material";
import { Clear, ShoppingCart } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { DatePicker } from "@mui/x-date-pickers";
import SaveModal from "./../confirmationsModal/saveModal";
import { createBudget, getAllEmployees } from "../../api/inventoryApi";
import { INewBudget } from "../../interfaces/inventory.interface";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";

interface NewBudgetProps {
  open: boolean;
  handleClose: () => void;
  refreshList: () => void;
  children?: React.ReactNode;
}

const initialValues: INewBudget = {
  employeeId: null as any,
  totalBudget: 0,
  availableBudget: 0,
  startDate: new Date().toISOString().split("T")[0] as any,
  endDate: new Date().toISOString().split("T")[0] as any,
};

const AddNewBudget: React.FC<NewBudgetProps> = ({
  open,
  handleClose,
  refreshList,
}) => {
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [allEmployees, setAllEmployees] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const validationSchema = Yup.object({
    employeeId: Yup.number()
      .required(`${translationsEn.validation.required}`)
      .test(
        "Cant be 0",
        `${translationsEn.validation.required}`,
        (value) => value > 0
      ),
    totalBudget: Yup.number()
      .required(`${translationsEn.validation.required}`)
      .test(
        "Cant be 0",
        `${translationsEn.validation.required}`,
        (value) => value > 0
      ),
    availableBudget: Yup.number()
      .required(`${translationsEn.validation.required}`)
      .test(
        "Cant be 0",
        `${translationsEn.validation.required}`,
        (value) => value > 0
      ),
  });

  const handleSave = () => {
    formik.setFieldTouched("employeeId", true);
    formik.setFieldTouched("totalBudget", true);
    formik.setFieldTouched("availableBudget", true);
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        setSaveModalOpen(true);
      }
    });
  };

  const onCloseModal = () => {
    handleClose();
    formik.resetForm();
    setErrorMessage("");
  };

  useEffect(() => {
    const filter = { companyIds: [] };

    getAllEmployees(filter)
      .then((response) => setAllEmployees(response.data))
      .catch((error) => console.error("Failed to fetch teams:", error));
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {

      createBudget(values)
        .then((response) => {
          setSaveModalOpen(false);
          action.resetForm();
          refreshList();
          onCloseModal();
        })
        .catch((error) => {
          console.error("Failed to fetch teams:", error);
          setSaveModalOpen(false);
          setErrorMessage(error.response.data.error);
        });
    },
  });

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open}>
          <Paper
            style={{
              padding: "20px",
              textAlign: "center",
              width: "40%",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <ShoppingCart style={{ marginRight: "5px" }} />
                <Typography>{translationsEn.budget.modalName}</Typography>
              </Box>

              <Clear onClick={onCloseModal} />
            </Box>
            <hr></hr>
            <form onSubmit={formik.handleSubmit}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormControl style={{ width: "100%", margin: "10px" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allEmployees}
                    getOptionLabel={(option: any) =>
                      option.employeeDto.firstName +
                      " " +
                      option.employeeDto.lastName
                    }
                    onChange={(event: any, value: any) => {
                      formik.setFieldValue(
                        "employeeId",
                        value?.employeeDto?.id || null
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={"employeeId"}
                        label={translationsEn.labels.person}
                        error={
                          formik.touched.employeeId &&
                          Boolean(formik.errors.employeeId)
                        }
                        helperText={
                          formik.touched.employeeId && formik.errors.employeeId
                        }
                      />
                    )}
                  />
                </FormControl>
                <TextField
                  name={"totalBudget"}
                  label={translationsEn.labels.budget}
                  variant="standard"
                  type="number"
                  style={{
                    margin: "10px",
                    width: "97.6%",
                  }}
                  value={formik.values.totalBudget || ""}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.totalBudget &&
                    Boolean(formik.errors.totalBudget)
                  }
                  helperText={
                    formik.touched.totalBudget && formik.errors.totalBudget
                  }
                />
                <TextField
                  name={"availableBudget"}
                  label={translationsEn.labels.availableBudget}
                  variant="standard"
                  type="number"
                  style={{
                    margin: "10px",
                    width: "97.6%",
                  }}
                  value={formik.values.availableBudget || ""}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.availableBudget &&
                    Boolean(formik.errors.availableBudget)
                  }
                  helperText={
                    formik.touched.availableBudget &&
                    formik.errors.availableBudget
                  }
                />
                <DatePicker
                  label={translationsEn.labels.startDate}
                  format="DD/MM/YYYY"
                  sx={{ margin: "10px", width: "97.6%" }}
                  value={dayjs(new Date(formik.values.startDate))}
                  onChange={(value: any) => {
                    formik.setFieldValue(
                      "startDate",
                      value.toISOString().split("T")[0]
                    );
                  }}
                />
                <DatePicker
                  label={translationsEn.labels.endDate}
                  format="DD/MM/YYYY"
                  sx={{ margin: "10px", width: "97.6%" }}
                  value={dayjs(new Date(formik.values.endDate))}
                  onChange={(value: any) => {
                    formik.setFieldValue(
                      "endDate",
                      value.toISOString().split("T")[0]
                    );
                  }}
                />
              </Box>
              <hr></hr>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: themeOptions.palette.confirm.main,
                    ":hover": {
                      backgroundColor: themeOptions.palette.confirm.hover,
                    },
                  }}
                  onClick={handleSave}
                >
                  {translationsEn.buttons.save}
                </Button>
              </Box>
            </form>
            {errorMessage && (
              <Alert
                style={{
                  position: "fixed",
                  left: "50%",
                  top: "40px",
                  transform: "translate(-50%, -50%)",
                  margin: "0 auto",
                }}
                severity="error"
              >
                {errorMessage}
              </Alert>
            )}
          </Paper>
        </Fade>
      </Modal>

      <SaveModal
        open={saveModalOpen}
        handleClose={()=>setSaveModalOpen(false)}
        handleSave={() => {
          formik.handleSubmit();
        }}
      />
    </>
  );
};

export default AddNewBudget;

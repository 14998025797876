import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import {
  Article,
  Add,
  Person,
  Email,
  AccountBalance,
  GTranslate,
  Work,
  AccountTree,
  HistoryEdu,
  Newspaper,
  SettingsSuggest,
  WorkspacePremium,
} from "@mui/icons-material";
import translationsEn from "../i18n/translations_en";
import CertificationModal from "../modals/cvModals/certificatesModal";
import EducationModal from "../modals/cvModals/educationModal";
import ExperienceModal from "../modals/cvModals/experienceModal";
import HonoursModal from "../modals/cvModals/honoursModal";
import LanguageModal from "../modals/cvModals/languageModal";
import ProjectModal from "../modals/cvModals/projectModal";
import PublicationsModal from "../modals/cvModals/publicationsModal";
import SkillModal from "../modals/cvModals/skillsModals/addSkill";
import SummaryModal from "../modals/cvModals/summaryModal";
import { getEmployeeById } from "../api/overviewApi";
import { useAuth } from "../provider/authProvider";
import themeOptions from "../style/componentsStyle";
import OverviewModal from "../modals/cvModals/OverviewModal";
import CreateCvModal from "../modals/cvModals/createCvModal";
import { getCv, getCvForEmployee } from "../api/cvApi";
import ViewAllCvs from "../modals/cvModals/cvAll/viewAllCvs";

export default function CvOverview() {
  const { loggedUserId } = useAuth();
  const [loggedUserFullname, setLoggedUserFullname] = useState("");
  const [loggedUserEmail, setUserEmail] = useState<string | null>("");
  const [userCv, setUserCv] = useState<any>(null);
  const { loggedUserRole } = useAuth();

  useEffect(() => {
    if (loggedUserId) {
      const fetchUserData = async () => {
        try {
          const userData = await getEmployeeById(loggedUserId);
          setLoggedUserFullname(userData.firstName + " " + userData.lastName);
          setUserEmail(userData.email);
        } catch (error) {
          console.error("Failed to fetch user data", error);
        }
      };

      fetchUserData();
    }
  }, [loggedUserId]);

  const fetchUserCv = async () => {
    if (loggedUserId) {
      try {
        const userCvData = await getCv(loggedUserId);
        if (userCvData && userCvData.length > 0) {
          setUserCv(userCvData[0].id); // Extract and set the id
        }
      } catch (error) {
        console.error("Failed to fetch user CV", error);
      }
    }
  };

  useEffect(() => {
    fetchUserCv();
  }, [loggedUserId]);

  const [modalStates, setModalStates] = useState({
    summary: false,
    education: false,
    languages: false,
    experiences: false,
    projects: false,
    certifications: false,
    publications: false,
    skills: false,
    honours: false,
    cvLook: false,
    createCv: false,
    viewAllCv: false,
  });
  const handleOpenModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: true,
    }));
  };

  const handleCloseModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: false,
    }));
    if (modalName === "createCv") {
      fetchUserCv();
    }
  };

  return (
    <div
      style={{
        height: "70%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.cv.name}
        </Typography>
        <Grid>
          {loggedUserRole?.id !== 1 && (
            <Button
              variant="contained"
              style={{
                marginRight: "10px",
                borderRadius: "20px",
              }}
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.primary.hover,
                },
              }}
              onClick={() => handleOpenModal("viewAllCv")}
            >
              {translationsEn.cv.viewAllCv}
            </Button>
          )}

          <Button
            variant="contained"
            style={{
              marginRight: "10px",
              borderRadius: "20px",
            }}
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              ":hover": {
                backgroundColor: themeOptions.palette.primary.hover,
              },
            }}
            onClick={() => handleOpenModal("cvLook")}
          >
            {translationsEn.cv.viewCv}
          </Button>
        </Grid>
      </Paper>

      <Paper sx={{ flexGrow: 1 }}>
        <Grid style={{ padding: "30px" }}>
          <Card
            sx={{ maxHeight: 300, width: "320px" }}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Person />
              <Typography>{loggedUserFullname}</Typography>
            </CardContent>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Email />
              <Typography>{loggedUserEmail}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "30px",
          }}
        >
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "30px",
            }}
          >
            <Grid container item xs={4}>
              <Card
                sx={{ maxHeight: 200, width: "320px" }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <Article />
                </Box>
                <Typography style={{ margin: "20px", fontWeight: "bold" }}>
                  {translationsEn.cv.summary}
                </Typography>
                <CardContent>
                  <Add onClick={() => handleOpenModal("summary")} />
                </CardContent>
              </Card>
            </Grid>
            <Grid container item xs={4}>
              <Card
                sx={{ maxHeight: 200, width: "320px" }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <AccountBalance />
                </Box>
                <Typography style={{ margin: "20px", fontWeight: "bold" }}>
                  {translationsEn.cv.education}
                </Typography>
                <CardContent>
                  <Add onClick={() => handleOpenModal("education")} />
                </CardContent>
              </Card>
            </Grid>

            <Grid container item xs={4}>
              <Card
                sx={{ maxHeight: 200, width: "320px" }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <GTranslate />
                </Box>
                <Typography style={{ margin: "20px", fontWeight: "bold" }}>
                  {translationsEn.cv.languages}
                </Typography>
                <CardContent>
                  <Add onClick={() => handleOpenModal("languages")} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "30px",
            }}
          >
            <Grid container item xs={4}>
              <Card
                sx={{ maxHeight: 200, width: "320px" }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <Work />
                </Box>
                <Typography style={{ margin: "20px", fontWeight: "bold" }}>
                  {translationsEn.cv.experiences}
                </Typography>
                <CardContent>
                  <Add onClick={() => handleOpenModal("experiences")} />
                </CardContent>
              </Card>
            </Grid>

            <Grid container item xs={4}>
              <Card
                sx={{ maxHeight: 200, width: "320px" }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <AccountTree />
                </Box>
                <Typography style={{ margin: "20px", fontWeight: "bold" }}>
                  {translationsEn.cv.projects}
                </Typography>
                <CardContent>
                  <Add onClick={() => handleOpenModal("projects")} />
                </CardContent>
              </Card>
            </Grid>

            <Grid container item xs={4}>
              <Card
                sx={{ maxHeight: 200, width: "320px" }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <WorkspacePremium />
                </Box>
                <Typography style={{ margin: "20px", fontWeight: "bold" }}>
                  {translationsEn.cv.licences}
                </Typography>
                <CardContent>
                  <Add onClick={() => handleOpenModal("certifications")} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "30px",
            }}
          >
            <Grid container item xs={4}>
              <Card
                sx={{ maxHeight: 200, width: "320px" }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <HistoryEdu />
                </Box>
                <Typography style={{ margin: "20px", fontWeight: "bold" }}>
                  {translationsEn.cv.publics}
                </Typography>
                <CardContent>
                  <Add onClick={() => handleOpenModal("publications")} />
                </CardContent>
              </Card>
            </Grid>

            <Grid container item xs={4}>
              <Card
                sx={{ maxHeight: 200, width: "320px" }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <SettingsSuggest />
                </Box>
                <Typography style={{ margin: "20px", fontWeight: "bold" }}>
                  {translationsEn.cv.skills}
                </Typography>
                <CardContent>
                  <Add onClick={() => handleOpenModal("skills")} />
                </CardContent>
              </Card>
            </Grid>

            <Grid container item xs={4}>
              <Card
                sx={{ maxHeight: 200, width: "320px" }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <Newspaper />
                </Box>
                <Typography style={{ margin: "20px", fontWeight: "bold" }}>
                  {translationsEn.cv.honours}
                </Typography>
                <CardContent>
                  <Add onClick={() => handleOpenModal("honours")} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <SummaryModal
        open={modalStates.summary}
        handleClose={() => handleCloseModal("summary")}
        cvId={userCv}
      />
      <EducationModal
        open={modalStates.education}
        handleClose={() => handleCloseModal("education")}
        cvId={userCv}
      />
      <LanguageModal
        open={modalStates.languages}
        handleClose={() => handleCloseModal("languages")}
        cvId={userCv}
      />
      <ExperienceModal
        open={modalStates.experiences}
        handleClose={() => handleCloseModal("experiences")}
        cvId={userCv}
      />
      <ProjectModal
        open={modalStates.projects}
        handleClose={() => handleCloseModal("projects")}
        cvId={userCv}
      />
      <CertificationModal
        open={modalStates.certifications}
        handleClose={() => handleCloseModal("certifications")}
        certificateId={userCv}
      />
      <PublicationsModal
        open={modalStates.publications}
        handleClose={() => handleCloseModal("publications")}
        cvId={userCv}
      />
      <SkillModal
        open={modalStates.skills}
        handleClose={() => handleCloseModal("skills")}
        cvId={userCv}
      />
      <HonoursModal
        open={modalStates.honours}
        handleClose={() => handleCloseModal("honours")}
        cvId={userCv}
      />
      <OverviewModal
        open={modalStates.cvLook}
        handleClose={() => handleCloseModal("cvLook")}
        loggedUserFullname={loggedUserFullname}
        cvId={userCv}
      />
      <ViewAllCvs
        open={modalStates.viewAllCv}
        handleClose={() => handleCloseModal("viewAllCv")}
      />
    </div>
  );
}

import {
  Typography,
  Paper,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  TableContainer,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  InputLabel,
  Select,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useState, useEffect } from "react";
import { getWorkOrders, getWorkOrderById } from "../api/workOrders";
import translationsEn from "../i18n/translations_en";
import themeOptions from "../style/componentsStyle";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getAllEmployees } from "../api/employeeApi";
import { getAllCompanies } from "../api/overviewApi";
import dayjs from "dayjs";
import { useAuth } from "../provider/authProvider";
import { Status } from "../utils/constants/workOrderConstants";

export default function WorkOrders() {
  const [loading, setLoading] = useState(false);
  const [workOrders, setWorkOrders] = useState<any[]>([]);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [allEmployees, setAllEmployees] = useState<any[]>([]);
  const [allCompanies, setAllCompanies] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const { loggedUserId, loggedUserRole } = useAuth();
  const statuses = Object.values(Status);

  useEffect(() => {
    const filter = { companyIds: [], employmentStatusId: 0 };
    getAllEmployees(filter)
      .then((response) => setAllEmployees(response.data))
      .catch((error) => console.error("Failed to fetch employees:", error));
  }, []);

  useEffect(() => {
    getAllCompanies()
      .then((response) => setAllCompanies(response))
      .catch((error) => console.error("Failed to fetch companies:", error));
  }, []);

  const handleEditClick = (id: number, workOrder: any) => {
    navigate(`/editWorkOrder/${id}`, { state: { workOrder } });
  };

  const handleViewClick = (id: number) => {
    navigate(`/viewWorkOrder/${id}`);
  };

  const fetchDefaultWorkOrders = async () => {
    setLoading(true);
    try {
      let response;
      if (loggedUserRole?.id === 2) {
        response = await getWorkOrders();
      } else if (loggedUserRole?.id === 1) {
        response = await getWorkOrderById(loggedUserId);
      }

      if (response && response.data) {
        setWorkOrders(response.data);
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Failed to fetch work orders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDefaultWorkOrders();
  }, [loggedUserRole, loggedUserId]);

  const resetFilters = () => {
    setSelectedCompany("");
    setSelectedEmployee("");
    setStatus("");
    setFromDate(null);
    setToDate(null);
    fetchDefaultWorkOrders();
  };

  const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newStatus = event.target.value as string;
    setStatus(newStatus);
  };

  const getStatusLabel = (status: string) => {
    return (
      translationsEn.status[status as keyof typeof translationsEn.status] ||
      status
    );
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const body: any = {};

      if (selectedCompany) {
        body.companyId = selectedCompany;
      }

      if (selectedEmployee) {
        body.assignedId = selectedEmployee;
      }

      if (status) {
        const statusEnumKey = Object.keys(Status).find(
          (key) => Status[key as keyof typeof Status] === status
        );

        if (!statusEnumKey) {
          console.error("Invalid status value:", status);
          body.status = undefined;
        } else {
          body.status = Status[statusEnumKey as keyof typeof Status];
        }
      }

      function formatDateToApiFormat(date: dayjs.Dayjs): string {
        return date.format("YYYY-MM-DD");
      }

      if (fromDate && !isNaN(fromDate.valueOf())) {
        body.startDate = formatDateToApiFormat(dayjs(fromDate));
      }

      if (toDate && !isNaN(toDate.valueOf())) {
        body.endDate = formatDateToApiFormat(dayjs(toDate));
      }

      const response = await getWorkOrders(body);

      if (response.error) {
        console.error("API Error:", response.error);
      } else {
        setWorkOrders(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch work orders:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Typography
        style={{
          margin: "40px",
          fontSize: "30px",
          fontWeight: "bold",
          color: "#555555",
        }}
      >
        {translationsEn.workOrders.name}
      </Typography>

      <Paper
        style={{
          margin: "10px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          padding: "20px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>{translationsEn.workOrders.companyName}</InputLabel>
              <Select
                label={translationsEn.workOrders.companyName}
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
              >
                {allCompanies.map((company: any) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>{translationsEn.workOrders.assignedUser}</InputLabel>
              <Select
                label={translationsEn.workOrders.assignedUser}
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
              >
                {allEmployees.length > 0 ? (
                  allEmployees.map((employee: any) => (
                    <MenuItem
                      key={employee.employeeDto.id}
                      value={employee.employeeDto.id}
                    >
                      {employee.employeeDto.firstName +
                        " " +
                        employee.employeeDto.lastName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No employees available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              select
              label="Status"
              value={status}
              onChange={handleStatusChange}
              fullWidth
              sx={{ borderRadius: "10px" }}
            >
              {statuses.map((option) => (
                <MenuItem key={option} value={option}>
                  {getStatusLabel(option)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "5px" }}>
          <Grid item xs={3}>
            <FormControl style={{ width: "100%", margin: "10px 0" }}>
              <LocalizationProvider>
                <DatePicker
                  label="From Date"
                  format="DD/MM/YYYY"
                  value={fromDate}
                  onChange={(newValue) => setFromDate(newValue)}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ width: "100%", margin: "10px 0" }}>
              <LocalizationProvider>
                <DatePicker
                  label="To Date"
                  format="DD/MM/YYYY"
                  value={toDate}
                  onChange={(newValue) => setToDate(newValue)}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
                borderRadius: "10px",
              }}
              onClick={handleSearch}
            >
              Search
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: themeOptions.palette.primary.main,
                color: themeOptions.palette.primary.main,
                marginLeft: "10px",
                borderRadius: "10px",
              }}
              onClick={resetFilters}
            >
              Reset Filters
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper
        style={{
          margin: "10px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <TableContainer
          style={{
            padding: "10px",
            maxHeight: "500px",
            overflowY: "auto",
          }}
          sx={{ maxWidth: "98.7%" }}
          component={Paper}
        >
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid></Grid>
            <Button
              variant="contained"
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
                borderRadius: "10px",
              }}
              onClick={() => navigate("/newWorkOrders")}
            >
              <Add style={{ marginRight: "5px" }} />
              {translationsEn.workOrders.create}
            </Button>
          </Grid>

          <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.rb}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.title}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.id}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.date}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.status}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.deadline}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.reporter}
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "bold", maxWidth: "200px" }}
                >
                  {translationsEn.table.companyType}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.assigned}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.actions}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : workOrders?.length > 0 ? (
                workOrders.map((workOrder: any, index: number) => (
                  <TableRow
                    key={workOrder.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{workOrder.title}</TableCell>
                    <TableCell align="left">{workOrder.id}</TableCell>
                    <TableCell align="left">
                      {workOrder.created
                        ? dayjs(workOrder.created).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell align="left">
                      {getStatusLabel(workOrder.status)}
                    </TableCell>
                    <TableCell align="left">
                      {workOrder.deadlineDate
                        ? dayjs(workOrder.deadlineDate).format("DD/MM/YYYY")
                        : ""}
                      / {workOrder.deadlineTime}
                    </TableCell>
                    <TableCell align="left">
                      {workOrder.reporter
                        ? `${workOrder.reporter.firstName} ${workOrder.reporter.lastName}`
                        : "N/A"}
                    </TableCell>
                    <TableCell align="left">{workOrder.companyType}</TableCell>
                    <TableCell align="left">
                      {workOrder.assigned
                        ? `${workOrder.assigned.firstName} ${workOrder.assigned.lastName}`
                        : "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        variant="text"
                        onClick={() => handleViewClick(workOrder.id)}
                      >
                        View
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => handleEditClick(workOrder.id, workOrder)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    {translationsEn.table.noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

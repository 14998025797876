import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box } from '@mui/material';
import DutyTableCell from './dutyTableCell.component';
import themeOptions from '../../style/componentsStyle'; // Adjust the path as necessary

const DutyTable = ({ callDutyDays, onCellClick, callDutyRecords, weekDays, handleTodayClick }: any) => {
    const [currentDate, setCurrentDate] = useState(new Date());

    const getWeekSummary = (employeeId: any, type: any) => {
        const employeeData = callDutyDays.find((employee: any) => employee.employeeDto.id === employeeId);
        if (employeeData) {
            return employeeData.callDutyDayDtos.reduce((acc: any, day: any) => acc + (type === 'passive' ? day.passiveTime : day.activeTime), 0);
        }
        return 0;
    };

    const getMonthSummary = (employeeId: any, type: any) => {
        const employeeRecord = callDutyRecords.find((record: any) => record.employeeId === employeeId);
        if (employeeRecord) {
            return type === 'passive' ? employeeRecord.workDayTypes.UKCDP : employeeRecord.workDayTypes.UKCDA;
        }
        return 0;
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, borderCollapse: 'collapse' }} aria-label="duty schedule table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                            <Button
                                variant="contained"
                                style={{ borderRadius: "20px" }}
                                sx={{
                                    backgroundColor: themeOptions.palette.primary.main,
                                    color: "white",
                                    ":hover": {
                                        background: themeOptions.palette.primary.hover,
                                    },
                                }}
                                onClick={handleTodayClick}
                            >
                                Today
                            </Button>
                        </TableCell>
                        {weekDays.map((date: any, index: any) => (
                            <TableCell
                                key={index}
                                align="center"
                                sx={{
                                    minWidth: '80px',
                                    maxWidth: '90px',
                                    padding: "5px",
                                    color: date.getTime() >= currentDate.getTime() ? "#027be3" : "inherit",
                                    opacity: date.toDateString() === currentDate.toDateString() ? 1 : 0.7,
                                    border: '1px solid rgba(224, 224, 224, 1)'
                                }}
                            >
                                <Box component="span" sx={{ display: 'inline-flex', alignItems: 'baseline' }}>
                                    {date.toLocaleString('default', { weekday: 'short' }) + " "}
                                    <Box component="span" sx={{
                                        borderRadius: '50%',
                                        minWidth: '24px',
                                        minHeight: '24px',
                                        display: 'inline-flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: date.toDateString() === currentDate.toDateString() ? themeOptions.palette.primary.main : "transparent",
                                        color: date.toDateString() === currentDate.toDateString() ? 'white' : 'inherit',
                                        fontSize: date.toDateString() === currentDate.toDateString() ? '0.875rem' : 'inherit',
                                        lineHeight: 'normal',
                                        ml: 0.5,
                                    }}>
                                        {date.getDate()}
                                    </Box>
                                </Box>
                            </TableCell>
                        ))}
                        <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>CDP</TableCell>
                        <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>CDA</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {callDutyDays.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={weekDays.length + 3} align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                No data
                            </TableCell>
                        </TableRow>
                    ) : (
                        callDutyDays.map((employee: any) => (
                            <TableRow key={employee.employeeDto.id} sx={{ height: '70px' }}>
                                <TableCell component="th" scope="row" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {`${employee.employeeDto.firstName} ${employee.employeeDto.lastName}`}
                                </TableCell>
                                {weekDays.map((date: any, index: any) => {
                                    const dutyDay = employee.callDutyDayDtos.find((d: any) => new Date(d.date).toDateString() === date.toDateString());
                                    return (
                                        <TableCell key={index} align="right" sx={{ padding: 0, height: "70px", border: '1px solid rgba(224, 224, 224, 1)' }} onClick={() => onCellClick(dutyDay, 'scheduler')}>
                                            {dutyDay ? <DutyTableCell passive={dutyDay.passiveTime} active={dutyDay.activeTime} /> : null}
                                        </TableCell>
                                    );
                                })}
                                <TableCell align="right" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {`${getWeekSummary(employee.employeeDto.id, 'passive')} h / ${getMonthSummary(employee.employeeDto.id, 'passive')} h`}
                                </TableCell>
                                <TableCell align="right" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {`${getWeekSummary(employee.employeeDto.id, 'active')} h / ${getMonthSummary(employee.employeeDto.id, 'active')} h`}
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DutyTable;

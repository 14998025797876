import themeOptions from "../../style/componentsStyle";

const DutyTableCell = ({ passive, active }: any) => {

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)',
                    background: 'lightgrey',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    padding: '0 5px'
                }}
            >
                {<span style={{ color: 'black', fontSize: 'small' }}>{`${passive}h`}</span>}
            </div>
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    clipPath: 'polygon(100% 0%, 100% 100%, 0% 100%)',
                    background: themeOptions.palette.primary.main,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    padding: '5px'
                }}
            >
                {<span style={{ color: 'white', fontSize: 'small' }}>{`${active}h`}</span>}
            </div>
        </div>
    );
};

export default DutyTableCell;
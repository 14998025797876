import APICall from "./axios";

const toQueryString = (config: any) => {
  const query: Array<string> = [];
  const param = config.params ? config.params : config;
  Object.keys(param).forEach((key) => {
    if (
      key !== "" &&
      param[key] !== null &&
      param[key] !== undefined &&
      param[key] !== ""
    ) {
      query.push(`${key}=${encodeURIComponent(param[key])}`);
    }
  });
  return `?${query.join("&")}`;
};

export const getEquipment = (filter?: any) => {
  return APICall.post(`/api/equipment/get-all`, filter);
};

export const getEquipmentById = (id?: any) => {
  return APICall.get(`/api/equipment/${id}`);
};
export const getDocuments = async (inventoryId: any, documentId: any) => {
  try {
    const response = await APICall.get(
      `/api/equipment/document/download/${inventoryId}/${documentId}`,
      {
        responseType: "blob",
      }
    );

    return {
      blob: response.data,
      headers: response.headers,
    };
  } catch (error) {
    console.error(`Error fetching document:`, error);
    throw error;
  }
};

export const getMyEquipment = (filter?: any) => {
  return APICall.post(`/api/equipment/my`, filter);
};

export const createEquipment = (data?: any) => {
  return APICall.post(`/api/equipment`, data);
};

export const editEquipment = (id: number, data?: any) => {
  return APICall.patch(`/api/equipment/${id}`, data);
};

export const addEquipmentAttachment = (id: number, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  return APICall.post(`/api/equipment/document/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

import React, { useState, useEffect } from 'react';
import { Grid, InputLabel, MenuItem, FormControl, Select, Button, Paper } from '@mui/material';
import themeOptions from '../../style/componentsStyle';
import { getAllCompanies } from "../../api/overviewApi";

const DutyFilterPanel = ({ onSearch }: any) => {
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [company, setCompany] = useState('');
    const [companies, setCompanies] = useState([] as any);

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const companyData = await getAllCompanies();
                setCompanies(companyData);
            } catch (error) {
                console.error("Failed to fetch companies:", error);
            }
        };

        fetchCompanies();
    }, []);

    const handleSearch = () => {
        if (!month && year) {
            alert('Please select a month if you want to filter by year.');
            return;
        }
        onSearch({ month, year, company });
    };

    return (
        <Paper style={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            padding: "10px",
        }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                    <FormControl fullWidth size='small'>
                        <InputLabel>Month</InputLabel>
                        <Select
                            value={month}
                            label="Month"
                            onChange={(e) => setMonth(e.target.value)}
                        >
                            <MenuItem value={1}>January</MenuItem>
                            <MenuItem value={2}>February</MenuItem>
                            <MenuItem value={3}>March</MenuItem>
                            <MenuItem value={4}>April</MenuItem>
                            <MenuItem value={5}>May</MenuItem>
                            <MenuItem value={6}>June</MenuItem>
                            <MenuItem value={7}>July</MenuItem>
                            <MenuItem value={8}>August</MenuItem>
                            <MenuItem value={9}>September</MenuItem>
                            <MenuItem value={10}>October</MenuItem>
                            <MenuItem value={11}>November</MenuItem>
                            <MenuItem value={12}>December</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth size='small'>
                        <InputLabel>Year</InputLabel>
                        <Select
                            value={year}
                            label="Year"
                            onChange={(e) => setYear(e.target.value)}
                        >
                            <MenuItem value={2023}>2023</MenuItem>
                            <MenuItem value={2024}>2024</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                <FormControl fullWidth size='small'>
                        <InputLabel>Company</InputLabel>
                        <Select
                            value={company}
                            label="Company"
                            onChange={(e) => setCompany(e.target.value)}
                        >
                            {companies.map((company:any) => (
                                <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained"
                        style={{
                            borderRadius: "20px",
                            float: "right"
                        }}
                        sx={{
                            backgroundColor: themeOptions.palette.primary.main,
                            color: "white",
                            ":hover": {
                                background: themeOptions.palette.primary.hover,
                            },
                        }} onClick={handleSearch}>
                        SEARCH
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DutyFilterPanel;

import { CSSProperties } from "react";
import Background from "./../../../background.jpg";

export const loginPageStyle: CSSProperties = {
  backgroundImage: `url(${Background})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export const boxStyle = {
  marginTop: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  bgcolor: "background.paper",
  boxShadow: 1,
  borderRadius: 2,
  p: 2,
  width: "30%",
};

export const logoStyle = {
  width: 100,
  height: "auto",
  marginBottom: 2,
};

export const inputForms: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  margin: 5,
  alignItems: "center",
};

export const separatorStyle: CSSProperties = {
  width: "100%",
  height: 1,
  backgroundColor: "gray",
  margin: 10,
};

import React from 'react';
import { Box, Typography } from '@mui/material';
import themeOptions from '../../style/componentsStyle';

const DutyTableLegend = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          width: 24,
          height: 24,
          backgroundColor: 'lightgrey',
          border: '1px solid black',
          marginRight: 1
        }}
      />
      <Typography variant="body2" sx={{ marginRight: 2 }}>Passive duty (hours)</Typography>
      <Box
        sx={{
          width: 24,
          height: 24,
          backgroundColor: themeOptions.palette.primary.main,
          marginRight: 1
        }}
      />
      <Typography variant="body2">Active duty (hours)</Typography>
    </Box>
  );
};

export default DutyTableLegend;

import React, { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  Alert,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { DatePicker } from "@mui/x-date-pickers";
import SaveModal from "./../confirmationsModal/saveModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import {
  IEditContract,
  IStatusType,
} from "../../interfaces/employee.interface";
import { getStatuses } from "../../api/employeeApi";
import { createEmployeeContract } from "../../api/requestsApi";

type RequestStatusName = "student" | "inactive" | "full_time" | "part_time";

interface NewContractProps {
  open: boolean;
  handleClose: () => void;
  refreshList: () => void;
  employeeId: number;
  children?: React.ReactNode;
}

const NewContractEmployee: React.FC<NewContractProps> = ({
  open,
  handleClose,
  refreshList,
  employeeId,
}) => {
  const initialValues: IEditContract = {
    employeeId: employeeId || 0,
    employeeStatus: {
      id: null as any,
      status: null as any,
    },
    employeeStatusId: 0,
    probationExpirationDate: new Date().toISOString().split("T")[0] as any,
    shiftDuration: "",
    startDate: new Date().toISOString().split("T")[0] as any,
    vacationDays: null as any,
  };
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [allStatuses, setAllStatuses] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    formik.setFieldValue("employeeId", employeeId);
  }, [employeeId]);

  const validationSchema = Yup.object({});

  const handleSave = () => {
    formik.setFieldTouched("employeeStatusId", true);
    formik.setFieldTouched("startDate", true);
    formik.setFieldTouched("probationExpirationDate", true);
    formik.setFieldTouched("shiftDuration", true);
    formik.setFieldTouched("vacationDays", true);
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        setSaveModalOpen(true);
      }
    });
  };

  const onCloseModal = () => {
    handleClose();
    formik.resetForm();
    setErrorMessage("");
  };

  useEffect(() => {
    getStatuses()
      .then((response) => setAllStatuses(response.data))
      .catch((error) => console.error("Failed to fetch teams:", error));
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      createEmployeeContract(values)
        .then((response) => {
          setSaveModalOpen(false);
          action.resetForm();
          refreshList();
          onCloseModal();
        })
        .catch((error) => {
          console.error("Failed to fetch teams:", error);
          setSaveModalOpen(false);
          setErrorMessage(error.response.data.error);
        });
    },
  });

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open}>
          <Paper
            style={{
              padding: "20px",
              textAlign: "center",
              width: "40%",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography>{translationsEn.editUser.newContract}</Typography>
              </Box>

              <Clear onClick={onCloseModal} />
            </Box>
            <hr></hr>
            <form onSubmit={formik.handleSubmit}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ width: "100%", margin: "5px" }}>
                  <InputLabel id="demo-simple-select-label">
                    {translationsEn.employees.status}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label={translationsEn.employees.status}
                    name="employeeStatusId"
                    value={formik.values.employeeStatusId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.employeeStatusId &&
                      Boolean(formik.errors.employeeStatusId)
                    }
                  >
                    {allStatuses.map((status: IStatusType) => (
                      <MenuItem key={status.id} value={status.id}>
                        {translationsEn.statusType[
                          status.status as RequestStatusName
                        ] || status.status}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.employeeStatusId &&
                    formik.errors.employeeStatusId && (
                      <Typography
                        sx={{
                          color: "#d42c2c",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          fontSize: "13px",
                          marginLeft: "15px",
                        }}
                      >
                        {formik.errors.employeeStatusId}
                      </Typography>
                    )}
                </FormControl>

                <DatePicker
                  label={translationsEn.labels.startDate}
                  format="DD/MM/YYYY"
                  sx={{ margin: "10px", width: "100%" }}
                  value={dayjs(new Date(formik.values.startDate))}
                  onChange={(value: any) => {
                    formik.setFieldValue(
                      "startDate",
                      value.toISOString().split("T")[0]
                    );
                  }}
                />
                <DatePicker
                  label={translationsEn.labels.probationDate}
                  format="DD/MM/YYYY"
                  sx={{ margin: "10px", width: "100%" }}
                  value={dayjs(new Date(formik.values.probationExpirationDate))}
                  onChange={(value: any) => {
                    formik.setFieldValue(
                      "probationExpirationDate",
                      value.toISOString().split("T")[0]
                    );
                  }}
                />
                <TextField
                  name={"shiftDuration"}
                  label={translationsEn.editUser.shiftDuration}
                  variant="outlined"
                  sx={{
                    width: "100%",
                    margin: "5px",
                  }}
                  value={formik.values.shiftDuration}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.shiftDuration &&
                    Boolean(formik.errors.shiftDuration)
                  }
                  helperText={
                    formik.touched.shiftDuration && formik.errors.shiftDuration
                  }
                />

                {allStatuses && allStatuses.status !== "Student" && (
                  <TextField
                    name={"vacationDays"}
                    label={translationsEn.employees.vacationDays}
                    variant="outlined"
                    sx={{
                      width: "100%",
                      margin: "5px",
                    }}
                    value={formik.values.vacationDays}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.vacationDays &&
                      Boolean(formik.errors.vacationDays)
                    }
                    helperText={
                      formik.touched.vacationDays && formik.errors.vacationDays
                    }
                  />
                )}
              </Box>
              <hr></hr>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: themeOptions.palette.confirm.main,
                    ":hover": {
                      backgroundColor: themeOptions.palette.confirm.hover,
                    },
                  }}
                  onClick={handleSave}
                >
                  {translationsEn.buttons.save}
                </Button>
              </Box>
            </form>
            {errorMessage && (
              <Alert
                style={{
                  position: "fixed",
                  left: "50%",
                  top: "40px",
                  transform: "translate(-50%, -50%)",
                  margin: "0 auto",
                }}
                severity="error"
              >
                {errorMessage}
              </Alert>
            )}
          </Paper>
        </Fade>
      </Modal>

      <SaveModal
        open={saveModalOpen}
        handleClose={() => setSaveModalOpen(false)}
        handleSave={() => {
          formik.handleSubmit();
        }}
      />
    </>
  );
};

export default NewContractEmployee;

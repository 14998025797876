import React from "react";
import { Modal, Paper, Fade, Box, Typography, Button } from "@mui/material";
import { Clear } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";

interface ConfirmationModalProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  children?: React.ReactNode;
}

const CancelContractConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  handleClose,
  handleConfirm,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "20%",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ fontWeight: "bold" }}>
              {translationsEn.modals.cancelContractName}
            </Typography>
          </Box>
          <hr></hr>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography style={{ margin: 10 }}>
              {translationsEn.modals.cancelContract}
            </Typography>
          </Box>
          <hr></hr>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: themeOptions.palette.cancel.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.cancel.hover,
                },
                marginRight: "5px",
              }}
              onClick={handleClose}
            >
              {translationsEn.buttons.cancel}
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: themeOptions.palette.confirm.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.confirm.hover,
                },
              }}
              onClick={handleConfirm}
            >
              {translationsEn.buttons.ok}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default CancelContractConfirmationModal;

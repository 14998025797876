import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";
import React, { useState, useEffect } from "react";
import { getMyRequests } from "../api/requestsApi";
import { useAuth } from "../provider/authProvider";
import moment from "moment";

export default function MyRequestOverview() {
  const years = [
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1,
  ];
  const { loggedUserId } = useAuth();
  const [tableData, setTableData] = useState<any>([]);
  const [selectedYear, setSelectedYear] = useState<any>(
    new Date().getFullYear()
  );
  const [searchClicked, setSearchClicked] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = React.useState<boolean>(true);
  const emptyRows = tableData
    ? 5 - tableData.slice(page * pageSize, page * pageSize + pageSize)!.length
    : 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (loggedUserId) {
      getMyRequests(loggedUserId, selectedYear)
        .then((response) => {
          setTableData(response.data.vacationDays);
          setLoading(false);
        })
        .catch((error) => console.error("Failed to fetch teams:", error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchClicked, loggedUserId]);

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.myRequest.name}
        </Typography>
      </Paper>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
        }}
        variant="outlined"
      >
        <Box
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Grid
            style={{ display: "flex", width: "500px", alignItems: "center" }}
          >
            <FormControl
              style={{ marginTop: "10px", width: "40%", marginRight: "10px" }}
            >
              <InputLabel id="demo-simple-select-label">
                {translationsEn.labels.year}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Person"
                onChange={(e) => setSelectedYear(e.target.value)}
                value={selectedYear}
              >
                {years.map((years) => (
                  <MenuItem key={years} value={years}>
                    {years}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              style={{
                marginTop: "10px",
                borderRadius: "20px",
                height: "60%",
              }}
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
              }}
              onClick={() => {
                setSearchClicked(!searchClicked);
                setPage(0);
              }}
            >
              {translationsEn.buttons.search}
            </Button>
          </Grid>
        </Box>
      </Paper>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 401 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.myRequest.type}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.myRequest.status}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.myRequest.author}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.myRequest.created}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.myRequest.dateFrom}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.myRequest.dateTo}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.myRequest.note}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.length === 0 && (
                <TableRow style={{ height: 344 }}>
                  <TableCell colSpan={7} align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>{translationsEn.table.noData}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {tableData
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((request: any) => (
                  <TableRow key={request.id}>
                    <TableCell align="left">
                      {
                        translationsEn.requestTypes[
                          request.requestType
                            .name as keyof typeof translationsEn.requestTypes
                        ]
                      }
                    </TableCell>
                    <TableCell align="left">
                      {
                        translationsEn.statuses[
                          request.status
                            .name as keyof typeof translationsEn.statuses
                        ]
                      }
                    </TableCell>
                    <TableCell align="left">
                      {request.authorEmployee.firstName}
                    </TableCell>
                    <TableCell align="left">
                      {moment(request.createDate).format("DD.MM.YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      {moment(request.dateFrom).format("DD.MM.YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      {moment(request.dateTo).format("DD.MM.YYYY")}
                    </TableCell>
                    <TableCell align="left">{request.note}</TableCell>
                  </TableRow>
                ))}
              {tableData.slice(page * pageSize, page * pageSize + pageSize)
                .length !== 0 &&
                emptyRows > 0 && (
                  <TableRow style={{ height: 68 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          colSpan={7}
          component="div"
          count={tableData.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Clear, Work } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { useFormik } from "formik";
import { addExperience, updatedExperience } from "../../api/cvApi";
import { useAlert } from "../../utils/alertContext";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import * as Yup from "yup";

interface ExperienceModalProps {
  open: boolean;
  handleClose: () => void;
  cvId: number;
  data?: any;
  onExperienceUpdate?: () => void;
}

const ExperienceModal: React.FC<ExperienceModalProps> = ({
  open,
  handleClose,
  cvId,
  data,
  onExperienceUpdate = () => {},
}) => {
  const [ongoingExperience, setOngoingExperience] = useState(false);
  const { showAlert } = useAlert();
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = {
    company: data ? data.company : "",
    title: data ? data.title : "",
    startDate: data ? dayjs(data.startDate) : null,
    endDate: data ? (data.endDate ? dayjs(data.endDate) : null) : null,
    contact: data ? data.contact : "",
    description: data ? data.description : "",
  };

  const validationSchema = Yup.object().shape({
    company: Yup.string().required(`${translationsEn.validation.required}`),
    title: Yup.string().required(`${translationsEn.validation.required}`),
    startDate: Yup.date().required(`${translationsEn.validation.required}`),
    endDate: ongoingExperience
      ? Yup.date().nullable().notRequired()
      : Yup.date().required(`${translationsEn.validation.required}`),
    contact: Yup.string(),
    description: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formatDate = (date: dayjs.Dayjs | null) =>
        date ? date.format("YYYY-MM") : null;

      const startDate = formatDate(values.startDate);
      const endDate = ongoingExperience ? null : formatDate(values.endDate);

      const endpointPayload = {
        cvId,
        company: values.company,
        title: values.title,
        contact: values.contact,
        startDate,
        endDate,
        description: values.description,
      };

      const saveExperience = data ? updatedExperience : addExperience;

      saveExperience({
        id: data ? data.id : 0,
        ...endpointPayload,
      })
        .then((response) => {
          showAlert(`${translationsEn.cvModals.successExpirience}`, "success");
          onExperienceUpdate();
          handleClose();
        })
        .catch((error) => {
          showAlert(
            error.response?.data?.error || "An error occurred",
            "error"
          );
          setErrorMessage(error.response?.data?.error || "An error occurred");
        });
    },
  });

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      formik.setValues({
        company: data.company || "",
        title: data.title || "",
        startDate: data.startDate ? dayjs(data.startDate) : null,
        endDate: data.endDate ? dayjs(data.endDate) : null,
        contact: data.contact || "",
        description: data.description || "",
      });
      setOngoingExperience(data.endDate === null);
    }
  }, [data]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOngoingExperience(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue("endDate", null);
    }
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "40%",
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Work style={{ marginRight: "5px" }} />
              <Typography>{translationsEn.cvModals.addExperience}</Typography>
            </Box>

            <Clear onClick={handleClose} />
          </Box>
          <hr />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "30px",
            }}
          >
            <TextField
              id="company"
              name="company"
              label="Company"
              variant="standard"
              required
              value={formik.values.company}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
              }}
              error={formik.touched.company && Boolean(formik.errors.company)}
            />

            <TextField
              id="title"
              name="title"
              label="Title"
              variant="standard"
              required
              value={formik.values.title}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
              }}
              error={formik.touched.title && Boolean(formik.errors.title)}
            />

            <FormControl style={{ width: "100%" }}>
              <DatePicker
                label="Start date"
                views={["month", "year"]}
                value={formik.values.startDate}
                onChange={(date) =>
                  formik.setFieldValue("startDate", date ? dayjs(date) : null)
                }
                slotProps={{
                  textField: {
                    helperText:
                      formik.touched.startDate &&
                      formik.errors.startDate &&
                      (formik.errors.startDate as string),
                    error: Boolean(
                      formik.touched.startDate && formik.errors.startDate
                    ),
                  },
                }}
                sx={{ width: "100%" }}
              />
            </FormControl>
            <FormControlLabel
              style={{ margin: "10px", width: "27%" }}
              control={
                <Checkbox
                  checked={ongoingExperience}
                  onChange={handleCheckboxChange}
                />
              }
              label="Ongoing experience"
            />

            {!ongoingExperience && (
              <FormControl style={{ width: "100%", margin: "10px 0" }}>
                <DatePicker
                  label="End date"
                  views={["month", "year"]}
                  value={formik.values.endDate}
                  onChange={(date) =>
                    formik.setFieldValue("endDate", date ? dayjs(date) : null)
                  }
                  slotProps={{
                    textField: {
                      helperText:
                        formik.touched.endDate &&
                        formik.errors.endDate &&
                        (formik.errors.endDate as string),
                      error: Boolean(
                        formik.touched.endDate && formik.errors.endDate
                      ),
                    },
                  }}
                  sx={{ margin: "10px 0", width: "100%" }}
                />
              </FormControl>
            )}

            <TextField
              id="contact"
              name="contact"
              label="Contact number"
              variant="standard"
              value={formik.values.contact}
              onChange={formik.handleChange}
              style={{ margin: "10px", width: "100%" }}
              error={formik.touched.contact && Boolean(formik.errors.contact)}
            />

            <TextField
              id="description"
              name="description"
              label="Description"
              multiline
              rows={10}
              value={formik.values.description}
              onChange={formik.handleChange}
              style={{ margin: "10px", width: "100%" }}
            />
          </Box>
          <hr />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: themeOptions.palette.confirm.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.confirm.hover,
                },
              }}
              onClick={handleSave}
            >
              {translationsEn.buttons.save}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ExperienceModal;

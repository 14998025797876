import React from "react";
import { Modal, Paper, Fade, Box, Typography, Button } from "@mui/material";
import { Clear } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";

interface SaveModalProps {
  open: boolean;
  handleClose: () => void;
  handleSave?: () => void;
  children?: React.ReactNode;
}

const SaveModal: React.FC<SaveModalProps> = ({
  open,
  handleClose,
  handleSave,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "20%",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ fontWeight: "bold" }}>
              {translationsEn.modals.saveName}
            </Typography>

            <Clear onClick={handleClose} />
          </Box>
          <hr></hr>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography style={{ margin: 10 }}>
              {translationsEn.modals.saveNote}
            </Typography>
          </Box>
          <hr></hr>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: themeOptions.palette.confirm.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.confirm.hover,
                },
              }}
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              onClick={handleSave}
            >
              {translationsEn.buttons.yes}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default SaveModal;

import { Grid } from "@mui/material";
import InProgressCard from "../components/inProgress/inProgress";
export default function Companies() {
  return (
    <>
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "200px",
        }}
      >
        <InProgressCard />
      </Grid>
    </>
  );
}

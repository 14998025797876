import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  Autocomplete,
  TextField,
  FormControl,
  FormHelperText
} from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Clear } from '@mui/icons-material';
import themeOptions from '../../../style/componentsStyle';

const validationSchemaUpdate = Yup.object({
  personId: Yup.number().required('Person is required'),
  substituteId: Yup.number().required('Substitute is required'),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date().required('End date is required').min(
    Yup.ref('startDate'),
    "End date cannot be before start date"
  ),
});

const validationSchemaOther = Yup.object({
  personId: Yup.number().required('Person is required'),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date().required('End date is required').min(
    Yup.ref('startDate'),
    "End date cannot be before start date"
  ),
});

const CallDutyModal = ({ open, onClose, onSubmit, action, initialData, persons }: any) => {
  let validationSchema = action === 'update' ? validationSchemaUpdate : validationSchemaOther;
  const formik = useFormik({
    initialValues: {
      personId: initialData?.personId || null,
      substituteId: initialData?.substituteId || null,
      companyId: initialData?.companyId || null,
      startDate: initialData?.startDate || null,
      endDate: initialData?.endDate || null,
    },
    validationSchema,
    onSubmit: (values) => {
      const payload = {
        employeeId: values.personId,
        newEmployeeId: values.substituteId || null,
        companyId: values.companyId,
        periodDto: {
          dateFrom: dayjs(values.startDate).format('YYYY-MM-DD'),
          dateTo: dayjs(values.endDate).format('YYYY-MM-DD')
        }
      };
      onSubmit(payload, action);
      onClose();
    },
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "40%",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">{action === 'create' ? 'Create' : action === 'update' ? 'Update' : 'Delete'} Call Duty Period</Typography>
              <Clear onClick={onClose} />
            </Box>
            <hr></hr>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FormControl style={{ width: "100%", margin: "10px 0" }}>
                <Autocomplete
                  options={persons}
                  getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                  value={formik.values.personId ? persons.find((person: any) => person.id === formik.values.personId) : null}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('personId', newValue ? newValue.id : null);
                    formik.setFieldValue('companyId', newValue ? newValue.companyId : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(formik.touched.personId && formik.errors.personId)}
                      helperText={(formik.touched.personId && formik.errors.personId) && formik.errors.personId as string}
                      label="Person"
                      placeholder="Search and select"
                    />
                  )}
                />
              </FormControl>
              {action === 'update' && (
                <FormControl style={{ width: "100%", margin: "10px 0" }}>
                  <Autocomplete
                    options={persons}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    value={formik.values.substituteId ? persons.find((person: any) => person.id === formik.values.substituteId) : null}
                    onChange={(event, newValue) => formik.setFieldValue('substituteId', newValue ? newValue.id : null)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(formik.touched.substituteId && formik.errors.substituteId)}
                        helperText={(formik.touched.substituteId && formik.errors.substituteId) && formik.errors.substituteId as string}
                        label="Substitute"
                        placeholder="Search and select"
                      />
                    )}
                  />
                </FormControl>
              )}
              <DatePicker
                label="Start Date"
                format="DD/MM/YYYY"
                value={formik.values.startDate ? dayjs(formik.values.startDate) : null}
                onChange={(date) => formik.setFieldValue('startDate', date ? date.toISOString() : undefined)}
                slotProps={{
                  textField: {
                    helperText: (formik.touched.startDate && formik.errors.startDate) && formik.errors.startDate as string,
                    error: Boolean(formik.touched.startDate && formik.errors.startDate)
                  },
                }}
                sx={{ margin: "10px 0", width: "100%" }}
              />
              <DatePicker
                label="End Date"
                format="DD/MM/YYYY"
                value={formik.values.endDate ? dayjs(formik.values.endDate) : null}
                onChange={(date) => formik.setFieldValue('endDate', date ? date.toISOString() : undefined)}
                slotProps={{
                  textField: {
                    helperText: (formik.touched.endDate && formik.errors.endDate) && formik.errors.endDate as string,
                    error: Boolean(formik.touched.endDate && formik.errors.endDate)
                  },
                }}
                sx={{ margin: "10px 0", width: "100%" }}
              />
            </Box>
            <hr></hr>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={onClose}
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.cancel.main,
                  ":hover": {
                    backgroundColor: themeOptions.palette.cancel.hover,
                  },
                  marginRight: "10px"
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.confirm.main,
                  ":hover": {
                    backgroundColor: themeOptions.palette.confirm.hover,
                  }
                }}
              >
                {action === 'create' ? 'Confirm' : action === 'update' ? 'Update' : 'Delete'}
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default CallDutyModal;

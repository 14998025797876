
import { jwtDecode } from 'jwt-decode';
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import APICall from '../api/axios';

const AuthContext = createContext(); // It's a good practice to initialize with a suitable default value

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loggedUserId, setLoggedUserId] = useState(null);
  const [loggedUserCompanyId, setUserCompanyId] = useState(null);
  const [loggedUserRole, setLoggedUserRole] = useState(null);
  const [loginError, setLoginError] = useState('');

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);

      try {
        const decoded = jwtDecode(token);
        setLoggedUserId(decoded.employeeId);
        setUserCompanyId(decoded.companyId);
        setLoggedUserRole(decoded.role);
      } catch (error) {
        console.error("Failed to decode token", error);
        setToken(null); // Potentially handle invalid token by resetting it
      }
    } else {
      delete APICall.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
      setLoggedUserId(null);
      setUserCompanyId(null);
      setLoggedUserRole(null);
    }
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 300000); // 5 minutes in milliseconds
  
    return () => clearInterval(interval);
  }, []);   

  const login = async (username, password, navigate) => {
    try {
      const response = await APICall.post("/api/auth/login", { username, password });

      // Parse the JSON response
      const jsonResponse = response.data;

      // Assuming the structure of the response is as you provided
      // Extract the token from the 'data' object
      const { token } = jsonResponse.data;
      setLoginError('');
      setToken(token);
      navigate("/", { replace: true });
    } catch (error) {
      setLoginError('Invalid username or password. Please try again.');
      console.error(error);
    }
  };

  const logout = () => {
    setToken(null); // Triggers cleanup in useEffect
  };

  const checkTokenExpiration = () => {
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
  
      if (decoded.exp < currentTime) {
        localStorage.removeItem('token');  // Clear the expired token from storage
        logout(); // Logout if the token has expired
        window.location.reload();   // Refresh the page
      }
    }
  };

  const contextValue = useMemo(() => ({
    token,
    setToken,
    loggedUserId,
    loggedUserCompanyId,
    loggedUserRole,
    loginError,
    login,
    logout,
    checkTokenExpiration,
  }), [token, loggedUserId, loggedUserCompanyId, loggedUserRole, loginError]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;

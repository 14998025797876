import React from "react";
import { Modal, Paper, Fade, Box, Typography, Button } from "@mui/material";
import { Clear } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";

interface DeleteModalProps {
  open: boolean;
  handleClose: () => void;
  onConfirm: () => void; // Function to call when confirmation is needed
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  handleClose,
  onConfirm,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "20%",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontWeight: "bold" }}>
              {translationsEn.modals.deleteName}
            </Typography>
            <Button
              sx={{
                color: themeOptions.palette.primary.main,
              }}
            >
              <Clear onClick={handleClose} />
            </Button>
          </Box>
          <hr />
          <Typography style={{ padding: 30 }}>
            {translationsEn.modals.deleteNote}
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{
                color: themeOptions.palette.primary.main,
                borderColor: themeOptions.palette.primary.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.primary.hover,
                },
                marginRight: "10px",
              }}
            >
              {translationsEn.buttons.no}
            </Button>
            <Button
              variant="contained"
              onClick={onConfirm}
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.primary.hover,
                },
              }}
            >
              {translationsEn.buttons.yes}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default DeleteModal;

import React, { useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { updateRequestStatus } from "../../api/requestsApi";
import { useAlert } from "../../utils/alertContext";

interface DeniedModalProps {
  open: boolean;
  requestID: any;
  handleClose: () => void;
  onActionComplete: () => void;
  children?: React.ReactNode;
}

const DeniedModal: React.FC<DeniedModalProps> = ({ open, requestID, handleClose, onActionComplete }) => {
  const { showAlert } = useAlert();
  const [comment, setComment] = useState("");

  const handleReject = async () => {
    // Logic to approve the request using the requestId
    const requestPayload = {
      statusDto: { id: 3 }, // Assuming '3' is the ID for 'reject'
      comment: comment,
    };

    updateRequestStatus(requestPayload, requestID)
      .then(() => {
        onActionComplete(); // Call this to update parent component state or perform additional actions after approval
        showAlert("Reject successfully!", "success");
        handleClose();
        setComment("");
      })
      .catch((error) => {
        console.error('Failed to approve request:', error);
        const errorMessage = error.response?.data?.error || 'Failed to change password. Please try again.';
        showAlert(errorMessage, 'error')
        setComment("");
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper style={{ padding: "20px", textAlign: "center" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography style={{ fontWeight: "bold" }}>
                {translationsEn.absenceRequest.deniedName}
              </Typography>
            </Box>

            <Clear onClick={handleClose} />
          </Box>
          <hr></hr>
          <Grid style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold" }}>
              {translationsEn.absenceRequest.comment}
            </Typography>
          </Grid>

          <TextField
            id="outlined-multiline-static"
            multiline
            rows={10}
            style={{ margin: "10px", width: "300px" }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />

          <hr></hr>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: themeOptions.palette.confirm.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.confirm.hover,
                },
              }}
              onClick={handleReject}
            >
              {translationsEn.buttons.save}
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: themeOptions.palette.cancel.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.cancel.hover,
                },
              }}
              onClick={handleClose}
            >
              {translationsEn.buttons.cancel}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default DeniedModal;

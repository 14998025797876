import React, { useState, useEffect } from 'react';
import { Grid, InputLabel, MenuItem, FormControl, Select, Button, Paper } from '@mui/material';
import themeOptions from '../../style/componentsStyle';
import { getAllCompanies } from "../../api/overviewApi";
import ExportModal from '../exportModal/exportModal';

const ShiftRecordFilterPanel = ({ month, year, company, onFilterChange, onSearch, onExport }: any) => {
  const [localMonth, setLocalMonth] = useState(month);
  const [localYear, setLocalYear] = useState(year);
  const [localCompany, setLocalCompany] = useState(company);
  const [companies, setCompanies] = useState([] as any);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companyData = await getAllCompanies();
        setCompanies(companyData);
      } catch (error) {
        console.error("Failed to fetch companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    setLocalMonth(month);
    setLocalYear(year);
    setLocalCompany(company);
  }, [month, year, company]);

  const handleSearch = () => {
    if (!localMonth && localYear) {
      alert('Please select a month if you want to filter by year.');
      return;
    }
    onSearch({ month: localMonth, year: localYear, company: localCompany });
  };

  const handleExport = (day: string) => {
    onExport({ day, month: localMonth, year: localYear, companyId: localCompany });
    setIsModalOpen(false);
  };

  return (
    <Paper style={{
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      padding: "10px",
    }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <FormControl fullWidth size='small'>
            <InputLabel>Month</InputLabel>
            <Select
              value={localMonth}
              label="Month"
              onChange={(e) => {
                setLocalMonth(e.target.value);
                onFilterChange({ month: e.target.value, year: localYear, company: localCompany });
              }}
            >
              <MenuItem value={1}>January</MenuItem>
              <MenuItem value={2}>February</MenuItem>
              <MenuItem value={3}>March</MenuItem>
              <MenuItem value={4}>April</MenuItem>
              <MenuItem value={5}>May</MenuItem>
              <MenuItem value={6}>June</MenuItem>
              <MenuItem value={7}>July</MenuItem>
              <MenuItem value={8}>August</MenuItem>
              <MenuItem value={9}>September</MenuItem>
              <MenuItem value={10}>October</MenuItem>
              <MenuItem value={11}>November</MenuItem>
              <MenuItem value={12}>December</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth size='small'>
            <InputLabel>Year</InputLabel>
            <Select
              value={localYear}
              label="Year"
              onChange={(e) => {
                setLocalYear(e.target.value);
                onFilterChange({ month: localMonth, year: e.target.value, company: localCompany });
              }}
            >
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth size='small'>
            <InputLabel>Company</InputLabel>
            <Select
              value={localCompany}
              label="Company"
              onChange={(e) => {
                setLocalCompany(e.target.value);
                onFilterChange({ month: localMonth, year: localYear, company: e.target.value });
              }}
            >
              {companies.map((company: any) => (
                <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} container spacing={2}>
          <Grid item xs={6}>
            <Button variant="contained"
              style={{
                borderRadius: "20px",
                float: "right"
              }}
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
              }} onClick={handleSearch}>
              SEARCH
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained"
              fullWidth
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                ':hover': { backgroundColor: themeOptions.palette.primary.hover },
              }}
              onClick={() => setIsModalOpen(true)}
            >
              Export to PDF
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ExportModal open={isModalOpen} onClose={() => setIsModalOpen(false)} onConfirm={handleExport} />
    </Paper>
  );
};

export default ShiftRecordFilterPanel;

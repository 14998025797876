import APICall from "./axios";

// Function to handle API response
const handleApiResponse = (response: any) => {
  if (response.data) {
    return response.data;
  } else {
    throw new Error("Data is missing or in an unexpected format.");
  }
};

// Functions for call duty operations
export const getAllCallDutyDays = async (payload: any) => {
  try {
    const response = await APICall.post(
      "/api/call-duty-day/all-call-duty-days",
      payload
    );
    return handleApiResponse(response);
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error;
  }
};

export const createCallDutyPeriod = async (payload: any) => {
  try {
    const response = await APICall.post("api/call-duty-day", payload);
    return handleApiResponse(response);
  } catch (error) {
    console.error(`Error creating call duty period`, error);
    throw error;
  }
};

export const updateCallDutyPeriod = async (payload: any) => {
  try {
    const response = await APICall.patch("api/call-duty-day", payload);
    return handleApiResponse(response);
  } catch (error) {
    console.error(`Error updating call duty period`, error);
    throw error;
  }
};

export const deleteCallDutyPeriod = async (payload: any) => {
  try {
    await APICall.delete("api/call-duty-day", { data: payload });
  } catch (error) {
    console.error(`Error deleting call duty period`, error);
    throw error;
  }
};

export const getAllActiveCallDuties = async (page: any) => {
  try {
    const response = await APICall.post(
      `api/active-call-duty/all-active-call-duties?${page.getPagebleUrl()}`,
      page
    );
    return handleApiResponse(response);
  } catch (error) {
    console.error(`Error fetching active call duties`, error);
    throw error;
  }
};

export const createActiveCallDuty = async (payload: any) => {
  try {
    const response = await APICall.post("api/active-call-duty", payload);
    return handleApiResponse(response);
  } catch (error) {
    console.error(`Error creating active call duty`, error);
    throw error;
  }
};

export const updateActiveCallDuty = async (payload: any) => {
  try {
    const response = await APICall.put("api/active-call-duty", payload);
    return handleApiResponse(response);
  } catch (error) {
    console.error(`Error updating active call duty`, error);
    throw error;
  }
};

export const deleteActiveCallDuty = async (activeCallDutyId: any) => {
  try {
    await APICall.delete(`api/active-call-duty/${activeCallDutyId}`);
  } catch (error) {
    console.error(`Error deleting active call duty`, error);
    throw error;
  }
};

export const getAllCallDutyRecords = async (payload: any) => {
  try {
    const response = await APICall.post(
      "api/call-duty-record/records",
      payload
    );
    return handleApiResponse(response);
  } catch (error) {
    console.error(`Error fetching call duty records`, error);
    throw error;
  }
};

export const getAllCallDutyEmployeesIds = async () => {
  try {
    const response = await APICall.get(
      "api/call-duty-day/all-call-duty-employee-ids"
    );
    return handleApiResponse(response);
  } catch (error) {
    console.error(`Error fetching call duty employee ids`, error);
    throw error;
  }
};

export const generateCallDutyRecord = async (payload: any) => {
  try {
    const response = await APICall.post(
      "api/call-duty-record/generate/",
      payload
    );
    return handleApiResponse(response);
  } catch (error) {
    console.error(`Error generating call duty record`, error);
    throw error;
  }
};

export const exportDataToPDF = async (filter: any) => {
  try {
    const response = await APICall.get(
      `api/call-duty-record/report/${filter.companyId}/${filter.year}/${filter.month}/${filter.day}`
    );
    return handleApiResponse(response);
  } catch (error) {
    console.error(`Error exporting data to PDF`, error);
    throw error;
  }
};

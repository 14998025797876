const STORAGE_KEY_PREFIX = "UserPreferences";

export const saveUserPreference = (loggedUserId, userPreference) => {
    const storageKey = `${STORAGE_KEY_PREFIX}-${loggedUserId}`;
    localStorage.setItem(storageKey, JSON.stringify(userPreference));
};

export const loadUserPreference = (loggedUserId) => {
    const storageKey = `${STORAGE_KEY_PREFIX}-${loggedUserId}`;
    const userPreferencesData = localStorage.getItem(storageKey);
    if (userPreferencesData) {
        return JSON.parse(userPreferencesData);
    }
    // Assuming UserPreference is an object structure you want to default to
    // Adjust this to fit the structure you expect if user preferences haven't been saved yet
    return {};
};

export const clearUserPreference = (loggedUserId) => {
    const storageKey = `${STORAGE_KEY_PREFIX}-${loggedUserId}`;
    localStorage.removeItem(storageKey);
};

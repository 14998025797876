import {
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  CircularProgress,
  TablePagination,
  Tooltip,
  Grid,
} from "@mui/material";
import { ArrowBack, ArrowLeft, Clear, Info } from "@mui/icons-material";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  deleteEmployeeApi,
  getVacationDayTeams,
  getVacationDaysPerEmployee,
} from "../api/teamsApi";
import { useParams } from "react-router-dom";
import VactionDayModal from "../modals/teamsInformation/vacationDayModal";
import EditTeamModal from "../modals/teamsInformation/editTeamModal";
import { getAllEmployees } from "../api/requestsApi";
import AddMemberModal from "../modals/teamsInformation/addMemberToTeam";

export default function TeamInformation() {
  const [tableData, setTableData] = useState<any>([]);
  const [employeeData, setEmployeeData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [editTeamModal, setEditTeamModal] = useState(false);
  const [openManageMember, setOpenManageMember] = useState(false);
  const [openAddMember, setOpenAddMember] = useState(false);
  const [allEmployees, setAllEmployees] = useState<any>([]);
  const [employeeId, setEmployeeId] = useState<number | null>(null);

  const emptyRows = tableData?.employeeDtos
    ? 5 -
      tableData?.employeeDtos?.slice(
        page * pageSize,
        page * pageSize + pageSize
      )!.length
    : 0;

  const handleGetEmployeeInfo = async (id: any) => {
    getVacationDaysPerEmployee(id)
      .then((response) => {
        setEmployeeData(response.data);
        setOpenModal(true);
        setLoading(false);
      })
      .catch((error) => console.error("Failed to fetch employee data:", error));
  };

  const handleMemberAdded = () => {
    const filter = [id ? +id : 0];
    getVacationDayTeams(filter)
      .then((response) => {
        setTableData(response.data[0]);
      })
      .catch((error) => console.error("Failed to fetch teams:", error));
  };

  useEffect(() => {
    const filter = [id ? +id : 0];

    getVacationDayTeams(filter)
      .then((response) => {
        setTableData(response.data[0]);
        setLoading(false);
      })
      .catch((error) => console.error("Failed to fetch teams:", error));
  }, []);
  const handleDeleteEmployee = async (employeeId: any) => {
    try {
      const teamId = tableData.teamDto.id;
      await deleteEmployeeApi(employeeId, teamId);

      setTableData((prevTableData: any) => ({
        ...prevTableData,
        employeeDtos: prevTableData.employeeDtos.filter(
          (item: any) => item.employee.id !== employeeId
        ),
      }));
    } catch (error) {
      console.error("Failed to delete employee:", error);
    }
  };

  useEffect(() => {
    const filter = { companyIds: [] };

    getAllEmployees(filter)
      .then((response) => setAllEmployees(response.data))
      .catch((error) => console.error("Failed to fetch teams:", error));
  }, []);

  useEffect(() => {
    const filter = [id ? +id : 0];

    getVacationDayTeams(filter)
      .then((response) => {
        setTableData(response.data[0]);
        setLoading(false);
      })
      .catch((error) => console.error("Failed to fetch teams:", error));
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenAddMember(false);
    setEditTeamModal(false);
  };

  const handleOpenAddMember = (employeeId: number) => {
    setOpenAddMember(true);
    setEmployeeId(employeeId);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          padding: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        variant="outlined"
      >
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <Button
            variant="contained"
            component={NavLink}
            to="/teams"
            style={{ margin: "10px" }}
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              color: "white",
              ":hover": {
                background: themeOptions.palette.primary.hover,
              },
            }}
          >
            <ArrowBack />
          </Button>
          <Typography
            style={{
              marginLeft: "10px",
              fontSize: "40px",
            }}
          >
            {tableData.teamDto?.name}
          </Typography>
        </Grid>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <Button
            variant="contained"
            style={{ marginRight: "5px" }}
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              color: "white",
              ":hover": {
                background: themeOptions.palette.primary.hover,
              },
            }}
            onClick={() => setEditTeamModal(true)}
          >
            {translationsEn.teamsInformation.editTeam}
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              color: "white",
              ":hover": {
                background: themeOptions.palette.primary.hover,
              },
            }}
            onClick={() => setOpenManageMember(!openManageMember)}
          >
            {translationsEn.teamsInformation.addNewMember}
          </Button>
        </Grid>
      </Paper>
      <Grid
        container
        direction={{
          xs: "column",
          lg: "row",
        }}
      >
        <Grid item lg={openManageMember ? 6 : 12} sx={{ padding: "10px" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 401 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      {translationsEn.table.firstName}
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      {translationsEn.teams.lastName}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontWeight: "bold" }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ height: "344px" }}>
                  {tableData.length === 0 && (
                    <TableRow style={{ height: 344 }}>
                      <TableCell colSpan={3} align="center">
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <>{translationsEn.table.noData}</>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  {tableData.length === 0 && (
                    <TableRow style={{ height: 344 }}>
                      <TableCell colSpan={3} align="center">
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <>{translationsEn.table.noData}</>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  {tableData.employeeDtos
                    ?.slice(page * pageSize, page * pageSize + pageSize)
                    .map((data: any) => (
                      <TableRow>
                        <TableCell align="left">
                          <Button
                            sx={{
                              color: themeOptions.palette.primary.main,
                            }}
                            onClick={() =>
                              handleDeleteEmployee(data.employee.id)
                            }
                          >
                            <Clear />
                          </Button>
                        </TableCell>
                        <TableCell align="left">
                          {data.employee.firstName}
                        </TableCell>
                        <TableCell align="left">
                          {data.employee.lastName}
                        </TableCell>
                        <TableCell align="right" style={{ display: "flex" }}>
                          <Card
                            style={{
                              width: openManageMember ? "60%" : "30%",
                              maxHeight: "50px",
                            }}
                          >
                            <CardContent
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                height: "40%",
                              }}
                            >
                              <Tooltip
                                title={
                                  translationsEn.labels.previousYearVacation
                                }
                                arrow
                                style={{ height: "30%" }}
                              >
                                <Typography variant="body1">
                                  {
                                    data.vacationDaysAvailability
                                      .vacationDaysPreviousYear.totalAvailable
                                  }
                                </Typography>
                              </Tooltip>

                              <Typography variant="body1">|</Typography>
                              <Tooltip
                                title={
                                  translationsEn.labels.currentYearVacation
                                }
                                arrow
                                style={{ height: "30%" }}
                              >
                                <Typography variant="body1">
                                  {
                                    data.vacationDaysAvailability
                                      .vacationDaysCurrentYear.totalAvailable
                                  }
                                </Typography>
                              </Tooltip>

                              <Typography variant="body1">|</Typography>
                              <Tooltip
                                title={translationsEn.labels.currentPaidLeave}
                                arrow
                                style={{ height: "30%" }}
                              >
                                <Typography variant="body1">
                                  {
                                    data.vacationDaysAvailability
                                      .paidLeaveDaysCurrentYear.totalAvailable
                                  }
                                </Typography>
                              </Tooltip>

                              <Typography variant="body1">|</Typography>
                              <Tooltip
                                title={translationsEn.labels.currentBloodLeave}
                                arrow
                                style={{ height: "30%" }}
                              >
                                <Typography variant="body1">
                                  {" "}
                                  {
                                    data.vacationDaysAvailability
                                      .bloodDonationDaysCurrentYear
                                      .totalAvailable
                                  }
                                </Typography>
                              </Tooltip>

                              <Button
                                size="small"
                                onClick={() => handleGetEmployeeInfo(data.id)}
                              >
                                <Info
                                  sx={{
                                    color: themeOptions.palette.primary.main,
                                  }}
                                ></Info>
                              </Button>
                            </CardContent>
                          </Card>
                        </TableCell>
                      </TableRow>
                    ))}
                  {tableData?.employeeDtos?.slice(
                    page * pageSize,
                    page * pageSize + pageSize
                  ).length !== 0 &&
                    emptyRows > 0 && (
                      <TableRow style={{ height: 68 * emptyRows }}>
                        <TableCell colSpan={3} />
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              colSpan={3}
              count={tableData.employeeDtos?.length}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        {openManageMember && (
          <Grid item lg={6} sx={{ padding: "10px", height: "700px" }}>
            <Paper>
              <TableContainer sx={{ maxHeight: 501 }}>
                <Table stickyHeader aria-label="sticky table" style={{}}>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="left" style={{ fontWeight: "bold" }}>
                        {translationsEn.table.firstName}
                      </TableCell>
                      <TableCell align="left" style={{ fontWeight: "bold" }}>
                        {translationsEn.teams.lastName}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ height: "344px" }}>
                    {allEmployees.length === 0 && (
                      <TableRow style={{ height: 344 }}>
                        <TableCell colSpan={3} align="center">
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <>{translationsEn.table.noData}</>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {allEmployees.length === 0 && (
                      <TableRow style={{ height: 344 }}>
                        <TableCell colSpan={3} align="center">
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <>{translationsEn.table.noData}</>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {allEmployees.map((data: any) => {
                      const { employeeDto } = data;
                      const { firstName, lastName } = employeeDto;

                      return (
                        <TableRow key={data.employeeId}>
                          <TableCell align="left">
                            <Button
                              sx={{
                                backgroundColor:
                                  themeOptions.palette.primary.main,
                                color: "white",
                                ":hover": {
                                  background:
                                    themeOptions.palette.primary.hover,
                                },
                              }}
                              onClick={() =>
                                handleOpenAddMember(data.employeeDto.id)
                              }
                            >
                              <ArrowLeft />
                            </Button>
                          </TableCell>
                          <TableCell align="left">{firstName}</TableCell>
                          <TableCell align="left">{lastName}</TableCell>
                        </TableRow>
                      );
                    })}
                    {allEmployees?.employeeDtos?.slice(
                      page * pageSize,
                      page * pageSize + pageSize
                    ).length !== 0 &&
                      emptyRows > 0 && (
                        <TableRow style={{ height: 68 * emptyRows }}>
                          <TableCell colSpan={3} />
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        )}
      </Grid>

      <VactionDayModal
        open={openModal}
        handleClose={() => handleCloseModal()}
        employeeData={employeeData}
      />

      <EditTeamModal
        open={editTeamModal}
        handleClose={() => handleCloseModal()}
        data={tableData.teamDto}
      />

      <AddMemberModal
        open={openAddMember}
        handleClose={() => handleCloseModal()}
        data={tableData}
        employeeId={employeeId}
        handleMemberAdded={handleMemberAdded}
      />
    </div>
  );
}

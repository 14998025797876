import { Modal, Box, styled, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import translationsEn from "../../i18n/translations_en";
import moment from "moment";
import { useState, useEffect } from "react";
import { getDocuments, getEquipmentById } from "../../api/equipmentApi";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useAlert } from "../../utils/alertContext";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  paddingTop: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  border: "2px solid #ccc",
};

const Title = styled("h1")({
  margin: "auto",
  color: "gray",
  marginBottom: "20px",
});

const Content = styled("div")({
  width: "900px",
  margin: "auto",
  padding: "30px 10px",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  marginTop: "30px",
});

const Label = styled("p")({
  color: "grey",
});

const File = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "4px",
  margin: "4px",
  cursor: "pointer",
  backgroundColor: "#e6e6e6",
  borderRadius: "5px",
  maxWidth: "100px",
  textAlign: "center",
  wordBreak: "break-all",
});

export default function EquipmentPreviewModal(props?: any) {
  const { open, closeModal, data } = props;
  const { showAlert } = useAlert();
  const [equipmentData, setEquipmentData] = useState<any>({});

  useEffect(() => {
    if (data.id) {
      getEquipmentById(data.id)
        .then((response) => {
          setEquipmentData(response.data.data);
        })
        .catch((error) => showAlert(error, "error"));
    }
  }, [data]);

  const handleDownload = async (
    inventoryId: any,
    documentId: any,
    defaultFilename: string
  ) => {
    try {
      const { blob, headers } = await getDocuments(inventoryId, documentId);

      console.log("Response Headers:", headers);

      const contentDisposition = headers["content-disposition"];
      let filename = defaultFilename;

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (filenameMatch?.[1]) {
          filename = filenameMatch[1];

          const parts = filename.split("__");
          if (parts.length > 1) {
            filename = parts.slice(-1)[0];
          }

          filename = filename.replace(/^.*__/, "");
          filename = filename.trim();
        }
      }

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      link.remove();

      showAlert("Successfully downloaded", "success");
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Modal open={open} onClose={() => {}}>
      <Box sx={style}>
        <CloseIcon
          onClick={() => {
            closeModal();
            setEquipmentData({});
          }}
          style={{
            position: "fixed",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
        />
        <Title>{equipmentData.name}</Title>
        <div
          style={{ height: "600px", overflow: "auto", paddingBottom: "20px" }}
        >
          <Content>
            <Grid container spacing={1}>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.equipment.name}</Label>
                <p>{equipmentData.name}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.equipment.status}</Label>
                <p>{equipmentData.status}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.equipment.price}</Label>
                <p>{equipmentData.price}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.equipment.warranty}</Label>
                <p>
                  {equipmentData.warrantyStart &&
                    moment(equipmentData.warrantyStart).format("DD.MM.YYYY") +
                      "-" +
                      moment(equipmentData.warrantyEnd).format("DD.MM.YYYY")}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.equipment.usingFrom}</Label>
                <p>
                  {equipmentData.usingFrom &&
                    moment(equipmentData.usingFrom).format("DD.MM.YYYY")}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.equipment.quantity}</Label>
                <p>{equipmentData.quantity}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.equipment.assignedTo}</Label>
                <p>
                  {equipmentData?.assignedTo &&
                    equipmentData?.assignedTo?.firstName +
                      " " +
                      equipmentData?.assignedTo?.lastName}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.equipment.lastEdit}</Label>
                <p>{moment(equipmentData.lastEdit).format("DD.MM.YYYY")}</p>
              </Grid>
              <Grid item xs={5} direction={"column"}>
                <Label>{translationsEn.equipment.description}</Label>
                <p>{equipmentData.description}</p>
              </Grid>
              <Grid item xs={12}>
                <Label>{translationsEn.equipment.files}</Label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    overflow: "auto",
                  }}
                >
                  {equipmentData?.documents?.map((item: any) => {
                    const formattedFilename = item.filename
                      .split("__")
                      .slice(-1)[0];
                    return (
                      <File
                        key={item.id}
                        onClick={() =>
                          handleDownload(
                            equipmentData.id,
                            item.id,
                            `${formattedFilename}`
                          )
                        }
                      >
                        <InsertDriveFileIcon fontSize="large" />
                        {formattedFilename}
                      </File>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </Content>
        </div>
      </Box>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  FormControl,
  TextField,
  Switch,
  Button,
  Grid,
  Autocomplete,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import translationsEn from "../../i18n/translations_en";
import { ICompany } from "../../interfaces/teams.interface";
import themeOptions from "../../style/componentsStyle";
import { createTeam } from "../../api/teamsApi";
import { getAllCompanies } from "../../api/overviewApi";
import SavedModal from "../confirmationsModal/saveModalMessage";

interface createNewTeamProps {
  open: boolean;
  handleClose: () => void;
  data?: ICompany[];
  setData: any;
  children?: React.ReactNode;
}

const NewTeamModal: React.FC<createNewTeamProps> = ({
  open,
  handleClose,
  data,
  setData,
}) => {
  const handleModalClose = () => {
    handleClose();
  };

  const [selectCompany, setSelectedCompany] = useState<any>(undefined);
  const [selectAllCompany, setAllCompany] = useState<any>([]);
  const [savedModalOpen, setSavedModalOpen] = useState(false);

  useEffect(() => {
    getAllCompanies()
      .then((response) => setAllCompany(response))
      .catch((error) => console.error("Failed to fetch companies:", error));
  }, []);

  const validationSchema = Yup.object({
    teamName: Yup.string().required("Team name is required"),
    companies: Yup.array().min(1, "At least one company must be selected"),
  });

  const handleSaveTeam = (values: any, { setSubmitting }: any) => {
    const companyIds = values.companies.map((company: any) => company.id);
    const companyDtos = selectAllCompany.filter((company: any) =>
      values.companies.some(
        (selectedCompany: any) => selectedCompany.id === company.id
      )
    );

    const payload = {
      id: null,
      name: values.teamName,
      active: values.activeTeam,
      companyIds: companyIds,
      companyDtos: companyDtos,
    };

    createTeam(payload)
      .then((response) => {
        setSelectedCompany(undefined);
        setData((oldData: any) => [...oldData, response.data]);
        setSavedModalOpen(true);
        handleClose();
      })
      .catch((error) => console.error("Failed to save team:", error))
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open}>
          <Paper
            style={{
              padding: "20px",
              textAlign: "center",
              width: "20%",
              height: "35%",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
                {translationsEn.teamsInformation.newTeam}
              </Typography>
              <Clear onClick={handleModalClose} />
            </Box>
            <hr />
            <Formik
              initialValues={{
                teamName: "",
                companies: [],
                activeTeam: false,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSaveTeam}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <Form>
                  <Grid container direction="column" alignItems="flex-start">
                    <Field
                      as={TextField}
                      name="teamName"
                      label={translationsEn.labels.teamName}
                      variant="outlined"
                      value={values.teamName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.teamName && Boolean(errors.teamName)}
                      helperText={touched.teamName && errors.teamName}
                      fullWidth
                      margin="normal"
                    />

                    <FormControl fullWidth margin="normal">
                      <Autocomplete
                        multiple
                        id="team-select-autocomplete"
                        options={selectAllCompany}
                        getOptionLabel={(option: any) => option.name}
                        value={values.companies}
                        onChange={(event, newValue) =>
                          setFieldValue("companies", newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Companies"
                            placeholder="Search and select"
                            error={
                              touched.companies && Boolean(errors.companies)
                            }
                            helperText={touched.companies && errors.companies}
                          />
                        )}
                      />
                    </FormControl>

                    <Grid container direction="column" padding="10px">
                      <Typography>
                        {translationsEn.labels.active}
                        <Switch
                          checked={values.activeTeam}
                          onChange={() =>
                            setFieldValue("activeTeam", !values.activeTeam)
                          }
                          value={values.activeTeam}
                          color={values.activeTeam ? "primary" : "default"}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <hr />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ background: themeOptions.palette.confirm.main }}
                    >
                      {translationsEn.buttons.save}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Paper>
        </Fade>
      </Modal>
      <SavedModal
        open={savedModalOpen}
        handleClose={() => setSavedModalOpen(false)}
      />
    </>
  );
};

export default NewTeamModal;

import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import {
  Business,
  ExpandLess,
  ExpandMore,
  FindInPage,
  FlightTakeoff,
  Group,
  GroupAddOutlined,
  History,
  LanOutlined,
  LockOutlined,
  ManageAccountsOutlined,
  Person,
  Phone,
  SchoolOutlined,
  HourglassTop,
  WatchLater,
  BadgeOutlined,
  Dashboard,
  Task,
} from "@mui/icons-material";
import { useState } from "react";
import themeOptions from "../../../../style/componentsStyle";
import { NavLink } from "react-router-dom";
import translationsEn from "../../../../i18n/translations_en";
import { CalendarIcon } from "@mui/x-date-pickers";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../../../../provider/authProvider";

export default function DefaultSidebar() {
  const [openEmployee, setOpenEmployee] = useState(false);
  const [openRequests, setOpenRequests] = useState(false);
  const [openInventory, setOpenInventory] = useState(false);
  const [openCallDuty, setOpenCallDuty] = useState(false);
  const [openInventoryManagement, setOpenInventoryManagement] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openEquipment, setOpenEquipment] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { token } = useAuth();
  const decoded: any = jwtDecode(token);

  const handleItemClick = (itemName: any) => {
    setSelectedItem(itemName);

    switch (itemName) {
      case "employeeManagement":
        setOpenEmployee(!openEmployee);
        break;
      case "requests":
        setOpenRequests(!openRequests);
        break;
      case "inventory":
        setOpenInventory(!openInventory);
        break;
      case "admin":
        setOpenAdmin(!openAdmin);
        break;
      case "equipment":
        setOpenEquipment(!openEquipment);
        break;
      case "callDuty":
        setOpenCallDuty(!openCallDuty);
        break;
      case "openInventoryManagement":
        setOpenInventoryManagement(!openInventoryManagement);
        break;
      default:
        break;
    }
  };

  return (
    <aside
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: themeOptions.palette.sideBar.main,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
        fontSize: "20px",
        overflow: "auto",
      }}
    >
      <List style={{ margin: 0 }}>
        <ListItem
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <ListItemButton
            style={{
              width: "100%",
              backgroundColor:
                selectedItem === "dashboardOverview"
                  ? "#02214A"
                  : "transparent",
            }}
            onClick={() => handleItemClick("dashboardOverview")}
          >
            <NavLink
              to="/"
              style={{
                textDecoration: "none",
                color: selectedItem === "dashboardOverview" ? "white" : "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Dashboard />
              <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                {translationsEn.sidebar.dashboard}
              </Typography>
            </NavLink>
          </ListItemButton>
          <ListItemButton
            style={{
              width: "100%",
              backgroundColor:
                selectedItem === "employeeOverview" ? "#02214A" : "transparent",
            }}
            onClick={() => handleItemClick("employeeOverview")}
          >
            <NavLink
              to="/overview"
              style={{
                textDecoration: "none",
                color: selectedItem === "employeeOverview" ? "white" : "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <BadgeOutlined />
              <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                {translationsEn.sidebar.employeeOverview}
              </Typography>
            </NavLink>
          </ListItemButton>

          <ListItemButton
            style={{
              width: "100%",
              color: selectedItem === "employeeManagement" ? "white" : "white",
              backgroundColor:
                selectedItem === "employeeManagement"
                  ? "#02214A"
                  : "transparent",
            }}
            onClick={() => handleItemClick("employeeManagement")}
          >
            <ManageAccountsOutlined />
            <Typography
              style={{
                marginLeft: "10px",
                fontSize: "large",
              }}
            >
              {translationsEn.sidebar.employeeManagement}
            </Typography>
            <div style={{ position: "absolute", right: 0 }}>
              {openEmployee ? <ExpandLess /> : <ExpandMore />}
            </div>
          </ListItemButton>

          <Collapse
            style={{
              width: "100%",
              color: selectedItem === "openEmployee" ? "white" : "white",
            }}
            in={openEmployee}
            timeout="auto"
            unmountOnExit
          >
            <List>
              <ListItemButton sx={{ pl: 4 }}>
                <NavLink
                  to="/employees"
                  style={{
                    textDecoration: "none",
                    color: selectedItem === "employees" ? "white" : "white",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor:
                      selectedItem === "employees" ? "#02214A" : "transparent",
                  }}
                  onClick={() => handleItemClick("employees")}
                >
                  <Group />
                  <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                    {translationsEn.sidebar.employees}
                  </Typography>
                </NavLink>
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton>
            <NavLink
              to="/workOrders"
              style={{
                textDecoration: "none",
                color: selectedItem === "workOrders" ? "white" : "white",
                display: "flex",
                alignItems: "center",
                backgroundColor:
                  selectedItem === "workOrders" ? "#02214A" : "transparent",
              }}
              onClick={() => handleItemClick("workOrders")}
            >
              <Task />
              <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                {translationsEn.sidebar.workOrders}
              </Typography>
            </NavLink>
          </ListItemButton>
          <ListItemButton
            sx={{
              width: "100%",
              color: selectedItem === "callDuty" ? "white" : "white",
              backgroundColor:
                selectedItem === "callDuty" ? "#02214A" : "transparent",
            }}
            onClick={() => handleItemClick("callDuty")}
          >
            <HourglassTop />
            <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
              {translationsEn.sidebar.shifts}
            </Typography>
            <div style={{ position: "absolute", right: 0 }}>
              {openCallDuty ? <ExpandLess /> : <ExpandMore />}
            </div>
          </ListItemButton>
          <Collapse in={openCallDuty} timeout="auto" unmountOnExit>
            <List>
              <ListItemButton sx={{ pl: 5 }}>
                <NavLink
                  to="/call-duty"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor:
                      selectedItem === "scheduler" ? "#02214A" : "transparent",
                  }}
                  onClick={() => handleItemClick("scheduler")}
                >
                  <CalendarIcon />
                  <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                    {translationsEn.sidebar.scheduler}
                  </Typography>
                </NavLink>
              </ListItemButton>
              <ListItemButton sx={{ pl: 5 }}>
                <NavLink
                  to="/activeDuties"
                  style={{
                    textDecoration: "none",
                    color: selectedItem === "active" ? "white" : "white",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor:
                      selectedItem === "active" ? "#02214A" : "transparent",
                  }}
                  onClick={() => handleItemClick("active")}
                >
                  <Phone />
                  <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                    {translationsEn.sidebar.active}
                  </Typography>
                </NavLink>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <NavLink
                  to="/shiftRecords"
                  style={{
                    textDecoration: "none",
                    color: selectedItem === "shift" ? "white" : "white",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor:
                      selectedItem === "shift" ? "#02214A" : "transparent",
                  }}
                  onClick={() => handleItemClick("shift")}
                >
                  <WatchLater />
                  <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                    {translationsEn.sidebar.shift}
                  </Typography>
                </NavLink>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <NavLink
                  to="/overtimes"
                  style={{
                    textDecoration: "none",
                    color: selectedItem === "overtimes" ? "white" : "white",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor:
                      selectedItem === "overtimes" ? "#02214A" : "transparent",
                  }}
                  onClick={() => handleItemClick("overtimes")}
                >
                  <History />
                  <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                    {translationsEn.sidebar.overtimes}
                  </Typography>
                </NavLink>
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton
            style={{
              width: "100%",
              color: selectedItem === "requests" ? "white" : "white",
              backgroundColor:
                selectedItem === "requests" ? "#02214A" : "transparent",
            }}
            onClick={() => handleItemClick("requests")}
          >
            <GroupAddOutlined />
            <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
              {" "}
              {translationsEn.sidebar.requests}
            </Typography>
            <div style={{ position: "absolute", right: 0 }}>
              {openRequests ? <ExpandLess /> : <ExpandMore />}
            </div>
          </ListItemButton>
          <Collapse in={openRequests} timeout="auto" unmountOnExit>
            <List>
              <ListItemButton sx={{ pl: 4 }}>
                <NavLink
                  to="teamHistoryRequest"
                  style={{
                    textDecoration: "none",
                    color: selectedItem === "teamHistory" ? "white" : "white",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor:
                      selectedItem === "teamHistory"
                        ? "#02214A"
                        : "transparent",
                  }}
                  onClick={() => handleItemClick("teamHistory")}
                >
                  <FindInPage />
                  <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                    {translationsEn.sidebar.teamHistory}
                  </Typography>
                </NavLink>
              </ListItemButton>

              <ListItemButton sx={{ pl: 4 }}>
                <NavLink
                  to="myRequest"
                  style={{
                    textDecoration: "none",
                    color: selectedItem === "myRequest" ? "white" : "white",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor:
                      selectedItem === "myRequest" ? "#02214A" : "transparent",
                  }}
                  onClick={() => handleItemClick("myRequest")}
                >
                  <Person />
                  <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                    {translationsEn.sidebar.myRequest}
                  </Typography>
                </NavLink>
              </ListItemButton>

              <ListItemButton sx={{ pl: 4 }}>
                <NavLink
                  to="absenceRequest"
                  style={{
                    textDecoration: "none",
                    color: selectedItem === "absence" ? "white" : "white",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor:
                      selectedItem === "absence" ? "#02214A" : "transparent",
                  }}
                  onClick={() => handleItemClick("absence")}
                >
                  <FlightTakeoff />
                  <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                    {translationsEn.sidebar.absence}
                  </Typography>
                </NavLink>
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton>
            <NavLink
              to="/teams"
              style={{
                textDecoration: "none",
                color: selectedItem === "teams" ? "white" : "white",
                display: "flex",
                alignItems: "center",
                backgroundColor:
                  selectedItem === "teams" ? "#02214A" : "transparent",
              }}
              onClick={() => handleItemClick("teams")}
            >
              <LanOutlined />
              <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                {" "}
                {translationsEn.sidebar.teams}
              </Typography>
            </NavLink>
          </ListItemButton>
          {/* 
          <ListItemButton
            style={{
              width: "100%",
              color: selectedItem === "inventory" ? "white" : "white",
              backgroundColor:
                selectedItem === "inventory" ? "#02214A" : "transparent",
            }}
            onClick={() => handleItemClick("inventory")}
          >
            <WarehouseOutlined />
            <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
              {" "}
              {translationsEn.sidebar.inventory}
            </Typography>
            <div style={{ position: "absolute", right: 0 }}>
              {openInventory ? <ExpandLess /> : <ExpandMore />}
            </div>
          </ListItemButton>
          <Collapse
            style={{
              width: "100%",
              color: selectedItem === "openInventory" ? "white" : "white",
            }}
            in={openInventory}
            timeout="auto"
            unmountOnExit
          >
            <List>
              <ListItemButton sx={{ pl: 4 }}>
                <NavLink
                  to="/userInventory"
                  style={{
                    textDecoration: "none",
                    color: selectedItem === "userInventory" ? "white" : "white",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor:
                      selectedItem === "userInventory"
                        ? "#02214A"
                        : "transparent",
                  }}
                  onClick={() => handleItemClick("userInventory")}
                >
                  <FolderShared />
                  <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                    {translationsEn.sidebar.userInventory}
                  </Typography>
                </NavLink>
              </ListItemButton>

              <ListItemButton
                sx={{
                  pl: 4,
                  color:
                    selectedItem === "openInventoryManagement"
                      ? "white"
                      : "white",
                  backgroundColor:
                    selectedItem === "openInventoryManagement"
                      ? "#02214A"
                      : "transparent",
                }}
                onClick={() => handleItemClick("openInventoryManagement")}
              >
                <Inventory />
                <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                  {translationsEn.sidebar.inventoryManagement}
                </Typography>
                <div style={{ position: "absolute", right: 0 }}>
                  {openInventoryManagement ? <ExpandLess /> : <ExpandMore />}
                </div>
              </ListItemButton>
              <Collapse
                in={openInventoryManagement}
                timeout="auto"
                unmountOnExit
              >
                <List>
                  <ListItemButton sx={{ pl: 5 }}>
                    <NavLink
                      to="/equipment"
                      style={{
                        textDecoration: "none",
                        color: selectedItem === "equipment" ? "white" : "white",
                        display: "flex",
                        alignItems: "center",

                        backgroundColor:
                          selectedItem === "equipment"
                            ? "#02214A"
                            : "transparent",
                      }}
                      onClick={() => handleItemClick("equipment")}
                    >
                      <Search />
                      <Typography
                        style={{ marginLeft: "10px", fontSize: "large" }}
                      >
                        {translationsEn.sidebar.equipment}
                      </Typography>
                    </NavLink>
                  </ListItemButton>
                  <ListItemButton sx={{ pl: 5 }}>
                    <NavLink
                      to="/budget"
                      style={{
                        textDecoration: "none",
                        color: selectedItem === "budget" ? "white" : "white",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor:
                          selectedItem === "budget" ? "#02214A" : "transparent",
                      }}
                      onClick={() => handleItemClick("budget")}
                    >
                      <ShoppingCart />
                      <Typography
                        style={{ marginLeft: "10px", fontSize: "large" }}
                      >
                        {translationsEn.sidebar.budget}
                      </Typography>
                    </NavLink>
                  </ListItemButton>
                </List>
              </Collapse>
            </List>
          </Collapse> */}

          <ListItemButton>
            <NavLink
              to="/travel-requests"
              style={{
                textDecoration: "none",
                color: selectedItem === "travel-requests" ? "white" : "white",
                display: "flex",
                alignItems: "center",
                backgroundColor:
                  selectedItem === "travel-requests"
                    ? "#02214A"
                    : "transparent",
              }}
              onClick={() => handleItemClick("travel-requests")}
            >
              <SchoolOutlined />
              <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                {translationsEn.sidebar.travelRequests}
              </Typography>
            </NavLink>
          </ListItemButton>
          <ListItemButton
            style={{
              width: "100%",
              color: selectedItem === "equipment" ? "white" : "white",
              backgroundColor:
                selectedItem === "equipment" ? "#02214A" : "transparent",
            }}
            onClick={() => handleItemClick("equipment")}
          >
            <LockOutlined />
            <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
              {translationsEn.sidebar.equipment}
            </Typography>
            <div style={{ position: "absolute", right: 0 }}>
              {openEquipment ? <ExpandLess /> : <ExpandMore />}
            </div>
          </ListItemButton>

          <Collapse in={openEquipment} timeout="auto" unmountOnExit>
            <List>
              <ListItemButton sx={{ pl: 4 }}>
                <NavLink
                  to="/my-equipment"
                  style={{
                    textDecoration: "none",
                    color: selectedItem === "equipment" ? "white" : "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Business />
                  <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                    {translationsEn.sidebar.myEquipment}
                  </Typography>
                </NavLink>
              </ListItemButton>
            </List>
            <List>
              {decoded.role.name === "ROLE_ADMIN" && (
                <ListItemButton sx={{ pl: 4 }}>
                  <NavLink
                    to="/equipment"
                    style={{
                      textDecoration: "none",
                      color: selectedItem === "equipment" ? "white" : "white",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Business />
                    <Typography
                      style={{ marginLeft: "10px", fontSize: "large" }}
                    >
                      {translationsEn.sidebar.equipment}
                    </Typography>
                  </NavLink>
                </ListItemButton>
              )}
            </List>
          </Collapse>
          <ListItemButton>
            <NavLink
              to="/cv"
              style={{
                textDecoration: "none",
                color: selectedItem === "cv" ? "white" : "white",
                display: "flex",
                alignItems: "center",
                backgroundColor:
                  selectedItem === "cv" ? "#02214A" : "transparent",
              }}
              onClick={() => handleItemClick("cv")}
            >
              <SchoolOutlined />
              <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                {" "}
                {translationsEn.sidebar.cv}
              </Typography>
            </NavLink>
          </ListItemButton>

          <ListItemButton
            style={{
              width: "100%",
              color: selectedItem === "admin" ? "white" : "white",
              backgroundColor:
                selectedItem === "admin" ? "#02214A" : "transparent",
            }}
            onClick={() => handleItemClick("admin")}
          >
            <LockOutlined />
            <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
              {" "}
              {translationsEn.sidebar.admim}
            </Typography>
            <div style={{ position: "absolute", right: 0 }}>
              {openAdmin ? <ExpandLess /> : <ExpandMore />}
            </div>
          </ListItemButton>

          <Collapse in={openAdmin} timeout="auto" unmountOnExit>
            <List>
              <ListItemButton sx={{ pl: 4 }}>
                <NavLink
                  to="/company"
                  style={{
                    textDecoration: "none",
                    color: selectedItem === "companies" ? "white" : "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Business />
                  <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
                    {translationsEn.sidebar.companies}
                  </Typography>
                </NavLink>
              </ListItemButton>
            </List>
          </Collapse>
        </ListItem>
      </List>
    </aside>
  );
}

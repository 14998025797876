import {
  Paper,
  Typography,
} from "@mui/material";
import themeOptions from "../../style/componentsStyle";
import translationsEn from "../../i18n/translations_en";
import { styled } from "@mui/system";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../provider/authProvider";
import { jwtDecode } from "jwt-decode";

const PageContainer = styled("div")({
  height: "100%",
  padding: "10px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

const Title = styled(Typography)({
  marginLeft: "10px",
  fontSize: "40px",
});

const MenuItem = styled(Paper)({
  borderRadius: "10px",
  height: "150px",
  marginBottom: "10px",
  position: "relative",
  padding: "20px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
});

const MenuImage = styled("img")({
  height: "150px",
  width: "190px",
  borderRadius: "10px",
  position: "absolute",
});

const MenuItemContent = styled("div")({
  marginLeft: "200px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const MenuItemBottomText = styled("div")({
  fontSize: "17px",
  fontWeight: "bold",
  color: themeOptions.palette.primary.main,
});

export default function TravelRequests() {
  const { token } = useAuth();
  
  const decoded:any = jwtDecode(token);

  return (
    <PageContainer>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <Title>{translationsEn.travelRequests.title}</Title>
      </Paper>

      <div style={{ width: "100%", height: "100%" }}>
        <NavLink
          to="/create-travel-requests"
          style={{ textDecoration: "none" }}
        >
          <MenuItem variant="outlined">
            <MenuImage src="/images/create.jpg" alt="test" />
            <MenuItemContent>
              <div>
                <h2 style={{ marginTop: "5px" }}>
                  {translationsEn.travelRequests.createRequest}
                </h2>
                <p style={{ color: "darkgray" }}>
                  {translationsEn.travelRequests.createText}
                </p>
              </div>
              <MenuItemBottomText>
                {translationsEn.travelRequests.addRequest}
              </MenuItemBottomText>
            </MenuItemContent>
          </MenuItem>
        </NavLink>
        <NavLink to="/my-travel-requests" style={{ textDecoration: "none" }}>
          <MenuItem variant="outlined">
            <MenuImage src="/images/myRequests.jpg" alt="test" />
            <MenuItemContent>
              <div>
                <h2 style={{ marginTop: "5px" }}>
                  {translationsEn.travelRequests.myRequests}
                </h2>
                <p style={{ color: "darkgray" }}>
                  {translationsEn.travelRequests.myText}
                </p>
              </div>
              <MenuItemBottomText>
                {translationsEn.travelRequests.viewMyRequests}
              </MenuItemBottomText>
            </MenuItemContent>
          </MenuItem>
        </NavLink>
        {decoded.role.name === "ROLE_ADMIN" && (
          <NavLink to="/all-travel-requests" style={{ textDecoration: "none" }}>
            <MenuItem variant="outlined">
              <MenuImage src="/images/allRequests.jpg" alt="test" />
              <MenuItemContent>
                <div>
                  <h2 style={{ marginTop: "5px" }}>
                    {translationsEn.travelRequests.allTravelRequests}
                  </h2>
                  <p style={{ color: "darkgray" }}>
                    {translationsEn.travelRequests.allText}
                  </p>
                </div>
                <MenuItemBottomText>
                  {translationsEn.travelRequests.viewAllRequests}
                </MenuItemBottomText>
              </MenuItemContent>
            </MenuItem>
          </NavLink>
        )}
      </div>
    </PageContainer>
  );
}

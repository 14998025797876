import { Info, Logout, Menu } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import themeOptions from "../../../../style/componentsStyle";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../provider/authProvider";
import { getEmployeeById } from "../../../../api/overviewApi";
import translationsEn from "../../../../i18n/translations_en";
import { getVacationDaysPerEmployee } from "../../../../api/teamsApi";
import VactionDayModal from "../../../../modals/teamsInformation/vacationDayModal";
import logo from "../../../../logoOfficePal.png";

export default function DefaultHeader({
  toggleSidebar,
}: {
  toggleSidebar: () => void;
}) {
  const handleMenuClick = () => {
    toggleSidebar();
  };

  const { loggedUserId, logout } = useAuth();
  const [loggedUserFullname, setLoggedUserFullname] = useState("");
  const [loggedUsername, setLoggedUsername] = useState<any>();
  const [openLogoutConfirmDialog, setOpenLogoutConfirmDialog] = useState(false);
  const [employeeData, setEmployeeData] = useState<any>();
  const [openModal, setOpenModal] = useState(false);

  const handleLogoutBtnClick = () => {
    setOpenLogoutConfirmDialog(true);
  };

  const handleClose = () => {
    setOpenLogoutConfirmDialog(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleLogout = () => {
    logout();
    // Perform actual logout operations here
    handleClose();
  };

  const handleGetEmployeeInfo = async (loggedUserId: any) => {
    getVacationDaysPerEmployee(loggedUserId)
      .then((response) => {
        setEmployeeData(response.data);
        setOpenModal(true);
      })
      .catch((error) => console.error("Failed to fetch employee data:", error));
  };

  useEffect(() => {
    if (loggedUserId) {
      const fetchUserData = async () => {
        try {
          const userData = await getEmployeeById(loggedUserId);
          const response = await getVacationDaysPerEmployee(loggedUserId);
          setEmployeeData(response.data);
          setLoggedUserFullname(userData.firstName + " " + userData.lastName);
          setLoggedUsername(userData.username);
        } catch (error) {
          console.error("Failed to fetch user data", error);
        }
      };

      fetchUserData();
    }
  }, [loggedUserId]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          sx={{
            backgroundColor: themeOptions.palette.primary.main,
          }}
          position="static"
        >
          <Toolbar style={{ height: "100%" }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleMenuClick}
            >
              <Menu />
            </IconButton>
            <Grid sx={{ flexGrow: 1 }}>
              {" "}
              <img
                src={logo}
                alt="Logo"
                style={{ height: "80px", padding: "5px" }}
              />
            </Grid>

            {employeeData && (
              <Card
                sx={{
                  backgroundColor: themeOptions.palette.primary.main,
                  border: "1px solid white",
                }}
                style={{ height: "15%", width: "120px", marginRight: "10px" }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "10px",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={translationsEn.labels.previousYearVacation}
                    arrow
                    style={{ height: "30%" }}
                  >
                    <Typography variant="body1" style={{ color: "white" }}>
                      {
                        employeeData?.vacationDaysAvailabilityDto
                          ?.vacationDaysPreviousYear?.totalAvailable
                      }
                    </Typography>
                  </Tooltip>

                  <Typography variant="body1" style={{ color: "white" }}>
                    |
                  </Typography>
                  <Tooltip
                    title={translationsEn.labels.currentYearVacation}
                    arrow
                    style={{ height: "30%" }}
                  >
                    <Typography variant="body1" style={{ color: "white" }}>
                      {
                        employeeData?.vacationDaysAvailabilityDto
                          ?.vacationDaysCurrentYear?.totalAvailable
                      }
                    </Typography>
                  </Tooltip>

                  <Typography variant="body1" style={{ color: "white" }}>
                    |
                  </Typography>
                  <Tooltip
                    title={translationsEn.labels.currentPaidLeave}
                    arrow
                    style={{ height: "30%" }}
                  >
                    <Typography variant="body1" style={{ color: "white" }}>
                      {
                        employeeData?.vacationDaysAvailabilityDto
                          ?.paidLeaveDaysCurrentYear?.totalAvailable
                      }
                    </Typography>
                  </Tooltip>

                  <Typography variant="body1" style={{ color: "white" }}>
                    |
                  </Typography>

                  <Tooltip
                    title={translationsEn.labels.currentBloodLeave}
                    arrow
                    style={{ height: "30%" }}
                  >
                    <Typography variant="body1" style={{ color: "white" }}>
                      {" "}
                      {
                        employeeData?.vacationDaysAvailabilityDto
                          ?.bloodDonationDaysCurrentYear?.totalAvailable
                      }
                    </Typography>
                  </Tooltip>

                  <Button
                    size="small"
                    onClick={() => handleGetEmployeeInfo(loggedUserId)}
                  >
                    <Info
                      sx={{
                        color: "white",
                        marginRight: "3px",
                      }}
                    ></Info>
                  </Button>
                </CardContent>
              </Card>
            )}
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ marginRight: 2 }}
            >
              {loggedUserFullname}
              <Typography
                variant="caption" // Smaller text for the username
                component="div"
                sx={{
                  display: "block", // Makes it appear on a new line below the full name
                  fontSize: "smaller", // You can adjust this to fit your design
                  color: "gray", // Optional: make the username a different color to distinguish it
                }}
              >
                {loggedUsername}
              </Typography>
            </Typography>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              onClick={handleLogoutBtnClick}
            >
              <Logout />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Dialog
          open={openLogoutConfirmDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                color: "black",
              }}
            >
              {translationsEn.labels.logoutMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
              }}
              onClick={handleClose}
            >
              {translationsEn.buttons.cancel}
            </Button>
            <Button
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
              }}
              onClick={handleLogout}
              autoFocus
            >
              {translationsEn.buttons.logout}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <VactionDayModal
        open={openModal}
        handleClose={() => handleModalClose()}
        employeeData={employeeData}
      />
    </>
  );
}

import { Box, Paper } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import DefaultHeader from "./layout-components/header/DefaultHeader";
import DefaultSidebar from "./layout-components/sidebar/DefaultSidebar";
import { Outlet } from "react-router-dom";

export default function DefaultLayout() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const getSidebarWidth = () => {
    if (location.pathname === "/activeDuties") {
      return "270px";
    }
    if (location.pathname === "/overtimes") {
      return "270px";
    }
    return isSidebarVisible ? "270px" : "0";
  };

  const sidebarStyle: React.CSSProperties = {
    width: getSidebarWidth(),
    transition: "width 0.3s ease-in-out",
    height: "calc(100vh - 64px)",
    overflow: "hidden",
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundImage: 'url("/background_content.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <DefaultHeader toggleSidebar={toggleSidebar} />
      <Box sx={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
        <div style={sidebarStyle}>{isSidebarVisible && <DefaultSidebar />}</div>
        <Paper
          elevation={0}
          sx={{ flexGrow: 1, overflowY: "auto", maxHeight: "100%" }}
        >
          <Outlet />
        </Paper>
      </Box>
    </Box>
  );
}

// overtimeConstants.ts
export enum OVERTIME_STATUS {
    APPROVED = 1,
    WAITING_FOR_APPROVAL = 2,
    DECLINED = 3,
}

export const calculationOvertimeStatuses = [
    {
        label: "Approved",
        value: OVERTIME_STATUS.APPROVED,
    },
    {
        label: "Waiting for approval",
        value: OVERTIME_STATUS.WAITING_FOR_APPROVAL,
    },
    {
        label: "Declined",
        value: OVERTIME_STATUS.DECLINED,
    },
];

import { Modal, Box, styled, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import translationsEn from "../../i18n/translations_en";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  getTravelDocument,
  getTravelRequestByID,
} from "../../api/travelRequestApi";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useAlert } from "../../utils/alertContext";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  paddingTop: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  border: "2px solid #ccc",
};

const Title = styled("h1")({
  margin: "auto",
  color: "gray",
  marginBottom: "20px",
});

const Content = styled("div")({
  width: "900px",
  margin: "auto",
  padding: "30px 10px",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  marginTop: "30px",
});

const Label = styled("p")({
  color: "grey",
});

const File = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100px",
  fontSize: "15px",
  backgroundColor: "#e6e6e6",
  borderRadius: "5px",
  padding: "4px",
  margin: "4px",
  cursor: "pointer",
});

export default function TravelRequestPreviewModal(props?: any) {
  const { open, closeModal, data } = props;
  const { showAlert } = useAlert();
  const [travelRequestData, setTravelRequestData] = useState<any>({});

  const handleDownload = async (
    travelRequestId: any,
    documentId: any,
    defaultFilename: string
  ) => {
    try {
      const { blob, headers } = await getTravelDocument(
        travelRequestId,
        documentId
      );

      console.log("Response Headers:", headers);

      const contentDisposition = headers["content-disposition"];
      let filename = defaultFilename;

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (filenameMatch?.[1]) {
          filename = filenameMatch[1];

          const parts = filename.split("__");
          if (parts.length > 1) {
            filename = parts.slice(-1)[0];
          }

          filename = filename.replace(/^.*__/, "").trim();
        }
      }

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      link.remove();

      showAlert("Successfully downloaded", "success");
    } catch (error) {
      console.error("Error downloading file:", error);
      showAlert("Failed to download the file", "error");
    }
  };

  useEffect(() => {
    if (data.id) {
      getTravelRequestByID(data.id)
        .then((response) => {
          setTravelRequestData(response.data.data);
        })
        .catch((error) => showAlert(error, "error"));
    }
  }, [data]);

  return (
    <Modal open={open} onClose={() => {}}>
      <Box sx={style}>
        <CloseIcon
          onClick={() => closeModal()}
          style={{
            position: "fixed",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
        />
        <Title>Travel request no.{data.id}</Title>
        <div
          style={{ height: "700px", overflow: "auto", paddingBottom: "20px" }}
        >
          <Content>
            <h3>General information</h3>
            <Grid container spacing={1}>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.user}</Label>
                <p>
                  {travelRequestData?.employeeDto?.firstName +
                    " " +
                    travelRequestData.employeeDto?.lastName || "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.company}</Label>
                <p>{travelRequestData.companyDto?.name || "No data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.travelsDay}</Label>
                <p>
                  {moment(travelRequestData.travelsday).format("DD.MM.YYYY") ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.travelTo}</Label>
                <p>{travelRequestData.travelTo || "No data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.travelCanLast}
                </Label>
                <p>{travelRequestData.travelDuration || "No data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.advancePayment}
                </Label>
                <p>{travelRequestData.advancePayment || "No data"}</p>
              </Grid>
            </Grid>
          </Content>
          <Content>
            <h3>Additional information</h3>
            <Grid container spacing={1}>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.departureDate}
                </Label>
                <p>
                  {(travelRequestData.travelRequestCalculationDto
                    ?.departureDate &&
                    moment(
                      travelRequestData.travelRequestCalculationDto
                        ?.departureDate
                    ).format("DD.MM.YYYY")) ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.departureTime}
                </Label>
                <p>
                  {travelRequestData.travelRequestCalculationDto
                    ?.departureTime || "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.returnDate}</Label>
                <p>
                  {(travelRequestData.travelRequestCalculationDto?.returnDate &&
                    moment(
                      travelRequestData.travelRequestCalculationDto?.returnDate
                    ).format("DD.MM.YYYY")) ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.returnTime}</Label>
                <p>
                  {travelRequestData.travelRequestCalculationDto?.returnTime ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.durationHours}
                </Label>
                <p>
                  {travelRequestData.travelRequestCalculationDto
                    ?.durationHours || "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.dailyAlowances}
                </Label>
                <p>
                  {travelRequestData.travelRequestCalculationDto
                    ?.numberOfAllowances || "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.dailyAmount}</Label>
                <p>
                  {travelRequestData.travelRequestCalculationDto?.dailyAmount ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.layUpClaim}</Label>
                <p>
                  {travelRequestData.travelRequestCalculationDto?.layUpClaim ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.applyAdvance}
                </Label>
                <p>
                  {travelRequestData.travelRequestCalculationDto
                    ?.applyAdvance || "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.restForPayment}
                </Label>
                <p>
                  {travelRequestData.travelRequestCalculationDto
                    ?.restForPayment || "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.currentDate}</Label>
                <p>
                  {(travelRequestData.travelRequestCalculationDto?.todayDate &&
                    moment(
                      travelRequestData.travelRequestCalculationDto?.todayDate
                    ).format("DD.MM.YYYY")) ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.responsiblePerson}
                </Label>
                <p>
                  {travelRequestData.travelRequestCalculationDto
                    ?.responsiblePerson?.firstName +
                    " " +
                    travelRequestData.travelRequestCalculationDto
                      ?.responsiblePerson?.firstName || "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.submittedBy}</Label>
                <p>
                  {travelRequestData.travelRequestCalculationDto?.submittedBy
                    ?.firstName +
                    " " +
                    travelRequestData.travelRequestCalculationDto?.submittedBy
                      ?.firstName || "No data"}
                </p>
              </Grid>
            </Grid>
          </Content>

          <Content>
            <h3>Travel expenses</h3>
            <Grid container spacing={1}>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.typeOfTransportation}
                </Label>
                <p>
                  {travelRequestData.travelExpensesDto?.typeOfTransportation ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.personalVehicle}
                </Label>
                <p>
                  {travelRequestData.travelExpensesDto?.personalVehicle === true
                    ? "Yes"
                    : "No"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.brandAndType}
                </Label>
                <p>
                  {travelRequestData.travelExpensesDto?.brandAndType ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.registrationNumber}
                </Label>
                <p>
                  {travelRequestData.travelExpensesDto?.registrationNumber ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.pricePerKm}</Label>
                <p>
                  {travelRequestData.travelExpensesDto?.pricePerKm || "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.startDate}</Label>
                <p>
                  {(travelRequestData.travelExpensesDto?.startDate &&
                    moment(
                      travelRequestData.travelExpensesDto?.startDate
                    ).format("DD.MM.YYYY")) ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.endDate}</Label>
                <p>
                  {(travelRequestData.travelExpensesDto?.endDate &&
                    moment(travelRequestData.travelExpensesDto?.endDate).format(
                      "DD.MM.YYYY"
                    )) ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.ecoScore}</Label>
                <p>
                  {travelRequestData.travelExpensesDto?.ecoScore || "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.km}</Label>
                <p>{travelRequestData.travelExpensesDto?.km || "No data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {translationsEn.createTravelRequests.totalOfEuros}
                </Label>
                <p>
                  {travelRequestData.travelExpensesDto?.totalOfEuros ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {
                    translationsEn.createTravelRequests
                      .additionalTransportationExpenses
                  }
                </Label>
                <p>
                  {
                    travelRequestData.travelExpensesDto
                      ?.additionalTransportationExpenses
                  }
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {
                    translationsEn.createTravelRequests
                      .totalAdditionalTransportationExpenses
                  }
                </Label>
                <p>
                  {
                    travelRequestData.travelExpensesDto
                      ?.totalAdditionalTransportationExpenses
                  }
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {
                    translationsEn.createTravelRequests
                      .totalTransportationExpenses
                  }
                </Label>
                <p>
                  {
                    travelRequestData.travelExpensesDto
                      ?.totalTransportationExpenses
                  }
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>
                  {
                    translationsEn.createTravelRequests
                      .descriptionOfAdditionalCosts
                  }
                </Label>
                <p>
                  {
                    travelRequestData.travelExpensesDto
                      ?.descriptionOfAdditionalCosts
                  }
                </p>
              </Grid>
              <Grid item xs={12} direction={"column"}>
                <h3>Costs</h3>
              </Grid>
              {travelRequestData.travelExpensesDto?.travelRequestCostsDtos.map(
                (item: any) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      direction={"row"}
                      style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                    >
                      <div style={{ width: "150px" }}>
                        <Label>Cost name</Label> {item.costName || "No data"}
                      </div>
                      <div style={{ width: "150px" }}>
                        <Label>Number of</Label> {item.numberOf || "No data"}
                      </div>
                      <div style={{ width: "150px" }}>
                        <Label>Amount of cost</Label>{" "}
                        {item.amountOfCost || "No data"}
                      </div>
                      <div style={{ width: "150px" }}>
                        <Label>Total amount</Label>{" "}
                        {item.totalAmount || "No data"}
                      </div>
                      <div style={{ width: "300px" }}>
                        <Label>Description</Label>{" "}
                        {item.description || "No data"}
                      </div>
                    </Grid>
                  );
                }
              )}
              {/* asdads asdadsa */}
            </Grid>
          </Content>

          <Content>
            <h3>Travel report</h3>
            <Grid container spacing={1}>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.description}</Label>
                <p>
                  {travelRequestData.travelReportDto?.description || "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.reportsTo}</Label>
                <p>
                  {travelRequestData.travelReportDto?.reportsTo?.firstName +
                    " " +
                    travelRequestData.travelReportDto?.reportsTo?.lastName ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Label>{translationsEn.createTravelRequests.reporter}</Label>
                <p>
                  {travelRequestData.travelReportDto?.reporter?.firstName +
                    " " +
                    travelRequestData.travelReportDto?.reporter?.lastName ||
                    "No data"}
                </p>
              </Grid>
              <Grid item xs={12}>
                <Label>{translationsEn.createTravelRequests.files}</Label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    overflow: "auto",
                  }}
                >
                  {travelRequestData?.documents?.map((item: any) => {
                    const formattedFilename = item.filename
                      .split("__")
                      .slice(-1)[0];
                    return (
                      <File
                        key={item.id}
                        onClick={() =>
                          handleDownload(
                            travelRequestData.id,
                            item.id,
                            `${formattedFilename}`
                          )
                        }
                      >
                        <InsertDriveFileIcon fontSize="large" />
                        {formattedFilename}
                      </File>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </Content>
        </div>
      </Box>
    </Modal>
  );
}

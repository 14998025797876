import { IEditEmployeePayload } from "../interfaces/employee.interface";
import {
  IAbsenceFilter,
  IEmployeeFilter,
  ITeamHistoryFilter,
} from "../interfaces/requests.interaface";
import APICall from "./axios";

export const getTeamHistoryRequests = async (
  teamHistoryFilter: ITeamHistoryFilter
): Promise<any> => {
  try {
    const response = await APICall.post<any>(
      "/api/vacation-days/history",
      teamHistoryFilter
    );

    // Assuming the response directly contains the employee data you're interested in
    if (response.data) {
      return response.data;
    } else {
      // Handle the case where the employee data is not in the expected format or missing
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const getMyRequests = async (
  userId: number,
  year: number
): Promise<any> => {
  try {
    const response = await APICall.get<any>(
      `api/vacation-days/${userId}/${year}`
    );

    // Assuming the response directly contains the employee data you're interested in
    if (response.data) {
      return response.data;
    } else {
      // Handle the case where the employee data is not in the expected format or missing
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const getAbsenceRequests = async (
  absenceFilter: IAbsenceFilter
): Promise<any> => {
  try {
    const response = await APICall.post<any>(
      "/api/vacation-days/waiting-for-approval/",
      absenceFilter
    );

    // Assuming the response directly contains the employee data you're interested in
    if (response.data) {
      return response.data;
    } else {
      // Handle the case where the employee data is not in the expected format or missing
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const getAllEmployees = async (
  employeeFilter: IEmployeeFilter
): Promise<any> => {
  try {
    const response = await APICall.post<any>(
      "api/employee/all",
      employeeFilter
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const getEmployee = async (id: number): Promise<any> => {
  try {
    const response = await APICall.get<any>(`api/employee/${id}`);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const getEmployeeContract = async (id: number): Promise<any> => {
  try {
    const response = await APICall.get<any>(`api/employee/${id}/contracts`);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const createEmployeeContract = async ({
  employeeId,
  employeeStatus,
  employeeStatusId,
  probationExpirationDate,
  shiftDuration,
  startDate,
  vacationDays,
}: any): Promise<any> => {
  try {
    const payload = {
      employeeId: employeeId,
      employeeStatus: employeeStatus,
      employeeStatusId: employeeStatusId,
      probationExpirationDate: probationExpirationDate,
      shiftDuration: shiftDuration,
      startDate: startDate,
      vacationDays: vacationDays,
    };

    const response = await APICall.post<any>(
      `api/employee/${employeeId}/contracts`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Team data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error creating team`, error);
    throw error;
  }
};

export const editUser = async (
  payload: IEditEmployeePayload,
  userId: number
): Promise<any> => {
  try {
    const response = await APICall.patch<any>(
      `api/employee/${userId}`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const cancelContract = async ({
  employeeId,
  endDate,
}: {
  employeeId: number;
  endDate: string;
}): Promise<any> => {
  try {
    const payload = {
      employeeId: employeeId,
      endDate: endDate,
    };

    const response = await APICall.post<any>(
      `api/contracts/contract-cancellation`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Team data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error creating team`, error);
    throw error;
  }
};

export const updateRequestStatus = async (
  request: any,
  requestId: number
): Promise<void> => {
  try {
    await APICall.patch(`/api/vacation-days/${requestId}`, request);
  } catch (error) {
    console.error("Failed to update request status:", error);
    throw error; // Rethrow to handle it in the calling component
  }
};

import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import themeOptions from "../../style/componentsStyle";

const MonthNavigator = ({
  currentDate,
  onPrev,
  onNext,
  navigateBy = "month",
}: any) => {
  const formatDate = () => {
    return navigateBy === "month"
      ? `${currentDate.toLocaleString("default", {
          month: "long",
        })} ${currentDate.getFullYear()}`
      : `${new Date(
          currentDate.setDate(currentDate.getDate() - currentDate.getDay())
        ).toLocaleDateString("en-GB")} - ${new Date(
          currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6)
        ).toLocaleDateString("en-GB")}`;
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <IconButton
        onClick={onPrev}
        sx={{ color: themeOptions.palette.primary.main }}
      >
        <ArrowCircleLeftRoundedIcon />
      </IconButton>
      <Typography
        variant="h5"
        sx={{ marginX: 2, color: themeOptions.palette.primary.main }}
      >
        {formatDate()}
      </Typography>
      <IconButton
        onClick={onNext}
        sx={{ color: themeOptions.palette.primary.main }}
      >
        <ArrowCircleRightRoundedIcon />
      </IconButton>
    </Box>
  );
};

export default MonthNavigator;

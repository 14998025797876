import APICall from "./axios";

const toQueryString = (config: any) => {
  const query: Array<string> = [];
  const param = config.params ? config.params : config;
  Object.keys(param).forEach((key) => {
    if (
      key !== "" &&
      param[key] !== null &&
      param[key] !== undefined &&
      param[key] !== ""
    ) {
      query.push(`${key}=${encodeURIComponent(param[key])}`);
    }
  });
  return `?${query.join("&")}`;
};

export const CreateTravelRequest = async (data: any): Promise<any> => {
  try {
    const response = await APICall.post<any>("/api/travel-requests", data);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
  }
};

export const editTravelRequest = (id: string, data: any) => {
  return APICall.patch(`/api/travel-requests/${id}`, data);
};

export const getTravelRequestByID = (id?: string) => {
  return APICall.get(`/api/travel-requests/${id}`);
};

export const createTravelRequestStep2 = (data: any, id: any) => {
  return APICall.patch(`/api/travel-requests/calculation/${id}`, data);
};

export const getTravelRequestStep2 = (id?: string) => {
  return APICall.get(`/api/travel-requests/calculation/travel/${id}`);
};

export const createTravelRequestStep3 = (data: any, id: any) => {
  return APICall.patch(`/api/travel-requests/expenses/${id}`, data);
};

export const getTravelRequestStep3 = (id?: string) => {
  return APICall.get(`/api/travel-requests/expenses/travel/${id}`);
};

export const createTravelRequestStep4 = (data: any, id: any) => {
  return APICall.patch(`/api/travel-requests/report/${id}`, data);
};

export const getTravelRequestStep4 = (id?: string) => {
  return APICall.get(`/api/travel-requests/report/travel/${id}`);
};

export const addCost = (data: any) => {
  return APICall.post(`/api/travel-requests/costs`, data);
};

export const getMyTravelRequests = (status?: string) => {
  const queryString = toQueryString({
    status,
  });
  return APICall.get(`/api/travel-requests/my-request${queryString}`);
};

export const getAllTravelRequests = (filter?: any) => {
  return APICall.post(`/api/travel-requests/filter`, filter);
};

export const getTravelDocument = async (
  travelRequestId: any,
  documentId: any
) => {
  try {
    const response = await APICall.get(
      `api/travel-requests/document/download/${travelRequestId}/${documentId}`,
      {
        responseType: "blob",
      }
    );

    return {
      blob: response.data,
      headers: response.headers,
    };
  } catch (error) {
    console.error(`Error fetching document:`, error);
    throw error;
  }
};

export const uploadFiles = (travelRequestId: number, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  return APICall.post(
    `/api/travel-requests/document/${travelRequestId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const addTravelAttachment = (travelRequestId: number, file: File[]) => {
  const formData = new FormData();
  file.forEach((file: any) => {
    formData.append("file", file);
  });

  return APICall.post(
    `/api/travel-requests/document/${travelRequestId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const getTravelRequest = (id: number) => {
  return APICall.get(`/api/travel-requests/${id}`);
};

export const deleteMyTravelRequest = (id: string) => {
  return APICall.patch(`/api/travel-requests/delete/${id}`);
};

export const rejectTravelRequest = (id: string) => {
  return APICall.patch(`/api/travel-requests/reject/${id}`);
};

export const approveTravelRequest = (id: string) => {
  return APICall.patch(`/api/travel-requests/approve/${id}`);
};

import React, { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  Alert,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { IDeleteContract } from "../../interfaces/employee.interface";
import { cancelContract } from "../../api/requestsApi";
import CancelContractConfirmationModal from "./cancelContractConfirmationModal";

interface CancelContractProps {
  open: boolean;
  handleClose: () => void;
  refreshList: () => void;
  employeeId: number;
  children?: React.ReactNode;
}

const CancelContract: React.FC<CancelContractProps> = ({
  open,
  handleClose,
  refreshList,
  employeeId,
}) => {
  const initialValues: IDeleteContract = {
    employeeId: employeeId,
    endDate: new Date().toISOString().split("T")[0] as any,
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [modalStates, setModalStates] = useState({
    delete: false,
  });

  useEffect(() => {
    formik.setFieldValue("employeeId", employeeId);
  }, [employeeId]);

  const validationSchema = Yup.object({
    endDate: Yup.date().required("End date is required"),
  });

  const onCloseModal = () => {
    handleClose();
    formik.resetForm();
    setErrorMessage("");
  };

  const handleOpenModal = () => {
    setModalStates({ delete: true });
  };

  const handleCloseModal = () => {
    setModalStates({ delete: false });
  };

  const handleConfirmAction = (values: any, { setSubmitting }: any) => {
    const payload = {
      employeeId: values.employeeId,
      endDate: dayjs(values.endDate).format("YYYY-MM-DD"),
    };

    cancelContract(payload)
      .then((response) => {
        refreshList();
        onCloseModal();
      })
      .catch((error) => {
        console.error("Failed to cancel contract:", error);
        setErrorMessage(error.response?.data?.error || "An error occurred");
      })
      .finally(() => {
        setSubmitting(false);
        handleCloseModal();
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, actions) => {
      handleOpenModal();
    },
  });

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open}>
          <Paper
            style={{
              padding: "20px",
              textAlign: "center",
              width: "40%",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: themeOptions.palette.primary.main,
                  }}
                >
                  {translationsEn.editUser.cancelContract}
                </Typography>
              </Box>

              <Clear onClick={onCloseModal} />
            </Box>
            <hr></hr>
            <form onSubmit={formik.handleSubmit}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  label={translationsEn.labels.endDateContract}
                  format="DD/MM/YYYY"
                  sx={{ margin: "10px", width: "100%" }}
                  onChange={(value) => {
                    formik.setFieldValue("endDate", value ? value : null);
                  }}
                />
              </Box>
              <hr></hr>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: themeOptions.palette.confirm.main,
                    ":hover": {
                      backgroundColor: themeOptions.palette.confirm.hover,
                    },
                    marginRight: "5px",
                  }}
                  type="submit"
                >
                  {translationsEn.buttons.confirm}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: themeOptions.palette.cancel.main,
                    ":hover": {
                      backgroundColor: themeOptions.palette.cancel.hover,
                    },
                  }}
                  onClick={handleClose}
                >
                  {translationsEn.buttons.cancel}
                </Button>
              </Box>
            </form>
            {errorMessage && (
              <Alert
                style={{
                  position: "fixed",
                  left: "50%",
                  top: "40px",
                  transform: "translate(-50%, -50%)",
                  margin: "0 auto",
                }}
                severity="error"
              >
                {errorMessage}
              </Alert>
            )}
          </Paper>
        </Fade>
      </Modal>
      <CancelContractConfirmationModal
        open={modalStates.delete}
        handleClose={() => handleCloseModal()}
        handleConfirm={() => handleConfirmAction(formik.values, formik)}
      />
    </>
  );
};

export default CancelContract;

import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  Card,
  TextField,
} from "@mui/material";
import { Article, Clear } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { useFormik } from "formik";
import { useAlert } from "../../utils/alertContext";
import { addSummary, updateSummary } from "../../api/cvApi";

interface SummaryModalProps {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
  cvId: number;
  data?: any;
  onSummaryUpdated?: () => void;
}

const initialValues = {
  text: "",
};

const SummaryModal: React.FC<SummaryModalProps> = ({
  open,
  handleClose,
  cvId,
  data,
  onSummaryUpdated = () => {},
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { showAlert } = useAlert();

  useEffect(() => {
    if (!open) {
      setErrorMessage("");
    }
  }, [open]);

  const formik = useFormik({
    initialValues: data ? { text: data.text } : initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const endpointPayload = {
        cvId: cvId,
        text: values.text,
      };

      const apiCall =
        data && data.id
          ? updateSummary({ id: data.id, ...endpointPayload })
          : addSummary(endpointPayload);

      apiCall
        .then((response) => {
          showAlert(`${translationsEn.cvModals.successSummary}`, "success");
          handleClose();
          onSummaryUpdated();
        })
        .catch((error) => {
          showAlert(error.response.data.error, "error");
          setErrorMessage(error.response.data.error);
        });
    },
  });

  useEffect(() => {
    if (!open) {
      formik.resetForm();
      setErrorMessage("");
    }
  }, [open]);

  useEffect(() => {
    if (data && data.text) {
      formik.setValues({ text: data.text });
    }
  }, [data]);

  const customHandleClose = () => {
    formik.resetForm();
    handleClose();
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  return (
    <Modal
      open={open}
      onClose={customHandleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            maxHeight: "400px",
            overflow: "auto",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Article style={{ marginRight: "5px" }} />
              <Typography>{translationsEn.cvModals.addSummary}</Typography>
            </Box>

            <Clear onClick={customHandleClose} />
          </Box>
          <hr></hr>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              id="outlined-multiline-static"
              name="text"
              label="Summary"
              multiline
              rows={10}
              style={{ margin: "10px", width: "300px" }}
              value={formik.values.text}
              onChange={formik.handleChange}
            />
            <Card style={{ margin: "10px", width: "200px" }}>
              <Typography>{translationsEn.cvModals.summaryNote}</Typography>
            </Card>
          </Box>

          <hr></hr>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: themeOptions.palette.confirm.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.confirm.hover,
                },
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default SummaryModal;

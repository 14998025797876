import React from 'react';
import { Typography } from '@mui/material';

const HeadingTitle = ({ text } : any) => {
  return (
    <Typography marginLeft={"10px"} variant="h4" component="h2">
      {text}
    </Typography>
  );
};

export default HeadingTitle;

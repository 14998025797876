import React, { useState } from 'react';
import { Box, Typography, Button, Modal, TextField, Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import styles from './shiftTable.module.css';
import themeOptions from '../../style/componentsStyle';

type EmployeeData = {
  firstName: string;
  lastName: string;
  shiftRecordDto: any;
  workDayTypes: { [key: string]: number };
};

const ShiftTable = ({ data, currentDate, handleTodayClick, workdayTypes, onCreateShift, onUpdateShift, onDeleteShift, onGenerateShift }: any) => {
  const [open, setOpen] = useState(false);
  const [openUK, setOpenUK] = useState(false);
  const [modalData, setModalData] = useState<EmployeeData | any>(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedWorkdayType, setSelectedWorkdayType] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDate, setConfirmDate] = useState('');

  const handleOpen = (employee: any, date: string, shift: any) => {
    setModalData({ ...employee, shift });
    setSelectedDate(date);
    setSelectedWorkdayType(shift ? shift.workDayType : null);
    setOpen(true);
  };

  const handleOpenUK = (employee: any) => {
    setModalData(employee);
    setOpenUK(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenUK(false);
    setModalData(null);
    setSelectedDate('');
    setSelectedWorkdayType(null);
  };

  const handleCreateShift = () => {
    if (modalData && selectedWorkdayType) {
      onCreateShift({
        employeeId: modalData.employeeId,
        date: selectedDate,
        workDayType: selectedWorkdayType
      });
      handleClose();
    }
  };

  const handleUpdateShift = () => {
    if (modalData && selectedWorkdayType) {
      onUpdateShift({
        shiftRecordId: modalData.shift.id,
        workDayType: selectedWorkdayType
      });
      handleClose();
    }
  };

  const handleDeleteShift = () => {
    if (modalData && modalData.shift) {
      onDeleteShift(modalData.shift);
      handleClose();
    }
  };

  const handleDayClick = (date: string) => {
    setConfirmDate(date);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
    setConfirmDate('');
  };

  const handleConfirmGenerate = () => {
    const date = dayjs(confirmDate);
    onGenerateShift({
      day: date.date(),
      month: date.month() + 1,
      year: date.year(),
      companyId: data[0]?.companyId // Adjust this based on your data structure
    });
    handleConfirmDialogClose();
  };

  const daysInMonth = dayjs(currentDate).daysInMonth();
  const startOfMonth = dayjs(currentDate).startOf('month');

  const getWeekday = (index: any) => {
    return dayjs(startOfMonth).add(index, 'day').format('dd').charAt(0).toUpperCase();
  };

  const isWeekend = (date: any) => {
    const day = dayjs(startOfMonth).add(date, 'day').day();
    return day === 6 ? 'saturday' : day === 0 ? 'sunday' : '';
  };

  const getDayClass = (index: any) => {
    const date = dayjs(startOfMonth).add(index, 'day').startOf('day');
    const today = dayjs().startOf('day');
    if (date.isSame(today, 'day')) {
      return 'today';
    } else if (date.isAfter(today, 'day')) {
      return 'futureDay';
    } else {
      return 'pastDay';
    }
  };

  return (
    <Box className={styles.tableContainer}>
      <Box className={styles.tableWrapper}>
        <Box className={styles.tableHeader} style={{ gridTemplateColumns: `150px repeat(${daysInMonth + 2}, minmax(40px, 1fr))` }}>
          <Box className={`${styles.tableCell} ${styles.tableCellHeader} ${styles.tableCellHeaderBtn}`}>
            <Button
              variant="contained"
              style={{ borderRadius: "20px" }}
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
              }}
              onClick={handleTodayClick}
            >
              Today
            </Button>
          </Box>
          {Array.from({ length: daysInMonth }, (_, i) => (
            <Tooltip key={i} title={`Generate shift records for ${dayjs(startOfMonth).add(i, 'day').format('YYYY-MM-DD')}`}>
              <Box key={i} className={`${styles.tableCell} ${styles.tableCellHeader} ${styles[isWeekend(i)]} ${styles[getDayClass(i)]}`} onClick={() => handleDayClick(dayjs(startOfMonth).add(i, 'day').format('YYYY-MM-DD'))}>
                <Typography>{i + 1}</Typography>
                <Typography>{getWeekday(i)}</Typography>
              </Box>
            </Tooltip>
          ))}
          <Box className={`${styles.tableCell} ${styles.tableCellHeader}`}>UK</Box>
          <Box className={`${styles.tableCell} ${styles.tableCellHeader}`}>RS</Box>
        </Box>
        <Box className={styles.tableBody} style={{ gridTemplateColumns: `150px repeat(${daysInMonth + 2}, minmax(40px, 1fr))` }}>
          {data.map((employee: any, index: any) => (
            <React.Fragment key={index}>
              <Box className={`${styles.tableCell} ${styles.employeeName}`}>
                <Typography noWrap>{`${employee.firstName} ${employee.lastName}`}</Typography>
              </Box>
              {Array.from({ length: daysInMonth }, (_, i) => {
                const shift = employee.shiftRecordDto.find(
                  (record: any) => dayjs(record.date).date() === i + 1
                );
                return (
                  <Tooltip key={i} title={shift ? 'Edit shift' : 'Create shift'}>
                    <Box
                      key={i}
                      className={`${styles.tableCell} ${styles[isWeekend(i)]} ${styles[getDayClass(i)]} ${shift ? styles.highlightCell : ''}`}
                      onClick={() => handleOpen(employee, dayjs(startOfMonth).add(i, 'day').format('YYYY-MM-DD'), shift)}
                    >
                      {shift && (
                        <Typography>
                          {shift.workDayType.code === 'RRR' ? employee.shiftDuration : shift.workDayType.code}
                        </Typography>
                      )}
                    </Box>
                  </Tooltip>
                );
              })}
              <Tooltip title="View UK workday types">
                <Box className={`${styles.tableCell} ${employee.workDayTypes?.UK ? styles.highlightUK : ''}`} onClick={() => handleOpenUK(employee)}>
                  {employee.workDayTypes?.UK || '-'}
                </Box>
              </Tooltip>
                <Box className={`${styles.tableCell} ${employee.workDayTypes?.RS ? styles.highlightRS : ''}`}>
                  {employee.workDayTypes?.RS || '-'}
                </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>

      <Modal
        open={openUK}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Work Day Types for {modalData?.firstName} {modalData?.lastName}
          </Typography>
          <Box id="modal-description" sx={{ mt: 2 }}>
            {modalData && Object.entries(modalData.workDayTypes || {}).map(([key, value]: any, index) => (
              <Typography key={index}>
                {key}: {value}
              </Typography>
            ))}
          </Box>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Select worktype
          </Typography>
          <Typography>Employee: {modalData?.firstName} {modalData?.lastName}</Typography>
          <Typography>Date: {selectedDate}</Typography>
          <Autocomplete
            options={workdayTypes}
            getOptionLabel={(option: any) => `${option.code} - ${option.name}`}
            value={selectedWorkdayType}
            onChange={(event, newValue) => setSelectedWorkdayType(newValue)}
            renderInput={(params) => <TextField {...params} label="Work days type:" variant="outlined" />}
            sx={{ mt: 2 }}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            {modalData?.shift ? (
              <>
                <Button variant="contained" onClick={handleUpdateShift}>Update</Button>
                <Button variant="contained" color="error" onClick={handleDeleteShift}>Delete</Button>
              </>
            ) : (
              <Button variant="contained" onClick={handleCreateShift}>Create</Button>
            )}
          </Box>
          <Button sx={{ mt: 2 }} onClick={handleClose}>Close</Button>
        </Box>
      </Modal>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
      >
        <DialogTitle>Confirm Shift Records Generation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to generate shift records for {confirmDate}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmGenerate} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ShiftTable;

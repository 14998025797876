import React, { useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  Collapse,
  Grid,
} from "@mui/material";
import { Clear, ExpandLess, ExpandMore } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";

interface employeeDataInterface {
  paidLeaveDaysAvailabilityDto: {
    paidLeaveDaysAvailabilityByType: {
      type: string;
      used: number;
      totalAvailable: number;
    }[];
  };
  vacationDaysAvailabilityDto: {
    bloodDonationDaysCurrentYear: {
      used: number;
      totalAvailable: number;
    };
    paidLeaveDaysCurrentYear: {
      used: number;
      totalAvailable: number;
    };
    vacationDaysCurrentYear: {
      used: number;
      totalAvailable: number;
    };
    vacationDaysPreviousYear: {
      used: number;
      totalAvailable: number;
    };
  };
  remoteWorkDaysAvailabilityByType: {
    used: number;
    totalAvailable: number;
    type: string;
  };
}

interface VacationDayModalProps {
  open: boolean;
  handleClose: () => void;
  employeeData?: employeeDataInterface;
  children?: React.ReactNode;
}

const VactionDayModal: React.FC<VacationDayModalProps> = ({
  open,
  handleClose,
  employeeData,
}) => {
  const [openPaidLeave, setOpenPaidLeave] = useState(false);

  const handlePaidLeaveClick = () => {
    setOpenPaidLeave(!openPaidLeave);
  };

  const handleModalClose = () => {
    handleClose();
    setOpenPaidLeave(false);
  };

  const paperHeight = openPaidLeave ? "80%" : "30%";

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "25%",

            overflow: "auto",
            height: paperHeight,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
                {translationsEn.teamsInformation.name}
              </Typography>
            </Box>

            <Clear onClick={handleModalClose} />
          </Box>
          <hr></hr>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography style={{ padding: "10px" }}>
              {translationsEn.teamsInformation.previousYearVacation}
              {employeeData?.vacationDaysAvailabilityDto
                .vacationDaysPreviousYear.used +
                "/" +
                employeeData?.vacationDaysAvailabilityDto
                  .vacationDaysPreviousYear.totalAvailable}
            </Typography>
            <Typography style={{ padding: "10px" }}>
              {translationsEn.teamsInformation.currentYearVacation}
              {employeeData?.vacationDaysAvailabilityDto.vacationDaysCurrentYear
                .used +
                "/" +
                employeeData?.vacationDaysAvailabilityDto
                  .vacationDaysCurrentYear.totalAvailable}
            </Typography>

            <Grid style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ padding: "10px" }}>
                {translationsEn.teamsInformation.currentPaidLeave}
                {employeeData?.vacationDaysAvailabilityDto
                  .paidLeaveDaysCurrentYear.used +
                  "/" +
                  employeeData?.vacationDaysAvailabilityDto
                    .paidLeaveDaysCurrentYear.totalAvailable}
              </Typography>
              <ListItemButton onClick={handlePaidLeaveClick}>
                {openPaidLeave ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </Grid>
            <Collapse in={openPaidLeave} timeout="auto" unmountOnExit>
              <List style={{ margin: 0 }}>
                {employeeData?.paidLeaveDaysAvailabilityDto.paidLeaveDaysAvailabilityByType.map(
                  (
                    typeData: {
                      type: string;
                      used: number;
                      totalAvailable: number;
                    },
                    index
                  ) => (
                    <ListItem
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      {
                        translationsEn.vacationDays[
                          typeData.type as keyof typeof translationsEn.vacationDays
                        ]
                      }{" "}
                      : {typeData.used}/{typeData.totalAvailable}
                    </ListItem>
                  )
                )}
              </List>
            </Collapse>

            <Typography style={{ padding: "10px" }}>
              {translationsEn.teamsInformation.currentBloodLeave}
              {employeeData?.vacationDaysAvailabilityDto
                .bloodDonationDaysCurrentYear.used +
                "/" +
                employeeData?.vacationDaysAvailabilityDto
                  .bloodDonationDaysCurrentYear.totalAvailable}
            </Typography>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default VactionDayModal;

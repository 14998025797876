import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { AccountBalance, Clear } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { useFormik } from "formik";
import { addEducation, updateEducation } from "../../api/cvApi";
import { useAlert } from "../../utils/alertContext";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import * as Yup from "yup";

interface EducationModalProps {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
  cvId: number;
  data?: any;
  onEducationUpdate?: () => void;
}

const EducationModal: React.FC<EducationModalProps> = ({
  open,
  handleClose,
  cvId,
  data,
  onEducationUpdate = () => {},
}) => {
  const [ongoingEducation, setOngoingEducation] = useState(false);
  const { showAlert } = useAlert();
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = {
    school: data ? data.school : "",
    degree: data ? data.degree : "",
    fieldOfStudy: data ? data.fieldOfStudy : "",
    startDate: data ? dayjs(data.startDate) : null,
    endDate: data ? (data.endDate ? dayjs(data.endDate) : null) : null,
    description: data ? data.description : "",
  };

  const validationSchema = Yup.object().shape({
    school: Yup.string().required(`${translationsEn.validation.required}`),
    degree: Yup.string().required(`${translationsEn.validation.required}`),
    fieldOfStudy: Yup.string().required(
      `${translationsEn.validation.required}`
    ),
    startDate: Yup.date().required(`${translationsEn.validation.required}`),
    endDate: ongoingEducation
      ? Yup.date().nullable().notRequired()
      : Yup.date().required(`${translationsEn.validation.required}`),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const startDate = values.startDate ? values.startDate.toDate() : null;
      const endDate = ongoingEducation
        ? null
        : values.endDate
        ? values.endDate.toDate()
        : null;

      const payload = {
        cvId,
        school: values.school,
        degree: values.degree,
        fieldOfStudy: values.fieldOfStudy,
        startDate,
        endDate,
        description: values.description,
      };

      const apiCall = data
        ? updateEducation({
            id: data.id,
            ...payload,
          })
        : addEducation(payload);

      apiCall
        .then((response) => {
          showAlert(`${translationsEn.cvModals.successEducation}`, "success");
          handleClose();
          onEducationUpdate();
        })
        .catch((error) => {
          showAlert(error.response.data.error, "error");
          setErrorMessage(error.response.data.error);
        });
    },
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOngoingEducation(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue("endDate", null);
    }
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      formik.setValues({
        school: data.school || "",
        degree: data.degree || "",
        fieldOfStudy: data.fieldOfStudy || "",
        startDate: data.startDate ? dayjs(data.startDate) : null,
        endDate: data.endDate ? dayjs(data.endDate) : null,
        description: data.description || "",
      });
      setOngoingEducation(data.endDate === null);
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "40%",
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <AccountBalance style={{ marginRight: "5px" }} />
              <Typography>{translationsEn.cvModals.addEducation}</Typography>
            </Box>

            <Clear onClick={handleClose} />
          </Box>
          <hr />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "30px",
            }}
          >
            <TextField
              id="school"
              name="school"
              label="School"
              variant="standard"
              required
              value={formik.values.school}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
              }}
              error={formik.touched.school && Boolean(formik.errors.school)}
            />

            <TextField
              id="degree"
              name="degree"
              label="Degree"
              variant="standard"
              required
              value={formik.values.degree}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
              }}
              error={formik.touched.degree && Boolean(formik.errors.degree)}
            />

            <TextField
              id="fieldOfStudy"
              name="fieldOfStudy"
              label="Field of study"
              variant="standard"
              required
              value={formik.values.fieldOfStudy}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
              }}
              error={
                formik.touched.fieldOfStudy &&
                Boolean(formik.errors.fieldOfStudy)
              }
            />

            <FormControl style={{ width: "100%" }}>
              <DatePicker
                label="Start date"
                views={["year"]}
                value={formik.values.startDate}
                onChange={(date) => formik.setFieldValue("startDate", date)}
                slotProps={{
                  textField: {
                    helperText:
                      formik.touched.startDate &&
                      formik.errors.startDate &&
                      (formik.errors.startDate as string),
                    error: Boolean(
                      formik.touched.startDate && formik.errors.startDate
                    ),
                  },
                }}
                sx={{ width: "100%" }}
              />
            </FormControl>
            <FormControlLabel
              style={{ margin: "10px", width: "27%" }}
              control={
                <Checkbox
                  checked={ongoingEducation}
                  onChange={handleCheckboxChange}
                />
              }
              label="Ongoing education"
            />

            {!ongoingEducation && (
              <FormControl style={{ width: "100%", margin: "10px 0" }}>
                <DatePicker
                  label="End date"
                  views={["year"]}
                  value={formik.values.endDate}
                  onChange={(date) => formik.setFieldValue("endDate", date)}
                  slotProps={{
                    textField: {
                      helperText:
                        formik.touched.endDate &&
                        formik.errors.endDate &&
                        (formik.errors.endDate as string),
                      error: Boolean(
                        formik.touched.endDate && formik.errors.endDate
                      ),
                    },
                  }}
                  sx={{ margin: "10px 0", width: "100%" }}
                />
              </FormControl>
            )}

            <TextField
              id="description"
              name="description"
              label="Description"
              multiline
              rows={10}
              value={formik.values.description}
              onChange={formik.handleChange}
              style={{ margin: "10px" }}
            />
          </Box>
          <hr />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: themeOptions.palette.confirm.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.confirm.hover,
                },
              }}
              onClick={handleSave}
            >
              {translationsEn.buttons.save}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default EducationModal;

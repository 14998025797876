import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  Select,
  Rating,
  MenuItem,
} from "@mui/material";
import { Clear, GTranslate } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { useFormik } from "formik";
import { useAlert } from "../../utils/alertContext";
import { getLanguage, addLanguage, updatedLanguage } from "../../api/cvApi";
import * as Yup from "yup";

interface LanguageModalProps {
  open: boolean;
  handleClose: () => void;
  cvId: number;
  children?: React.ReactNode;
  data?: any;
  onLanguageUpdate?: () => void;
}

const validationSchema = Yup.object({
  language: Yup.string().required(`${translationsEn.validation.required}`),
});

const LanguageModal: React.FC<LanguageModalProps> = ({
  open,
  handleClose,
  cvId,
  data,
  onLanguageUpdate = () => {},
}) => {
  const [languages, setLanguages] = useState<{ id: number; name: string }[]>(
    []
  );

  const { showAlert } = useAlert();

  const initialValues = {
    language: data ? data.language.name : "",
    proficiency: data ? data.proficiency : 0,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const selectedLanguageId = languages.find(
        (lang) => lang.name === values.language
      )?.id;
      if (selectedLanguageId !== undefined) {
        const endpointPayload = {
          id: data ? data.id : 0,
          cvId,
          languageId: selectedLanguageId,
          proficiency: values.proficiency,
        };

        try {
          if (data) {
            await updatedLanguage(endpointPayload);
            showAlert(`${translationsEn.cvModals.successLanguage}`, "success");
          } else {
            await addLanguage(endpointPayload);
            showAlert(`${translationsEn.cvModals.successLanguage}`, "success");
          }
          onLanguageUpdate();
          handleClose();
        } catch (error) {
          showAlert("Failed to save language proficiency.", "error");
        }
      } else {
        showAlert("Invalid language selected.", "error");
      }
    },
  });

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const userLanguages = await getLanguage();
        setLanguages(userLanguages || []);
      } catch (error) {
        console.error("Failed to fetch languages", error);
        setLanguages([]);
      }
    };

    if (open) {
      fetchLanguages();
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  const customHandleClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={customHandleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "20%",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <GTranslate style={{ marginRight: "5px" }} />
              <Typography>{translationsEn.cvModals.addLanguage}</Typography>
            </Box>
            <Clear onClick={customHandleClose} />
          </Box>
          <hr />
          <form onSubmit={formik.handleSubmit}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Select
                name="language"
                value={formik.values.language}
                onChange={formik.handleChange}
                style={{ width: "60%", margin: "10px" }}
                error={
                  formik.touched.language && Boolean(formik.errors.language)
                }
                inputProps={{
                  "aria-label": "select language",
                }}
              >
                {languages.map((language) => (
                  <MenuItem key={language.id} value={language.name}>
                    {language.name}
                  </MenuItem>
                ))}
              </Select>

              <Rating
                name="proficiency"
                value={formik.values.proficiency}
                onChange={(event, newValue) =>
                  formik.setFieldValue("proficiency", newValue)
                }
                style={{ margin: "10px" }}
              />
            </Box>
            <hr />
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.confirm.main,
                  ":hover": {
                    backgroundColor: themeOptions.palette.confirm.hover,
                  },
                }}
              >
                {translationsEn.buttons.save}
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default LanguageModal;

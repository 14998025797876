import {
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";

import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";

import { getCompany } from "../../api/companyApi";
import CreateCompanyModal from "./createCompany";

export default function Company() {
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [companyModal, setCompanyModal] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);

  const emptyRows = tableData
    ? 5 - tableData.slice(page * pageSize, page * pageSize + pageSize)!.length
    : 0;

  const closeCompanyModal = () => {
    setCompanyModal(false);
    setSelectedCompany(null);
    setRefresh(!refresh);
  };

  useEffect(() => {
    getCompany()
      .then((response) => {
        setTableData(response.data.data);
      })
      .catch((error) => console.error("Failed to fetch companies:", error))
      .finally(() => {
        setLoading(false);
      });
  }, [refresh]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleEditClick = (company: any) => {
    setSelectedCompany(company);
    setCompanyModal(true);
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.company.pageName}
        </Typography>
        <Button
          variant="contained"
          style={{
            margin: "10px",
            borderRadius: "20px",
            height: "50%",
          }}
          sx={{
            backgroundColor: themeOptions.palette.primary.main,
            color: "white",
            ":hover": {
              background: themeOptions.palette.primary.hover,
            },
            textTransform: "none",
          }}
          onClick={() => setCompanyModal(true)}
        >
          {translationsEn.company.button}
        </Button>
      </Paper>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      ></Paper>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 401 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.company.name}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.company.oib}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.company.address}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.company.director}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.company.internal}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.company.actions}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ height: "344px" }}>
              {tableData.length === 0 && (
                <TableRow style={{ height: 344 }}>
                  <TableCell colSpan={9} align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>{translationsEn.table.noData}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {tableData
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((dataRow: any) => (
                  <TableRow
                    key={dataRow.id}
                    sx={{
                      cursor: "pointer",
                      "&:last-child td, &:last-child th": { border: 0 },
                      height: "20px",
                    }}
                  >
                    <TableCell align="left">{dataRow.name}</TableCell>
                    <TableCell align="left">{dataRow.oib}</TableCell>
                    <TableCell align="left">{dataRow.address}</TableCell>
                    <TableCell align="left">{dataRow.director}</TableCell>
                    <TableCell align="left">
                      {dataRow.internal ? (
                        <CheckCircleIcon
                          style={{ color: "green" }}
                          fontSize="small"
                        />
                      ) : (
                        <CancelIcon style={{ color: "red" }} fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <IconButton
                        onClick={() => handleEditClick(dataRow)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              {tableData.slice(page * pageSize, page * pageSize + pageSize)
                .length !== 0 &&
                emptyRows > 0 && (
                  <TableRow style={{ height: 68 * emptyRows }}>
                    <TableCell colSpan={10} />
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          colSpan={3}
          count={tableData.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <CreateCompanyModal
          open={companyModal}
          closeModal={closeCompanyModal}
          selectedCompany={selectedCompany}
        />
      </Paper>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  Rating,
  Chip,
} from "@mui/material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import {
  AccountBalance,
  AccountTree,
  Article,
  GTranslate,
  HistoryEdu,
  Newspaper,
  Person,
  SettingsSuggest,
  Work,
  WorkspacePremium,
} from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import StarIcon from "@mui/icons-material/Star";
import {
  getCertificates,
  getEducation,
  getExperiences,
  getHonors,
  getLanguageForUser,
  getProjects,
  getPublications,
  getSkillsCv,
  getSummaries,
} from "../../api/cvApi";
import EditIcon from "@mui/icons-material/Edit";
import CertificationModal from "./certificatesModal";
import EducationModal from "./educationModal";
import ExperienceModal from "./experienceModal";
import HonoursModal from "./honoursModal";
import LanguageModal from "./languageModal";
import ProjectModal from "./projectModal";
import PublicationsModal from "./publicationsModal";
import SkillModal from "./skillsModals/addSkill";
import SummaryModal from "./summaryModal";

interface OverviewModalProps {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
  loggedUserFullname?: any;
  cvId: number;
}

const OverviewModal: React.FC<OverviewModalProps> = ({
  open,
  handleClose,
  loggedUserFullname,
  cvId,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage] = useState(3);
  const [summaries, setSummaries] = useState<any>({});
  const [userEducation, setUserEducation] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDataLanguage, setSelectedDataLanguage] = useState(null);
  const [selectDataExperiences, setSelectDataExperiences] = useState(null);
  const [selectDataCertification, setSelectDataCertification] = useState(null);
  const [selectDataProjects, setSelectDataProjects] = useState(null);
  const [selectedDataPublications, setSelectedDataPublications] =
    useState(null);
  const [selectedDataSkill, setSelectedSkillData] = useState(null);
  const [selectedHonoursData, setSelectedHonoursData] = useState(null);
  const [userLangugaes, setUserLanguages] = useState<any[]>([]);
  const [userExperiences, setUserExperiences] = useState<any[]>([]);
  const [userProjects, setUserProjects] = useState<any[]>([]);
  const [userCertificates, setUserCertificates] = useState<any[]>([]);
  const [userPublications, setUserPublications] = useState<any[]>([]);
  const [userSkills, setUserSkills] = useState<any[]>([]);
  const [userHonors, setUserHonors] = useState<any[]>([]);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [modalStates, setModalStates] = useState({
    summary: false,
    education: false,
    languages: false,
    experiences: false,
    projects: false,
    certifications: false,
    publications: false,
    skills: false,
    honours: false,
    cvLook: false,
    createCv: false,
    viewAllCv: false,
  });
  const handleOpenModal = (modalName: keyof typeof modalStates, id: any) => {
    const educationData = userEducation.find((item: any) => item.id === id);
    const languageData = userLangugaes.find((item: any) => item.id === id);
    const experienceData = userExperiences.find((item: any) => item.id === id);
    const projectData = userProjects.find((item: any) => item.id === id);
    const certificationData = userCertificates.find(
      (item: any) => item.id === id
    );
    const publicationData = userPublications.find(
      (item: any) => item.id === id
    );
    const skillData = userSkills.find((item: any) => item.id === id);
    const honorusData = userHonors.find((item: any) => item.id === id);
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: true,
    }));

    setSelectedData(educationData);
    setSelectedDataLanguage(languageData);
    setSelectDataExperiences(experienceData);
    setSelectDataProjects(projectData);
    setSelectDataCertification(certificationData);
    setSelectedDataPublications(publicationData);
    setSelectedSkillData(skillData);
    setSelectedHonoursData(honorusData);
  };

  const handleCloseModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: false,
    }));
    setSelectedDataLanguage(null);
    setSelectDataExperiences(null);
    setSelectedData(null);
    setSelectDataCertification(null);
    setSelectedDataPublications(null);
    setSelectedHonoursData(null);
  };

  const handleButtonClick = () => {
    setShowEditIcon(!showEditIcon);
    console.log(selectDataCertification);
  };
  const fetchUserSummaries = async () => {
    try {
      const userSummaries = await getSummaries(cvId);
      setSummaries(userSummaries || []);
    } catch (error) {
      console.error("Failed to fetch user data", error);
      setSummaries([]);
    }
  };

  useEffect(() => {
    if (open) {
      fetchUserSummaries();
    }
  }, [open, cvId]);

  const handleSummaryUpdated = () => {
    fetchUserSummaries();
  };

  const fetchUserEducation = async () => {
    try {
      const userEducation = await getEducation(cvId);
      setUserEducation(userEducation || []);
    } catch (error) {
      console.error("Failed to fetch user education", error);
      setUserEducation([]);
    }
  };

  const handleEducationUpdated = () => {
    fetchUserEducation();
  };

  useEffect(() => {
    if (open) {
      fetchUserEducation();
    }
  }, [open, cvId]);

  const fetchLanguagesForUser = async () => {
    try {
      const userLanguages = await getLanguageForUser(cvId);
      setUserLanguages(userLanguages || []);
    } catch (error) {
      console.error("Failed to fetch languages", error);
      setUserLanguages([]);
    }
  };

  useEffect(() => {
    if (open) {
      fetchLanguagesForUser();
    }
  }, [open, cvId]);

  const handleLanguageUpdated = () => {
    fetchLanguagesForUser();
  };

  const fetchUserExperiences = async () => {
    try {
      const userExperiences = await getExperiences(cvId);
      setUserExperiences(userExperiences || []);
    } catch (error) {
      console.error("Failed to fetch user education", error);
      setUserExperiences([]);
    }
  };

  useEffect(() => {
    if (open) {
      fetchUserExperiences();
    }
  }, [open, cvId]);

  const handleExpirienceUpdated = () => {
    fetchUserExperiences();
  };

  const fetchUserProjects = async () => {
    try {
      const userProjects = await getProjects(cvId);
      setUserProjects(userProjects || []);
    } catch (error) {
      console.error("Failed to fetch user education", error);
      setUserProjects([]);
    }
  };
  useEffect(() => {
    if (open) {
      fetchUserProjects();
    }
  }, [open, cvId]);

  const handleProjectUpdated = () => {
    fetchUserProjects();
  };

  const fetchUserCertificates = async () => {
    try {
      const userCertificates = await getCertificates(cvId);
      setUserCertificates(userCertificates || []);
    } catch (error) {
      console.error("Failed to fetch user education", error);
      setUserCertificates([]);
    }
  };

  useEffect(() => {
    if (open) {
      fetchUserCertificates();
    }
  }, [open, cvId]);

  const handleCertificationUpdated = () => {
    fetchUserCertificates();
  };

  const fetchUserPublications = async () => {
    try {
      const userPublications = await getPublications(cvId);
      setUserPublications(userPublications || []);
    } catch (error) {
      console.error("Failed to fetch user education", error);
      setUserPublications([]);
    }
  };

  useEffect(() => {
    if (open) {
      fetchUserPublications();
    }
  }, [open, cvId]);

  const handlePublicationUpdate = () => {
    fetchUserPublications();
  };

  const fetchUserSkills = async () => {
    try {
      const userSkills = await getSkillsCv(cvId);
      setUserSkills(userSkills || []);
    } catch (error) {
      console.error("Failed to fetch user education", error);
      setUserSkills([]);
    }
  };
  useEffect(() => {
    if (open) {
      fetchUserSkills();
    }
  }, [open, cvId]);

  const handleSkillUpdate = () => {
    fetchUserSkills();
  };

  const fetchUserHonors = async () => {
    try {
      const userHonors = await getHonors(cvId);
      setUserHonors(userHonors || []);
    } catch (error) {
      console.error("Failed to fetch user honors", error);
      setUserHonors([]);
    }
  };

  useEffect(() => {
    if (open) {
      fetchUserHonors();
    }
  }, [open, cvId]);

  const handleHonoursUpdate = () => {
    fetchUserHonors();
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % 3);
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => (prevPage + 3 - 1) % 3);
  };

  const renderContent = () => {
    switch (currentPage) {
      case 0:
        return (
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                maxHeight: 200,
                width: "100%",
                overflow: "auto",
                marginBottom: "5px",
                background: themeOptions.palette.primary.main,
                color: "white",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Article />
                </Box>
                <Typography style={{ fontWeight: "bold", marginLeft: "5px" }}>
                  {translationsEn.cv.summary}
                </Typography>
              </Grid>

              <CardContent>
                {showEditIcon && (
                  <EditIcon
                    onClick={() => handleOpenModal("summary", selectedData)}
                  />
                )}
                {summaries?.text ? (
                  <>{summaries.text}</>
                ) : (
                  <Typography>{translationsEn.cvModals.noSummary}</Typography>
                )}
              </CardContent>
            </Card>
            <Card
              sx={{
                maxHeight: 200,
                width: "100%",
                overflow: "auto",
                marginBottom: "5px",
                background: themeOptions.palette.primary.main,
                color: "white",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <AccountBalance />
                </Box>
                <Typography style={{ fontWeight: "bold", marginLeft: "5px" }}>
                  {translationsEn.cv.education}
                </Typography>
              </Grid>

              <CardContent style={{ display: "flex", flexDirection: "column" }}>
                {userEducation.length > 0 ? (
                  userEducation.map((education: any) => (
                    <Paper
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.5)",
                        padding: "10px",
                        marginBottom: "10px",
                      }}
                      key={education.id}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.school}
                            />{" "}
                            : {education.school}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.degree}
                            />{" "}
                            : {education.degree}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.fieldOfStudy}
                            />{" "}
                            : {education.fieldOfStudy}
                          </Grid>
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.startDate}
                            />
                            : {education.startDate}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.endDate}
                            />{" "}
                            : {education.endDate}
                          </Grid>
                        </Grid>
                        {showEditIcon && (
                          <EditIcon
                            onClick={() =>
                              handleOpenModal("education", education.id)
                            }
                          />
                        )}
                      </Grid>

                      <Grid>
                        {" "}
                        <Chip
                          sx={{
                            background: themeOptions.palette.primary.main,
                            color: "white",
                          }}
                          label={translationsEn.cvModals.description}
                        />{" "}
                        : {education.description}
                      </Grid>
                    </Paper>
                  ))
                ) : (
                  <Typography>{translationsEn.cvModals.noEducation}</Typography>
                )}
              </CardContent>
            </Card>
            <Card
              sx={{
                maxHeight: 200,
                width: "100%",
                overflow: "auto",
                background: themeOptions.palette.primary.main,
                color: "white",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <GTranslate />
                </Box>
                <Typography style={{ marginLeft: "5px", fontWeight: "bold" }}>
                  {translationsEn.cv.languages}
                </Typography>
              </Grid>

              <CardContent>
                {userLangugaes.length > 0 ? (
                  userLangugaes.map((language: any) => (
                    <>
                      <Grid
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={language.language.id}
                      >
                        {language.language.name}
                        <Rating
                          name="read-only"
                          style={{
                            marginLeft: "5px",
                          }}
                          value={language.proficiency}
                          readOnly
                          emptyIcon={
                            <StarIcon
                              fontSize="inherit"
                              style={{ color: "white" }}
                            />
                          }
                        />

                        {showEditIcon && (
                          <EditIcon
                            style={{
                              marginLeft: "10px",
                            }}
                            onClick={() =>
                              handleOpenModal("languages", language.id)
                            }
                          />
                        )}
                      </Grid>
                    </>
                  ))
                ) : (
                  <Typography>{translationsEn.cvModals.noLanguage}</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        );
      case 1:
        return (
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                maxHeight: 200,
                width: "100%",
                overflow: "auto",
                marginBottom: "5px",
                background: themeOptions.palette.primary.main,
                color: "white",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Work />
                </Box>
                <Typography style={{ marginLeft: "5px", fontWeight: "bold" }}>
                  {translationsEn.cv.experiences}
                </Typography>
              </Grid>

              <CardContent style={{ display: "flex", flexDirection: "column" }}>
                {userExperiences.length > 0 ? (
                  userExperiences.map((experiences: any) => (
                    <Paper
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.5)",
                        padding: "10px",
                        marginBottom: "10px",
                      }}
                      key={experiences.id}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.title}
                            />{" "}
                            : {experiences.title}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.compay}
                            />{" "}
                            : {experiences.company}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.contactNumber}
                            />{" "}
                            : {experiences.contact}
                          </Grid>
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.startDate}
                            />
                            : {experiences.startDate}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.endDate}
                            />{" "}
                            : {experiences.endDate}
                          </Grid>
                        </Grid>
                        {showEditIcon && (
                          <EditIcon
                            style={{}}
                            onClick={() =>
                              handleOpenModal("experiences", experiences.id)
                            }
                          />
                        )}
                      </Grid>

                      <Grid>
                        {" "}
                        <Chip
                          sx={{
                            background: themeOptions.palette.primary.main,
                            color: "white",
                          }}
                          label={translationsEn.cvModals.description}
                        />{" "}
                        : {experiences.description}
                      </Grid>
                    </Paper>
                  ))
                ) : (
                  <Typography>{translationsEn.cvModals.noEducation}</Typography>
                )}
              </CardContent>
            </Card>
            <Card
              sx={{
                maxHeight: 200,
                width: "100%",
                overflow: "auto",
                marginBottom: "5px",
                background: themeOptions.palette.primary.main,
                color: "white",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <AccountTree />
                </Box>
                <Typography style={{ marginLeft: "5px", fontWeight: "bold" }}>
                  {translationsEn.cv.projects}
                </Typography>
              </Grid>

              <CardContent>
                {userProjects.length > 0 ? (
                  userProjects.map((projects: any) => (
                    <Paper
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.5)",
                        padding: "10px",
                        marginBottom: "10px",
                      }}
                      key={projects.id}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.title}
                            />{" "}
                            : {projects.name}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.compay}
                            />{" "}
                            : {projects.client}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.role}
                            />{" "}
                            : {projects.role}
                          </Grid>
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.startDate}
                            />
                            : {projects.startDate}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.endDate}
                            />{" "}
                            : {projects.endDate}
                          </Grid>
                        </Grid>
                        {showEditIcon && (
                          <EditIcon
                            style={{}}
                            onClick={() =>
                              handleOpenModal("projects", projects.id)
                            }
                          />
                        )}
                      </Grid>

                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          marginTop: "10px",
                          marginLeft: "103px",
                        }}
                      >
                        <Grid
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          {" "}
                          <Chip
                            sx={{
                              background: themeOptions.palette.primary.main,
                              color: "white",
                            }}
                            label={translationsEn.cvModals.shortDescription}
                          />{" "}
                          : {projects.shortDescriptionOfTheProject}{" "}
                        </Grid>
                        <Grid
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          <Chip
                            sx={{
                              background: themeOptions.palette.primary.main,
                              color: "white",
                            }}
                            label={
                              translationsEn.cvModals
                                .responsibilitiesDescriptiom
                            }
                          />{" "}
                          : {projects.descriptionOfResponsibilities}
                        </Grid>
                        <Grid
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          {" "}
                          <Chip
                            sx={{
                              background: themeOptions.palette.primary.main,
                              color: "white",
                            }}
                            label={translationsEn.cvModals.techStack}
                          />{" "}
                          : {projects.techStack}
                        </Grid>
                      </Grid>
                    </Paper>
                  ))
                ) : (
                  <Typography>{translationsEn.cvModals.noProject}</Typography>
                )}
              </CardContent>
            </Card>
            <Card
              sx={{
                maxHeight: 200,
                width: "100%",
                overflow: "auto",
                background: themeOptions.palette.primary.main,
                color: "white",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <HistoryEdu />
                </Box>
                <Typography style={{ marginLeft: "5px", fontWeight: "bold" }}>
                  {translationsEn.cv.licences}
                </Typography>
              </Grid>

              <CardContent>
                {userCertificates.length > 0 ? (
                  userCertificates.map((certificates: any) => (
                    <Paper
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.5)",
                        padding: "10px",
                        marginBottom: "10px",
                      }}
                      key={certificates.id}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.certificateName}
                            />{" "}
                            : {certificates.name}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.issuer}
                            />{" "}
                            : {certificates.issuer}
                          </Grid>
                          <Grid style={{ marginBottom: "5px" }}>
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.url}
                            />{" "}
                            :{" "}
                            <a
                              href={certificates.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {certificates.url}
                            </a>
                          </Grid>{" "}
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.startDate}
                            />
                            : {certificates.startDate}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.endDate}
                            />{" "}
                            : {certificates.endDate}
                          </Grid>
                        </Grid>
                        {showEditIcon && (
                          <EditIcon
                            style={{}}
                            onClick={() =>
                              handleOpenModal("certifications", certificates.id)
                            }
                          />
                        )}
                      </Grid>
                    </Paper>
                  ))
                ) : (
                  <Typography>
                    {translationsEn.cvModals.noCertificate}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        );
      case 2:
        return (
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                maxHeight: 200,
                width: "100%",
                overflow: "auto",
                marginBottom: "5px",
                background: themeOptions.palette.primary.main,
                color: "white",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Newspaper />
                </Box>
                <Typography style={{ marginLeft: "5px", fontWeight: "bold" }}>
                  {translationsEn.cv.publics}
                </Typography>
              </Grid>

              <CardContent>
                {userPublications.length > 0 ? (
                  userPublications.map((publications: any) => (
                    <Paper
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.5)",
                        padding: "10px",
                        marginBottom: "10px",
                      }}
                      key={publications.id}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.publicationName}
                            />{" "}
                            : {publications.name}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.eventName}
                            />{" "}
                            : {publications.event}
                          </Grid>
                          <Grid style={{ marginBottom: "5px" }}>
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.url}
                            />{" "}
                            :{" "}
                            <a
                              href={publications.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {publications.url}
                            </a>
                          </Grid>{" "}
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.startDate}
                            />
                            : {publications.dateOfEvent}
                          </Grid>
                        </Grid>
                        {showEditIcon && (
                          <EditIcon
                            style={{}}
                            onClick={() =>
                              handleOpenModal("publications", publications.id)
                            }
                          />
                        )}
                      </Grid>
                    </Paper>
                  ))
                ) : (
                  <Typography>
                    {translationsEn.cvModals.noCertificate}
                  </Typography>
                )}
              </CardContent>
            </Card>
            <Card
              sx={{
                maxHeight: 200,
                width: "100%",
                overflow: "auto",
                marginBottom: "5px",
                background: themeOptions.palette.primary.main,
                color: "white",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <SettingsSuggest />
                </Box>
                <Typography style={{ marginLeft: "5px", fontWeight: "bold" }}>
                  {translationsEn.cv.skills}
                </Typography>
              </Grid>

              <CardContent>
                <Paper
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.5)",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Chip
                    sx={{
                      background: themeOptions.palette.primary.main,
                      color: "white",
                    }}
                    label={translationsEn.cv.skills}
                  />{" "}
                  {userSkills.length > 0 ? (
                    userSkills.map((skills: any) => (
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                        key={skills.id}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            {skills.name}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <Typography>{translationsEn.cvModals.noSkills}</Typography>
                  )}
                </Paper>
              </CardContent>
            </Card>
            <Card
              sx={{
                maxHeight: 200,
                width: "100%",
                overflow: "auto",
                background: themeOptions.palette.primary.main,
                color: "white",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <WorkspacePremium />
                </Box>
                <Typography style={{ marginLeft: "5px", fontWeight: "bold" }}>
                  {translationsEn.cv.honours}
                </Typography>
              </Grid>

              <CardContent>
                {userHonors.length > 0 ? (
                  userHonors.map((honors: any) => (
                    <Paper
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.5)",
                        padding: "10px",
                        marginBottom: "10px",
                      }}
                      key={honors.id}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.honorName}
                            />{" "}
                            : {honors.name}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.issuer}
                            />{" "}
                            : {honors.issuer}
                          </Grid>
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.issuerDate}
                            />
                            : {honors.issueDate}
                          </Grid>
                          <Grid
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            <Chip
                              sx={{
                                background: themeOptions.palette.primary.main,
                                color: "white",
                              }}
                              label={translationsEn.cvModals.description}
                            />{" "}
                            : {honors.description}
                          </Grid>
                        </Grid>
                        {showEditIcon && (
                          <EditIcon
                            onClick={() =>
                              handleOpenModal("honours", honors.id)
                            }
                          />
                        )}
                      </Grid>
                    </Paper>
                  ))
                ) : (
                  <Typography>{translationsEn.cvModals.noHonor}</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open}>
          <Paper
            style={{
              padding: "20px",
              textAlign: "center",
              width: "40%",
            }}
          >
            <Card
              sx={{
                maxHeight: 50,
                width: "40%",
                marginLeft: "10px",
                background: themeOptions.palette.primary.main,
                color: "white",
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  padding: "5px",
                  flexDirection: "row",
                }}
              >
                <Person />
                <Typography style={{ marginLeft: "5px" }}>
                  {loggedUserFullname}
                </Typography>
              </CardContent>
            </Card>
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
              }}
            >
              {renderContent()}
            </Grid>

            <hr></hr>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handlePrevious}
                  style={{ marginRight: "5px" }}
                >
                  <KeyboardArrowLeftIcon />
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleNext}
                  style={{ marginRight: "5px" }}
                >
                  <KeyboardArrowRightIcon />
                </Button>
                <Grid>
                  {currentPage + 1} / {maxPage}
                </Grid>
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button
                  variant="outlined"
                  style={{ marginRight: "5px" }}
                  sx={{
                    ":hover": {
                      backgroundColor: themeOptions.palette.confirm.hover,
                      borderColor: themeOptions.palette.confirm.hover,
                      color: themeOptions.palette.textField.main,
                    },
                  }}
                  onClick={handleButtonClick}
                >
                  {translationsEn.buttons.edit}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: themeOptions.palette.confirm.main,
                    ":hover": {
                      backgroundColor: themeOptions.palette.confirm.hover,
                    },
                  }}
                  onClick={handleClose}
                >
                  {translationsEn.buttons.save}
                </Button>
              </Box>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
      <SummaryModal
        open={modalStates.summary}
        handleClose={() => handleCloseModal("summary")}
        cvId={cvId}
        data={summaries}
        onSummaryUpdated={handleSummaryUpdated}
      />
      <EducationModal
        open={modalStates.education}
        handleClose={() => handleCloseModal("education")}
        cvId={cvId}
        data={selectedData}
        onEducationUpdate={handleEducationUpdated}
      />
      <LanguageModal
        open={modalStates.languages}
        handleClose={() => handleCloseModal("languages")}
        cvId={cvId}
        data={selectedDataLanguage}
        onLanguageUpdate={handleLanguageUpdated}
      />
      <ExperienceModal
        open={modalStates.experiences}
        handleClose={() => handleCloseModal("experiences")}
        cvId={cvId}
        data={selectDataExperiences}
        onExperienceUpdate={handleExpirienceUpdated}
      />
      <ProjectModal
        open={modalStates.projects}
        handleClose={() => handleCloseModal("projects")}
        cvId={cvId}
        data={selectDataProjects}
        onProjectUpdated={handleProjectUpdated}
      />
      <CertificationModal
        open={modalStates.certifications}
        handleClose={() => handleCloseModal("certifications")}
        certificateId={cvId}
        data={selectDataCertification}
        onCertificateUpdated={handleCertificationUpdated}
      />
      <PublicationsModal
        open={modalStates.publications}
        handleClose={() => handleCloseModal("publications")}
        cvId={cvId}
        data={selectedDataPublications}
        onPublicationUpdated={handlePublicationUpdate}
      />
      <SkillModal
        open={modalStates.skills}
        handleClose={() => handleCloseModal("skills")}
        cvId={cvId}
      />
      <HonoursModal
        open={modalStates.honours}
        handleClose={() => handleCloseModal("honours")}
        cvId={cvId}
        data={selectedHonoursData}
        onHonoursUpdated={handleHonoursUpdate}
      />
    </>
  );
};

export default OverviewModal;

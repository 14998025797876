import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  FormControl,
  TextField,
  Switch,
  Button,
  Grid,
  Autocomplete,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import translationsEn from "../../i18n/translations_en";
import { ICompany } from "../../interfaces/teams.interface";
import themeOptions from "../../style/componentsStyle";
import { editTeam } from "../../api/teamsApi";
import { getAllCompanies } from "../../api/overviewApi";
import SavedModal from "../confirmationsModal/saveModalMessage";

interface EditTeamModalProps {
  open: boolean;
  handleClose: () => void;
  data?: ICompany;
  children?: React.ReactNode;
}

const EditTeamModal: React.FC<EditTeamModalProps> = ({
  open,
  handleClose,
  data,
}) => {
  const handleModalClose = () => {
    handleClose();
  };

  const [selectAllCompany, setAllCompany] = useState<any>([]);
  const [savedModalOpen, setSavedModalOpen] = useState(false);

  useEffect(() => {
    getAllCompanies()
      .then((response) => setAllCompany(response))
      .catch((error) => console.error("Failed to fetch companies:", error));
  }, []);

  const validationSchema = Yup.object({
    teamName: Yup.string().required("Team name is required"),
    companies: Yup.array().min(1, "At least one company must be selected"),
  });

  const handleEditTeam = (values: any, { setSubmitting }: any) => {
    const companyIds = values.companies.map((company: any) => company.id);
    const payload = {
      id: data?.id !== undefined ? data.id : null,
      active: values.activeTeam,
      name: values.teamName,
      companyDtos: values.companies,
      companyIds: companyIds,
    };

    editTeam(payload)
      .then(() => setSavedModalOpen(true))
      .finally(() => {
        handleModalClose();
        setSubmitting(false);
      })
      .catch((error) => console.error("Failed to save team:", error));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open}>
          <Paper
            style={{
              padding: "20px",
              textAlign: "center",
              width: "20%",
              height: "35%",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
                {translationsEn.teamsInformation.editTeam}
              </Typography>
              <Clear onClick={handleModalClose} />
            </Box>
            <hr />
            <Formik
              initialValues={{
                teamName: data?.name || "",
                companies: data?.companyDtos || [],
                activeTeam: data?.active || false,
              }}
              validationSchema={validationSchema}
              onSubmit={handleEditTeam}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <Form>
                  <Grid container direction="column" alignItems="flex-start">
                    <Field
                      as={TextField}
                      name="teamName"
                      label={translationsEn.labels.teamName}
                      variant="outlined"
                      value={values.teamName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.teamName && Boolean(errors.teamName)}
                      helperText={
                        touched.teamName && errors.teamName
                          ? String(errors.teamName)
                          : ""
                      }
                      fullWidth
                      margin="normal"
                    />

                    <FormControl fullWidth margin="normal">
                      <Autocomplete
                        multiple
                        id="team-select-autocomplete"
                        options={selectAllCompany}
                        getOptionLabel={(option: any) => option.name}
                        value={values.companies}
                        onChange={(event, newValue) =>
                          setFieldValue("companies", newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Companies"
                            placeholder="Search and select"
                            error={
                              touched.companies && Boolean(errors.companies)
                            }
                            helperText={
                              touched.companies && errors.companies
                                ? String(errors.companies)
                                : ""
                            }
                          />
                        )}
                      />
                    </FormControl>

                    <Grid container direction="column" padding="10px">
                      <Typography>
                        {translationsEn.labels.active}
                        <Switch
                          checked={values.activeTeam}
                          onChange={() =>
                            setFieldValue("activeTeam", !values.activeTeam)
                          }
                          value={values.activeTeam}
                          color={values.activeTeam ? "primary" : "default"}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <hr />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ background: themeOptions.palette.confirm.main }}
                    >
                      {translationsEn.buttons.save}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Paper>
        </Fade>
      </Modal>
      <SavedModal
        open={savedModalOpen}
        handleClose={() => setSavedModalOpen(false)}
      />
    </>
  );
};

export default EditTeamModal;

import { CSSProperties } from "react";
import themeOptions from "../../style/componentsStyle";

export const directionCss: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  padding: "1em",
};

export const modalCss: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "1e",
  maxWidth: "90%",
};

export const contentCss: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "1e",
  maxWidth: "100%",
};

export const buttonCss: CSSProperties = {
  backgroundColor: themeOptions.palette.primary.main,
  color: "white",
  border: themeOptions.palette.primary.main,
  margin: "0.5em",
  padding: "0.5em 1em",
};

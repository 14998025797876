import { Add, ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import translationsEn from "../i18n/translations_en";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  IEditEmployeePayload,
  IStatusType,
} from "../interfaces/employee.interface";
import { useParams } from "react-router-dom";
import { editUser, getEmployee, getEmployeeContract } from "../api/requestsApi";
import themeOptions from "../style/componentsStyle";
import NewContractEmployee from "../modals/employeeManagement/newContractEmployee";
import CancelContract from "../modals/employeeManagement/cancelContract";
import { getStatuses } from "../api/employeeApi";
import { getAllCompanies } from "../api/overviewApi";

type RequestStatusName = "student" | "inactive" | "full_time" | "part_time";

const initialValues: IEditEmployeePayload = {
  firstName: "",
  lastName: "",
  username: "",
  oib: "",
  email: "",
  dateOfBirth: "",
  companyDto: "",
  businessPhone: "", // Added businessPhone to initial values
};

export default function EditUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [modalStates, setModalStates] = useState({
    newContract: false,
    cancelContract: false,
  });
  const [refreshListTrigger, setRefreshListTrigger] = useState(true);
  const [employeeData, setEmployeeData] = useState<any>([]);
  const [employeeContract, setEmployeeContract] = useState<any>([]);
  const [allStatuses, setAllStatuses] = useState<any>([]);
  const [companies, setCompanies] = useState([] as any);

  const validationSchema = Yup.object({
    firstName: Yup.string().required(`${translationsEn.validation.required}`),
    lastName: Yup.string().required(`${translationsEn.validation.required}`),
    oib: Yup.string().required(`${translationsEn.validation.required}`),
    email: Yup.string()
      .email(`${translationsEn.validation.emailInvalid}`)
      .required(`${translationsEn.validation.required}`),
    businessPhone: Yup.string().required(
      `${translationsEn.validation.required}`
    ), // Added validation for businessPhone
  });

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companyData = await getAllCompanies();
        setCompanies(companyData);
      } catch (error) {
        console.error("Failed to fetch companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, action) => {
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        username: values.username,
        oib: values.oib,
        email: values.email,
        dateOfBirth: values.dateOfBirth,
        companyDto: {
          id: values.companyDto,
        },
        businessPhone: values.businessPhone, // Added businessPhone to payload
      };

      editUser(payload, employeeData.data.id)
        .then((response) => {
          navigate("/employees");
        })
        .catch((error) => console.error("Failed editing user:", error));
    },
  });

  useEffect(() => {
    getStatuses()
      .then((response) => setAllStatuses(response.data))
      .catch((error) => console.error("Failed to fetch teams:", error));
  }, []);

  const handleOpenModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: true,
    }));
  };

  const handleCloseModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: false,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const userData = await getEmployee(Number(id));

          console.log(userData);

          formik.setFieldValue("firstName", userData.data.firstName);
          formik.setFieldValue("lastName", userData.data.lastName);
          formik.setFieldValue("username", userData.data.username);
          formik.setFieldValue("oib", userData.data.oib);
          formik.setFieldValue("dateOfBirth", userData.data.dateOfBirth);
          formik.setFieldValue("email", userData.data.email);
          formik.setFieldValue("companyDto", userData.data.companyDto.id);
          formik.setFieldValue("businessPhone", userData.data.businessPhone); // Set businessPhone field value

          setEmployeeData(userData);
        } else {
          console.error("User ID is undefined");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchContractData = async () => {
      try {
        if (id) {
          const employeeContract = await getEmployeeContract(Number(id));
          setEmployeeContract(employeeContract.data);
        } else {
          console.error("User ID is undefined");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchContractData();
  }, [id, refreshListTrigger]);

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Grid style={{ display: "flex", justifyContent: "space-between" }}>
        <Link to={"/employees"}>
          <Button
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              color: "white",
              ":hover": { background: themeOptions.palette.primary.hover },
            }}
          >
            <ArrowBack />
          </Button>
        </Link>
        <Grid style={{ padding: "5px" }}>
          <Typography style={{ fontWeight: "bold", fontSize: "30px" }}>
            {translationsEn.editUser.name}
          </Typography>
        </Grid>

        <Button
          sx={{
            backgroundColor: themeOptions.palette.primary.main,
            color: "white",
            ":hover": { background: themeOptions.palette.primary.hover },
          }}
          onClick={() => handleOpenModal("cancelContract")}
        >
          {translationsEn.buttons.cancelContract}
        </Button>
      </Grid>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          height: "75%",
          padding: "10px",
        }}
        variant="outlined"
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <TextField
              name={"firstName"}
              label={translationsEn.employees.firstName}
              value={formik.values.firstName}
              variant="outlined"
              sx={{ width: "99%", margin: "5px" }}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <TextField
              name={"lastName"}
              label={translationsEn.employees.lastName}
              variant="outlined"
              sx={{
                width: "99%",
                margin: "5px",
              }}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <TextField
              name={"username"}
              disabled
              label={translationsEn.employees.username}
              variant="outlined"
              sx={{
                width: "99%",
                margin: "5px",
              }}
              value={formik.values.username}
            />
            <TextField
              name={"oib"}
              label={translationsEn.employees.oib}
              variant="outlined"
              sx={{
                width: "99%",
                margin: "5px",
              }}
              value={formik.values.oib}
              onChange={formik.handleChange}
              error={formik.touched.oib && Boolean(formik.errors.oib)}
              helperText={formik.touched.oib && formik.errors.oib}
            />
            <DatePicker
              label={translationsEn.labels.dateOfBirth}
              format="DD/MM/YYYY"
              sx={{ width: "99%", margin: "5px" }}
              value={dayjs(new Date(formik.values.dateOfBirth))}
              onChange={(value: any) => {
                formik.setFieldValue(
                  "dateOfBirth",
                  value.toISOString().split("T")[0]
                );
              }}
            />

            {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
              <Typography sx={{ color: "red" }}>
                {formik.errors.dateOfBirth}
              </Typography>
            )}
            <Typography>
              {formik.touched.dateOfBirth && formik.errors.dateOfBirth}
            </Typography>
            <TextField
              name={"email"}
              label={translationsEn.employees.email}
              variant="outlined"
              sx={{
                width: "99%",
                margin: "5px",
              }}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              disabled
            />
            <TextField
              name={"businessPhone"} // Added TextField for businessPhone
              label={translationsEn.employees.businessPhone}
              variant="outlined"
              sx={{
                width: "99%",
                margin: "5px",
              }}
              value={formik.values.businessPhone}
              onChange={formik.handleChange}
              error={
                formik.touched.businessPhone &&
                Boolean(formik.errors.businessPhone)
              }
              helperText={
                formik.touched.businessPhone &&
                formik.errors.businessPhone &&
                (formik.errors.businessPhone as string)
              }
            />
            <FormControl sx={{ width: "99%", margin: "5px" }}>
              <InputLabel id="demo-simple-select-label">
                {translationsEn.employees.company}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label={translationsEn.employees.company}
                name="companyDto"
                value={formik.values.companyDto}
                onChange={formik.handleChange}
              >
                {companies.map((company: any) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.confirm.main,
                  ":hover": {
                    backgroundColor: themeOptions.palette.confirm.hover,
                  },
                  margin: "5px",
                }}
              >
                {translationsEn.buttons.save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Paper
        style={{
          height: "25%",
          maxWidth: "1600px",
          overflowX: "auto",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            padding: "10px",
            maxHeight: "200px",
          }}
        >
          {employeeContract?.map((contract: any, index: number) => (
            <Grid key={index} item xs={3}>
              <CardContent key={index}>
                <Card sx={{ padding: "10px" }}>
                  <Typography>
                    {translationsEn.editUser.startDate}:{" "}
                    {dayjs(contract.startDate).format("DD-MM-YYYY")}
                  </Typography>
                  <Typography>
                    {translationsEn.editUser.endDate}:{" "}
                    {contract.endDate
                      ? dayjs(contract.endDate).format("DD-MM-YYYY")
                      : ""}
                  </Typography>
                  <Typography>
                    {translationsEn.editUser.shiftDuration}:{" "}
                    {contract.shiftDuration}
                  </Typography>
                  <Typography>
                    {translationsEn.editUser.vacationDays}:{" "}
                    {contract.vacationDays}
                  </Typography>
                  <Typography>
                    {translationsEn.editUser.probatioDate}:{" "}
                    {contract.probationExpirationDate
                      ? dayjs(contract.probationExpirationDate).format(
                          "DD-MM-YYYY"
                        )
                      : ""}
                  </Typography>
                  <Typography>
                    {translationsEn.editUser.employeeStatus}:{" "}
                    {translationsEn.statusType[
                      allStatuses.find(
                        (status: any) => status.id === contract.employeeStatusId
                      )?.status as RequestStatusName
                    ] || contract.employeeStatusId}
                  </Typography>
                </Card>
              </CardContent>
            </Grid>
          ))}
        </Grid>
        <Grid sx={{ padding: "10px" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              ":hover": {
                backgroundColor: themeOptions.palette.primary.hover,
              },
              width: "1%",
              height: "30%",
              borderRadius: "100px",
            }}
            onClick={() => {
              handleOpenModal("newContract");
            }}
          >
            <Add />
          </Button>
        </Grid>
      </Paper>
      <NewContractEmployee
        open={modalStates.newContract}
        employeeId={employeeData?.data?.id}
        handleClose={() => handleCloseModal("newContract")}
        refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
      />
      <CancelContract
        open={modalStates.cancelContract}
        handleClose={() => handleCloseModal("cancelContract")}
        employeeId={employeeData?.data?.id}
        refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
      />
    </div>
  );
}

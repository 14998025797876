import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { HistoryEdu, Clear } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { useFormik } from "formik";
import { addCertificates, updatedCertificate } from "../../api/cvApi";
import { useAlert } from "../../utils/alertContext";
import dayjs from "dayjs";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers";

interface CertificationModalProps {
  open: boolean;
  handleClose: () => void;
  certificateId: number;
  data?: any;
  onCertificateUpdated?: () => void;
}

const CertificationModal: React.FC<CertificationModalProps> = ({
  open,
  handleClose,
  certificateId,
  data,
  onCertificateUpdated = () => {},
}) => {
  const [ongoingCertification, setOngoingCertification] = useState(false);
  const { showAlert } = useAlert();

  const initialValues = {
    name: data ? data.name : "",
    cvId: certificateId,
    issuer: data ? data.issuer : "",
    url: data ? data.url : "",
    startDate: data ? dayjs(data.startDate) : null,
    endDate: data ? (data.endDate ? dayjs(data.endDate) : null) : null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${translationsEn.validation.required}`),
    issuer: Yup.string().required(`${translationsEn.validation.required}`),
    url: Yup.string().required(`${translationsEn.validation.required}`),
    startDate: Yup.date().required(`${translationsEn.validation.required}`),
    endDate: ongoingCertification
      ? Yup.date().nullable().notRequired()
      : Yup.date().required(`${translationsEn.validation.required}`),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const startDate = values.startDate
        ? dayjs(values.startDate).format("YYYY-MM")
        : null;
      const endDate =
        ongoingCertification || !values.endDate
          ? null
          : dayjs(values.endDate).format("YYYY-MM");

      const endpointPayload = {
        certificateId,
        name: values.name,
        cvId: certificateId,
        issuer: values.issuer,
        url: values.url,
        startDate,
        endDate,
      };

      const saveCertificate = data ? updatedCertificate : addCertificates;

      saveCertificate({
        id: data ? data.id : 0,
        ...endpointPayload,
      })
        .then((response) => {
          showAlert(
            `${translationsEn.cvModals.successCertificates}`,
            "success"
          );
          onCertificateUpdated();
          handleClose();
        })
        .catch((error) => {
          showAlert(
            error.response?.data?.error || "An error occurred",
            "error"
          );
          console.error("Error adding certificate", error);
        });
    },
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOngoingCertification(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue("endDate", null);
    }
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      formik.setValues({
        name: data.name || "",
        cvId: data.id || "",
        issuer: data.issuer || "",
        url: data.url || "",
        startDate: data.startDate ? dayjs(data.startDate) : null,
        endDate: data.endDate ? dayjs(data.endDate) : null,
      });
      setOngoingCertification(data.endDate === null);
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "40%",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <HistoryEdu style={{ marginRight: "5px" }} />
              <Typography>{translationsEn.cvModals.addCertificates}</Typography>
            </Box>

            <Clear onClick={handleClose} />
          </Box>
          <hr />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "30px",
            }}
          >
            <TextField
              id="name"
              name="name"
              label="Certificate name"
              variant="standard"
              required
              value={formik.values.name}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
              }}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />

            <TextField
              id="issuer"
              name="issuer"
              label="Issuing organization"
              variant="standard"
              required
              value={formik.values.issuer}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
              }}
              error={formik.touched.issuer && Boolean(formik.errors.issuer)}
            />

            <TextField
              id="url"
              name="url"
              label="URL"
              variant="standard"
              required
              value={formik.values.url}
              onChange={formik.handleChange}
              style={{
                margin: "10px",
              }}
              error={formik.touched.url && Boolean(formik.errors.url)}
            />

            <FormControl style={{ width: "100%" }}>
              <DatePicker
                label="Start date"
                views={["month", "year"]}
                value={
                  formik.values.startDate
                    ? dayjs(formik.values.startDate)
                    : null
                }
                onChange={(date) =>
                  formik.setFieldValue(
                    "startDate",
                    date ? date.format("YYYY-MM") : null
                  )
                }
                slotProps={{
                  textField: {
                    helperText:
                      formik.touched.startDate && formik.errors.startDate,
                    error: Boolean(
                      formik.touched.startDate && formik.errors.startDate
                    ),
                  },
                }}
                sx={{ width: "100%" }}
              />
            </FormControl>

            <FormControlLabel
              style={{ margin: "10px", width: "30%" }}
              control={
                <Checkbox
                  checked={ongoingCertification}
                  onChange={handleCheckboxChange}
                />
              }
              label="Ongoing certification"
            />

            {!ongoingCertification && (
              <FormControl style={{ width: "100%" }}>
                <DatePicker
                  label="End date"
                  views={["year", "month"]}
                  value={
                    formik.values.endDate ? dayjs(formik.values.endDate) : null
                  }
                  onChange={(date) =>
                    formik.setFieldValue(
                      "endDate",
                      date ? date.format("YYYY-MM") : null
                    )
                  }
                  slotProps={{
                    textField: {
                      helperText:
                        formik.touched.endDate && formik.errors.endDate,
                      error: Boolean(
                        formik.touched.endDate && formik.errors.endDate
                      ),
                    },
                  }}
                  sx={{ width: "100%" }}
                />
              </FormControl>
            )}
          </Box>
          <hr />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: themeOptions.palette.confirm.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.confirm.hover,
                },
              }}
              onClick={handleSave}
            >
              {translationsEn.buttons.save}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default CertificationModal;

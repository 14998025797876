import {
  Paper,
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Autocomplete,
  FormControl,
  TextField,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import themeOptions from "../../style/componentsStyle";
import translationsEn from "../../i18n/translations_en";
import { useEffect, useState } from "react";
import {
  deleteMyTravelRequest,
  getMyTravelRequests,
} from "../../api/travelRequestApi";
import { Link } from "react-router-dom";
import ConfirmationModal from "./confirmationModal";
import { useAlert } from "../../utils/alertContext";

export default function MyTravelRequests() {
  const { showAlert } = useAlert();
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTR, setSelectedTR] = useState<number>();

  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const [refresh, setRefresh] = useState<boolean>(true);
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const emptyRows = tableData
    ? 5 - tableData.slice(page * pageSize, page * pageSize + pageSize)!.length
    : 0;

  const statusList = [
    { name: "created", label: "Created" },
    { name: "approved", label: "Approved" },
    { name: "denied", label: "Denied" },
    { name: "completed", label: "Completed" },
    { name: "deleted", label: "Deleted" },
    { name: "waiting_for_approval", label: "Waiting for approval" },
  ];

  useEffect(() => {
    getMyTravelRequests(status)
      .then((response) => {
        setPage(0);
        setTableData(response.data.data);
      })
      .catch((error) => console.error("Failed to fetch employees:", error))
      .finally(() => {
        setLoading(false);
      });
  }, [status, refresh]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleDeleteTravelRequest = async (id: any) => {
    deleteMyTravelRequest(id)
      .then((response) => {
        setRefresh(!refresh);
        showAlert("Rejected successfully!", "success");
      })
      .catch((error) => showAlert(error, "error"))
      .finally(() => {
        setDeleteModal(false);
        setSelectedTR(0);
      });
  };

  const getStatusLabel = (name: string): string | undefined => {
    const status = statusList.find((item) => item.name === name);
    return status ? status.label : undefined;
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.myTravelRequests.title}
        </Typography>
      </Paper>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <Box
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <FormControl
            style={{ marginTop: "10px", width: "200px", marginRight: "10px" }}
          >
            <Autocomplete
              options={statusList}
              getOptionLabel={(option: any) => option.label}
              onChange={(event: any, value: any) => {
                setStatus(value?.name);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translationsEn.myTravelRequests.status}
                />
              )}
            />
          </FormControl>
        </Box>
      </Paper>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 401 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.myTravelRequests.number}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.myTravelRequests.trTitle}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.myTravelRequests.status}
                </TableCell>
                <TableCell align="center">
                  {translationsEn.myTravelRequests.action}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ height: "344px" }}>
              {tableData.length === 0 && (
                <TableRow style={{ height: 344 }}>
                  <TableCell colSpan={4} align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>{translationsEn.table.noData}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {tableData
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((dataRow: any, index: any) => (
                  <TableRow
                    key={dataRow.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      height: "20px",
                    }}
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">
                      {translationsEn.createTravelRequests.travelOrder}{" "}
                      {dataRow.id}
                    </TableCell>

                    <TableCell align="left">
                      {getStatusLabel(dataRow.status)}
                    </TableCell>

                    <TableCell align="center">
                      {dataRow.status !== "deleted" ? (
                        <Button style={{ padding: "5px" }}>
                          <Link
                            style={{ height: "24px" }}
                            to={`/create-travel-requests/${dataRow.id}`}
                          >
                            <EditIcon
                              style={{
                                color: themeOptions.palette.primary.main,
                              }}
                            />
                          </Link>
                        </Button>
                      ) : (
                        <></>
                      )}
                      {dataRow.status === "waiting_for_approval" ? (
                        <Button
                          onClick={() => {
                            setDeleteModal(true);
                            setSelectedTR(dataRow.id);
                          }}
                          style={{ padding: "5px" }}
                        >
                          <Delete
                            style={{ color: themeOptions.palette.cancel.main }}
                          />
                        </Button>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {tableData.slice(page * pageSize, page * pageSize + pageSize)
                .length !== 0 &&
                emptyRows > 0 && (
                  <TableRow style={{ height: 68 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          colSpan={3}
          count={tableData.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationModal
        open={deleteModal}
        handleClose={() => {
          setDeleteModal(false);
        }}
        onConfirm={() => {
          handleDeleteTravelRequest(selectedTR);
        }}
        type={"Delete"}
      />
    </div>
  );
}

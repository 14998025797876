import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from "@fullcalendar/interaction";
import { Paper } from '@mui/material';
import "./customCalendar.css";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import styles from './TooltipStyles.module.css';

const statusStyles = {
    waiting_for_approval: { color: 'yellow', tooltip: 'Waiting for approval', class: 'tooltip-waiting' },
    approved: { color: 'green', tooltip: 'Approved', class: 'tooltip-approved' },
    cancelled: { color: 'red', tooltip: 'Cancelled', class: 'tooltip-cancelled' }
};

type Statuses = 'approved' | 'cancelled' | 'waiting_for_approval';

const CustomScheduler = ({ calendarRequestClicked, ...calendarProps }: any) => {
    const getClassName = (status: any) => {
        switch (status) {
            case 'tooltip-waiting':
                return styles.tooltipWaiting;
            case 'tooltip-approved':
                return styles.tooltipApproved;
            case 'tooltip-cancelled':
                return styles.tooltipCancelled;
            default:
                return '';
        }
    };

    const handleEventClick = (clickInfo: any) => {
        if (clickInfo.event.extendedProps.status !== 'holiday')
            calendarRequestClicked(clickInfo.event);
    };

    const renderEventContent = (eventInfo: any) => {
        const { event } = eventInfo;
        const status = event.extendedProps.status;
        const style = statusStyles[status?.name as Statuses] || {};

        return (
            <Tippy content={(event.title + '  -  ' + style.tooltip) || ''} className={getClassName(style.class)} placement="top">
                <div >
                    {event.title}
                </div>
            </Tippy>
        );
    };

    return (
        <div id="overviewCalendar">
            <Paper style={{ margin: "10px" }}>
                <div style={{ padding: "10px" }} id="custom-fullcalendar">
                    <FullCalendar
                        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                        plugins={[resourceTimelinePlugin, interactionPlugin]}
                        initialView="resourceTimelineWeek"
                        resourceAreaHeaderContent="Employees"
                        resourceAreaWidth="200px"
                        slotDuration={{ days: 1 }}
                        firstDay={1}
                        aspectRatio={3}
                        headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "resourceTimelineWeek,resourceTimelineMonth",
                        }}
                        resources={calendarProps.resources}
                        events={calendarProps.events}
                        selectable={true}
                        eventClick={handleEventClick}
                        eventContent={renderEventContent}
                        {...calendarProps}
                    />
                </div>
            </Paper>
        </div>
    );
};

export default CustomScheduler;

import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper } from '@mui/material';
import dayjs from 'dayjs';
import HeadingTitle from '../components/headingTitle/headingTitle.component';
import MonthNavigator from '../components/monthNavigator/monthNavigator.component';
import ShiftTable from '../components/shiftRecords/shiftTable.component';
import NotificationBar from '../components/notificationBar/notificationBar.component';
import {
  getShiftRecords,
  getShiftRecordsForScheduler,
  getShiftRecordsForCompany,
  getShiftRecordsForSchedulerForCompany,
  getWorkdayTypes,
  patchShiftRecord,
  createShiftRecord,
  deleteShiftRecord,
  generateShiftRecords,
  getReportShiftRecords,
} from '../api/shiftRecordsApi';
import ShiftRecordFilterPanel from '../components/shiftRecords/shiftFilterPanel.component';
import { useAlert } from '../utils/alertContext';

const ShiftRecords = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [shiftRecords, setShiftRecords] = useState([] as any);
  const [workdayTypes, setWorkdayTypes] = useState([] as any);
  const [filter, setFilter] = useState({ month: currentDate.getMonth() + 1, year: currentDate.getFullYear(), company: '' });
  const [unfilteredData, setUnfilteredData] = useState([] as any);
  const { showAlert } = useAlert();

  useEffect(() => {
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    const filterParams = {
      day: null,
      month: filter.month,
      year: filter.year,
      companyId: filter.company || null,
    };

    try {
      let shiftRecordsResponse: any, shiftRecordsSchedulerResponse: any;

      if (filter.company) {
        shiftRecordsResponse = await getShiftRecordsForCompany(filterParams);
        shiftRecordsSchedulerResponse = await getShiftRecordsForSchedulerForCompany(filterParams);
      } else {
        shiftRecordsResponse = await getShiftRecords(filterParams);
        shiftRecordsSchedulerResponse = await getShiftRecordsForScheduler(filterParams);
      }

      const workdayData: any = await getWorkdayTypes();

      // Merge the two responses
      const mergedData = shiftRecordsSchedulerResponse.data.map((scheduler: any) => {
        const employeeData = shiftRecordsResponse.data.find((employee: any) => employee.employeeId === scheduler.employeeDto.id);
        return {
          ...scheduler.employeeDto,
          ...employeeData,
          shiftRecordDto: scheduler.shiftRecordDto,
          companyId: scheduler.employeeDto.companyDto?.id,
        };
      });

      setUnfilteredData(mergedData);
      setShiftRecords(mergedData);
      setWorkdayTypes(workdayData.data);
    } catch (error) {
      console.error('Failed to fetch shift data', error);
    }
  };

  const handleSearch = (filterParams: any) => {
    setFilter(filterParams);
    setCurrentDate(new Date(filterParams.year, filterParams.month - 1, 1));

    const filteredData = filterParams.company
      ? unfilteredData.filter((employee: any) => employee.companyId === filterParams.company)
      : unfilteredData;

    setShiftRecords(filteredData);
  };

  const handlePrevMonth = () => {
    const prevMonth = dayjs(currentDate).subtract(1, 'month').toDate();
    setCurrentDate(prevMonth);
    setFilter({ ...filter, month: prevMonth.getMonth() + 1, year: prevMonth.getFullYear() });
  };

  const handleNextMonth = () => {
    const nextMonth = dayjs(currentDate).add(1, 'month').toDate();
    setCurrentDate(nextMonth);
    setFilter({ ...filter, month: nextMonth.getMonth() + 1, year: nextMonth.getFullYear() });
  };

  const handleTodayClick = () => {
    const today = new Date();
    setCurrentDate(today);
    setFilter({ month: today.getMonth() + 1, year: today.getFullYear(), company: '' });
  };

  const handleFilterChange = (newFilter: any) => {
    setFilter(newFilter);
  };

  const handleCreateShift = async (shiftRecord: any) => {
    try {
      const response = await createShiftRecord(shiftRecord);
      console.log('Shift created:', response);
      fetchData();
    } catch (error) {
      console.error('Error creating shift record:', error);
    }
  };

  const handleUpdateShift = async (shiftRecord: any) => {
    try {
      const response = await patchShiftRecord(shiftRecord.workDayType, shiftRecord.shiftRecordId);
      console.log('Shift updated:', response);
      fetchData();
    } catch (error) {
      console.error('Error updating shift record:', error);
    }
  };

  const handleDeleteShift = async (shiftRecord: any) => {
    try {
      const response = await deleteShiftRecord(shiftRecord);
      console.log('Shift deleted:', response);
      fetchData();
    } catch (error) {
      console.error('Error deleting shift record:', error);
    }
  };

  const handleGenerateShift = async (generateParams: any) => {
    if (!generateParams.companyId) {
      showAlert('Please select a company to generate shift records.', 'warning');
      return;
    }
    try {
      const response = await generateShiftRecords(generateParams);
      console.log('Shift records generated:', response);
      fetchData();
    } catch (error) {
      console.error('Error generating shift records:', error);
    }
  };

  const handleExport = async (exportParams: any) => {
    if (!exportParams.companyId) {
      showAlert('Please select a company to export shift records.', 'warning');
      return;
    }
    try {
      const response: any = await getReportShiftRecords(exportParams);
      const base64Data = response.data;

      const byteCharacters = atob(base64Data.replace(/\s/g, ''));
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `report-${exportParams.day}-${exportParams.month}-${exportParams.year}.pdf`;
      link.click();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error exporting shift records:', error);
    }
  };

  return (
    <Box sx={{ height: '100%', padding: '10px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <HeadingTitle text="Shift Records" />
          <MonthNavigator currentDate={currentDate} onPrev={handlePrevMonth} onNext={handleNextMonth} />
        </Grid>
      </Paper>
      <ShiftRecordFilterPanel
        month={filter.month}
        year={filter.year}
        company={filter.company}
        onFilterChange={handleFilterChange}
        onSearch={handleSearch}
        onExport={handleExport}
      />
      <ShiftTable
        data={shiftRecords}
        currentDate={currentDate}
        handleTodayClick={handleTodayClick}
        workdayTypes={workdayTypes}
        onCreateShift={handleCreateShift}
        onUpdateShift={handleUpdateShift}
        onDeleteShift={handleDeleteShift}
        onGenerateShift={handleGenerateShift}
      />
      <NotificationBar message={'Generate shift records and export data to PDF actions can only be made if day and company are selected'} />
    </Box>
  );
};

export default ShiftRecords;

import {
  Paper,
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Autocomplete,
  FormControl,
  TextField,
} from "@mui/material";
import { Close, Delete, Done } from "@mui/icons-material";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";
import { useEffect, useState } from "react";
import { deleteTeamApi, getAllTeams } from "../api/teamsApi";
import NewTeamModal from "../modals/teamsInformation/createNewTeam";
import { getAllCompanies } from "../api/overviewApi";

export default function Teams() {
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number[] | null>();
  const [selectAllCompany, setAllComapny] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const emptyRows = tableData
    ? 5 - tableData.slice(page * pageSize, page * pageSize + pageSize)!.length
    : 0;

  const handleSearch = () => {
    if (selectedCompanyId) {
      getAllTeams({
        companyIds: selectedCompanyId,
        employmentStatusId: null,
      })
        .then((response) => setTableData(response.data))
        .catch((error) => console.error("Failed to fetch companies:", error));
    }
  };

  useEffect(() => {
    getAllCompanies()
      .then((response) => setAllComapny(response))
      .catch((error) => console.error("Failed to fetch companies:", error));
  }, []);
  useEffect(() => {
    const filter = {
      companyIds: [],
      employmentStatusId: null,
    };

    if (!selectedCompanyId) {
      getAllTeams(filter)
        .then((response) => {
          setTableData(response.data);
          let companies = response.data
            .map((item: any) => {
              const { companyDtos } = item;
              if (companyDtos.length !== 0) {
                const { name } = companyDtos[0];
                return name;
              }
            })
            .filter((name: any) => name !== undefined);
          companies = [...new Set(companies)];
          setSelectedCompanyId(companies);
          setLoading(false);
        })
        .catch((error) => console.error("Failed to fetch teams:", error));
    }
  }, [selectedCompanyId]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteTeam = async (teamId: any) => {
    try {
      await deleteTeamApi(teamId);
      const copyData = [...tableData].filter(
        (item: { id: number }) => item.id !== teamId
      );
      setTableData(copyData);
      setLoading(false);
    } catch (error) {
      console.error("Failed to delete employee:", error);
    }
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.teams.teams}
        </Typography>
        <Button
          variant="contained"
          style={{ margin: "10px", borderRadius: "20px" }}
          sx={{
            backgroundColor: themeOptions.palette.primary.main,
            color: "white",
            ":hover": {
              background: themeOptions.palette.primary.hover,
            },
          }}
          onClick={handleOpenModal}
        >
          {translationsEn.buttons.create}
        </Button>
      </Paper>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <Box
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <FormControl
            style={{ marginTop: "10px", width: "40%", marginRight: "10px" }}
          >
            <Autocomplete
              multiple
              disablePortal
              sx={{ width: "50%" }}
              id="combo-box-demo"
              options={selectAllCompany}
              getOptionLabel={(option: any) => option.name}
              onChange={(event: any, value: any) => {
                if (value && value.length > 0) {
                  const selectedIds = value.map((item: any) => item.id);
                  setSelectedCompanyId(selectedIds);
                } else {
                  setSelectedCompanyId(null);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Companies" />
              )}
            />
          </FormControl>

          <Button
            variant="contained"
            style={{
              marginTop: "10px",
              borderRadius: "20px",
              height: "60%",
            }}
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              color: "white",
              ":hover": {
                background: themeOptions.palette.primary.hover,
              },
            }}
            onClick={handleSearch}
          >
            {translationsEn.buttons.search}
          </Button>
        </Box>
      </Paper>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 401 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.teams.name}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.teams.active}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.teams.company}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ height: "344px" }}>
              {tableData.length === 0 && (
                <TableRow style={{ height: 344 }}>
                  <TableCell colSpan={3} align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>{translationsEn.table.noData}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {tableData
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((dataRow: any) => (
                  <TableRow
                    key={dataRow.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">{dataRow.name}</TableCell>
                    <TableCell align="left">
                      {dataRow.active ? (
                        <Done
                          sx={{ color: themeOptions.palette.confirm.main }}
                        />
                      ) : (
                        <Close
                          sx={{ color: themeOptions.palette.cancel.main }}
                        />
                      )}
                    </TableCell>

                    <TableCell align="left">
                      {dataRow.companyDtos.length > 0 &&
                        dataRow.companyDtos.map(
                          (dataRow: any) => dataRow.name + " , "
                        )}
                    </TableCell>

                    <TableCell align="right">
                      <Button
                        onClick={() => handleDeleteTeam(dataRow.id)}
                        style={{ padding: "5px" }}
                        sx={{ color: themeOptions.palette.primary.main }}
                      >
                        <Delete />
                      </Button>
                      <Button
                        variant="contained"
                        href={`/teamsInformation/${dataRow.id}`}
                        sx={{
                          backgroundColor: themeOptions.palette.primary.main,
                          color: "white",
                          ":hover": {
                            background: themeOptions.palette.primary.hover,
                          },
                        }}
                      >
                        {translationsEn.teams.select}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              {tableData.slice(page * pageSize, page * pageSize + pageSize)
                .length !== 0 &&
                emptyRows > 0 && (
                  <TableRow style={{ height: 68 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          colSpan={3}
          count={tableData.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <NewTeamModal
        open={openModal}
        handleClose={() => handleCloseModal()}
        data={tableData}
        setData={setTableData}
      />
    </div>
  );
}

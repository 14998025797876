import {
  Modal,
  Box,
  styled,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import translationsEn from "../../i18n/translations_en";
import { useFormik } from "formik";
import * as Yup from "yup";
import themeOptions from "../../style/componentsStyle";
import { useEffect, useState } from "react";
import { addCost } from "../../api/travelRequestApi";
import { useAlert } from "../../utils/alertContext";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  paddingTop: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  border: "2px solid #ccc",
};

const Title = styled("h3")({
  margin: "auto",
});

const HorizontalLine = styled("hr")({
  display: "block",
  height: "1px",
  border: "0",
  borderTop: "2px solid #ccc",
  margin: "1em 0",
  padding: "0",
  width: "100%",
});

const Content = styled("div")({
  width: "900px",
  margin: "auto",
  padding: "30px 0px",
});

const validationSchema = Yup.object({
  costName: Yup.string().required("Departure date is required"),
  numberOf: Yup.number().required("Departure time is required"),
  amountOfCost: Yup.number().required("Return date is required"),
  totalAmount: Yup.number().required("Return time is required"),
  description: Yup.string().required("Duration hours is required"),
});

const initialValues = {
  costName: "",
  numberOf: "",
  amountOfCost: "",
  totalAmount: "",
  description: "",
  travelExpensesId: 0,
};

export default function AddCostModal(props?: any) {
  const { open, closeModal,  travelExpensesId } = props;
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState<boolean>(false);


  useEffect(() => {
    formik.setFieldValue("travelExpensesId", travelExpensesId);
  }, [travelExpensesId]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      console.log(values);
      console.log(travelExpensesId);
        setLoading(true);
        addCost(values)
          .then(() => {
            closeModal();
            showAlert("Cost added successfully!", "success");
          })
          .catch((error) => {
            showAlert(error, "error");
          })
          .finally(() => {
            setLoading(false);
          });
    },
  });
  return (
    <Modal open={open} onClose={() => {}}>
      <Box sx={style}>
        <Title>{translationsEn.addCost.title}</Title>
        <HorizontalLine />
        <Content>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label={translationsEn.addCost.costName}
                    name="costName"
                    value={formik.values.costName}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.costName && formik.errors.costName
                    )}
                    helperText={
                      formik.touched.costName &&
                      formik.errors.costName &&
                      (formik.errors.costName as string)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    margin="dense"
                    label={translationsEn.addCost.numberOf}
                    name="numberOf"
                    value={formik.values.numberOf}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.numberOf && formik.errors.numberOf
                    )}
                    helperText={
                      formik.touched.numberOf &&
                      formik.errors.numberOf &&
                      (formik.errors.numberOf as string)
                    }
                    fullWidth
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label={translationsEn.addCost.description}
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.description && formik.errors.description
                  )}
                  helperText={
                    formik.touched.description &&
                    formik.errors.description &&
                    (formik.errors.description as string)
                  }
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  margin="dense"
                  label={translationsEn.addCost.amountOfCost}
                  name="amountOfCost"
                  value={formik.values.amountOfCost}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.amountOfCost && formik.errors.amountOfCost
                  )}
                  helperText={
                    formik.touched.amountOfCost &&
                    formik.errors.amountOfCost &&
                    (formik.errors.amountOfCost as string)
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  margin="dense"
                  label={translationsEn.addCost.totalAmount}
                  name="totalAmount"
                  value={formik.values.totalAmount}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.totalAmount && formik.errors.totalAmount
                  )}
                  helperText={
                    formik.touched.totalAmount &&
                    formik.errors.totalAmount &&
                    (formik.errors.totalAmount as string)
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  size="large"
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    color: themeOptions.palette.primary.main,
                    borderColor: themeOptions.palette.primary.main,
                    textTransform: "none",
                    marginTop: "20px",
                  }}
                  disabled={loading}
                  onClick={() => closeModal()}
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    translationsEn.createTravelRequests.cancel
                  )}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  style={{
                    width: "100%",
                    backgroundColor: themeOptions.palette.primary.main,
                    textTransform: "none",
                    marginTop: "20px",
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    translationsEn.createTravelRequests.submit
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Content>
      </Box>
    </Modal>
  );
}

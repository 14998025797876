// utils/filesHandler.ts

export const handleAttachFiles = (
  event: React.ChangeEvent<HTMLInputElement>,
  attachedDocuments: File[],
  setAttachedDocuments: React.Dispatch<React.SetStateAction<File[]>>
) => {
  const files = event.target.files;
  if (files) {
    setAttachedDocuments([...attachedDocuments, ...Array.from(files)]);
  }
};

export const handleDeleteFile = (
  index: number,
  attachedDocuments: File[],
  setAttachedDocuments: React.Dispatch<React.SetStateAction<File[]>>
) => {
  const updatedDocuments = attachedDocuments.filter((_, i) => i !== index);
  setAttachedDocuments(updatedDocuments);
};

export const handleDownloadFile = (file: any) => {
  if (!file || !file.filename) {
    console.error("Invalid file or filename");
    return;
  }

  fetch(`/api/download/${file.filename}`)
    .then((response) => {
      if (!response.ok) throw new Error("Network response was not ok.");
      return response.blob();
    })
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = file.filename.split("/").pop() || "download";
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
};

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

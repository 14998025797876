import React, { useState, useEffect } from "react";
import { Snackbar, Alert, LinearProgress, Grid } from "@mui/material";

interface CustomAlertProps {
  open: boolean;
  handleClose: () => void;
  message: string;
  severity: any;
}

const LinearProgressWithLabel: React.FC = () => {
  const [progress, setProgress] = React.useState(0);
  const incrementAmount = 25;

  React.useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + incrementAmount;
        return newProgress >= 100 ? 100 : newProgress;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <LinearProgress
        variant="determinate"
        color="info"
        value={progress}
        sx={{
          borderRadius: 0,
          width: "111%",
          borderColor: "neutral.500",
          boxShadow: "sm",
        }}
      />
    </>
  );
};

const CustomAlert: React.FC<CustomAlertProps> = ({
  open,
  handleClose,
  message,
  severity,
}) => {
  const [showProgress, setShowProgress] = useState(true);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (open) {
      timer = setTimeout(() => {
        setShowProgress(false);
        handleClose();
      }, 5000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [open, handleClose]);

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Grid style={{ display: "flex", flexDirection: "column" }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
        {showProgress && <LinearProgressWithLabel />}
      </Grid>
    </Snackbar>
  );
};

export default CustomAlert;

import React, { useState } from 'react';
import { Button, Menu, MenuItem, ListItemIcon, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import themeOptions from '../../style/componentsStyle';
import CallDutyModal from './modals/callDutyPeriodModal.component';
import ExportDataModal from './modals/exportToPdfModal.component';
import { createCallDutyPeriod, updateCallDutyPeriod, deleteCallDutyPeriod, generateCallDutyRecord } from '../../api/activeDutyApi';

const DutyActionsMenu = ({ persons, onActionComplete }: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalType, setModalType] = useState(null); // 'create', 'update', 'delete'
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = (type: any) => {
    if (type === 'export') {
      setIsExportModalOpen(true);
    } else {
      setModalType(type);
    }
    handleClose();
  };

  const handleModalClose = () => {
    setModalType(null);
  };

  const handleExportModalClose = () => {
    setIsExportModalOpen(false);
  };

  const handleModalSubmit = async (payload: any, action: string) => {
    try {
      let response;
      switch (action) {
        case 'create':
          response = await createCallDutyPeriod(payload);
          console.log('Created Call Duty Period:', response);
          break;
        case 'update':
          response = await updateCallDutyPeriod(payload);
          console.log('Updated Call Duty Period:', response);
          break;
        case 'delete':
          response = await deleteCallDutyPeriod(payload);
          console.log('Deleted Call Duty Period');
          break;
        default:
          console.error(`Unknown action: ${action}`);
      }

      await generateCallDutyRecord(payload.periodDto);

      // Call the callback function to re-fetch data
      if (onActionComplete) {
        onActionComplete();
      }
    } catch (error) {
      console.error(`Error performing ${action} action`, error);
    }
  };

  const handleExportSubmit = (values: any) => {
    console.log('Export data for day:', values.day);
    // Handle export data logic
  };

  return (
    <div>
      <Button
        id="actions-button"
        style={{ margin: "10px", borderRadius: "20px", backgroundColor: themeOptions.palette.primary.main }}
        aria-controls={anchorEl ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={handleClick}
        endIcon={anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        variant="contained"
      >
        ACTIONS
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'actions-button' }}
      >
        <MenuItem onClick={() => handleModalOpen('create')}>
          <ListItemIcon><AddIcon fontSize="small" /></ListItemIcon>
          <Typography variant="inherit">New duty period</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen('update')}>
          <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
          <Typography variant="inherit">Update duty period</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen('delete')}>
          <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon>
          <Typography variant="inherit">Delete duty period</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen('export')}>
          <ListItemIcon><PictureAsPdfIcon fontSize="small" /></ListItemIcon>
          <Typography variant="inherit">Export data to PDF</Typography>
        </MenuItem>
      </Menu>
      {modalType && (
        <CallDutyModal
          open={!!modalType}
          onClose={handleModalClose}
          onSubmit={handleModalSubmit}
          action={modalType}
          persons={persons}
        />
      )}
      <ExportDataModal
        open={isExportModalOpen}
        onClose={handleExportModalClose}
        onSubmit={handleExportSubmit}
      />
    </div>
  );
};

export default DutyActionsMenu;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Autocomplete,
  TextField,
  CircularProgress,
  Grid,
} from "@mui/material";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";
import { getAllEmployees } from "../api/requestsApi";
import AddNewBudget from "../modals/budgetModal/newBudget";
import { getBudgets } from "../api/inventoryApi";
import moment from "moment";

export default function BudgetOverview() {
  const [modalStates, setModalStates] = useState({
    newBudget: false,
  });
  const [allEmployees, setAllEmployees] = useState<any>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [totalContent, setTotalContent] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [refreshListTrigger, setRefreshListTrigger] = useState(true);
  const emptyRows = tableData ? pageSize - tableData!.length : 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const handleOpenModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: true,
    }));
  };

  const handleCloseModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: false,
    }));
  };

  useEffect(() => {
    const fetchAndSetData = async () => {
      const page = pageNumber;
      const size = pageSize;
      const filter = { page, size };

      try {
        const publicHolidaysRes = (await getBudgets(
          filter,
          selectedEmployee
        )) as any;
        setTableData(publicHolidaysRes.data.content);
        setTotalContent(publicHolidaysRes.data.totalElements);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAndSetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize, searchClicked, refreshListTrigger]);

  useEffect(() => {
    const filter = { companyIds: [] };

    getAllEmployees(filter)
      .then((response) => setAllEmployees(response.data))
      .catch((error) => console.error("Failed to fetch teams:", error));
  }, []);

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.budget.name}
        </Typography>
        <Button
          variant="contained"
          style={{
            marginRight: "10px",
            borderRadius: "20px",
          }}
          sx={{
            backgroundColor: themeOptions.palette.primary.main,
            ":hover": {
              backgroundColor: themeOptions.palette.primary.hover,
            },
          }}
          onClick={() => handleOpenModal("newBudget")}
        >
          Create
        </Button>
      </Paper>

      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
        }}
        variant="outlined"
      >
        <Box
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Grid
            style={{ display: "flex", width: "500px", alignItems: "center" }}
          >
            <FormControl
              style={{ marginTop: "10px", width: "40%", marginRight: "10px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allEmployees}
                getOptionLabel={(option: any) =>
                  option.employeeDto.firstName +
                  " " +
                  option.employeeDto.lastName
                }
                onChange={(event: any, value: any) => {
                  setSelectedEmployee(value?.employeeDto?.id);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={translationsEn.labels.person} />
                )}
              />
            </FormControl>

            <Button
              variant="contained"
              style={{
                marginTop: "10px",
                borderRadius: "20px",
                height: "60%",
              }}
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
              }}
              onClick={() => {
                setSearchClicked(!searchClicked);
              }}
            >
              {translationsEn.buttons.search}
            </Button>
          </Grid>
        </Box>
      </Paper>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 401 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.firstName}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.lastName}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.budget}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.availableBudget}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.fromDate}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.toDate}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ height: "344px" }}>
              {tableData.length === 0 && (
                <TableRow style={{ height: 344 }}>
                  <TableCell colSpan={6} align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>{translationsEn.table.noData}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {tableData.map((person: any) => (
                <TableRow
                  key={person.employee.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    {person.employee.firstName}
                  </TableCell>
                  <TableCell align="left">{person.employee.lastName}</TableCell>
                  <TableCell align="left">{person.totalBudget}</TableCell>
                  <TableCell align="left">{person.availableBudget}</TableCell>
                  <TableCell align="left">{moment(person.startDate).format('DD.MM.YYYY')}</TableCell>
                  <TableCell align="left">{moment(person.endDate).format('DD.MM.YYYY')}</TableCell>
                </TableRow>
              ))}
              {tableData.length !== 0 && emptyRows > 0 && (
                <TableRow style={{ height: 68 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          colSpan={3}
          count={totalContent}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <AddNewBudget
        open={modalStates.newBudget}
        handleClose={() => handleCloseModal("newBudget")}
        refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { Add, SettingsSuggest, Clear } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import translationsEn from "../../../i18n/translations_en";
import themeOptions from "../../../style/componentsStyle";
import { addSkillToCv, getAllSkills } from "../../../api/cvApi";
import { useAlert } from "../../../utils/alertContext";
import AddSkillModal from "./addNewSkills";

interface SkillModalProps {
  open: boolean;
  handleClose: () => void;
  cvId: number;
}

const validationSchema = Yup.object({
  skillId: Yup.number().required("Skill is required"),
});

const SkillModal: React.FC<SkillModalProps> = ({ open, handleClose, cvId }) => {
  const [openAddSkillModal, setOpenAddSkillModal] = useState<boolean>(false);
  const [availableSkills, setAvailableSkills] = useState<any[]>([]);
  const { showAlert } = useAlert();

  const formik = useFormik({
    initialValues: {
      skillId: 0,
    },
    validationSchema,
    onSubmit: (values) => {
      const skillIds = [values.skillId];

      addSkillToCv(cvId, skillIds)
        .then(() => {
          showAlert(`${translationsEn.cvModals.successAddSkill}`, "success");
          handleClose();
          handleSkillAdded();
        })
        .catch((error) => {
          showAlert(
            error.response?.data?.error || "Error adding skill",
            "error"
          );
        });
    },
  });

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const skills = await getAllSkills();
        setAvailableSkills(skills);
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    };

    if (open) {
      fetchSkills();
      formik.resetForm();
    }
  }, [open]);

  const handleOpenAddSkillModal = () => {
    setOpenAddSkillModal(true);
  };

  const handleCloseAddSkillModal = () => {
    setOpenAddSkillModal(false);
  };

  const handleSkillAdded = async () => {
    try {
      const skills = await getAllSkills();
      setAvailableSkills(skills);
    } catch (error) {
      console.error("Error fetching skills:", error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open}>
          <Paper
            style={{
              padding: "20px",
              textAlign: "center",
              width: "40%",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <SettingsSuggest style={{ marginRight: "5px" }} />
                <Typography>{translationsEn.cvModals.addSkills}</Typography>
              </Box>
              <Clear onClick={handleClose} />
            </Box>
            <hr />
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "30px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Select
                  id="skillId"
                  name="skillId"
                  value={formik.values.skillId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.skillId && Boolean(formik.errors.skillId)
                  }
                  displayEmpty
                  style={{ width: "100%", margin: "10px" }}
                >
                  <MenuItem value="" disabled>
                    {translationsEn.cvModals.selectSkill}
                  </MenuItem>
                  {availableSkills.map((skill) => (
                    <MenuItem key={skill.id} value={skill.id}>
                      {skill.name}
                    </MenuItem>
                  ))}
                </Select>
                <Add onClick={handleOpenAddSkillModal} />
              </Box>
              {formik.touched.skillId && formik.errors.skillId && (
                <Typography color="error" variant="body2">
                  {formik.errors.skillId}
                </Typography>
              )}
              <hr />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: themeOptions.palette.confirm.main,
                    ":hover": {
                      backgroundColor: themeOptions.palette.confirm.hover,
                    },
                  }}
                >
                  {translationsEn.buttons.save}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Fade>
      </Modal>
      <AddSkillModal
        open={openAddSkillModal}
        handleClose={handleCloseAddSkillModal}
        onSkillAdded={handleSkillAdded}
      />
    </>
  );
};

export default SkillModal;

import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  Grid,
} from "@mui/material";
import { Clear, Newspaper } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { useFormik } from "formik";
import { addPublication, updatePublication } from "../../api/cvApi";
import { useAlert } from "../../utils/alertContext";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface PublicationsModalProps {
  open: boolean;
  handleClose: () => void;
  cvId: number;
  data?: any;
  onPublicationUpdated?: () => void;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Publication Name is required"),
  url: Yup.string().url("Enter a valid URL").required("URL is required"),
  event: Yup.string().required("Event name is required"),
  dateOfEvent: Yup.string().required("Event date is required"),
});

const PublicationsModal: React.FC<PublicationsModalProps> = ({
  open,
  handleClose,
  cvId,
  data,
  onPublicationUpdated = () => {},
}) => {
  const { showAlert } = useAlert();

  const formik = useFormik({
    initialValues: {
      name: data ? data.name : "",
      url: data ? data.url : "",
      event: data ? data.event : "",
      dateOfEvent: data ? dayjs(data.dateOfEvent) : null,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = {
        cvId,
        name: values.name,
        url: values.url,
        event: values.event,
        dateOfEvent: values.dateOfEvent
          ? dayjs(values.dateOfEvent).format("YYYY-MM")
          : null,
      };

      const savePublication = data ? updatePublication : addPublication;

      savePublication({
        id: data ? data.id : 0,
        ...payload,
      })
        .then(() => {
          showAlert(`${translationsEn.cvModals.succesPublication}`, "success");
          onPublicationUpdated();
          handleClose();
        })
        .catch((error) => {
          showAlert(
            error.response?.data?.error || "Error adding publication",
            "error"
          );
        });
    },
  });

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      formik.setValues({
        name: data.name || "",
        url: data.url || "",
        event: data.event || "",
        dateOfEvent: data.dateOfEvent ? dayjs(data.dateOfEvent) : null,
      });
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "40%",
          }}
        >
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Newspaper style={{ marginRight: "5px" }} />
              <Typography>{translationsEn.cvModals.addPublications}</Typography>
            </Box>

            <Clear onClick={handleClose} />
          </Grid>
          <hr />
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "30px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <TextField
                id="publication-name"
                name="name"
                label="Publication Name"
                variant="standard"
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                style={{
                  margin: "10px",
                  width: "50%",
                }}
              />

              <TextField
                id="link-to-publication"
                name="url"
                label="Link to publication"
                variant="standard"
                required
                value={formik.values.url}
                onChange={formik.handleChange}
                error={formik.touched.url && Boolean(formik.errors.url)}
                style={{
                  margin: "10px",
                  width: "50%",
                }}
              />
            </Box>

            <TextField
              id="event-name"
              name="event"
              label="Event name"
              variant="standard"
              required
              value={formik.values.event}
              onChange={formik.handleChange}
              error={formik.touched.event && Boolean(formik.errors.event)}
              style={{
                margin: "10px",
              }}
            />

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <FormControl style={{ width: "100%" }}>
                <DatePicker
                  label={translationsEn.cvModals.startDate}
                  views={["month", "year"]}
                  value={
                    formik.values.dateOfEvent
                      ? dayjs(formik.values.dateOfEvent)
                      : null
                  }
                  onChange={(date) =>
                    formik.setFieldValue(
                      "dateOfEvent",
                      date ? date.format("YYYY-MM") : null
                    )
                  }
                  slotProps={{
                    textField: {
                      helperText:
                        formik.touched.dateOfEvent &&
                        formik.errors.dateOfEvent &&
                        (formik.errors.dateOfEvent as string),
                      error: Boolean(
                        formik.touched.dateOfEvent && formik.errors.dateOfEvent
                      ),
                    },
                  }}
                  sx={{ width: "100%" }}
                />
              </FormControl>
            </Box>
            <hr />
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.confirm.main,
                  ":hover": {
                    backgroundColor: themeOptions.palette.confirm.hover,
                  },
                }}
                type="submit"
              >
                {translationsEn.buttons.save}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default PublicationsModal;

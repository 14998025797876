import dayjs from "dayjs";
import {
  ICompany,
  IEmployee,
  IEmployeeList,
  IHoliday,
  IHolidayQuery,
  IRequestType,
  ITeams,
  ITeamsFilter,
  IVocationDaysSearch,
  VocationDaysResponse,
} from "../interfaces/overview.interface";
import APICall from "./axios";

export const searchVocationDays = async (
  vocationDaysSearch: IVocationDaysSearch
): Promise<any[]> => {
  try {
    const response = await APICall.post<any[]>(
      "/api/vacation-days/search",
      vocationDaysSearch
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching vacation days:", error);
    throw error;
  }
};

// Fetches a single request type
export const getVocationDaysRequestType = async (): Promise<IRequestType[]> => {
  try {
    const { data: apiResponse } = await APICall.get<{
      data: IRequestType[];
      error: string;
    }>("/api/vacation-days/request-types");

    if (apiResponse.error) {
      console.error("Failed to fetch with error:", apiResponse.error);
      throw new Error(apiResponse.error); // Throw an error with the error message from the apiResponse object
    }

    return apiResponse.data; // This is the array of companies you want to return
  } catch (error) {
    console.error("Failed to fetch:", error);
    throw error; // Rethrow the error to handle it further up the call stack if needed
  }
};

// Fetches a single paid leave request type
export const getVocationDaysPaidLeaveRequestType = async (): Promise<IRequestType[]> => {
  try {
    const { data: apiResponse } = await APICall.get<{
      data: IRequestType[];
      error: string;
    }>("/api/vacation-days/paid-leave-types");

    if (apiResponse.error) {
      console.error("Failed to fetch with error:", apiResponse.error);
      throw new Error(apiResponse.error); // Throw an error with the error message from the apiResponse object
    }

    return apiResponse.data; // This is the array of companies you want to return
  } catch (error) {
    console.error("Failed to fetch:", error);
    throw error; // Rethrow the error to handle it further up the call stack if needed
  }
};

export const getPublicHolidays = async (
  filter: IHolidayQuery
): Promise<IHoliday[]> => {
  const url = `/api/shift-record/holidays/${filter.year}/${filter.month}`;
  try {
    const response = await APICall.get(url);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch public holidays:", error);
    throw error;
  }
};

export const getAllCompanies = async (): Promise<ICompany[]> => {
  try {
    // Make the API call and destructure the `data` property from the Axios response object
    const { data: apiResponse } = await APICall.get<{
      data: ICompany[];
      error: string;
    }>("/api/company");

    // apiResponse is now what you were referring to as the response object that includes both `data` and `error`.
    // Check if there's an error message in the apiResponse object
    if (apiResponse.error) {
      console.error("Failed to fetch companies with error:", apiResponse.error);
      throw new Error(apiResponse.error); // Throw an error with the error message from the apiResponse object
    }

    // Assuming apiResponse includes the actual `data` property you're interested in,
    // which contains the array of companies
    return apiResponse.data; // This is the array of companies you want to return
  } catch (error) {
    console.error("Failed to fetch companies:", error);
    throw error; // Rethrow the error to handle it further up the call stack if needed
  }
};

export const getOverviewTeams = async (
  teamsFilter: ITeamsFilter
): Promise<ITeams> => {
  try {
    // Make a POST request with the teams filter
    // Note: The assumption here is that the API returns an object that directly matches the Teams interface
    // If the API response structure is different, you may need to adapt the parsing logic accordingly
    const response = await APICall.post<ITeams>(
      "/api/team/overview-teams",
      teamsFilter
    );
    return response.data; // Assuming the data property of the response directly contains the Teams structure
  } catch (error) {
    console.error("Error fetching overview teams:", error);
    throw error; // Propagate the error for further handling
  }
};

export const getEmployeesByTeam = async (
  listOfIds: number[]
): Promise<any[]> => {
  try {
    const { data: apiResponse } = await APICall.post<{
      data: any[];
      error: string;
    }>("/api/vacation-days/teams", listOfIds);

    if (apiResponse.error) {
      console.error("Failed to fetch employees with error:", apiResponse.error);
      throw new Error(apiResponse.error);
    }

    // Check if the data array is not empty
    if (apiResponse.data.length > 0) {
      // Accumulate all 'employee' objects from employeeDtos into a single array
      const allEmployees = apiResponse.data.reduce((acc, currentItem) => {
        if (currentItem.employeeDtos) {
          // Map over the employeeDtos to extract only the employee object
          const employeeData = currentItem.employeeDtos.map((dto: any) => dto.employee);
          return [...acc, ...employeeData];
        }
        return acc;
      }, []);

      return allEmployees;
    } else {
      // Handle cases where the expected data structure is not met
      return []; // Or throw an error, depending on how you wish to handle this case
    }
  } catch (error) {
    console.error("Error fetching employees by team:", error);
    throw error;
  }
};

export const getEmployeeById = async (
  employeeId: number
): Promise<IEmployee> => {
  try {
    // Use the appropriate endpoint and replace `/api/employee/${employeeId}`
    // with your actual API endpoint for fetching a single employee's details
    const { data: apiResponse } = await APICall.get<{
      data: IEmployee;
      error: string;
    }>(
      `/api/employee/${employeeId}`
    );

    // Assuming the response directly contains the employee data you're interested in
    if (apiResponse.data) {
      return apiResponse.data;
    } else {
      // Handle the case where the employee data is not in the expected format or missing
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(
      `Error fetching details for employee ID ${employeeId}:`,
      error
    );
    throw error; // Rethrow the error for further handling
  }
};

export const getAllTeams = async (): Promise<ICompany[]> => {
  try {
    // Make the API call and destructure the `data` property from the Axios response object
    const { data: apiResponse } = await APICall.get<{
      data: ICompany[];
      error: string;
    }>("/api/company");

    // apiResponse is now what you were referring to as the response object that includes both `data` and `error`.
    // Check if there's an error message in the apiResponse object
    if (apiResponse.error) {
      console.error("Failed to fetch companies with error:", apiResponse.error);
      throw new Error(apiResponse.error); // Throw an error with the error message from the apiResponse object
    }

    // Assuming apiResponse includes the actual `data` property you're interested in,
    // which contains the array of companies
    return apiResponse.data; // This is the array of companies you want to return
  } catch (error) {
    console.error("Failed to fetch companies:", error);
    throw error; // Rethrow the error to handle it further up the call stack if needed
  }
};

export const createVocationDays = async (request: any): Promise<VocationDaysResponse> => {
  try {
    const response = await APICall.post("api/vacation-days", request);
    return response.data as VocationDaysResponse; // Ensure the backend actually sends this format
  } catch (error) {
    console.error('Error creating vacation days:', error);
    throw error;
  }
};

export const updateVocationDays = async (request: any): Promise<VocationDaysResponse> => {
  try {
    const response = await APICall.put("api/vacation-days", request);
    return response.data as VocationDaysResponse; // Ensure the backend actually sends this format
  } catch (error) {
    console.error('Error updating vacation days:', error);
    throw error;
  }
};

export function getVocationDaysRequest(values: any) {
  const vocationDaysRequest: any = {
    id: values.id ? values.id : null, // This would be provided for editing an existing request
    dateFrom: formatDate(values.startDate),
    dateTo: formatDate(values.endDate),
    note: values.note,
    employeeId: values.person,
    statusId: 2
  };

  if (values.id) {
    vocationDaysRequest.updateDate = new Date().toISOString().split("T")[0]
  }
  else {
    vocationDaysRequest.createDate = new Date().toISOString().split("T")[0]
  }

  // Additional fields that depend on conditions can be added like so:
  if (values.authorEmployee) {
    vocationDaysRequest.calculationType = values.calculationType;
  }

  // If you have dropdowns or other inputs for requestType, paidLeaveType, etc., you'd handle them similarly:
  if (values.requestType) {
    vocationDaysRequest.requestTypeId = values.requestType;
  }

  if (values.paidLeaveType) {
    vocationDaysRequest.paidLeaveTypeId = values.paidLeaveType;
  }
  if (values.remoteWorkType) {
    vocationDaysRequest.remoteWorkTypeId = values.remoteWorkType.id;
  }
  if (values.calculationType) {
    vocationDaysRequest.statusId = values.calculationType.id;
  }

  return vocationDaysRequest;
}

function formatDate(dateString: any) {
  return dayjs(dateString).format('YYYY-MM-DD'); // Make sure dayjs is configured to parse the incoming date formats
}
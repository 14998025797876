import { useEffect, useState } from "react";
import { Paper, Typography } from "@mui/material";
import translationsEn from "../../i18n/translations_en";
import { styled } from "@mui/system";
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import GeneralInformation from "./stepGeneralInformation";
import { useParams } from "react-router-dom";
import { getTravelRequest } from "../../api/travelRequestApi";
import AdditionalInformation from "./stepAdditionalInformation";
import TravelExpenses from "./stepTravelExpenses";
import TravelReport from "./stepTravelReport";

const steps = [
  translationsEn.createTravelRequests.generalInformation,
  translationsEn.createTravelRequests.additionalInformation,
  translationsEn.createTravelRequests.travelExpenses,
  translationsEn.createTravelRequests.travelReport,
];

const PageContainer = styled("div")({
  height: "100%",
  padding: "10px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

const Title = styled(Typography)({
  marginLeft: "10px",
  fontSize: "40px",
});

export default function CreateTravelRequest() {
  const { travelRequestID } = useParams<{ travelRequestID: string }>();
  const [activeStep, setActiveStep] = useState(0);
  const [travelRequest, setTravelRequest] = useState<any>()

  useEffect(() => {
    travelRequestID &&
    getTravelRequest(Number(travelRequestID))
      .then((response) => {
        setTravelRequest(response.data.data)
      })
      .catch((error) => console.error("Failed to fetch Travel request:", error));

  }, [travelRequestID]);

  useEffect(()=>{
    if(travelRequest)
    travelRequest.status === "approved" && setActiveStep(1)
  },[travelRequest])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <PageContainer>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <Title>
          {travelRequest
            ? translationsEn.createTravelRequests.edit
            : translationsEn.createTravelRequests.new}
        </Title>
      </Paper>
      <div>
        <div style={{ maxWidth: "900px", margin: "auto", marginTop: "20px" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}

            <Paper
              style={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                padding: "20px 0px",
                marginTop: "20px",
              }}
              variant="outlined"
            >
              {activeStep === 0 && (
                <GeneralInformation travelRequest={travelRequest} />
              )}
              {activeStep === 1 && (
                <AdditionalInformation
                  travelRequestID={travelRequest?.id}
                  handleNext={handleNext}
                />
              )}
              {activeStep === 2 && (
                <TravelExpenses
                  travelRequestID={travelRequest?.id}
                  handleNext={handleNext}
                />
              )}
              {activeStep === 3 && (
                <TravelReport travelRequestID={travelRequest?.id} />
              )}
            </Paper>

            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </div>
    </PageContainer>
  );
}

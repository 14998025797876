import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  CircularProgress,
  TablePagination,
  ButtonGroup,
  Tooltip,
} from "@mui/material";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";
import AddOvertimeModal from "../modals/overtimeOverview/createOvertime";
import {
  deleteOvertime,
  getAllOvertimes,
  setStatusOvertime,
} from "../api/overtimeApi";
import { getFullTimeEmployees } from "../api/employeeApi";
import dayjs from "dayjs";
import {
  calculationOvertimeStatuses,
  OVERTIME_STATUS,
} from "../utils/constants/overtimeConstants";
import DeleteModal from "../modals/confirmationsModal/deleteModal";
import { useAlert } from "../utils/alertContext";

export default function OvertimeOverview() {
  const { showAlert } = useAlert();
  const [modalStates, setModalStates] = useState({
    newDuty: false,
    edit: false,
    delete: false,
  });
  const [overtimeData, setOvertimeData] = useState([] as any);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [size, setPageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isSaving, setIsSaving] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | null>(
    null
  );
  const [selectedStatusId, setSelectedStatusId] = useState<number | null>(null);
  const [selectedOvertimeId, setSelectedOvertimeId] = useState<number | null>(
    null
  );
  const [selectedOvertime, setSelectedOvertime] = useState<any>(null);

  const handleOpenModal = (modalName: any, overtime?: any) => {
    if (overtime) {
      setSelectedOvertime(overtime);
    }
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: true,
    }));
  };

  const handleCloseModal = (modalName: any) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: false,
    }));
    setSelectedOvertime(null);
    console.log(selectedStatusId);
  };

  const fetchOvertimes = async (page: any, size: any) => {
    setLoading(true);
    try {
      const response: any = await getAllOvertimes(page, size);
      setOvertimeData(response.data.content);
      setTotalCount(response.data.totalElements);
    } catch (error) {
      console.error("Error fetching overtimes", error);
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = (
    data: any[],
    month: number,
    year: number,
    employeeId: number | null,
    statusId: number | null
  ) => {
    return data.filter((item) => {
      const date = dayjs(item.date);
      const matchesMonth = date.month() + 1 === month; // Correct month comparison
      const matchesYear = date.year() === year; // Correct year comparison
      const matchesEmployee = employeeId
        ? item.employeeDto.id === employeeId
        : true;
      const matchesStatus = statusId ? item.statusId === statusId : true;

      return matchesMonth && matchesYear && matchesEmployee && matchesStatus;
    });
  };

  const handleSearch = () => {
    // Fetch overtimes from the current data if it's already fetched.
    fetchOvertimes(page, size).then(() => {
      const filteredData = applyFilters(
        overtimeData,
        selectedMonth,
        selectedYear,
        selectedEmployeeId,
        selectedStatusId
      );

      // Set the filtered data directly
      setOvertimeData(filteredData);
      setTotalCount(filteredData.length);
    });
  };

  const fetchFullTimeEmployees = async () => {
    try {
      const teamsFilter: any = [];

      const employees = await getFullTimeEmployees(teamsFilter);

      const formattedPersons = employees.data.map((emp: any) => ({
        id: emp.employeeDto.id,
        firstName: emp.employeeDto.firstName,
        lastName: emp.employeeDto.lastName,
        companyId: emp.employeeDto.companyDto.id,
      }));
      setEmployees(formattedPersons);
    } catch (error) {
      console.error("Failed to fetch full-time employees:", error);
    }
  };

  useEffect(() => {
    fetchFullTimeEmployees();
  }, []);

  useEffect(() => {
    fetchOvertimes(page, size);
  }, [page, size]);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusLabel = (statusId: number) => {
    const status = calculationOvertimeStatuses.find(
      (s) => s.value === statusId
    );
    return status ? status.label : "Unknown";
  };

  const handleDeleteOvertime = (overtimeId: number) => {
    setSelectedOvertimeId(overtimeId);
    handleOpenModal("delete");
  };

  const handleApproveOvertime = async (overtimeId: number) => {
    try {
      await setStatusOvertime(overtimeId, {
        statusDto: { id: OVERTIME_STATUS.APPROVED },
      });
      showAlert("Overtime approved successfully!", "success");
      fetchOvertimes(page, size);
    } catch (error) {
      console.error("Error approving overtime", error);
      showAlert("Failed to approve overtime. Please try again.", "error");
    }
  };

  const handleDeclineOvertime = async (overtimeId: number) => {
    try {
      await setStatusOvertime(overtimeId, {
        statusDto: { id: OVERTIME_STATUS.DECLINED },
      });
      showAlert("Overtime declined successfully!", "success");
      fetchOvertimes(page, size);
    } catch (error) {
      console.error("Error declining overtime", error);
      showAlert("Failed to decline overtime. Please try again.", "error");
    }
  };

  const handleConfirmDelete = async () => {
    if (selectedOvertimeId !== null) {
      try {
        await deleteOvertime(selectedOvertimeId);
        showAlert("Overtime deleted successfully!", "success");
        fetchOvertimes(page, size); // Refresh data after deletion
      } catch (error) {
        console.error("Error deleting overtime", error);
        showAlert("Failed to delete overtime. Please try again.", "error");
      } finally {
        setSelectedOvertimeId(null);
        handleCloseModal("delete");
      }
    }
  };
  const getButtonVariant = (statusId: number, currentStatus: number) => {
    return statusId === currentStatus ? "contained" : "outlined";
  };

  return (
    <>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          margin: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.overtime.name}
        </Typography>
        <Button
          variant="contained"
          style={{
            marginRight: "10px",
            borderRadius: "20px",
          }}
          sx={{
            backgroundColor: themeOptions.palette.primary.main,
            ":hover": {
              backgroundColor: themeOptions.palette.primary.hover,
            },
          }}
          onClick={() => handleOpenModal("newDuty")}
        >
          Create
        </Button>
      </Paper>

      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          margin: "10px",
        }}
      >
        <Box
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Grid style={{ display: "flex", width: "28%" }}>
            <FormControl style={{ width: "100%", marginRight: "10px" }}>
              <InputLabel id="demo-simple-select-label">
                {translationsEn.labels.month}
              </InputLabel>
              <Select
                labelId="month-label"
                label={translationsEn.labels.month}
                value={selectedMonth}
                onChange={(event: any) => setSelectedMonth(event.target.value)}
              >
                {Array.from({ length: 12 }, (_, index) => {
                  const month = index + 1;
                  return (
                    <MenuItem key={month} value={month}>
                      {new Date(0, month - 1).toLocaleString("default", {
                        month: "long",
                      })}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">
                {translationsEn.labels.year}
              </InputLabel>
              <Select
                labelId="year-label"
                label={translationsEn.labels.year}
                value={selectedYear}
                onChange={(event: any) => setSelectedYear(event.target.value)}
              >
                {Array.from({ length: 100 }, (_, index) => {
                  const year = new Date().getFullYear() - index;
                  return (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid style={{ display: "flex", width: "28.6%", marginTop: "10px" }}>
            <FormControl style={{ width: "100%", marginRight: "10px" }}>
              <InputLabel id="demo-simple-select-label">
                {translationsEn.labels.person}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label={translationsEn.labels.person}
                value={selectedEmployeeId}
                onChange={(event: any) =>
                  setSelectedEmployeeId(event.target.value)
                }
              >
                {employees.map((employee: any) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.firstName} {employee.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ width: "100%", marginRight: "10px" }}>
              <InputLabel id="demo-simple-select-label">
                {translationsEn.labels.status}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label={translationsEn.labels.status}
                value={selectedStatusId}
                onChange={(event: any) =>
                  setSelectedStatusId(event.target.value)
                }
              >
                {calculationOvertimeStatuses.map((status: any) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Button
            variant="contained"
            style={{
              marginTop: "10px",
              borderRadius: "20px",
            }}
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              ":hover": {
                backgroundColor: themeOptions.palette.primary.hover,
              },
            }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Box>
      </Paper>
      <Paper
        style={{
          margin: "10px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <TableContainer
          style={{ padding: "10px" }}
          sx={{ maxWidth: "98.7%" }}
          component={Paper}
        >
          <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.firstName}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.lastName}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.status}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.date}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.fromDate}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.toDate}
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "bold", maxWidth: "200px" }}
                >
                  {translationsEn.table.reason}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.table.ticket}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                overtimeData.map((overtime: any, index: any) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      {overtime.employeeDto.firstName}
                    </TableCell>
                    <TableCell align="left">
                      {overtime.employeeDto.lastName}
                    </TableCell>
                    <TableCell align="left">
                      {getStatusLabel(overtime.statusId)}
                    </TableCell>
                    <TableCell align="left">
                      {dayjs(overtime.date).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      {dayjs(overtime.timeFrom, "HH:mm:ss").format("HH:mm")}
                    </TableCell>
                    <TableCell align="left">
                      {dayjs(overtime.timeTo, "HH:mm:ss").format("HH:mm")}
                    </TableCell>
                    <Tooltip title={overtime.reason} placement="top">
                      <TableCell
                        align="left"
                        style={{
                          maxWidth: 200,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {overtime.reason}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={overtime.ticketReference} placement="top">
                      <TableCell
                        align="left"
                        style={{
                          maxWidth: 30,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {overtime.ticketReference}
                      </TableCell>
                    </Tooltip>
                    <TableCell align="left">
                      <ButtonGroup>
                        <Tooltip title="Approve">
                          <Button
                            variant={getButtonVariant(
                              overtime.statusId,
                              OVERTIME_STATUS.APPROVED
                            )}
                            onClick={() => handleApproveOvertime(overtime.id)}
                          >
                            Approve
                          </Button>
                        </Tooltip>
                        <Tooltip title="Decline">
                          <Button
                            variant={getButtonVariant(
                              overtime.statusId,
                              OVERTIME_STATUS.DECLINED
                            )}
                            onClick={() => handleDeclineOvertime(overtime.id)}
                          >
                            Decline
                          </Button>
                        </Tooltip>
                        <Tooltip title="Edit">
                          <Button
                            variant="outlined"
                            onClick={() => handleOpenModal("edit", overtime)}
                          >
                            Edit
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleDeleteOvertime(overtime.id)}
                          >
                            Delete
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={size}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
      <AddOvertimeModal
        persons={employees}
        initialData={selectedOvertime}
        mode={modalStates.edit ? "update" : "create"}
        open={modalStates.newDuty || modalStates.edit}
        handleClose={() =>
          handleCloseModal(modalStates.newDuty ? "newDuty" : "edit")
        }
        onSuccess={() => fetchOvertimes(page, size)}
      />
      <DeleteModal
        open={modalStates.delete}
        handleClose={() => handleCloseModal("delete")}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
}

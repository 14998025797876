import {
  Modal,
  Box,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useAlert } from "../../utils/alertContext";
import { createCompany, updatedCompany } from "../../api/companyApi"; // Import updatedCompany API
import themeOptions from "../../style/componentsStyle";
import translationsEn from "../../i18n/translations_en";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  border: "2px solid #ccc",
};

interface CreateCompanyModalProps {
  open: boolean;
  closeModal: () => void;
  selectedCompany?: any;
}

export default function CreateCompanyModal({
  open,
  closeModal,
  selectedCompany,
}: CreateCompanyModalProps) {
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState<boolean>(false);
  const [isInternal, setIsInternal] = useState(
    selectedCompany?.internal || false
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setIsInternal(isChecked);
    formik.setFieldValue("internal", isChecked);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    oib: Yup.string().required("OIB is required"),
    address: Yup.string().required("Address is required"),
    director: Yup.string().required("Director is required"),
  });
  useEffect(() => {
    if (selectedCompany) {
      formik.setValues({
        name: selectedCompany.name,
        oib: selectedCompany.oib,
        address: selectedCompany.address,
        director: selectedCompany.director,
        internal: selectedCompany.internal,
      });
    } else {
      formik.resetForm();
    }
  }, [selectedCompany, open]);

  const formik = useFormik({
    initialValues: {
      name: "",
      oib: "",
      address: "",
      director: "",
      internal: isInternal || false,
    },
    validationSchema,
    onSubmit: (values, action) => {
      setLoading(true);
      if (selectedCompany) {
        updatedCompany(selectedCompany.id, values)
          .then(() => {
            showAlert("Company updated successfully!", "success");
            closeModal();
          })
          .catch((error) => {
            showAlert(error.message || "Error updating company", "error");
          })
          .finally(() => {
            setLoading(false);
            action.resetForm();
          });
      } else {
        createCompany(values)
          .then(() => {
            showAlert("Company created successfully!", "success");
            closeModal();
          })
          .catch((error) => {
            showAlert(error.message || "Error updating company", "error");
          })
          .finally(() => {
            setLoading(false);
            action.resetForm();
          });
      }
    },
  });

  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={style}>
        <h3>{selectedCompany ? "Edit Company" : "Create Company"}</h3>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="OIB"
                name="oib"
                value={formik.values.oib}
                onChange={formik.handleChange}
                error={formik.touched.oib && Boolean(formik.errors.oib)}
                helperText={formik.touched.oib && formik.errors.oib}
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Director"
                name="director"
                value={formik.values.director}
                onChange={formik.handleChange}
                error={
                  formik.touched.director && Boolean(formik.errors.director)
                }
                helperText={formik.touched.director && formik.errors.director}
                fullWidth
                margin="dense"
              />
            </Grid>
            <FormControlLabel
              style={{ margin: "10px", width: "27%" }}
              control={
                <Checkbox
                  checked={formik.values.internal}
                  onChange={handleCheckboxChange}
                />
              }
              label="Internal company?"
            />
            <Grid item xs={12} style={{ display: "flex" }}>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: themeOptions.palette.primary.main,
                    color: "white",
                    ":hover": {
                      background: themeOptions.palette.primary.hover,
                    },
                    textTransform: "none",
                  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="text"
                  onClick={closeModal}
                  fullWidth
                  sx={{
                    backgroundColor: themeOptions.palette.cancel.main,
                    color: "white",
                    ":hover": {
                      background: themeOptions.palette.cancel.hover,
                    },
                    textTransform: "none",
                  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Cancel"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}

import { createContext, useContext, useState, ReactNode, FC } from "react";
import CustomAlert from "../components/customAlert/customAlert";

interface AlertContextType {
  showAlert: (msg: string, sev?: string) => void;
}

const AlertContext = createContext<AlertContextType | null>(null);

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (context === null) {
    throw new Error("useAlert must be used within a AlertProvider");
  }
  return context;
};

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: FC<AlertProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");

  const showAlert = (msg: string, sev: string = "info") => {
    setMessage(msg);
    setSeverity(sev);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMessage("");
    setSeverity("info");
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      <CustomAlert
        open={open}
        handleClose={handleClose}
        message={message}
        severity={severity}
      />
    </AlertContext.Provider>
  );
};

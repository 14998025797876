import axios from "axios";
//const BASE_URL = 'http://localhost:8080/';
const BASE_URL = "https://api.demo.officepal.co/";

const APICall = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

// Request Interceptor for appending the Authorization header
APICall.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default APICall;

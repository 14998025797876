import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  FormControl,
} from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Clear } from '@mui/icons-material';
import themeOptions from '../../../style/componentsStyle';

const validationSchema = Yup.object({
  day: Yup.date().required('Day is required'),
});

const ExportDataModal = ({ open, onClose, onSubmit }: any) => {
  const formik = useFormik({
    initialValues: {
      day: null,
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
      onClose();
    },
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "30%", // Adjust width as needed
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">Select day to export data to</Typography>
              <Clear onClick={onClose} />
            </Box>
            <hr></hr>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FormControl style={{ width: "100%", margin: "10px 0" }}>
                <DatePicker
                  label="Day"
                  format="DD/MM/YYYY"
                  value={formik.values.day}
                  onChange={value => formik.setFieldValue('day', value)}
                  slotProps={{
                    textField: {
                      helperText: (formik.touched.day && formik.errors.day) && formik.errors.day as string,
                      error: Boolean(formik.touched.day && formik.errors.day)
                    },
                  }}
                  sx={{ width: "100%" }}
                />
              </FormControl>
            </Box>
            <hr></hr>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={onClose}
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.cancel.main,
                  ":hover": {
                    backgroundColor: themeOptions.palette.cancel.hover,
                  },
                  marginRight: "10px"
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.confirm.main,
                  ":hover": {
                    backgroundColor: themeOptions.palette.confirm.hover,
                  },
                }}
              >
                Confirm
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ExportDataModal;

import React, { useEffect } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { SettingsSuggest, Clear } from "@mui/icons-material";
import translationsEn from "../../../i18n/translations_en";
import themeOptions from "../../../style/componentsStyle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createSkill } from "../../../api/cvApi";
import { useAlert } from "../../../utils/alertContext";

interface AddSkillModalProps {
  open: boolean;
  handleClose: () => void;
  onSkillAdded: () => void;
}

const initialValues = {
  name: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required(translationsEn.validation.required),
});

const AddSkillModal: React.FC<AddSkillModalProps> = ({
  open,
  handleClose,
  onSkillAdded,
}) => {
  const { showAlert } = useAlert();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await createSkill({ name: values.name });
        showAlert(`${translationsEn.cvModals.successAddSkill}`, "success");
        handleClose();
        resetForm();
        onSkillAdded();
      } catch (error) {
        showAlert("Error adding skill", "error");
        console.error("Error creating skill", error);
      }
    },
  });

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <SettingsSuggest style={{ marginRight: "5px" }} />
              <Typography>{translationsEn.cvModals.addSkill}</Typography>
            </Box>

            <Clear onClick={handleClose} />
          </Box>
          <hr />

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "20px",
            }}
          >
            <TextField
              id="name"
              name="name"
              label="Skill"
              variant="standard"
              required
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                margin: "10px",
              }}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Box>
          <hr />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                backgroundColor: themeOptions.palette.cancel.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.cancel.hover,
                },
              }}
            >
              {translationsEn.buttons.cancel}
            </Button>
            <Button
              variant="contained"
              onClick={formik.submitForm}
              sx={{
                backgroundColor: themeOptions.palette.confirm.main,
                ":hover": {
                  backgroundColor: themeOptions.palette.confirm.hover,
                },
              }}
            >
              {translationsEn.buttons.save}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default AddSkillModal;

import React, { useEffect, useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
  FormControl,
} from '@mui/material';
import { getAllCallDutyDays, getAllCallDutyEmployeesIds } from "../../../api/activeDutyApi";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { Clear } from '@mui/icons-material';
import themeOptions from '../../../style/componentsStyle';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  initialData: any;
  persons: any;
  mode: 'create' | 'update' | 'scheduler'; // New prop to distinguish between create and update
}

const validationSchema = Yup.object({
  employeeDto: Yup.object().shape({
    id: Yup.number().required('Person is required')
  }),
  date: Yup.date().required('Date is required'),
  timeFrom: Yup.string().required('Time from is required'),
  timeTo: Yup.string().required('Time to is required'),
  reason: Yup.string().required('Reason is required'),
  ticketReference: Yup.string(),
  responderComment: Yup.string().required('Comment is required'),
});

const initialValues = {
  employeeDto: {
    id: null,
  },
  date: '',
  timeFrom: '',
  timeTo: '',
  reason: '',
  ticketReference: '',
  responderComment: '',
}

const ActiveDutyModal: React.FC<Props> = ({ open, onClose, onSubmit, initialData, persons, mode }) => {
  const [callDutyDays, setCallDutyDays] = useState([] as any);
  const [employeeIds, setEmployeeIds] = useState<any[]>([]);
  const [mappedPersons, setMappedPersons] = useState<any[]>([]);
  const [availableDates, setAvailableDates] = useState<string[]>([]);

  const getInitialValues = () => {
    const values = { ...initialValues };
    if (mode === 'create')
      values.employeeDto.id = null;
    if (initialData) {
      if (mode === 'scheduler') {
        values.employeeDto.id = initialData?.employeeDto?.id;
        values.date = initialData?.date;
      } else if (mode === 'update') {
        values.employeeDto.id = initialData?.employeeDto?.id;
        values.date = dayjs(initialData?.date).toISOString(); // Fix date handling
        values.timeFrom = initialData?.timeFrom;
        values.timeTo = initialData?.timeTo;
        values.reason = initialData?.reason;
        values.ticketReference = initialData?.ticketReference;
        values.responderComment = initialData?.responderComment;
      }
    }
    return values;
  };

  const isTimeOverlap = (newFrom: string, newTo: string, existingRanges: { timeFrom: string, timeTo: string }[]) => {
    const newFromTime = dayjs(newFrom, 'HH:mm');
    const newToTime = dayjs(newTo, 'HH:mm');

    return existingRanges.some(range => {
      const existingFrom = dayjs(range.timeFrom, 'HH:mm');
      const existingTo = dayjs(range.timeTo, 'HH:mm');
      return (newFromTime.isBetween(existingFrom, existingTo, null, '[)') ||
        newToTime.isBetween(existingFrom, existingTo, null, '(]') ||
        existingFrom.isBetween(newFromTime, newToTime, null, '[)') ||
        existingTo.isBetween(newFromTime, newToTime, null, '(]'));
    });
  }

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validate: (values) => {
      const errors: any = {};

      if (values.timeFrom && values.timeTo) {
        if (dayjs(values.timeFrom, 'HH:mm').isAfter(dayjs(values.timeTo, 'HH:mm'))) {
          errors.timeFrom = 'Start time must be before end time';
          errors.timeTo = 'End time must be after start time';
        }

        const selectedDate = values.date ? dayjs(values.date).format('YYYY-MM-DD') : null;
        if (mode !== 'update' && selectedDate && callDutyDays.length > 0) {
          const dutyDay = callDutyDays[0].callDutyDayDtos.find((day: any) => day.date === selectedDate);
          if (dutyDay && isTimeOverlap(values.timeFrom, values.timeTo, dutyDay.activeCallDutyDtos)) {
            errors.timeFrom = 'Selected time overlaps with an existing duty time';
            errors.timeTo = 'Selected time overlaps with an existing duty time';
          }
        }
      }

      return errors;
    },
    onSubmit: (values) => {
      const payload = {
        ...values,
        date: dayjs(values.date).format('YYYY-MM-DD'),
        callDutyDayId: mode === 'create' ? getCallDutyDayId().id : mode === 'update' ? initialData?.callDutyDayId : initialData?.id,
        id: mode === 'update' ? initialData?.id : null
      };
      onSubmit(payload);
      handleModalClose();
    },
  });

  const handleModalClose = () => {
    formik.resetForm();
    onClose();
  };

  const isUpdateMode = mode === 'update';
  const isSchedulerMode = mode === 'scheduler';

  useEffect(() => {
    if (mode === 'create') {
      getAllCallDutyEmployeesIds()
        .then(response => {
          setEmployeeIds(response.data); // Assuming response.data is the array of employee IDs
        })
        .catch(error => {
          console.error("Failed to fetch employee IDs:", error);
        });
    }
  }, [mode]);

  useEffect(() => {
    if (formik.values.employeeDto.id) {
      const payload = {
        employeeId: formik.values.employeeDto.id,
        companyId: null, // Adjust as necessary
        periodDto: {
          dateFrom: null, // Adjust these dates based on your needs
          dateTo: null,
        },
      };

      getAllCallDutyDays(payload)
        .then(response => {
          setCallDutyDays(response.data); // Assuming response.data is the array of call duty days
        })
        .catch(error => {
          console.error("Failed to fetch active duty days:", error);
        });
    }
  }, [formik.values.employeeDto.id]);

  useEffect(() => {
    if (mode === 'create' && employeeIds.length > 0 && persons.length > 0) {
      const filteredPersons = persons.filter((person: any) => employeeIds.includes(person.id));
      setMappedPersons(filteredPersons);
    }
  }, [mode, employeeIds, persons]);

  useEffect(() => {
    if (mode === 'create' && callDutyDays.length > 0 && formik.values.employeeDto.id) {
      const dutyDay = callDutyDays[0].callDutyDayDtos.filter((day: any) => day.employeeDto.id === formik.values.employeeDto.id);
      const dates = dutyDay.map((day: any) => day.date);
      setAvailableDates(dates);
    }
  }, [callDutyDays, formik.values.employeeDto.id]);

  function getCallDutyDayId() {
    const callDutyDayDtos = callDutyDays.length > 0 ? callDutyDays[0].callDutyDayDtos : [];
    return callDutyDayDtos.find((day: any) => day.date === dayjs(formik.values.date).format('YYYY-MM-DD')) || {};
  }

  const isDateDisabled = (date: any) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    return !availableDates.includes(formattedDate);
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "40%",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">{isUpdateMode ? 'Update active duty' : 'New active duty'}</Typography>
              <Clear onClick={handleModalClose} />
            </Box>
            <hr></hr>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FormControl style={{ width: "100%", margin: "10px 0" }}>
                <Autocomplete
                  disabled={isUpdateMode || isSchedulerMode}
                  options={mode === 'create' ? mappedPersons : persons}
                  getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                  value={formik.values.employeeDto.id ? persons.find((person: any) => person.id === formik.values.employeeDto.id) : null}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('employeeDto.id', newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(formik.touched.employeeDto?.id && formik.errors.employeeDto?.id)}
                      helperText={(formik.touched.employeeDto?.id && formik.errors.employeeDto?.id) && formik.errors.employeeDto.id as string}
                      label="Person"
                      placeholder="Search and select"
                    />
                  )}
                />
              </FormControl>
              <FormControl style={{ width: "100%", margin: "10px 0" }}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="Date"
                  disabled={isUpdateMode || isSchedulerMode}
                  value={formik.values.date ? dayjs(formik.values.date) : null}
                  onChange={(date) => formik.setFieldValue('date', date ? date.toISOString() : null)}
                  shouldDisableDate={mode === 'create' ? isDateDisabled : undefined}
                  slotProps={{
                    textField: {
                      helperText: (formik.touched.date && formik.errors.date) && formik.errors.date as string,
                      error: Boolean(formik.touched.date && formik.errors.date),
                    },
                  }}
                  sx={{ margin: "10px 0", width: "100%" }}
                />
              </FormControl>
              <FormControl style={{ width: "100%", margin: "10px 0" }}>
                <TimePicker
                  label="From"
                  value={formik.values.timeFrom ? dayjs(formik.values.timeFrom, 'HH:mm') : null}
                  onChange={(time) => formik.setFieldValue('timeFrom', time ? time.format('HH:mm') : '')}
                  ampm={false}
                  slotProps={{
                    textField: {
                      helperText: (formik.touched.timeFrom && formik.errors.timeFrom) && formik.errors.timeFrom as string,
                      error: Boolean(formik.touched.timeFrom && formik.errors.timeFrom)
                    },
                  }}
                  sx={{ margin: "10px 0", width: "100%" }}
                />
              </FormControl>
              <FormControl style={{ width: "100%", margin: "10px 0" }}>
                <TimePicker
                  label="To"
                  value={formik.values.timeTo ? dayjs(formik.values.timeTo, 'HH:mm') : null}
                  onChange={(time) => formik.setFieldValue('timeTo', time ? time.format('HH:mm') : '')}
                  ampm={false}
                  slotProps={{
                    textField: {
                      helperText: (formik.touched.timeTo && formik.errors.timeTo) && formik.errors.timeTo as string,
                      error: Boolean(formik.touched.timeTo && formik.errors.timeTo)
                    },
                  }}
                  sx={{ margin: "10px 0", width: "100%" }}
                />
              </FormControl>
              <FormControl style={{ width: "100%", margin: "10px 0" }}>
                <TextField
                  margin="dense"
                  label="Reason"
                  name="reason"
                  value={formik.values.reason}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.reason && formik.errors.reason)}
                  helperText={(formik.touched.reason && formik.errors.reason) && formik.errors.reason as string}
                  fullWidth
                />
              </FormControl>
              <FormControl style={{ width: "100%", margin: "10px 0" }}>
                <TextField
                  margin="dense"
                  label="Ticket reference"
                  name="ticketReference"
                  value={formik.values.ticketReference}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </FormControl>
              <FormControl style={{ width: "100%", margin: "10px 0" }}>
                <TextField
                  margin="dense"
                  label="Comment"
                  name="responderComment"
                  value={formik.values.responderComment}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.responderComment && formik.errors.responderComment)}
                  helperText={(formik.touched.responderComment && formik.errors.responderComment) && formik.errors.responderComment as string}
                  fullWidth
                />
              </FormControl>
            </Box>
            <hr></hr>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={handleModalClose}
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.cancel.main,
                  ":hover": {
                    backgroundColor: themeOptions.palette.cancel.hover,
                  },
                  marginRight: "10px"
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.confirm.main,
                  ":hover": {
                    backgroundColor: themeOptions.palette.confirm.hover,
                  }
                }}
              >
                Confirm
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ActiveDutyModal;

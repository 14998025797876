import React, { useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import * as Yup from "yup";
import { Key, Visibility, VisibilityOff } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { IResetPassword } from "../../interfaces/employee.interface";
import { useFormik } from "formik";
import { useAlert } from "../../utils/alertContext";
import { changeEmployeePassword } from "../../api/employeeApi";

interface ResetPasswordProps {
  open: boolean;
  handleClose: () => void;
  user: string;
  children?: React.ReactNode;
}

const initialValues: IResetPassword = {
  password: "",
  confirmPassword: "",
};

const ResetPassword: React.FC<ResetPasswordProps> = ({
  open,
  handleClose,
  user,
}) => {
  const { showAlert } = useAlert();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(`${translationsEn.validation.required}`)
      .test(
        "Min 8 characters",
        `Password must contain at least 8 characters`,
        (value) => value.length >= 8
      ),
    confirmPassword: Yup.string()
      .required(`${translationsEn.validation.required}`)
      .test(
        "Do not match",
        `Passwords do not match`,
        (value) => value === formik.values.password
      )
      .test(
        "Min 8 characters",
        `Password must contain at least 8 characters`,
        (value) => value.length >= 8
      ),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      const password = {
        password: values.password,
        username: user,
      };

      changeEmployeePassword(password)
        .then((response) => {
          showAlert('Password changed successfully!', 'success');
          handleClose();
          formik.resetForm();
        })
        .catch((error) => {
          console.error("Failed to fetch teams:", error);
          const errorMessage = error.response?.data?.error || 'Failed to change password. Please try again.';
          showAlert(errorMessage, 'error')
        });
    },
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "40%",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Key style={{ marginRight: "5px" }} />
                <Typography>Reset password</Typography>
              </Box>
            </Box>
            <hr></hr>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "30px",
              }}
            >
              <TextField
                id="standard-basic"
                label="New password"
                variant="standard"
                name={"password"}
                required
                style={{
                  margin: "10px",
                }}
                type={showPassword ? "text" : "password"}
                value={formik.values.password || ""}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                id="standard-basic"
                label="Confirm new password"
                variant="standard"
                required
                style={{
                  margin: "10px",
                }}
                name={"confirmPassword"}
                type={showPassword ? "text" : "password"}
                value={formik.values.confirmPassword || ""}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <hr></hr>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.cancel.main,
                  ":hover": {
                    backgroundColor: themeOptions.palette.cancel.hover,
                  },
                }}
                onClick={handleClose}
                style={{ marginRight: "10px" }}
              >
                {translationsEn.buttons.cancel}
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.confirm.main,
                  ":hover": {
                    backgroundColor: themeOptions.palette.confirm.hover,
                  },
                }}
                type="submit"
              >
                {translationsEn.buttons.save}
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ResetPassword;

import React from "react";
import {
  Modal,
  Paper,
  Fade,
  Button,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import themeOptions from "../../style/componentsStyle";
import translationsEn from "../../i18n/translations_en";
import { useAlert } from "../../utils/alertContext";
import { forgotPasswordReset } from "../../api/employeeApi";

interface ForgotPasswordModalProps {
  open: boolean;
  handleClose: () => void;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  open,
  handleClose,
}) => {
  const { showAlert } = useAlert();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Username is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await forgotPasswordReset({ username: values.email });
        showAlert("Password reset email sent successfully!", "success");
        handleClose();
        resetForm();
      } catch (err: any) {
        console.error("Failed to send password reset email:", err);
        const errorMessage =
          err.response?.data?.error ||
          "Failed to send password reset email. Please try again.";
        showAlert(errorMessage, "error");
      }
    },
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper style={{ padding: "20px", textAlign: "center" }}>
          <Box>
            <Typography variant="h6">
              {translationsEn.forgotPassword.letter}
            </Typography>
            <hr
              style={{
                borderColor: "#e3e3e3",
                borderStyle: "solid",
                borderWidth: "thin",
                width: "100%",
              }}
            />
            <form onSubmit={formik.handleSubmit}>
              <TextField
                margin="normal"
                required
                id="email"
                name="email"
                label={translationsEn.login.username}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <hr
                style={{
                  borderColor: "#e3e3e3",
                  borderStyle: "solid",
                  borderWidth: "thin",
                  width: "100%",
                }}
              />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginTop: "15px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{
                    backgroundColor: themeOptions.palette.cancel.main,
                    borderColor: themeOptions.palette.cancel.main,
                    "&:hover": {
                      backgroundColor: themeOptions.palette.cancel.hover,
                      borderColor: themeOptions.palette.cancel.hover,
                    },
                  }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  style={{ marginRight: "5px" }}
                  sx={{
                    backgroundColor: themeOptions.palette.confirm.main,
                    borderColor: themeOptions.palette.confirm.main,
                    "&:hover": {
                      backgroundColor: themeOptions.palette.confirm.hover,
                      borderColor: themeOptions.palette.confirm.hover,
                    },
                  }}
                >
                  Save
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ForgotPasswordModal;

import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';

const ExportModal = ({ open, onClose, onConfirm }: any) => {
  const [selectedDay, setSelectedDay] = useState('');

  const handleConfirm = () => {
    onConfirm(selectedDay);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select day to export data to</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Day"
          type="text"
          fullWidth
          variant="standard"
          value={selectedDay}
          onChange={(e) => setSelectedDay(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportModal;

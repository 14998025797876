import AuthProvider from "./provider/authProvider";
import "./App.css";
import Routes from "./routes/Routes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AlertProvider } from "./utils/alertContext";

function App() {
  return (
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AlertProvider>
          <Routes />
        </AlertProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}

export default App;

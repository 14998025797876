import axios from "axios";
import APICall from "./axios";

export const getWorkOrders = async (body: any = {}): Promise<any> => {
  try {
    const response = await APICall.post<any>("/api/work-orders/get-all", body);

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Work orders data is missing or in an unexpected format."
      );
    }
  } catch (error) {
    console.error("Error fetching work orders:", error);
    throw error;
  }
};

export const getWorkOrderById = async (workOrderId: any): Promise<any> => {
  try {
    const response = await APICall.get<any>(`/api/work-orders/${workOrderId}`);

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Work orders data is missing or in an unexpected format."
      );
    }
  } catch (error) {
    console.error("Error fetching work orders:", error);
    throw error;
  }
};

export const createWorkOrders = async ({
  title,
  companyType,
  deadlineDate,
  deadlineTime,
  location,
  description,
  reporterId,
  assignedId,
  companyId,
}: {
  title: string;
  companyType: string;
  deadlineDate: string | null;
  deadlineTime: string | null;
  location: string;
  description: string;
  reporterId: number;
  assignedId: number;
  companyId: number;
}): Promise<any> => {
  try {
    const payload = {
      title,
      companyType,
      deadlineDate,
      deadlineTime,
      location,
      description,
      reporterId,
      assignedId,
      companyId,
    };

    const response = await APICall.post<any>("/api/work-orders", payload);

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Work orders data is missing or in an unexpected format."
      );
    }
  } catch (error) {
    console.error(`Error creating work orders`, error);
    throw error;
  }
};

export const editWorkOrders = async ({
  id,
  title,
  status,
  companyType,
  deadlineDate,
  deadlineTime,
  location,
  description,
  reporterId,
  assignedId,
  companyId,
  document,
}: {
  id: number;
  title: string;
  status: string;
  companyType: string;
  deadlineDate: string | null;
  deadlineTime: string | null;
  location: string;
  description: string;
  reporterId: string | null;
  assignedId: string | null;
  companyId: number;
  document: string | null;
}): Promise<any> => {
  try {
    const payload = {
      id,
      title,
      status,
      companyType,
      deadlineDate,
      deadlineTime,
      location,
      description,
      reporterId,
      assignedId,
      companyId,
      document,
    };

    const workOrderId = id;

    const response = await APICall.patch<any>(
      `/api/work-orders/${workOrderId}`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Work orders data is missing or in an unexpected format."
      );
    }
  } catch (error) {
    console.error(`Error creating work orders`, error);
    throw error;
  }
};

export const uploadFile = (workOrderId: number, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  return APICall.post(`/api/work-orders/document/${workOrderId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getWorkOrderDocument = async (
  workOrderId: any,
  documentId: any
) => {
  try {
    const response = await APICall.get(
      `/api/work-orders/document/download/${workOrderId}/${documentId}`,
      {
        responseType: "blob",
      }
    );

    return {
      blob: response.data,
      headers: response.headers,
    };
  } catch (error) {
    console.error(`Error fetching document:`, error);
    throw error;
  }
};

export const getDocumentByWorkOrderId = async (
  workOrderId: any
): Promise<any> => {
  try {
    const response = await APICall.get<any>(
      `/api/work-orders/document/${workOrderId}`
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Work orders data is missing or in an unexpected format."
      );
    }
  } catch (error) {
    console.error("Error fetching work orders:", error);
    throw error;
  }
};

export const getWorkOrdersDocumentById = async (
  workOrderId: number
): Promise<any> => {
  try {
    const response = await APICall.get<any>(`/api/work-orders/${workOrderId}`);

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Work orders data is missing or in an unexpected format."
      );
    }
  } catch (error) {
    console.error("Error fetching work orders:", error);
    throw error;
  }
};

export const deleteWorkOrder = async (workOrderId: number): Promise<any> => {
  try {
    const response = await APICall.delete<any>(
      `/api/work-orders/${workOrderId}`
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Work orders data is missing or in an unexpected format."
      );
    }
  } catch (error) {
    console.error("Error fetching work orders:", error);
    throw error;
  }
};
export const createComment = async ({
  text,
  employeeId,
  commentableId,
}: {
  text: string;
  employeeId: number;
  commentableId: number;
}): Promise<any> => {
  try {
    const payload = {
      text,
      employeeId,
      commentableId,
    };

    const response = await APICall.post<any>("/api/comments", payload);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Comment data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error creating comment`, error);
    throw error;
  }
};

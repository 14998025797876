const themeOptions = {
  palette: {
    primary: {
      main: "#17355D",
      hover: "#02214A",
    },
    confirm: {
      main: "#4caf50",
      hover: "#5fc963",
      gray: "#808080",
    },
    cancel: {
      main: "#f44336",
      hover: "#de554b",
    },
    textField: {
      main: "#ffffff",
      white: "#000000",
    },
    sideBar: {
      main: "#17355D",
    },
    login: {
      main: "#000000",
      hover: "#808080",
    },
  },
};

export default themeOptions;

import { IBudgetQuery, INewBudget } from "../interfaces/inventory.interface";
import {
  IEmployeeFilter,
} from "../interfaces/requests.interaface";
import APICall from "./axios";

export const getBudgets = async (filter: IBudgetQuery, employeeIdParam : number): Promise<any[]> => {
  const url = `/api/budget/all-budgets?&page=${filter.page}&size=${filter.size}`;
  try {
    const response = await APICall.post(url, { employeeId: employeeIdParam });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch public holidays:", error);
    throw error;
  }
};

export const getAllEmployees = async (
  employeeFilter: IEmployeeFilter
): Promise<any> => {
  try {
    const response = await APICall.post<any>(
      "api/employee/all",
      employeeFilter
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
  }
};

export const createBudget = async (newBudgetData: INewBudget): Promise<any> => {
  try {
    const response = await APICall.post<any>("/api/budget", newBudgetData);

    // Assuming the response directly contains the employee data you're interested in
    if (response.data) {
      return response.data;
    } else {
      // Handle the case where the employee data is not in the expected format or missing
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

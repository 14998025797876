import APICall from './axios';

export const getShiftRecordsForCompany = async (filter: any): Promise<any[]> => {
  try {
    const response = await APICall.get<any[]>(
      `/api/shift-record/records/${filter.year}/${filter.month}/${filter.companyId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching shift records for company:", error);
    throw error;
  }
};

export const getShiftRecords = async (filter: any): Promise<any[]> => {
  try {
    const response = await APICall.get<any[]>(
      `/api/shift-record/records/${filter.year}/${filter.month}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching shift records:", error);
    throw error;
  }
};

export const getShiftRecordsForSchedulerForCompany = async (filter: any): Promise<any[]> => {
  try {
    const response = await APICall.get<any[]>(
      `/api/shift-record/scheduler/${filter.year}/${filter.month}/${filter.companyId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching shift records for scheduler for company:", error);
    throw error;
  }
};

export const getShiftRecordsForScheduler = async (filter: any): Promise<any[]> => {
  try {
    const response = await APICall.get<any[]>(
      `/api/shift-record/scheduler/${filter.year}/${filter.month}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching shift records for scheduler:", error);
    throw error;
  }
};

export const generateShiftRecords = async (filter: any): Promise<string> => {
  try {
    const response = await APICall.get<string>(
      `/api/shift-record/${filter.companyId}/${filter.year}/${filter.month}/${filter.day}`
    );
    return response.data;
  } catch (error) {
    console.error("Error generating shift records:", error);
    throw error;
  }
};

export const getReportShiftRecords = async (filter: any): Promise<string> => {
  try {
    const response = await APICall.get<string>(
      `/api/shift-record/report/${filter.companyId}/${filter.year}/${filter.month}/${filter.day}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching shift record report:", error);
    throw error;
  }
};

export const getWorkdayTypes = async (): Promise<any[]> => {
  try {
    const response = await APICall.get<any[]>("/api/shift-record/work-day-types");
    return response.data;
  } catch (error) {
    console.error("Error fetching workday types:", error);
    throw error;
  }
};

export const getPublicHolidays = async (filter: any): Promise<any[]> => {
  try {
    const response = await APICall.get<any[]>(
      `/api/shift-record/holidays/${filter.year}/${filter.month}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching public holidays:", error);
    throw error;
  }
};

export const patchShiftRecord = async (workdayType: any, shiftRecordId: string): Promise<any> => {
  try {
    const response = await APICall.patch<any>(
      `/api/shift-record/${shiftRecordId}`,
      workdayType
    );
    return response.data;
  } catch (error) {
    console.error("Error patching shift record:", error);
    throw error;
  }
};

export const createShiftRecord = async (shiftRecord: any): Promise<any> => {
  try {
    const response = await APICall.post<any>(
      `/api/shift-record/${shiftRecord.employeeId}`,
      shiftRecord
    );
    return response.data;
  } catch (error) {
    console.error("Error creating shift record:", error);
    throw error;
  }
};

export const deleteShiftRecord = async (shiftRecord: any): Promise<any> => {
  try {
    const response = await APICall.delete<any>(
      `/api/shift-record/${shiftRecord.id}`,
      { data: shiftRecord }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting shift record:", error);
    throw error;
  }
};

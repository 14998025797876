import {
  Paper,
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Autocomplete,
  FormControl,
  TextField,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import themeOptions from "../../style/componentsStyle";
import translationsEn from "../../i18n/translations_en";
import { useEffect, useState } from "react";
import {
  approveTravelRequest,
  getAllTravelRequests,
  rejectTravelRequest,
} from "../../api/travelRequestApi";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { getAllEmployees } from "../../api/requestsApi";
import ConfirmationModal from "./confirmationModal";
import { Link } from "react-router-dom";
import { useAlert } from "../../utils/alertContext";
import TravelRequestPreviewModal from "./travelRequestPreview";

export default function AllTravelRequests() {
  const { showAlert } = useAlert();
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [dateFrom, setDateFrom] = useState<any>("");
  const [dateTo, setDateTo] = useState<any>("");
  const [employeeID, setEmployeeID] = useState<number>(0);
  const [allEmployees, setAllEmployees] = useState<any>([]);
  const [selectedTR, setSelectedTR] = useState<number>();

  const [rejectModal, setRejectModal] = useState<boolean>(false);
  const [approveModal, setApproveModal] = useState<boolean>(false);
  const [previewModal, setPreviewModal] = useState<boolean>(false);
  const [previewItem, setPreviewItem] = useState<any>({});

  const [refresh, setRefresh] = useState<boolean>(true);
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const emptyRows = tableData
    ? 5 - tableData.slice(page * pageSize, page * pageSize + pageSize)!.length
    : 0;

  const statusList = [
    { name: "created", label: "Created" },
    { name: "approved", label: "Approved" },
    { name: "denied", label: "Denied" },
    { name: "completed", label: "Completed" },
    { name: "deleted", label: "Deleted" },
    { name: "waiting_for_approval", label: "Waiting for approval" },
  ];

  useEffect(() => {
    const filter = {
      status: status || null,
      employeeId: employeeID || null,
      startDate: dateFrom || null,
      endDate: dateTo || null,
    };
    getAllTravelRequests(filter)
      .then((response) => {
        setPage(0);
        setTableData(response.data.data);
      })
      .catch((error) => console.error("Failed to fetch employees:", error))
      .finally(() => {
        setLoading(false);
      });
  }, [status, refresh, dateTo, dateFrom, employeeID]);

  useEffect(() => {
    const filter = { companyIds: [] };

    getAllEmployees(filter)
      .then((response) => setAllEmployees(response.data))
      .catch((error) => console.error("Failed to fetch teams:", error));
  }, []);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRejectTravelRequest = async (id: any) => {
    rejectTravelRequest(id)
      .then((response) => {
        setRefresh(!refresh);
        showAlert("Rejected successfully!", "success");
      })
      .catch((error) => showAlert(error, "error"))
      .finally(() => {
        setRejectModal(false);
        setSelectedTR(0);
      });
  };

  const handleApproveTravelRequest = async (id: any) => {
    approveTravelRequest(id)
      .then((response) => {
        setRefresh(!refresh);
        showAlert("Approved successfully!", "success");
      })
      .catch((error) => showAlert(error, "error"))
      .finally(() => {
        setApproveModal(false);
        setSelectedTR(0);
      });
  };

  const getStatusLabel = (name: string): string | undefined => {
    const status = statusList.find((item) => item.name === name);
    return status ? status.label : undefined;
  };

  const displayValue = (value: any, defaultValue: string = "No data") => {
    return value !== undefined && value !== null ? value : defaultValue;
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.allTravelRequests.title}
        </Typography>
      </Paper>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <Box
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <FormControl
            style={{
              marginTop: "10px",
              marginRight: "10px",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <DatePicker
              label={translationsEn.allTravelRequests.dateFrom}
              format="DD/MM/YYYY"
              onChange={(date: any) => {
                if (date) {
                  const selectedDate = new Date(date);
                  const adjustedDate = new Date(
                    selectedDate.getTime() -
                      selectedDate.getTimezoneOffset() * 60000
                  );
                  setDateFrom(adjustedDate.toISOString());
                }
              }}
              slotProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              onAccept={(newDate) => {
                if (newDate === null) setDateFrom(newDate);
              }}
              sx={{ width: "200px" }}
            />
            <DatePicker
              label={translationsEn.allTravelRequests.dateTo}
              format="DD/MM/YYYY"
              onChange={(date: any) => {
                if (date) {
                  const selectedDate = new Date(date);
                  const adjustedDate = new Date(
                    selectedDate.getTime() -
                      selectedDate.getTimezoneOffset() * 60000
                  );
                  setDateTo(adjustedDate.toISOString());
                }
              }}
              slotProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              onAccept={(newDate) => {
                if (newDate === null) setDateFrom(newDate);
              }}
              sx={{ width: "200px" }}
            />
            <Autocomplete
              sx={{ width: "200px" }}
              options={allEmployees}
              getOptionLabel={(option: any) =>
                option.employeeDto.firstName + " " + option.employeeDto.lastName
              }
              onChange={(event: any, value: any | null) => {
                setEmployeeID(value?.employeeDto.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translationsEn.allTravelRequests.person}
                />
              )}
            />
            <Autocomplete
              sx={{ width: "200px" }}
              options={statusList}
              getOptionLabel={(option: any) => option.label}
              onChange={(event: any, value: any) => {
                setStatus(value?.name);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translationsEn.allTravelRequests.status}
                />
              )}
            />
          </FormControl>
        </Box>
      </Paper>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 401 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.allTravelRequests.number}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.allTravelRequests.trTitle}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.allTravelRequests.traveler}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.allTravelRequests.created}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.allTravelRequests.status}
                </TableCell>
                <TableCell align="center">
                  {translationsEn.allTravelRequests.action}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ height: "344px" }}>
              {tableData.length === 0 && (
                <TableRow style={{ height: 344 }}>
                  <TableCell colSpan={6} align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>{translationsEn.table.noData}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {tableData
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((dataRow: any, index: any) => (
                  <TableRow
                    onClick={(event: any) => {
                      if (
                        event.currentTarget
                          .querySelector(".exclude-click")
                          .contains(event.target)
                      ) {
                        return;
                      }
                      setPreviewItem(dataRow);
                      setPreviewModal(true);
                    }}
                    key={dataRow.id}
                    sx={{
                      cursor: "pointer",
                      "&:last-child td, &:last-child th": { border: 0 },
                      height: "20px",
                    }}
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">
                      {translationsEn.createTravelRequests.travelOrder}{" "}
                      {dataRow.id}
                    </TableCell>
                    <TableCell align="left">
                      {dataRow.employeeDto.firstName +
                        " " +
                        dataRow.employeeDto.lastName}
                    </TableCell>
                    <TableCell align="left">
                      {moment(dataRow.createTimestamp).format("DD.MM.YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      {getStatusLabel(dataRow.status)}
                    </TableCell>

                    <TableCell align="center" className="exclude-click">
                      {dataRow.status !== "deleted" &&
                      dataRow.status !== "denied" ? ( // Add condition to disable the Edit button
                        <Button style={{ padding: "5px" }}>
                          <Link
                            style={{ height: "24px" }}
                            to={`/create-travel-requests/${dataRow.id}`}
                          >
                            <EditIcon
                              style={{
                                color: themeOptions.palette.primary.main,
                              }}
                            />
                          </Link>
                        </Button>
                      ) : (
                        <></>
                      )}
                      {dataRow.status === "waiting_for_approval" ? (
                        <Button
                          onClick={() => {
                            setApproveModal(true);
                            setSelectedTR(dataRow.id);
                          }}
                          style={{ padding: "5px" }}
                        >
                          <CheckIcon
                            style={{
                              color: themeOptions.palette.confirm.main,
                            }}
                          />
                        </Button>
                      ) : (
                        <></>
                      )}
                      {dataRow.status === "waiting_for_approval" ? (
                        <Button
                          onClick={() => {
                            setRejectModal(true);
                            setSelectedTR(dataRow.id);
                          }}
                          style={{ padding: "5px" }}
                        >
                          <CloseIcon
                            style={{ color: themeOptions.palette.cancel.main }}
                          />
                        </Button>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {tableData.slice(page * pageSize, page * pageSize + pageSize)
                .length !== 0 &&
                emptyRows > 0 && (
                  <TableRow style={{ height: 68 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          colSpan={3}
          count={tableData.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationModal
        open={rejectModal}
        handleClose={() => {
          setRejectModal(false);
        }}
        onConfirm={() => {
          handleRejectTravelRequest(selectedTR);
        }}
        type={"Reject"}
      />
      <ConfirmationModal
        open={approveModal}
        handleClose={() => {
          setApproveModal(false);
        }}
        onConfirm={() => {
          handleApproveTravelRequest(selectedTR);
        }}
        type={"Approve"}
      />
      <TravelRequestPreviewModal
        open={previewModal}
        closeModal={() => setPreviewModal(false)}
        data={previewItem}
      />
    </div>
  );
}

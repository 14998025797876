import APICall from "./axios";

export const getCompany = (filter?: any) => {
  return APICall.get(`/api/company`, filter);
};
export const createCompany = (data?: any) => {
  return APICall.post(`/api/company`, data);
};
export const updatedCompany = async (
  companyId: number,
  body: any
): Promise<any> => {
  try {
    const response = await APICall.patch<any>(`api/company/${companyId}`, body);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
  }
};

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import { Button, TextField, Box, Typography, Paper } from '@mui/material';
import { verifyPasswordToken, setEmployeePassword } from './../../api/employeeApi';
import { useAuth } from '../../provider/authProvider';

const SetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { setToken } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await verifyPasswordToken(token);
                console.log(response.data)
                setUsername(response.data.username);
            } catch (error) {
                setError('Invalid or expired token. Please try again.');
            }
        };

        verifyToken();
    }, [token]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            await setEmployeePassword({ token, username, password });
            setSuccess('Password set successfully. Redirecting to login...');
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (error) {
            setError('Failed to set password. Please try again.');
        }
    };

    return (
        <Paper style={{ padding: '20px', maxWidth: '500px', margin: '20px auto' }}>
            <Typography variant="h5" gutterBottom>
                Set Your Password
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            {success && <Typography color="primary">{success}</Typography>}
            {!success && (
                <form onSubmit={handleSubmit}>
                    <Box mb={2}>
                        <TextField
                            label="New Password"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="Confirm Password"
                            type="password"
                            fullWidth
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </Box>
                    <Button variant="contained" color="primary" type="submit" fullWidth>
                        Set Password
                    </Button>
                </form>
            )}
        </Paper>
    );
};

export default SetPassword;

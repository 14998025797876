import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { Article, Clear } from "@mui/icons-material";
import translationsEn from "../../../i18n/translations_en";
import HelpIcon from "@mui/icons-material/Help";
import { getAllCvs } from "../../../api/cvApi";
import OverviewModal from "../OverviewModal";

interface SummaryModalProps {
  open: boolean;
  handleClose: () => void;
}

const ViewAllCvs: React.FC<SummaryModalProps> = ({ open, handleClose }) => {
  const [cvData, setCvData] = useState<any[]>([]);
  const [selectedCvId, setSelectedCvId] = useState<number | null>(null);
  const [selectedEmployeeName, setSelectedEmployeeName] = useState<string>("");

  useEffect(() => {
    const fetchUserCvs = async () => {
      try {
        const response = await getAllCvs();
        setCvData(response || []);
        console.log(response);
      } catch (error) {
        console.error("Failed to fetch CV data", error);
        setCvData([]);
      }
    };

    if (open) {
      fetchUserCvs();
    }
  }, [open]);

  const handleInfoClick = (cvId: number, fullName: string) => {
    setSelectedCvId(cvId);
    setSelectedEmployeeName(fullName);
  };

  const handleCloseModal = () => {
    setSelectedCvId(null);
    setSelectedEmployeeName("");
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            maxHeight: "400px",
            overflow: "auto",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Article style={{ marginRight: "5px" }} />
              <Typography>{translationsEn.cvModals.viewAllCvs}</Typography>
            </Box>

            <Clear onClick={handleClose} />
          </Box>
          <hr />
          <List>
            {cvData?.map((cv) => (
              <ListItem key={cv.id}>
                <ListItemText
                  primary={`${cv.employeeDto.firstName} ${cv.employeeDto.lastName}`}
                />
                <IconButton
                  onClick={() =>
                    handleInfoClick(
                      cv.id,
                      `${cv.employeeDto.firstName} ${cv.employeeDto.lastName}`
                    )
                  }
                >
                  <HelpIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
          {selectedCvId !== null && (
            <OverviewModal
              open={true}
              handleClose={handleCloseModal}
              cvId={selectedCvId}
              loggedUserFullname={selectedEmployeeName}
            />
          )}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ViewAllCvs;

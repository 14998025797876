import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";

const equipment = [
  {
    id: 1,
    name: "Laptop",
    description: "Electronics",
    location: "Floor 1",
    type: "HP",
    status: "Taken",
    serialNumber: "123333",
    price: "200",
    warranty: "Yes",
    usingFrom: "17.01.2024",
  },
  {
    id: 2,
    name: "Phone",
    description: "Electronics",
    location: "Floor 1",
    type: "HP",
    status: "Taken",
    serialNumber: "123333",
    price: "200",
    warranty: "Yes",
    usingFrom: "17.01.2024",
  },
  {
    id: 3,
    name: "Tablet",
    description: "Electronics",
    location: "Floor 1",
    type: "HP",
    status: "Taken",
    serialNumber: "3321",
    price: "200",
    warranty: "Yes",
    usingFrom: "17.01.2024",
  },
];

export default function UserInventoryOverview() {
  const [budgetValue, setBudgetValue] = useState(0);
  const [availibleBudget, setAvailibleBudget] = useState(0);
  return (
    <>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          margin: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.userInventory.name}
        </Typography>
        <Grid style={{ marginRight: "10px" }}>
          <Typography
            style={{ fontWeight: "bold" }}
          >{`${translationsEn.userInventory.budget}: ${budgetValue} kn`}</Typography>
          <Typography
            style={{ fontWeight: "bold" }}
          >{`${translationsEn.userInventory.availibleBudget}: ${availibleBudget} kn`}</Typography>
        </Grid>
      </Paper>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          margin: "10px",
          display: "flex",
        }}
        variant="outlined"
      >
        <Box
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Grid
            style={{ display: "flex", width: "500px", alignItems: "center" }}
          >
            <FormControl
              style={{ marginTop: "10px", width: "40%", marginRight: "10px" }}
            >
              <InputLabel id="demo-simple-select-label">
                {translationsEn.labels.equipment}
              </InputLabel>
              <Select labelId="demo-simple-select-label" label="Person">
                {equipment.map((equipment) => (
                  <MenuItem key={equipment.id} value={equipment.name}>
                    {equipment.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              style={{ marginTop: "10px", width: "40%", marginRight: "10px" }}
            >
              <InputLabel id="demo-simple-select-label">
                {translationsEn.labels.serialNumber}
              </InputLabel>
              <Select labelId="demo-simple-select-label" label="Person">
                {equipment.map((equipment) => (
                  <MenuItem key={equipment.id} value={equipment.serialNumber}>
                    {equipment.serialNumber}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              style={{
                marginTop: "10px",
                borderRadius: "20px",
                height: "60%",
              }}
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
              }}
            >
              {translationsEn.buttons.search}
            </Button>
          </Grid>
        </Box>
      </Paper>
      <Paper
        style={{
          margin: "10px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <TableContainer
          style={{ padding: "10px" }}
          sx={{ maxWidth: "98.7%" }}
          component={Paper}
        >
          <Table
            style={{
              minWidth: 550,
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.userInventory.equipmentName}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.userInventory.description}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.userInventory.location}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.userInventory.type}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.userInventory.status}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.userInventory.serialNumber}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.userInventory.price}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.userInventory.warranty}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {translationsEn.userInventory.usingFrom}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {equipment.map((equipment) => (
                <TableRow key={equipment.id}>
                  <TableCell align="left">{equipment.name}</TableCell>
                  <TableCell align="left">{equipment.description}</TableCell>
                  <TableCell align="left">{equipment.location}</TableCell>
                  <TableCell align="left">{equipment.type}</TableCell>
                  <TableCell align="left">{equipment.status}</TableCell>
                  <TableCell align="left">{equipment.serialNumber}</TableCell>
                  <TableCell align="left">{equipment.price}</TableCell>
                  <TableCell align="left">{equipment.warranty}</TableCell>
                  <TableCell align="left">{equipment.usingFrom}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import themeOptions from "../../style/componentsStyle";
import translationsEn from "../../i18n/translations_en";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { getAllEmployees } from "../../api/employeeApi";
import { getAllCompanies } from "../../api/overviewApi";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  CreateTravelRequest,
  editTravelRequest,
} from "../../api/travelRequestApi";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../utils/alertContext";

const PageContainer = styled("div")({
  maxWidth: "900px",
  margin: "auto",
  height: "100%",
  padding: "10px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
});

const validationSchema = Yup.object({
  travelTo: Yup.string().required("Travel to is required"),
  travelDuration: Yup.string().required("Travel can last is required"),
  travelsDay: Yup.string().required("Travels day is required"),
  // advancePayment: Yup.number().required("Advanced payment is required"),
  companyId: Yup.number().required("Company is required"),
  employeeId: Yup.number().required("User is required"),
});

const initialValues = {
  travelTo: "",
  travelDuration: "",
  travelsDay: "",
  advancePayment: "",
  companyId: "",
  employeeId: "",
};

export default function GeneralInformation(props?: any) {
  const { travelRequest } = props;
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);
  const navigate = useNavigate();

  const isApproved = travelRequest?.status === "approved"; // Check if status is approved

  useEffect(() => {
    if (travelRequest) {
      formik.setFieldValue("employeeId", travelRequest.employeeDto.id);
      formik.setFieldValue("companyId", travelRequest.companyDto.id);
      formik.setFieldValue("travelsDay", travelRequest.travelsDay);
      formik.setFieldValue("travelTo", travelRequest.travelTo);
      formik.setFieldValue("travelDuration", travelRequest.travelDuration);
      formik.setFieldValue("advancePayment", travelRequest.advancePayment);
    }
  }, [travelRequest]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      setLoading(true);
      if (travelRequest) {
        editTravelRequest(travelRequest.id, values)
          .then(() => {
            navigate("/my-travel-requests");
            showAlert("Travel request edited successfully!", "success");
          })
          .catch((error) => {
            showAlert(error, "error");
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        CreateTravelRequest(values)
          .then((response) => {
            navigate("/my-travel-requests");
            showAlert("Travel request created successfully!", "success");
          })
          .catch((error) => showAlert(error, "error"))
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  useEffect(() => {
    const filter: any = { companyIds: [] };
    getAllEmployees(filter)
      .then((response) =>
        setUsers(response.data.map((item: any) => item.employeeDto))
      )
      .catch((error) => console.error("Failed to fetch employees:", error));

    getAllCompanies()
      .then((response) => setCompanies(response))
      .catch((error) => console.error("Failed to fetch companies:", error));
  }, []);

  return (
    <PageContainer>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
            <span style={{ width: "100%" }}>
              {/* user */}
              <Autocomplete
                options={users}
                getOptionLabel={(option: any) =>
                  `${option.firstName} ${option.lastName}`
                }
                value={
                  formik.values.employeeId
                    ? users.find(
                        (user: any) => user.id === formik.values.employeeId
                      )
                    : null
                }
                onChange={(event, value: any) => {
                  formik.setFieldValue("employeeId", value?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(
                      formik.touched.employeeId && formik.errors.employeeId
                    )}
                    helperText={
                      formik.touched.employeeId &&
                      formik.errors.employeeId &&
                      (formik.errors.employeeId as string)
                    }
                    label={translationsEn.createTravelRequests.user}
                    placeholder={translationsEn.createTravelRequests.chooseUser}
                    disabled={isApproved} // Disable if approved
                  />
                )}
              />
            </span>
          </Grid>
          <Grid item xs={6}>
            {/* company */}
            <Autocomplete
              options={companies}
              getOptionLabel={(option: any) => option.name}
              value={
                formik.values.companyId
                  ? companies.find(
                      (company: any) => company.id === formik.values.companyId
                    )
                  : null
              }
              onChange={(event, value: any) => {
                formik.setFieldValue("companyId", value.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(
                    formik.touched.companyId && formik.errors.companyId
                  )}
                  helperText={
                    formik.touched.companyId &&
                    formik.errors.companyId &&
                    (formik.errors.companyId as string)
                  }
                  label={translationsEn.createTravelRequests.company}
                  placeholder={
                    translationsEn.createTravelRequests.chooseCompany
                  }
                  disabled={isApproved} // Disable if approved
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            {/* travels day */}
            <DatePicker
              label={translationsEn.createTravelRequests.travelsDay}
              format="DD/MM/YYYY"
              value={
                formik.values.travelsDay
                  ? dayjs(formik.values.travelsDay)
                  : null
              }
              onChange={(date) =>
                formik.setFieldValue(
                  "travelsDay",
                  date ? date.format("YYYY-MM-DD") : ""
                )
              }
              slotProps={{
                textField: {
                  helperText:
                    formik.touched.travelsDay &&
                    formik.errors.travelsDay &&
                    (formik.errors.travelsDay as string),
                  error: Boolean(
                    formik.touched.travelsDay && formik.errors.travelsDay
                  ),
                },
              }}
              sx={{ margin: "10px 0", width: "100%" }}
              disabled={isApproved} // Disable if approved
            />
          </Grid>

          <Grid item xs={6}>
            {/* travel to */}
            <TextField
              margin="dense"
              label={translationsEn.createTravelRequests.travelTo}
              name="travelTo"
              value={formik.values.travelTo}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.travelTo && formik.errors.travelTo)}
              helperText={
                formik.touched.travelTo &&
                formik.errors.travelTo &&
                (formik.errors.travelTo as string)
              }
              fullWidth
              disabled={isApproved} // Disable if approved
            />
          </Grid>

          <Grid item xs={6}>
            {/* travel can last */}
            <TextField
              type="number"
              margin="dense"
              label={translationsEn.createTravelRequests.travelCanLast}
              name="travelDuration"
              value={formik.values.travelDuration}
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.travelDuration && formik.errors.travelDuration
              )}
              helperText={
                formik.touched.travelDuration &&
                formik.errors.travelDuration &&
                (formik.errors.travelDuration as string)
              }
              fullWidth
              InputProps={{
                inputProps: { min: 0 },
              }}
              disabled={isApproved} // Disable if approved
            />
          </Grid>

          <Grid item xs={6}>
            {/* advance payment approved */}
            <TextField
              type="number"
              margin="dense"
              label={translationsEn.createTravelRequests.advancePayment}
              name="advancePayment"
              value={formik.values.advancePayment}
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.advancePayment && formik.errors.advancePayment
              )}
              helperText={
                formik.touched.advancePayment &&
                formik.errors.advancePayment &&
                (formik.errors.advancePayment as string)
              }
              fullWidth
              InputProps={{
                inputProps: { min: 0 },
              }}
              disabled={isApproved} // Disable if approved
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              type="submit"
              variant="outlined"
              size="large"
              style={{
                width: "100%",
                backgroundColor: "white",
                color: themeOptions.palette.primary.main,
                borderColor: themeOptions.palette.primary.main,
                textTransform: "none",
                marginTop: "20px",
              }}
              disabled={loading || isApproved} // Disable if approved
              onClick={() => navigate("/travel-requests")}
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                translationsEn.createTravelRequests.cancel
              )}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{
                width: "100%",
                backgroundColor: themeOptions.palette.primary.main,
                textTransform: "none",
                marginTop: "20px",
              }}
              disabled={loading || isApproved} // Disable if approved
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : travelRequest ? (
                translationsEn.createTravelRequests.editButton
              ) : (
                translationsEn.createTravelRequests.create
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </PageContainer>
  );
}

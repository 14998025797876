import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { Clear, History } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import SaveModal from "./../confirmationsModal/saveModal";
import { createOvertime, updateOvertime } from "../../api/overtimeApi";
import dayjs from "dayjs";
import { useAlert } from "../../utils/alertContext";

interface OvertimeOverviewProps {
  persons: any;
  open: boolean;
  handleClose: () => void;
  onSuccess: () => void;
  initialData?: any; // To distinguish between create and update modes
  mode: "create" | "update";
}

const validationSchema = Yup.object({
  employeeDto: Yup.object().shape({
    id: Yup.number().required("Person is required"),
  }),
  date: Yup.date().required("Date is required"),
  timeFrom: Yup.string().required("Time from is required"),
  timeTo: Yup.string().required("Time to is required"),
  reason: Yup.string().required("Reason is required"),
  ticketReference: Yup.string().required("Ticket reference is required"),
});

const initialValues = {
  employeeDto: {
    id: null,
  },
  date: "",
  timeFrom: "",
  timeTo: "",
  reason: "",
  ticketReference: "",
};

const AddOvertimeModal: React.FC<OvertimeOverviewProps> = ({
  persons,
  open,
  handleClose,
  onSuccess,
  initialData,
  mode,
}) => {
  const { showAlert } = useAlert();
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const getInitialValues = () => {
    const values = { ...initialValues };
    if (mode === "create") {
      values.employeeDto.id = null;
    } else if (initialData) {
      values.employeeDto.id = initialData?.employeeDto?.id;
      values.date = dayjs(initialData?.date).format("YYYY-MM-DD");
      values.timeFrom = initialData?.timeFrom;
      values.timeTo = initialData?.timeTo;
      values.reason = initialData?.reason;
      values.ticketReference = initialData?.ticketReference;
    }
    return values;
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      const overtimeDetail = {
        employeeId: values.employeeDto.id,
        date: values.date,
        timeFrom: values.timeFrom,
        timeTo: values.timeTo,
        reason: values.reason,
        ticketReference: values.ticketReference,
        statusId: 2,
        id: initialData?.id || null, // Add ID for update operation
      };

      setLoading(true);
      try {
        if (mode === "create") {
          await createOvertime(overtimeDetail);
          showAlert("Overtime created successfully!", "success");
        } else {
          await updateOvertime(overtimeDetail);
          showAlert("Overtime updated successfully!", "success");
        }
        onSuccess();
        handleModalClose();
      } catch (error) {
        console.error(
          `Error ${mode === "create" ? "creating" : "updating"} overtime:`,
          error
        );
        showAlert(
          `Failed to ${
            mode === "create" ? "create" : "update"
          } overtime. Please try again.`,
          "error"
        );
        handleModalClose();
      } finally {
        setLoading(false); // Stop loading
      }
    },
  });

  const handleModalClose = () => {
    formik.resetForm();
    handleClose();
  };

  const handleSaveModalClose = () => {
    setSaveModalOpen(false);
    handleClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open}>
          <Paper
            style={{
              padding: "20px",
              textAlign: "center",
              width: "40%",
              position: "relative",
            }}
          >
            {loading && (
              <CircularProgress
                size={60}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -30,
                  marginLeft: -30,
                  zIndex: 1,
                }}
              />
            )}
            <form onSubmit={formik.handleSubmit}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <History style={{ marginRight: "5px" }} />
                  <Typography>
                    {mode === "create"
                      ? translationsEn.overtime.addNewOvertime
                      : "Update Overtime"}
                  </Typography>
                </Box>

                <Clear onClick={handleModalClose} />
              </Box>
              <hr></hr>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormControl style={{ width: "100%", margin: "10px 0" }}>
                  <Autocomplete
                    options={persons}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    value={
                      formik.values.employeeDto.id
                        ? persons.find(
                            (person: any) =>
                              person.id === formik.values.employeeDto.id
                          )
                        : null
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "employeeDto.id",
                        newValue ? newValue.id : null
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(
                          formik.touched.employeeDto?.id &&
                            formik.errors.employeeDto?.id
                        )}
                        helperText={
                          formik.touched.employeeDto?.id &&
                          formik.errors.employeeDto?.id &&
                          (formik.errors.employeeDto.id as string)
                        }
                        label="Person"
                        placeholder="Search and select"
                        disabled={loading}
                      />
                    )}
                  />
                </FormControl>
                <FormControl style={{ width: "100%", margin: "10px 0" }}>
                  <DatePicker
                    label="Date"
                    format="DD/MM/YYYY"
                    value={
                      formik.values.date ? dayjs(formik.values.date) : null
                    }
                    onChange={(date) =>
                      formik.setFieldValue(
                        "date",
                        date ? date.format("YYYY-MM-DD") : ""
                      )
                    }
                    slotProps={{
                      textField: {
                        helperText:
                          formik.touched.date &&
                          formik.errors.date &&
                          (formik.errors.date as string),
                        error: Boolean(
                          formik.touched.date && formik.errors.date
                        ),
                        disabled: loading,
                      },
                    }}
                    sx={{ margin: "10px 0", width: "100%" }}
                  />
                </FormControl>
                <FormControl style={{ width: "100%", margin: "10px 0" }}>
                  <TimePicker
                    label="From"
                    value={
                      formik.values.timeFrom
                        ? dayjs(formik.values.timeFrom, "HH:mm")
                        : null
                    }
                    onChange={(time) =>
                      formik.setFieldValue(
                        "timeFrom",
                        time ? time.format("HH:mm") : ""
                      )
                    }
                    ampm={false}
                    slotProps={{
                      textField: {
                        helperText:
                          formik.touched.timeFrom &&
                          formik.errors.timeFrom &&
                          (formik.errors.timeFrom as string),
                        error: Boolean(
                          formik.touched.timeFrom && formik.errors.timeFrom
                        ),
                        disabled: loading,
                      },
                    }}
                    sx={{ margin: "10px 0", width: "100%" }}
                  />
                </FormControl>
                <FormControl style={{ width: "100%", margin: "10px 0" }}>
                  <TimePicker
                    label="To"
                    value={
                      formik.values.timeTo
                        ? dayjs(formik.values.timeTo, "HH:mm")
                        : null
                    }
                    onChange={(time) =>
                      formik.setFieldValue(
                        "timeTo",
                        time ? time.format("HH:mm") : ""
                      )
                    }
                    ampm={false}
                    slotProps={{
                      textField: {
                        helperText:
                          formik.touched.timeTo &&
                          formik.errors.timeTo &&
                          (formik.errors.timeTo as string),
                        error: Boolean(
                          formik.touched.timeTo && formik.errors.timeTo
                        ),
                        disabled: loading,
                      },
                    }}
                    sx={{ margin: "10px 0", width: "100%" }}
                  />
                </FormControl>
                <FormControl style={{ width: "100%", margin: "10px 0" }}>
                  <TextField
                    margin="dense"
                    label="Reason"
                    name="reason"
                    value={formik.values.reason}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.reason && formik.errors.reason
                    )}
                    helperText={
                      formik.touched.reason &&
                      formik.errors.reason &&
                      (formik.errors.reason as string)
                    }
                    fullWidth
                    disabled={loading}
                  />
                </FormControl>
                <FormControl style={{ width: "100%", margin: "10px 0" }}>
                  <TextField
                    margin="dense"
                    label="Ticket reference"
                    name="ticketReference"
                    value={formik.values.ticketReference}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.ticketReference &&
                        formik.errors.ticketReference
                    )}
                    helperText={
                      formik.touched.ticketReference &&
                      formik.errors.ticketReference &&
                      (formik.errors.ticketReference as string)
                    }
                    fullWidth
                    disabled={loading}
                  />
                </FormControl>
              </Box>
              <hr></hr>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={handleModalClose}
                  variant="contained"
                  sx={{
                    backgroundColor: themeOptions.palette.cancel.main,
                    ":hover": {
                      backgroundColor: themeOptions.palette.cancel.hover,
                    },
                    marginRight: "10px",
                  }}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: themeOptions.palette.confirm.main,
                    ":hover": {
                      backgroundColor: themeOptions.palette.confirm.hover,
                    },
                  }}
                  disabled={loading}
                >
                  Confirm
                </Button>
              </Box>
            </form>
          </Paper>
        </Fade>
      </Modal>

      <SaveModal open={saveModalOpen} handleClose={handleSaveModalClose} />
    </>
  );
};

export default AddOvertimeModal;

import React, { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  Fade,
  TextField,
  Select,
  MenuItem,
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Grid,
} from "@mui/material";
import { contentCss, buttonCss, directionCss } from "./newEmployeeModalCss";
import { DatePicker } from "@mui/x-date-pickers";
import translationsEn from "../../i18n/translations_en";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  IEmployee,
  INewEmployee,
  IRolesTypes,
  IStatusType,
} from "../../interfaces/employee.interface";
import dayjs from "dayjs";
import SaveModal from "../confirmationsModal/saveModal";
import { PersonAddAlt1Rounded } from "@mui/icons-material";
import { createEmployee, getRoles, getStatuses } from "../../api/employeeApi";
import { getAllCompanies } from "../../api/overviewApi";
import { useAlert } from "../../utils/alertContext";

type RequestTypeName = "ROLE_USER" | "ROLE_ADMIN";
type RequestStatusName = "student" | "inactive" | "full_time" | "part_time";

interface NewEmployeeModalProps {
  open: boolean;
  handleClose: () => void;
  refetchEmployees: () => void;
  children?: React.ReactNode;
}

const initialValues: IEmployee = {
  firstName: "",
  lastName: "",
  oib: "",
  birthDate: new Date().toISOString().split("T")[0] as any,
  email: "",
  username: "",
  shiftDuration: "",
  startedDate: new Date().toISOString().split("T")[0] as any,
  selectedProbationDate: new Date().toISOString().split("T")[0] as any,
  vacationDays: "",
  company: "",
  role: "",
  status: "",
  businessPhone: "",
};

const NewEmployeeModal: React.FC<NewEmployeeModalProps> = ({
  open,
  handleClose,
  refetchEmployees,
}) => {
  const { showAlert } = useAlert();
  const [activeStep, setActiveStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [statuses, setStatuses] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required(`${translationsEn.validation.required}`),
    lastName: Yup.string().required(`${translationsEn.validation.required}`),
    oib: Yup.string().required(`${translationsEn.validation.required}`),
    email: Yup.string()
      .email(`${translationsEn.validation.emailInvalid}`)
      .required(`${translationsEn.validation.required}`),
    username: Yup.string().required(`${translationsEn.validation.required}`),
    shiftDuration: Yup.string().required(
      `${translationsEn.validation.required}`
    ),
    startedDate: Yup.date().required(`${translationsEn.validation.required}`),
    selectedProbationDate: Yup.date().required(
      `${translationsEn.validation.required}`
    ),
    vacationDays: Yup.string().required(
      `${translationsEn.validation.required}`
    ),
    company: Yup.string().required(`${translationsEn.validation.required}`),
    role: Yup.string().required(`${translationsEn.validation.required}`),
    status: Yup.string().required(`${translationsEn.validation.required}`),
    businessPhone: Yup.string().required(
      `${translationsEn.validation.required}`
    ), // Added validation
  });

  const handleSave = () => {
    if (activeStep === 0) {
      formik.setFieldTouched("firstName", true);
      formik.setFieldTouched("lastName", true);
      formik.setFieldTouched("oib", true);
      formik.setFieldTouched("email", true);
      formik.setFieldTouched("businessPhone", true);
      formik.setFieldTouched("company", true);

      formik.validateForm().then((errors) => {
        if (
          !errors.firstName &&
          !errors.lastName &&
          !errors.oib &&
          !errors.email &&
          !errors.businessPhone
        ) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      });
    } else if (activeStep === 1) {
      formik.setFieldTouched("username", true);
      formik.setFieldTouched("role", true);

      formik.validateForm().then((errors) => {
        if (!errors.username) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      });
    } else if (activeStep === 2) {
      formik.setFieldTouched("status", true);
      formik.setFieldTouched("startedDate", true);
      formik.setFieldTouched("selectedProbationDate", true);
      formik.setFieldTouched("shiftDuration", true);
      formik.setFieldTouched("vacationDays", true);
      formik.setFieldTouched("businessPhone", true); // Added field

      formik.validateForm().then((errors) => {
        if (
          !errors.startedDate &&
          !errors.selectedProbationDate &&
          !errors.shiftDuration &&
          !errors.vacationDays &&
          !errors.businessPhone // Added validation
        ) {
          setSaveModalOpen(true);
        }
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      const endpointPayload = {
        employeeDto: {
          firstName: values.firstName,
          lastName: values.lastName,
          businessPhone: values.businessPhone, // Added field
          oib: values.oib,
          email: values.email,

          dateOfBirth: values.birthDate,
          active: true,
          companyDto: {
            id: values.company,
          },
        },
        contractDto: {
          startDate: values.startedDate,
          probationExpirationDate: values.selectedProbationDate,
          shiftDuration: values.shiftDuration,
          vacationDays: values.vacationDays,
          employeeStatusId: values.status,
        },
        userDto: {
          username: values.username,
          employeeRoleDto: {
            roleDto: {
              id: values.role,
            },
          },
        },
      };

      createEmployee(endpointPayload)
        .then((response) => {
          showAlert("Employee created successfully!", "success");
          handleClose();
          setSaveModalOpen(false);
          formik.resetForm();
          refetchEmployees();
        })
        .catch((error) => {
          console.error("Failed to create employee:", error);
          const errorMessage =
            error.response?.data?.error ||
            "Failed to create employee. Please try again.";
          showAlert(errorMessage, "error");
          handleClose();
          setSaveModalOpen(false);
          formik.resetForm();
        });

      onCloseModal();
    },
  });

  useEffect(() => {
    if (open) {
      getStatuses()
        .then((response) => {
          setStatuses(response.data);
        })
        .catch((error) => console.error("Failed to fetch teams:", error));

      getRoles()
        .then((response) => {
          setRoles(response.data);
        })
        .catch((error) => console.error("Failed to fetch teams:", error));

      getAllCompanies()
        .then(setCompanies)
        .catch((error) => console.error("Failed to fetch companies:", error));
    }
  }, [open]);

  const onCloseModal = () => {
    handleClose();
    formik.resetForm();
    setErrorMessage("");
  };

  useEffect(() => {
    if (!open) {
      setActiveStep(0);
      formik.resetForm();
      setErrorMessage("");
    }
  }, [open]);

  const renderStep0 = () => {
    return (
      <Box style={{ ...contentCss }}>
        <TextField
          name={"firstName"}
          label={translationsEn.employees.firstName}
          variant="filled"
          sx={{ width: "100%", margin: "5px" }}
          value={formik.values.firstName || ""}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          name={"lastName"}
          label={translationsEn.employees.lastName}
          variant="filled"
          sx={{ width: "100%", margin: "5px" }}
          value={formik.values.lastName || ""}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <TextField
          name={"oib"}
          label={translationsEn.employees.oib}
          variant="filled"
          sx={{ width: "100%", margin: "5px" }}
          value={formik.values.oib || ""}
          onChange={formik.handleChange}
          error={formik.touched.oib && Boolean(formik.errors.oib)}
          helperText={formik.touched.oib && formik.errors.oib}
        />
        <DatePicker
          label={translationsEn.labels.dateOfBirth}
          format="DD/MM/YYYY"
          sx={{ margin: "5px", width: "100%" }}
          value={dayjs(new Date(formik.values.birthDate))}
          onChange={(value: any) => {
            formik.setFieldValue(
              "birthDate",
              value.toISOString().split("T")[0]
            );
          }}
        />
        {formik.touched.birthDate && formik.errors.birthDate && (
          <Typography sx={{ color: "red" }}>
            {formik.errors.birthDate}
          </Typography>
        )}
        <TextField
          name={"email"}
          label={translationsEn.employees.email}
          variant="filled"
          sx={{ width: "100%", margin: "5px" }}
          value={formik.values.email || ""}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          name={"businessPhone"}
          label={translationsEn.employees.businessPhone} // Added field
          variant="filled"
          sx={{ width: "100%", margin: "5px" }}
          value={formik.values.businessPhone || ""}
          onChange={formik.handleChange}
          error={
            formik.touched.businessPhone && Boolean(formik.errors.businessPhone)
          }
          helperText={
            formik.touched.businessPhone && formik.errors.businessPhone
          }
        />
        <FormControl sx={{ width: "100%", margin: "3px" }}>
          <InputLabel id="demo-simple-select-label">
            {translationsEn.employees.company}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label={translationsEn.employees.company}
            name="company"
            value={formik.values.company}
            onChange={formik.handleChange}
            error={formik.touched.company && Boolean(formik.errors.company)}
          >
            {companies.map((company: any) => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
          {formik.touched.company && formik.errors.company && (
            <Typography
              sx={{
                color: "#d42c2c",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                fontSize: "13px",
                marginLeft: "15px",
              }}
            >
              {formik.errors.company}
            </Typography>
          )}
        </FormControl>
      </Box>
    );
  };

  const renderStep1 = () => {
    return (
      <Box
        style={{
          ...contentCss,
        }}
      >
        <TextField
          name={"username"}
          label={translationsEn.employees.username}
          variant="filled"
          style={{
            width: "50%",
            margin: "10px",
          }}
          value={formik.values.username || ""}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />

        <FormControl sx={{ width: "50%", margin: "7px" }}>
          <InputLabel id="demo-simple-select-label">
            {translationsEn.employees.role}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label={translationsEn.employees.role}
            name="role"
            value={formik.values.role}
            onChange={formik.handleChange}
            error={formik.touched.role && Boolean(formik.errors.role)}
          >
            {roles.map((role: IRolesTypes) => (
              <MenuItem key={role.id} value={role.id}>
                {translationsEn.rolesTypes[role.name as RequestTypeName] ||
                  role.name}
              </MenuItem>
            ))}
          </Select>
          {formik.touched.role && formik.errors.role && (
            <Typography
              sx={{
                color: "#d42c2c",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                fontSize: "13px",
                marginLeft: "15px",
              }}
            >
              {formik.errors.role}
            </Typography>
          )}
        </FormControl>
      </Box>
    );
  };

  const renderStep2 = () => {
    return (
      <Box
        style={{
          ...contentCss,
        }}
      >
        <FormControl sx={{ width: "50%", margin: "10px" }}>
          <InputLabel id="demo-simple-select-label">
            {translationsEn.employees.status}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label={translationsEn.employees.status}
            name="status"
            value={formik.values.status}
            onChange={formik.handleChange}
            error={formik.touched.status && Boolean(formik.errors.status)}
          >
            {statuses.map((status: IStatusType) => (
              <MenuItem key={status.id} value={status.id}>
                {translationsEn.statusType[
                  status.status as RequestStatusName
                ] || status.status}
              </MenuItem>
            ))}
          </Select>
          {formik.touched.status && formik.errors.status && (
            <Typography
              sx={{
                color: "#d42c2c",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                fontSize: "13px",
                marginLeft: "15px",
              }}
            >
              {formik.errors.status}
            </Typography>
          )}
        </FormControl>

        <DatePicker
          label={translationsEn.labels.startDate}
          format="DD/MM/YYYY"
          sx={{ margin: "10px", width: "50%" }}
          value={dayjs(new Date(formik.values.startedDate))}
          onChange={(value: any) => {
            formik.setFieldValue(
              "startedDate",
              value.toISOString().split("T")[0]
            );
          }}
        />

        {formik.touched.startedDate && formik.errors.startedDate && (
          <Typography sx={{ color: "red" }}>
            {formik.errors.startedDate}
          </Typography>
        )}
        <Typography>
          {formik.touched.startedDate && formik.errors.startedDate}
        </Typography>

        <DatePicker
          label={translationsEn.labels.probationDate}
          format="DD/MM/YYYY"
          sx={{ margin: "10px", width: "50%" }}
          value={dayjs(new Date(formik.values.selectedProbationDate))}
          onChange={(value: any) => {
            formik.setFieldValue(
              "selectedProbationDate",
              value.toISOString().split("T")[0]
            );
          }}
        />

        {formik.touched.selectedProbationDate &&
          formik.errors.selectedProbationDate && (
            <Typography sx={{ color: "red" }}>
              {formik.errors.selectedProbationDate}
            </Typography>
          )}
        <Typography>
          {formik.touched.selectedProbationDate &&
            formik.errors.selectedProbationDate}
        </Typography>

        <TextField
          name={"shiftDuration"}
          label={translationsEn.employees.shiftDuration}
          variant="filled"
          style={{
            width: "50%",
            margin: "10px",
          }}
          value={formik.values.shiftDuration || ""}
          onChange={formik.handleChange}
          error={
            formik.touched.shiftDuration && Boolean(formik.errors.shiftDuration)
          }
          helperText={
            formik.touched.shiftDuration && formik.errors.shiftDuration
          }
        />
        <TextField
          name={"vacationDays"}
          label={translationsEn.employees.vacationDays}
          variant="filled"
          style={{
            width: "50%",
            margin: "10px",
          }}
          value={formik.values.vacationDays || ""}
          onChange={formik.handleChange}
          error={
            formik.touched.vacationDays && Boolean(formik.errors.vacationDays)
          }
          helperText={formik.touched.vacationDays && formik.errors.vacationDays}
        />
        <Box
          style={{
            ...directionCss,
          }}
        ></Box>
      </Box>
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      label: ` ${translationsEn.employees.personalInformation}`,
      description: renderStep0(),
    },
    {
      label: ` ${translationsEn.employees.accountInformation}`,
      description: renderStep1(),
    },
    {
      label: ` ${translationsEn.employees.contractInformation}`,
      description: renderStep2(),
    },
  ];

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open}>
          <Box style={{ maxWidth: "90%", width: "30%", maxHeight: "99%" }}>
            <Paper sx={{ width: "100%", height: "870px", overflowY: "auto" }}>
              <Grid
                style={{
                  display: "flex",
                  padding: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PersonAddAlt1Rounded style={{ marginRight: "5px" }} />
                <Typography style={{ fontWeight: "bold" }}>
                  {translationsEn.employees.create}
                </Typography>
              </Grid>

              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                sx={{ padding: "30px" }}
              >
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      optional={
                        index === steps.length - 1 ? (
                          <Typography variant="caption">Last step</Typography>
                        ) : null
                      }
                    >
                      {step.label}
                    </StepLabel>
                    <form onSubmit={formik.handleSubmit}>
                      <StepContent>{step.description}</StepContent>
                      {index === activeStep && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingRight: "24px",
                          }}
                        >
                          {activeStep !== 0 && (
                            <Button
                              onClick={handleBack}
                              style={{
                                ...buttonCss,
                              }}
                            >
                              {translationsEn.buttons.back}
                            </Button>
                          )}
                          {activeStep !== steps.length - 1 ? (
                            <Button
                              onClick={handleSave}
                              style={{
                                ...buttonCss,
                              }}
                            >
                              {translationsEn.buttons.next}
                            </Button>
                          ) : (
                            <Button
                              onClick={handleSave}
                              style={{
                                ...buttonCss,
                              }}
                            >
                              {translationsEn.buttons.save}
                            </Button>
                          )}
                        </Box>
                      )}
                    </form>
                  </Step>
                ))}
              </Stepper>
            </Paper>
          </Box>
        </Fade>
      </Modal>
      <SaveModal
        open={saveModalOpen}
        handleClose={() => setSaveModalOpen(false)}
        handleSave={() => {
          formik.handleSubmit();
        }}
      />
    </>
  );
};

export default NewEmployeeModal;

import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import themeOptions from '../../style/componentsStyle';

const NotificationBar = ({ message }: any) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                background: themeOptions.palette.primary.main,
                color: 'white',
                padding: '8px 24px',
                borderRadius: '4px',
                margin: '8px 0'
            }}
        >
            <IconButton
                sx={{
                    color: 'goldenrod',
                    marginRight: '16px'
                }}
                size="large"
            >
                <InfoOutlinedIcon />
            </IconButton>
            <Typography variant="body2" sx={{ color: 'goldenrod' }}>
                {message}
            </Typography>
        </Box>
    );
};

export default NotificationBar;

import React, { useEffect } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  Grid,
} from "@mui/material";
import { Clear, WorkspacePremium } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addHonors, updateHonors } from "../../api/cvApi";
import { useAlert } from "../../utils/alertContext";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface HonoursModalProps {
  open: boolean;
  handleClose: () => void;
  cvId: number;
  data?: any;
  onHonoursUpdated?: () => void;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  issuer: Yup.string().required("Issuer is required"),
  issueDate: Yup.string().required("Issue date is required"),
  description: Yup.string().required("Description is required"),
});

const HonoursModal: React.FC<HonoursModalProps> = ({
  open,
  handleClose,
  cvId,
  data,
  onHonoursUpdated = () => {},
}) => {
  const { showAlert } = useAlert();

  const formik = useFormik({
    initialValues: {
      name: data ? data.name : "",
      issuer: data ? data.issuer : "",
      issueDate: data ? dayjs(data.issueDate) : "",
      description: data ? data.description : "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = {
        cvId,
        name: values.name,
        issuer: values.issuer,
        issueDate: values.issueDate
          ? dayjs(values.issueDate).format("YYYY-MM")
          : "",
        description: values.description,
      };

      const saveHonor = data ? updateHonors : addHonors;

      saveHonor({
        id: data ? data.id : 0,
        ...payload,
      })
        .then(() => {
          showAlert(`${translationsEn.cvModals.successHonor}`, "success");
          onHonoursUpdated();
          handleClose();
        })
        .catch((error) => {
          showAlert(
            error.response?.data?.error || "Error saving honor",
            "error"
          );
        });
    },
  });

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      formik.setValues({
        name: data.name || "",
        issuer: data.issuer || "",
        issueDate: data.issueDate ? dayjs(data.issueDate) : "",
        description: data.description || "",
      });
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "40%",
          }}
        >
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <WorkspacePremium style={{ marginRight: "5px" }} />
              <Typography>{translationsEn.cvModals.addHonours}</Typography>
            </Box>
            <Clear onClick={handleClose} />
          </Grid>
          <hr />
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "30px",
            }}
          >
            <TextField
              id="name"
              name="name"
              label="Name"
              variant="standard"
              required
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              style={{
                margin: "10px",
              }}
            />
            <TextField
              id="issuer"
              name="issuer"
              label="Issuer"
              variant="standard"
              required
              value={formik.values.issuer}
              onChange={formik.handleChange}
              error={formik.touched.issuer && Boolean(formik.errors.issuer)}
              style={{
                margin: "10px",
              }}
            />
            <FormControl style={{ width: "100%" }}>
              <DatePicker
                label={translationsEn.cvModals.startDate}
                views={["month", "year"]}
                value={
                  formik.values.issueDate
                    ? dayjs(formik.values.issueDate)
                    : null
                }
                onChange={(date) =>
                  formik.setFieldValue(
                    "issueDate",
                    date ? date.format("YYYY-MM") : null
                  )
                }
                slotProps={{
                  textField: {
                    helperText:
                      formik.touched.issueDate &&
                      formik.errors.issueDate &&
                      (formik.errors.issueDate as string),
                    error: Boolean(
                      formik.touched.issueDate && formik.errors.issueDate
                    ),
                  },
                }}
                sx={{ width: "100%" }}
              />
            </FormControl>
            <TextField
              id="description"
              name="description"
              label="Description"
              multiline
              rows={4}
              variant="outlined"
              required
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              style={{
                marginTop: "10px",
                width: "100%",
              }}
            />
            <hr />
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: themeOptions.palette.confirm.main,
                  ":hover": {
                    backgroundColor: themeOptions.palette.confirm.hover,
                  },
                }}
              >
                {translationsEn.buttons.save}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default HonoursModal;

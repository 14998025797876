import {
  INewEmployee,
  IEmployeeFilter,
  IPasswordReset,
} from "../interfaces/employee.interface";
import APICall from "./axios";

const toQueryString = (config: any) => {
  const query: Array<string> = [];
  const param = config.params ? config.params : config;
  Object.keys(param).forEach((key) => {
    if (
      key !== "" &&
      param[key] !== null &&
      param[key] !== undefined &&
      param[key] !== ""
    ) {
      query.push(`${key}=${encodeURIComponent(param[key])}`);
    }
  });
  return `?${query.join("&")}`;
};

export const getAllEmployees = async (
  employeeFilter: IEmployeeFilter
): Promise<any> => {
  try {
    const response = await APICall.post<any>(
      "api/employee/all",
      employeeFilter
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
  }
};

export const getFullTimeEmployees = async (
  employeeFilter: any
): Promise<any> => {
  try {
    const response = await APICall.post<any>(
      "/api/employee/full-time",
      employeeFilter
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
  }
};

export const getStatuses = async (): Promise<any> => {
  try {
    const response = await APICall.get<any>("api/employee/statuses");

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
  }
};

export const getEmploymentStatuses = async (): Promise<any> => {
  try {
    const response = await APICall.get<any>("api/employee/employment-statuses");

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
  }
};

export const getRoles = async (): Promise<any> => {
  try {
    const response = await APICall.get<any>("api/auth/roles");

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
  }
};

export const createEmployee = async (employee: INewEmployee): Promise<any> => {
  try {
    const response = await APICall.post<any>("api/auth/register", employee);
    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow to ensure the caller is aware of the error
  }
};

export const getActiveDuty = async (
  page: number,
  size: number,
  payload: { [key: string]: any }
): Promise<any> => {
  const queryString = `?page=${page}&size=${size}`;

  try {
    const response = await APICall.post<any>(
      `api/active-call-duty/all-active-call-duties${queryString}`,
      payload
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error;
  }
};

export const changeEmployeePassword = async (
  resetPassword: IPasswordReset
): Promise<any> => {
  try {
    const response = await APICall.patch<any>("api/auth/reset", resetPassword);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
  }
};

export const newEmployeePassword = async (
  resetPassword: IPasswordReset
): Promise<any> => {
  try {
    const response = await APICall.post<any>(
      "/api/auth/change-password",
      resetPassword
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
  }
};

export const forgotPasswordReset = async (payload: any) => {
  try {
    const response = await APICall.post("/api/auth/reset-password", payload);
    return response.data;
  } catch (error) {
    console.error("Failed to verify token:", error);
    throw error;
  }
};

export const verifyPasswordToken = async (token: any) => {
  try {
    const response = await APICall.post(
      "/api/auth/reset-password/verify-token",
      { token }
    );
    return response.data; // Assuming the response data includes the username
  } catch (error) {
    console.error("Failed to verify token:", error);
    throw error;
  }
};

export const setEmployeePassword = async ({
  token,
  username,
  password,
}: any) => {
  const response = await APICall.post(
    "/api/auth/reset-password/set-password",
    {
      username,
      password,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ExpandLess, KeyboardArrowDown } from "@mui/icons-material";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";
import { getAllEmployees, getTeamHistoryRequests } from "../api/requestsApi";
import moment from "moment";

export default function TeamHistoryRequest() {
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [tableData, setTableData] = useState<any>([]);
  const [allEmployees, setAllEmployees] = useState<any>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [extendedPageSize] = useState(5);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [extendedPage, setExtendedPage] = useState<{ [key: number]: number }>(
    {}
  );
  const emptyRows = tableData
    ? 5 - tableData.slice(page * pageSize, page * pageSize + pageSize)!.length
    : 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangePageExtended = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPageExtended: number,
    id: number
  ) => {
    setExtendedPage((prevPages) => ({
      ...prevPages,
      [id]: newPageExtended,
    }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  const toggleRowExpansion = (id: number) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));

    if (!expandedRows[id]) {
      setExtendedPage((prev) => ({
        ...prev,
        [id]: 0,
      }));
    }
  };

  useEffect(() => {
    const filter = { employeeId: selectedEmployee?.employeeDto?.id };

    getTeamHistoryRequests(filter)
      .then((response) => {
        setTableData(response.data);
        setLoading(false);
      })
      .catch((error) => console.error("Failed to fetch teams:", error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchClicked]);

  useEffect(() => {
    const filter = { companyIds: [] };

    getAllEmployees(filter)
      .then((response) => setAllEmployees(response.data))
      .catch((error) => console.error("Failed to fetch teams:", error));
  }, []);
  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <Typography style={{ marginLeft: "10px", fontSize: "40px" }}>
          {translationsEn.teamHistory.name}
        </Typography>
      </Paper>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
        }}
        variant="outlined"
      >
        <Box
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Grid
            style={{ display: "flex", width: "500px", alignItems: "center" }}
          >
            <FormControl
              style={{ marginTop: "10px", width: "40%", marginRight: "10px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allEmployees}
                getOptionLabel={(option: any) =>
                  option.employeeDto.firstName +
                  " " +
                  option.employeeDto.lastName
                }
                onChange={(event: any, value: string | null) => {
                  setSelectedEmployee(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={translationsEn.labels.person} />
                )}
              />
            </FormControl>

            <Button
              variant="contained"
              style={{
                marginTop: "10px",
                borderRadius: "20px",
                height: "60%",
              }}
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
              }}
              onClick={() => {
                setSearchClicked(!searchClicked);
                setPage(0);
              }}
            >
              {translationsEn.buttons.search}
            </Button>
          </Grid>
        </Box>
      </Paper>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 401 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell width={20}></TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.teamRequest.firstName}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.teamRequest.lastName}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ height: "344px" }}>
              {tableData.length === 0 && (
                <TableRow style={{ height: 344 }}>
                  <TableCell colSpan={3} align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>{translationsEn.table.noData}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {tableData
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((person: any) => (
                  <React.Fragment key={person?.employee?.id}>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Button
                          sx={{
                            backgroundColor: themeOptions.palette.primary.main,
                            color: "white",
                            ":hover": {
                              background: themeOptions.palette.primary.hover,
                            },
                          }}
                          onClick={() =>
                            toggleRowExpansion(person?.employee.id)
                          }
                        >
                          {expandedRows[person!.employee.id] ? (
                            <ExpandLess />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </Button>
                      </TableCell>
                      <TableCell align="left">
                        {person?.employee.firstName}
                      </TableCell>
                      <TableCell align="left">
                        {person?.employee.lastName}
                      </TableCell>
                    </TableRow>
                    {expandedRows[person!.employee.id] && (
                      <TableRow sx={{ height: "30px !important" }}>
                        <TableCell colSpan={3}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.id}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.type}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.status}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.author}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.created}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.dateFrom}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.dateTo}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.note}
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    {translationsEn.teamRequest.comment}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {person?.vacationDays
                                  .slice(
                                    extendedPage[person?.employee.id] *
                                      extendedPageSize,
                                    extendedPage[person?.employee.id] *
                                      extendedPageSize +
                                      extendedPageSize
                                  )
                                  .map((vacation: any) => (
                                    <TableRow key={vacation.id}>
                                      <TableCell align="left">
                                        {vacation.id}
                                      </TableCell>
                                      <TableCell align="left">
                                        {translationsEn.requestTypes[vacation.requestType.name as keyof typeof translationsEn.requestTypes]}
                                      </TableCell>
                                      <TableCell align="left">
                                        {translationsEn.statuses[vacation.status.name as keyof typeof translationsEn.statuses]}
                                      </TableCell>
                                      <TableCell align="left">
                                        {vacation.authorEmployee.firstName}
                                      </TableCell>
                                      <TableCell align="left">
                                        {moment(vacation.createDate).format('DD.MM.YYYY')}
                                      </TableCell>
                                      <TableCell align="left">
                                        {moment(vacation.dateFrom).format('DD.MM.YYYY')}
                                      </TableCell>
                                      <TableCell align="left">
                                        {moment(vacation.dateTo).format('DD.MM.YYYY')}
                                      </TableCell>
                                      <TableCell align="left">
                                        {vacation.note}
                                      </TableCell>
                                      <TableCell align="left">
                                        {vacation.comment}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                              <TableFooter>
                                <TableRow>
                                  <TablePagination
                                    rowsPerPageOptions={[5]}
                                    colSpan={9}
                                    count={person?.vacationDays?.length}
                                    rowsPerPage={5}
                                    page={
                                      extendedPage[person?.employee.id] ?? 0
                                    }
                                    onPageChange={(event, page) =>
                                      handleChangePageExtended(
                                        event,
                                        page,
                                        person?.employee.id
                                      )
                                    }
                                  />
                                </TableRow>
                              </TableFooter>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              {tableData.slice(page * pageSize, page * pageSize + pageSize)
                .length !== 0 &&
                emptyRows > 0 && (
                  <TableRow style={{ height: 68 * emptyRows }}>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          colSpan={3}
          component="div"
          count={tableData.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { loadUserPreference, saveUserPreference } from './userPreferencesUtils';

export const useUserPreference = (loggedUserId) => {
    const [userPreference, setUserPreference] = useState(loadUserPreference(loggedUserId));
    useEffect(() => {
        if (loggedUserId) {
            const prefs = loadUserPreference(loggedUserId);
            setUserPreference(prefs);
        }
    }, [loggedUserId]);

    const isPreferenceEmpty = (preference) => {
        // Example check for an empty object
        if (Object.keys(preference).length === 0) {
            return true;
        }

        // More specific checks can be added here, e.g., checking for specific keys or values
        // Example: return !preference.selectedUserIds || preference.selectedUserIds.length === 0;

        return false;
    };

    useEffect(() => {
        // Ensure loggedUserId is not null before saving
        if (loggedUserId && !isPreferenceEmpty(userPreference)) {
            saveUserPreference(loggedUserId, userPreference);
        }
    }, [loggedUserId, userPreference]);

    return [userPreference, setUserPreference];
};
import APICall from "./axios";

// Assuming the Pageable, OvertimeTableResponse, OvertimeDetail, and OvertimeApproveDetails are defined in your project

export const getAllOvertimes = async (page: number, size: number): Promise<any> => {
  try {
    const response = await APICall.post<any>(
      `/api/overtime/all?page=${page}&size=${size}`
    );
    return response.data;
  } catch (error) {
    console.error(`Error fetching overtimes`, error);
    throw error;
  }
};

export const deleteOvertime = async (overtimeId: number): Promise<void> => {
  try {
    await APICall.delete(`/api/overtime/${overtimeId}`);
  } catch (error) {
    console.error(`Error deleting overtime`, error);
    throw error;
  }
};

export const createOvertime = async (overtimeDetail: any): Promise<any> => {
  try {
    const response = await APICall.post<any>(
      "/api/overtime",
      overtimeDetail
    );
    return response.data;
  } catch (error) {
    console.error(`Error creating overtime`, error);
    throw error;
  }
};

export const updateOvertime = async (overtimeDetail: any): Promise<any> => {
  try {
    const response = await APICall.put<any>(
      "/api/overtime",
      overtimeDetail
    );
    return response.data;
  } catch (error) {
    console.error(`Error updating overtime`, error);
    throw error;
  }
};

export const setStatusOvertime = async (
  overtimeId: number,
  overtimeStatusDetails: any
): Promise<unknown> => {
  try {
    const response = await APICall.patch<unknown>(
      `/api/overtime/${overtimeId}`,
      overtimeStatusDetails
    );
    return response.data;
  } catch (error) {
    console.error(`Error approving overtime`, error);
    throw error;
  }
};

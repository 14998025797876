import { ITeamsFilter, NewTeam } from "../interfaces/teams.interface";
import APICall from "./axios";

export const getAllTeams = async (teamsFilter: ITeamsFilter): Promise<any> => {
  try {
    const response = await APICall.post<any>("api/team/all-teams", teamsFilter);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const getVacationDayTeams = async (
  teamsVacationFilter: number[]
): Promise<any> => {
  try {
    const response = await APICall.post<any>(
      "api/vacation-days/teams",
      teamsVacationFilter
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const getVacationDaysPerEmployee = async (
  employeeId: number
): Promise<any> => {
  try {
    const response = await APICall.get<any>(
      `api/requests/employees/${employeeId}/2024`
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const deleteEmployeeApi = async (
  employeeId: number,
  teamId: number
): Promise<any> => {
  try {
    const response = await APICall.delete<any>(
      `api/team/${teamId}/${employeeId}`
    );
    if (response.data) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const deleteTeamApi = async (teamId: number): Promise<any> => {
  try {
    const response = await APICall.delete<any>(`api/team/${teamId}`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Employee data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const createTeam = async ({
  active,
  companyDtos,
  companyIds,
  name,
  id,
}: {
  active: boolean;
  companyDtos: NewTeam[];
  companyIds: number[];
  name: string;
  id: number | null;
}): Promise<any> => {
  try {
    const payload = {
      active: active,
      companyDtos: companyDtos,
      companyIds: companyIds,
      name: name,
      id: id,
    };

    const response = await APICall.post<any>("api/team", payload);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Team data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error creating team`, error);
    throw error;
  }
};

export const editTeam = async ({
  active,
  companyDtos,
  companyIds,
  name,
  id,
}: {
  active: boolean;
  companyDtos: NewTeam[];
  companyIds: number[];
  name: string;
  id: number | null;
}): Promise<any> => {
  try {
    const payload = {
      active: active,
      companyDtos: companyDtos,
      companyIds: companyIds,
      name: name,
      id: id,
    };

    const response = await APICall.patch<any>(`api/team/${id}`, payload);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Team data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error creating team`, error);
    throw error;
  }
};

export const addMemberToTeam = async ({
  active,
  createdDate,
  employeeId,
  employeeTypeId,
  teamId,
}: {
  active: boolean;
  createdDate: Date;
  employeeId: number;
  employeeTypeId: number;
  teamId: number;
}): Promise<any> => {
  try {
    const payload = {
      active: active,
      createdDate: createdDate,
      employeeId: employeeId,
      employeeTypeId: employeeTypeId,
      teamId: teamId,
    };

    const response = await APICall.post<any>("api/team/employee", payload);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Team data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error creating team`, error);
    throw error;
  }
};

import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { Key } from "@mui/icons-material";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";
import NewEmployeeModal from "../modals/employeeManagement/newEmployeeModal";
import ResetPassword from "../modals/employeeManagement/resetPassword";
import { getAllEmployees } from "../api/requestsApi";
import { getEmploymentStatuses } from "../api/employeeApi";
import { Link } from "react-router-dom";
import moment from "moment";

export default function Employees() {
  const [modalStates, setModalStates] = useState({
    newEmployee: false,
    resetPassword: false,
  });
  const [passwordResetUser, setPasswordResetUser] = React.useState<string>("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [statuses, setStatuses] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const emptyRows = tableData ? pageSize - tableData!.length : 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchEmployees = () => {
    setLoading(true);
    const filter = { companyIds: [], employmentStatusId: selectedStatus };

    getAllEmployees(filter)
      .then((response) => {
        setPage(0);

        let filteredData = response.data.filter((prop: any) =>
          prop.employeeDto.firstName
            .toLowerCase()
            .includes(firstName.toLowerCase())
        );

        filteredData = filteredData.filter((prop: any) =>
          prop.employeeDto.lastName
            .toLowerCase()
            .includes(lastName.toLowerCase())
        );

        setTableData(filteredData);
        setLoading(false);
      })
      .catch((error) => console.error("Failed to fetch teams:", error));
  };

  useEffect(() => {
    fetchEmployees();
  }, [searchClicked]);

  useEffect(() => {
    getEmploymentStatuses()
      .then((response) => {
        setStatuses(response.data);
      })
      .catch((error) => console.error("Failed to fetch teams:", error));
  }, []);

  const handleOpenModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: true,
    }));
  };

  const handleCloseModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: false,
    }));
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          padding: "10px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Paper
          style={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
          variant="outlined"
        >
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={{
                marginLeft: "10px",
                fontSize: "40px",
              }}
            >
              {translationsEn.employees.name}
            </Typography>
            <Button
              variant="contained"
              style={{
                margin: "10px",
                borderRadius: "20px",
                height: "50%",
              }}
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
              }}
              onClick={() => handleOpenModal("newEmployee")}
            >
              Create
            </Button>
          </Grid>
        </Paper>

        <Paper
          style={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            margin: "10px",
          }}
        >
          <Box
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <TextField
                label="First name"
                id="outlined-size-small"
                size="small"
                style={{ marginRight: "5px" }}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                label="Last name"
                id="outlined-size-small"
                size="small"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Box>
            <FormControl style={{ marginTop: "10px", width: "28%" }}>
              <Autocomplete
                sx={{ width: "440px" }}
                disablePortal
                id="combo-box-demo"
                options={statuses}
                getOptionLabel={(option: any) => option.status}
                onChange={(event: any, value: any) => {
                  setSelectedStatus(value?.id);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={"Status"} size="small" />
                )}
              />
            </FormControl>

            <Button
              variant="contained"
              style={{
                marginTop: "10px",
                borderRadius: "20px",
              }}
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
              }}
              onClick={() => {
                setSearchClicked(!searchClicked);
              }}
            >
              Search
            </Button>
          </Box>
        </Paper>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 401 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    {translationsEn.table.firstName}
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    {translationsEn.table.lastName}
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    {translationsEn.table.dateOfBirth}
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    {translationsEn.table.company}
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    {translationsEn.table.oib}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ height: "344px" }}>
                {tableData.length === 0 && (
                  <TableRow style={{ height: 344 }}>
                    <TableCell colSpan={7} align="center">
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <>{translationsEn.table.noData}</>
                      )}
                    </TableCell>
                  </TableRow>
                )}
                {tableData
                  .slice(page * pageSize, page * pageSize + pageSize)
                  .map((person: any) => (
                    <TableRow
                      key={person.employeeDto.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">
                        {person.employeeDto.firstName}
                      </TableCell>
                      <TableCell align="left">
                        {person.employeeDto.lastName}
                      </TableCell>
                      <TableCell align="left">
                        {moment(person.employeeDto.dateOfBirth).format(
                          "DD.MM.YYYY"
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {person.employeeDto.companyDto.name}
                      </TableCell>
                      <TableCell align="left">
                        {person.employeeDto.oib}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          onClick={() => (
                            // eslint-disable-next-line no-sequences
                            handleOpenModal("resetPassword"),
                            setPasswordResetUser(person.employeeDto.username)
                          )}
                          sx={{
                            backgroundColor: themeOptions.palette.primary.main,
                            color: "white",
                            ":hover": {
                              background: themeOptions.palette.primary.hover,
                            },
                          }}
                        >
                          <Key />
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Link to={`/editUser/${person.employeeDto.id}`}>
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor:
                                themeOptions.palette.primary.main,
                              color: "white",
                              ":hover": {
                                background: themeOptions.palette.primary.hover,
                              },
                              maxHeight: "36px",
                            }}
                          >
                            Edit
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                {tableData.slice(page * pageSize, page * pageSize + pageSize)
                  .length !== 0 &&
                  emptyRows > 0 && (
                    <TableRow style={{ height: 68 * emptyRows }}>
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={7}
            component="div"
            count={tableData.length}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <NewEmployeeModal
          open={modalStates.newEmployee}
          handleClose={() => handleCloseModal("newEmployee")}
          refetchEmployees={fetchEmployees}
        />
        <ResetPassword
          open={modalStates.resetPassword}
          handleClose={() => handleCloseModal("resetPassword")}
          user={passwordResetUser}
        />
      </div>
    </>
  );
}

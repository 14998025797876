import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Fade,
  Box,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import translationsEn from "../../i18n/translations_en";
import { IMember } from "../../interfaces/teams.interface";
import themeOptions from "../../style/componentsStyle";
import { addMemberToTeam } from "../../api/teamsApi";
import SavedModal from "../confirmationsModal/saveModalMessage";
import moment from "moment";

interface addMemberProps {
  open: boolean;
  handleClose: () => void;
  data?: IMember;
  employeeId?: number | null;
  children?: React.ReactNode;
  handleMemberAdded: () => void;
}

const AddMemberModal: React.FC<addMemberProps> = ({
  open,
  handleClose,
  data,
  employeeId,
  handleMemberAdded,
}) => {
  const handleModalClose = () => {
    handleClose();
  };

  const [selectTeam, setSelectTeam] = useState<any>();
  const [activeTeam, setActiveTeam] = useState(false);
  const [savedModalOpen, setSavedModalOpen] = useState(false);

  useEffect(() => {
    if (data?.teamDto) {
      setActiveTeam(data.teamDto.active || false);
      setSelectTeam(data.teamDto.id);
    }
  }, [data?.teamDto]);

  const handleAddMember = (employeeTypeId: number) => {
    const payload = {
      active: activeTeam,
      createdDate: moment().toDate(),
      employeeId: employeeId || 0,
      employeeTypeId: employeeTypeId,
      teamId: selectTeam,
    };

    addMemberToTeam(payload)
      .then(() => {
        handleMemberAdded();
        setSavedModalOpen(true);
      })
      .finally(() => handleModalClose())
      .catch((error) => console.error("Failed to fetch teams:", error));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => handleModalClose()}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open}>
          <Paper
            style={{
              padding: "20px",
              textAlign: "center",
              width: "20%",
              height: "20%",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {translationsEn.teamsInformation.chooseRole}
                </Typography>
              </Box>

              <Clear onClick={() => handleModalClose()} />
            </Box>
            <hr></hr>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                padding: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.primary.main,
                  color: "white",
                  ":hover": {
                    background: themeOptions.palette.primary.hover,
                  },
                }}
                onClick={() => handleAddMember(1)}
              >
                {translationsEn.buttons.member}
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.primary.main,
                  color: "white",
                  ":hover": {
                    background: themeOptions.palette.primary.hover,
                  },
                }}
                onClick={() => handleAddMember(2)}
              >
                {translationsEn.buttons.approve}
              </Button>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
      <SavedModal
        open={savedModalOpen}
        handleClose={() => setSavedModalOpen(false)}
      />
    </>
  );
};

export default AddMemberModal;

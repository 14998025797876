import dayjs from "dayjs";
import translationsEn from "../i18n/translations_en";

type RequestTypeName =
  | "vacation"
  | "blood_donation"
  | "parental_leave"
  | "plan"
  | "sick_leave"
  | "student_absence"
  | "paid_leave";

export const transformDataToResources = (data: any) => {
  return data.map((employee: any) => ({
    id: employee.employee.id.toString(),
    title: `${employee.employee.firstName} ${employee.employee.lastName}`,
  }));
};

export const transformDataToEvents = (data: any, holidays: any) => {
  const safeHolidays = Array.isArray(holidays) ? holidays : [];
  const vacationEvents = data.flatMap(({ employee, vacationDays }: any) =>
    (vacationDays || []).map((vacation: any) => ({
      id: vacation.id.toString(),
      note: vacation.note,
      createDate: vacation.createDate,
      resourceId: employee.id.toString(),
      requestType: vacation.requestType,
      title:
        translationsEn.requestTypes[vacation.requestType.name as RequestTypeName] || vacation.requestType.name,
      status: vacation.status,
      updateDate: vacation.updateDate,
      updatedBy: vacation.updatedBy,
      authorEmployee: vacation.authorEmployee,
      start: vacation.dateFrom,
      end: dayjs(vacation.dateTo).add(1, 'day').format('YYYY-MM-DD'),
      originalEnd: vacation.dateTo,
      color: vacation.status.name === "approved" ? "green" : "orange",
    }))
  );

  const holidayEvents = safeHolidays.map((holiday: any) => ({
    title: holiday.name,
    status: 'holiday',
    start: holiday.date,
    end: dayjs(holiday.date).add(1, 'day').format('YYYY-MM-DD'),
    allDay: true,
    display: "background",
    backgroundColor: "red",
  }));

  return [...vacationEvents, ...holidayEvents];
};
